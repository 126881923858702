import { Paper, useTheme } from '@mui/material';
import { ResponsiveBar } from '@nivo/bar';
import { format } from '../../../helpers/format';

export type BarChartData = {
  label: string;
  value: number;
};

type BarChartProps = {
  data: BarChartData[];
  color?: string;
};

export const BarChart = ({ data, color }: BarChartProps) => {
  const theme = useTheme();
  return (
    <ResponsiveBar
      data={data}
      indexBy="label"
      margin={{ top: 0, right: 0, bottom: 35, left: 0 }}
      padding={0.3}
      valueScale={{ type: 'linear' }}
      indexScale={{ type: 'band', round: true }}
      colors={color ?? { scheme: 'nivo' }}
      axisTop={null}
      axisRight={null}
      axisBottom={{
        tickSize: 0,
        tickPadding: 5,
        tickRotation: -90,
        legendOffset: 0,
        truncateTickAt: 0,
      }}
      enableLabel={false}
      enableGridY={true}
      enableGridX={false}
      gridYValues={[0]}
      role="application"
      tooltip={({ value }) => (
        <Paper sx={{ padding: 1 }}>{format(value, 'money')}</Paper>
      )}
      theme={{
        axis: {
          ticks: {
            text: { fill: theme.palette.text.secondary },
            line: { strokeWidth: 1 },
          },
        },
        grid: {
          line: {
            strokeWidth: '0.7px',
          },
        },
        tooltip: {
          container: {
            background: theme.palette.background.default,
            color: theme.palette.text.secondary,
          },
        },
      }}
    />
  );
};
