import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import CheckIcon from '@mui/icons-material/Check';
import EditIcon from '@mui/icons-material/Edit';
import LoadingButton from '@mui/lab/LoadingButton/LoadingButton';
import {
  Alert,
  Box,
  Card,
  Chip,
  CircularProgress,
  InputAdornment,
  Link,
  Stack,
  Typography,
} from '@mui/material';
import Button from '@mui/material/Button';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import dayjs from 'dayjs';
import _ from 'lodash';
import { Constants } from 'pr-constants';
import { MouseEvent, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { Models } from 'types';
import FilterForm from '../../../components/FilterForm/FilterForm';
import { PageHeader } from '../../../components/PageHeader/PageHeader';
import { RadioGroupFieldOption } from '../../../components/RadioGroupField/RadioGroupField';
import ResponsiveDialog from '../../../components/ResponsiveDialog/ResponsiveDialog';
import Table from '../../../components/Table/Table';
import { TableColumn } from '../../../components/Table/table.types';
import { MaskedInputWithRef } from '../../../components/TextField/CurrencyMaskedInput';
import { TextFieldWithControl } from '../../../components/TextField/TextFieldWithControl';
import {
  BalanceItem,
  CalcBalanceOpts,
  calcBalance,
} from '../../../helpers/calc-balance';
import { priceMask } from '../../../helpers/create-number-mask';
import { getFirestoreConsoleLink } from '../../../helpers/firebase';
import { format } from '../../../helpers/format';
import { useAppDispatch, useAppSelector } from '../../../helpers/hooks';
import { dayjsBusinessTime } from '../../../helpers/operating-hours';
import {
  parseMaskedInput,
  safeParseMaskedInput,
} from '../../../helpers/safe-parse';
import useCompanyStatus from '../../../hooks/useCompanyStatus';
import useUserGrant from '../../../hooks/useUserGrant';
import { AgendasFilterForm } from '../../../types';
import CompanyInfo from '../components/CompanyInfo';
import PaymentRequestFormInfo from '../components/PaymentRequestFormInfo';
import { supplierActions } from '../supplier.slice';
import {
  AddPaymentForm,
  AddPaymentFormDetail,
  AddPaymentSubForm,
  ListaAgenda,
} from '../supplier.types';
import NovoPagamento from './NovoPagamento';
import { calcFirstAvailableDate, useFirstAvailableDate } from './payment-hooks';

function FornecedorAgendas() {
  const [params] = useSearchParams();
  const { idRetailer } = useParams();
  const startDate = params.get('s');
  const endDate = params.get('e');
  const externalIdParam = params?.get('externalId');
  const originalValueParam = params?.get('originalValue');
  const paymentDueDateParam = params?.get('paymentDueDate');
  const effectTypeParam = params?.get('effectType');

  let navigate = useNavigate();
  const dispatch = useAppDispatch();
  const {
    paymentFormData,
    isLoadingReceivables,
    retailerReceivables,
    selectedRetailer,
    retailerCalcSuggestedValues,
    isRunningCalcSuggestedValues,
  } = useAppSelector((state) => state.supplier);
  const [isFormOpen, setIsFormOpen] = useState(externalIdParam !== null);
  const [isNewPaymentFormOpen, setIsNewPaymentFormOpen] = useState(false);
  const [balance, setBalance] = useState<BalanceItem[]>([]);
  const [rollingBalance, setRollingBalance] = useState<number | undefined>(
    undefined
  );

  const { isOperatingHours } =
    useAppSelector((state) => state.app.operatingHours) ?? {};

  const { isDebugActivated } = useAppSelector((state) => state.devTools);
  const { supplierWarrantyConfig, supplierOwnershipAssignmentConfig } =
    useAppSelector((state) => state.settings);

  const showCreatePayment = useUserGrant(['supplier-payment-create']);

  const effectTypes: RadioGroupFieldOption<Models.PaymentRequestEffectType>[] =
    [];
  if (supplierWarrantyConfig?.enabled)
    effectTypes.push({
      label: 'Garantia',
      value: 'garantia',
    });
  if (supplierOwnershipAssignmentConfig?.enabled)
    effectTypes.push({
      label: 'Troca de Titularidade',
      value: 'transferencia',
    });

  const defaultValues: AddPaymentForm = {
    externalId: paymentFormData?.externalId ?? externalIdParam ?? '',
    effectType: effectTypeParam
      ? (effectTypeParam as Models.PaymentRequestEffectType)
      : effectTypes[0]?.value ?? 'garantia',
    paymentDueDate: paymentDueDateParam
      ? dayjs(paymentDueDateParam).format('YYYY-MM-DD')
      : dayjsBusinessTime().format('YYYY-MM-DD'),
    originalValue: format(originalValueParam, 'number') ?? '',
    totalValue: 0,
    requireRetailerApproval: true,
    details: retailerReceivables.reduce((acc, cur) => {
      let value: string = paymentFormData
        ? paymentFormData.details[cur.id]?.value
        : '';
      if (retailerCalcSuggestedValues) {
        const suggestedValue = retailerCalcSuggestedValues.find(
          (v) => v.idReceivable === cur.id
        )?.suggestedValue;
        if (suggestedValue)
          value =
            suggestedValue > 0
              ? suggestedValue.toString().replaceAll('.', ',')
              : '';
      }

      acc[cur.id] = {
        idReceivable: cur.id,
        value,
        incomingDate: cur.incomingDate,
        debtor: cur.debtor,
        paymentScheme: cur.paymentScheme,
        availableValue: cur.value,
      };
      return acc;
    }, {} as Record<string, AddPaymentFormDetail>),
  };

  const {
    handleSubmit,
    formState: { errors },
    control,
    watch,
    reset,
    setError,
    clearErrors,
    setValue,
  } = useForm<AddPaymentForm>({
    mode: 'onChange',
    defaultValues,
  });
  const values = watch('details');
  const effectType = watch('effectType');
  const externalId = watch('externalId');
  const paymentDueDate = watch('paymentDueDate');
  const strOriginalValue = watch('originalValue');
  const originalValue = safeParseMaskedInput(strOriginalValue);

  const subFormData: AddPaymentSubForm = {
    effectType,
    externalId,
    originalValue: strOriginalValue,
    paymentDueDate,
    idRetailer: selectedRetailer?.id ?? '',
  };

  const resetForm = () => {
    reset(defaultValues);
  };
  useEffect(() => {
    resetForm();
    dispatch(supplierActions.clearPaymentFormData());
  }, [retailerReceivables]);

  const defaultStartDate = dayjs();
  const defaultEndDate = dayjs().add(1, 'month');
  const dateExpression = params.get('x') ?? undefined;
  const queryParams: AgendasFilterForm = {
    startDate: startDate ?? defaultStartDate.format('YYYYMMDD'),
    endDate: endDate ?? defaultEndDate.format('YYYYMMDD'),
  };

  const currentDateRangeLabel =
    queryParams.startDate !== queryParams.endDate
      ? `${dayjs(queryParams.startDate).format('DD/MM/YYYY')} a ${dayjs(
          queryParams.endDate
        ).format('DD/MM/YYYY')}`
      : dayjs(queryParams.startDate).format('DD/MM/YYYY');
  const currentDateRangeFilter = `Data: ${currentDateRangeLabel}`;

  const navigateWithFilter = (
    destination: string,
    { dateExpression, startDate, endDate }: AgendasFilterForm
  ) => {
    const dateFilter = `?s=${startDate}&e=${endDate}`;
    const dateExpressionFilter = dateExpression ? `&x=${dateExpression}` : '';
    navigate(`${destination}${dateFilter}${dateExpressionFilter}`);
  };

  const handleFilter = (data: AgendasFilterForm) => {
    navigateWithFilter('.', {
      dateExpression:
        data.dateExpression as Models.LoadPaymentRequestsForSupplierRequestDateExpression,
      startDate: dayjs(data.startDate).format('YYYYMMDD'),
      endDate: dayjs(data.endDate).format('YYYYMMDD'),
    });
  };

  const handleResetFilter = () => {
    navigateWithFilter('.', {
      startDate: queryParams.startDate,
      endDate: queryParams.endDate,
      dateExpression,
    });
  };

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handleFilterChipClick = (event: MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleFilterClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (idRetailer) {
      dispatch(
        supplierActions.loadReceivables({
          startDate: startDate ?? defaultStartDate.format('YYYYMMDD'),
          endDate: endDate ?? defaultEndDate.format('YYYYMMDD'),
          idRetailer,
        })
      );
    }
  }, [idRetailer]);
  // useEffect(() => {
  //   resetField('details');
  // }, [effectType]);

  const requireRetailerApproval =
    (effectType === 'garantia'
      ? supplierWarrantyConfig?.requireRetailerApproval
      : supplierOwnershipAssignmentConfig?.requireRetailerApproval) ?? true;

  const fine =
    (effectType === 'garantia'
      ? supplierWarrantyConfig?.fine
      : supplierOwnershipAssignmentConfig?.fine) ?? 0;

  const monthlyInterest =
    (effectType === 'garantia'
      ? supplierWarrantyConfig?.interestRate
      : supplierOwnershipAssignmentConfig?.interestRate) ?? 0;

  const firstAvailableDate = useFirstAvailableDate(effectType, paymentDueDate);

  const columns: TableColumn<ListaAgenda>[] = [
    {
      id: 'idRecebivel',
      label: 'idRecebivel',
      hidden: !isDebugActivated,
      renderer: (v) => (
        <Link
          target="_blank"
          rel="noopener noreferrer"
          variant="caption"
          color="textSecondary"
          href={getFirestoreConsoleLink(`/receivables/${v}`)}
        >
          {v}
        </Link>
      ),
    },
    {
      id: 'dataRecebimento',
      label: 'Data Recebimento',
      type: 'date',
      nowrap: true,
      formatter: (v) =>
        `${format(v, 'date')} (${format(v, 'dayOfWeek')})${
          dayjsBusinessTime(v).isHoliday() ? ' - Feriado' : ''
        }`,
    },
    { id: 'credenciadora', label: 'Credenciadora' },
    { id: 'bandeira', label: 'Bandeira do Cartão' },
    {
      id: 'ultimaAtualizacao',
      label: 'Última Atualização',
      type: 'dateTime',
    },
    {
      id: 'statusPagamento',
      label: 'Status',
    },
    {
      id: 'valor',
      label: 'Valor Disponível',
      type: 'money',
      nowrap: true,
    },
    {
      id: 'valorReceberInput',
      label: 'Valor a Receber',
      hidden: !isFormOpen,
      center: true,
      renderer: (v, row) => {
        let disabled = false;
        if (row.statusPagamento !== 'Disponível') disabled = true;
        if (row.valor === 0) disabled = true;
        if (
          dayjsBusinessTime(row.dataRecebimento)
            .startOf('day')
            .isBefore(firstAvailableDate)
        )
          disabled = true;

        if (disabled) return <>-</>;
        if (isRunningCalcSuggestedValues)
          return (
            <>
              <CircularProgress size="1em" />
            </>
          );
        return (
          <TextFieldWithControl
            id={`details.${row.idRecebivel}.value`}
            type="text"
            // label={`details.${row.idRecebivel}.value`}
            name={`details.${row.idRecebivel}.value`}
            control={control}
            disabled={disabled || isRunningCalcSuggestedValues}
            sx={{
              '& input': {
                padding: '0px 0 0px',
                width: '80px',
              },
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">R$</InputAdornment>
              ),
              inputComponent: MaskedInputWithRef as any,
            }}
            inputProps={{
              mask: priceMask,
            }}
            rules={{
              validate: (v) => {
                if (v === undefined || v === '') return true;
                const val = parseMaskedInput(v);
                if (isNaN(val)) return 'Valor inválido';
                if (val > row.valor) return 'Valor maior que o disponível';
                return true;
              },
            }}
          />
        );
      },
    },
    {
      id: 'saldo',
      label: 'Saldo',
      type: 'money',
      nowrap: true,
      hidden: !isFormOpen,
    },
  ];

  const debouncedCalcBalance = useMemo(
    () =>
      _.debounce((opts: CalcBalanceOpts) => {
        const { balance, rollingBalance } = calcBalance(opts);
        setBalance(balance);
        setRollingBalance(rollingBalance);
      }, 500),
    [values, effectType, originalValue, paymentDueDate]
  );
  const sum = Object.values(values)
    .map(({ value }) => value)
    .reduce((acc, cur) => {
      if (!acc) acc = 0;
      acc += safeParseMaskedInput(cur);
      return acc;
    }, 0);

  useEffect(() => {
    clearErrors('totalValue');
  }, [sum]);

  const onConfirm = (data: AddPaymentForm) => {
    if (!selectedRetailer) throw new Error('Unexpected error');
    const filteredData = {
      ...data,
      details: Object.entries(data.details).reduce((acc, [key, detail]) => {
        if (safeParseMaskedInput(detail.value) > 0) acc[key] = detail;
        return acc;
      }, {} as Record<string, AddPaymentFormDetail>),
    };
    const totalValue = Object.values(filteredData.details)
      .map(({ value }) => value)
      .reduce((acc, cur) => {
        if (!acc) acc = 0;
        acc += safeParseMaskedInput(cur);
        return acc;
      }, 0);
    if (totalValue === 0) {
      setError('totalValue', {
        message: 'O valor total precisa ser maior do que zero',
      });
      return;
    }

    if (rollingBalance !== 0) {
      setError('totalValue', {
        message:
          'O valor total precisa ser igual ao valor futuro com juros e multa',
      });
      return;
    }
    dispatch(
      supplierActions.setPaymentFormData({
        ...filteredData,
        totalValue: totalValue ?? 0,
        requireRetailerApproval,
      })
    );
    resetForm();
    navigate('confirma-pagamento');
  };

  const enhancedData: ListaAgenda[] = retailerReceivables
    .filter(
      (d) =>
        !isFormOpen ||
        (isFormOpen && !dayjs(d.incomingDate).isBefore(firstAvailableDate))
    )
    .map((d, rowIndex) => {
      return {
        rowIndex,
        idRecebivel: d.id,
        dataRecebimento: d.incomingDate,
        valor: d.value,
        credenciadora: Constants.getDebtor(d.debtor),
        bandeira: Constants.getPaymentScheme(d.paymentScheme) ?? '-',
        ultimaAtualizacao: d.registrarLastUpdated,
        statusPagamento: d.idPendingPaymentRequest
          ? 'Em processamento'
          : 'Disponível',
        visualizacao: '',
        valorReceberInput: 0,
        saldo:
          balance.find(
            ({
              incomingDate: incomingDateToFind,
              idReceivable: idReceivableToFind,
            }) =>
              incomingDateToFind === d.incomingDate &&
              idReceivableToFind === d.id
          )?.balance ?? 0,
        debtor: d.debtor,
        paymentScheme: d.paymentScheme,
        valorSugerido:
          retailerCalcSuggestedValues?.find((v) => v.idReceivable === d.id)
            ?.suggestedValue ?? 0,
      };
    });

  const sumAvailable = retailerReceivables
    .map(({ value }) => value)
    .reduce((acc, cur) => {
      acc += cur;
      return acc;
    }, 0);

  const { supplierSettingsOk } = useCompanyStatus();

  const canCreatePaymentRequest = isOperatingHours && supplierSettingsOk;

  const handleNewPaymentClick = () => {
    setIsFormOpen(true);
    setIsNewPaymentFormOpen(true);
  };

  const handleSubFormSave = (data: AddPaymentSubForm) => {
    resetForm();
    setValue('effectType', data.effectType);
    setValue('externalId', data.externalId);
    setValue('originalValue', data.originalValue);
    setValue('paymentDueDate', data.paymentDueDate);

    if (idRetailer) {
      dispatch(
        supplierActions.calcSuggestedValues({
          opts: {
            idRetailer,
            externalId: data.externalId,
            effectType: data.effectType,
            originalValue: safeParseMaskedInput(data.originalValue),
            paymentDueDate: data.paymentDueDate,
          },
          navigate,
        })
      );
    }
    setIsNewPaymentFormOpen(false);

    const newStartDate = calcFirstAvailableDate(
      supplierWarrantyConfig,
      supplierOwnershipAssignmentConfig,
      data.effectType,
      data.paymentDueDate
    );
    const endDate = dayjs(startDate).add(1, 'month').format('YYYYMMDD');

    navigate(
      `/clientes/${data.idRetailer}/agenda?s=${newStartDate.format(
        'YYYYMMDD'
      )}&e=${endDate}&externalId=${data.externalId}&originalValue=${
        data.originalValue
      }&paymentDueDate=${data.paymentDueDate}&effectType=${data.effectType}`
    );
  };

  useEffect(() => {
    debouncedCalcBalance({
      effectType,
      fine,
      originalValue,
      values,
      paymentDueDate,
      monthlyInterest,
      firstAvailableDate: firstAvailableDate.format('YYYYMMDD'),
    });
  }, [
    sum,
    values,
    effectType,
    originalValue,
    paymentDueDate,
    retailerCalcSuggestedValues,
  ]);

  const sumSuggested = (retailerCalcSuggestedValues ?? []).reduce(
    (acc, cur) => {
      acc += cur.runningSum;
      return acc;
    },
    0
  );

  useEffect(() => {
    if (retailerCalcSuggestedValues) {
      retailerCalcSuggestedValues.forEach((v) => {
        setValue(
          `details.${v.idReceivable}.value`,
          v.suggestedValue > 0
            ? v.suggestedValue.toString().replaceAll('.', ',')
            : ''
        );
      });
    }
  }, [sumSuggested]);

  const handleSubFormClose = () => {
    setIsNewPaymentFormOpen(false);
    // if (!isDirty) handleCancelClick();
  };

  const handleCancelClick = () => {
    resetForm();
    setIsFormOpen(false);
  };

  return (
    <>
      {!supplierOwnershipAssignmentConfig?.enabled &&
        !supplierWarrantyConfig?.enabled && (
          <Box marginBottom={2}>
            <Alert variant="outlined" severity="error">
              Atenção! É necessário ativar pelo menos um tipo de operação.
              Verifique nas configurações.
            </Alert>
          </Box>
        )}
      {isFormOpen ? (
        <>
          <Grid2 container paddingBottom={2}>
            <Grid2 xs={12}>
              <CompanyInfo />
            </Grid2>
          </Grid2>
          <form
            onSubmit={handleSubmit(onConfirm)}
            style={{
              display: 'flex',
              flexDirection: 'column',
              height: '0px',
              flexGrow: 1,
            }}
          >
            <Table<ListaAgenda>
              columns={columns}
              data={enhancedData}
              isLoading={isLoadingReceivables}
              dense
            />
            <Stack paddingTop={2} gap={2}>
              {Object.keys(errors).length > 0 && errors.totalValue && (
                <Alert severity="error">{errors.totalValue.message}</Alert>
              )}
              {Object.keys(errors).length > 0 && !errors.totalValue && (
                <Alert severity="error">Corrija os erros para prosseguir</Alert>
              )}
              <Card variant="outlined">
                <Box p={1}>
                  <Stack direction="row" alignItems="center" gap={2} width={1}>
                    <PaymentRequestFormInfo
                      effectType={effectType}
                      externalId={externalId}
                      sum={sum ?? 0}
                      originalValue={originalValue ?? 0}
                      paymentDueDate={paymentDueDate}
                    />
                    <Box flexGrow={1} />
                    <Stack direction="row" alignItems="center" gap={1}>
                      <Button variant="text" onClick={handleCancelClick}>
                        Cancelar
                      </Button>
                      <Button
                        variant="text"
                        onClick={() => setIsNewPaymentFormOpen(true)}
                        startIcon={<EditIcon />}
                      >
                        Alterar
                      </Button>
                      <LoadingButton
                        onClick={handleSubmit(onConfirm)}
                        type="submit"
                        loadingPosition="start"
                        startIcon={<CheckIcon />}
                        variant="contained"
                        disabled={!isOperatingHours}
                      >
                        Confirmar
                      </LoadingButton>
                    </Stack>
                  </Stack>
                </Box>
              </Card>
            </Stack>
          </form>
        </>
      ) : (
        <>
          <PageHeader
            title="Agenda"
            actions={
              <Stack direction="row" gap={2} alignItems="center">
                <Chip
                  label={currentDateRangeFilter}
                  onClick={handleFilterChipClick}
                />
                <FilterForm<AgendasFilterForm>
                  onFilter={handleFilter}
                  onClear={handleResetFilter}
                  onClose={handleFilterClose}
                  anchorEl={anchorEl}
                  appliedFilterCount={0}
                  defaultValues={{
                    startDate: queryParams.startDate,
                    endDate: queryParams.endDate,
                    dateExpression: dateExpression,
                  }}
                  fields={[
                    {
                      type: 'date-range',
                      name: 'dateType',
                      startName: 'startDate',
                      endName: 'endDate',
                      formulaName: 'dateExpression',
                      label: 'Data',
                      disabledExpressions: [
                        'currentMonth',
                        'last30days',
                        'yesterday',
                      ],
                    },
                  ]}
                />
                {showCreatePayment && (
                  <LoadingButton
                    disabled={!canCreatePaymentRequest}
                    onClick={handleNewPaymentClick}
                    type="submit"
                    loading={isLoadingReceivables}
                    loadingPosition="start"
                    startIcon={<AttachMoneyIcon />}
                    variant="contained"
                  >
                    Pagamento
                  </LoadingButton>
                )}
              </Stack>
            }
          />
          <Grid2 container paddingBottom={2}>
            <Grid2 xs={12}>
              <CompanyInfo />
            </Grid2>
          </Grid2>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              height: '0px',
              flexGrow: 1,
            }}
          >
            <Table<ListaAgenda>
              columns={columns}
              data={enhancedData}
              isLoading={isLoadingReceivables}
              dense
            />
          </Box>
          <Box paddingTop={2}>
            <Card variant="outlined">
              <Stack
                p={1}
                direction="row"
                alignItems="center"
                gap={1}
                justifyContent="center"
              >
                <Typography variant="body2" color="text.secondary">
                  Valor disponível ({currentDateRangeLabel}):
                </Typography>
                <Typography variant="h6">
                  <b>{format(sumAvailable, 'money')}</b>
                </Typography>
              </Stack>
            </Card>
          </Box>
        </>
      )}
      <ResponsiveDialog open={isNewPaymentFormOpen} maxWidth="xs" fullWidth>
        <NovoPagamento
          onCloseClick={handleSubFormClose}
          onSaveClick={handleSubFormSave}
          paymentFormData={subFormData}
        />
      </ResponsiveDialog>
    </>
  );
}

export default FornecedorAgendas;
