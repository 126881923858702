import { Models, Service } from 'types';
import { apiCall } from '../helpers/api';

type T = Models.Receivable;
const service: Service = 'supplier';
const resource = 'web/retailer-receivable';

export class WebRetailerReceivableHelpers {
  public static list = async (
    startDate: string,
    endDate: string
  ): Promise<T[]> => {
    const dateFilter = `?s=${startDate}&e=${endDate}`;
    return await apiCall<T[]>({
      service,
      resource: `${resource}${dateFilter}`,
    });
  };
}
