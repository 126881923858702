import { Alert, Box, BoxProps } from '@mui/material';
import { useAppSelector } from '../../helpers/hooks';

export default function EditForm({ children, ...otherProps }: BoxProps) {
  const { isOperatingHours, outsideOperatingHoursText } =
    useAppSelector((state) => state.app.operatingHours) ?? {};

  return (
    <Box
      width={1}
      height={1}
      p={2}
      display="flex"
      flexDirection="column"
      {...otherProps}
    >
      {isOperatingHours !== undefined && !isOperatingHours && (
        <Box mb={2}>
          <Alert variant="outlined" severity="warning">
            {outsideOperatingHoursText}
          </Alert>
        </Box>
      )}
      {children}
    </Box>
  );
}
