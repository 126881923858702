import { doc, onSnapshot } from 'firebase/firestore';
import ky, { Options as KyOptions, KyResponse } from 'ky';
import { EventChannel, eventChannel } from 'redux-saga';
import { call, take } from 'redux-saga/effects';
import { RPC, Service } from 'types';
import store from '../store';
import { db, firebaseApp, parseDocument } from './firebase';

type GeneratorFunction = () => Promise<string>;

let tokenGenerator: GeneratorFunction;

export const getBaseUrl = (service: Service) => {
  const {
    devTools: { isLocalApi },
  } = store.getState();

  let port = '';
  switch (service) {
    case 'supplier':
      port = '5000';
      break;
    case 'webhook':
      port = '5001';
      break;
    case 'bank':
      port = '5002';
      break;
    case 'partner':
      port = '5003';
      break;
  }
  //TODO: route service
  if (isLocalApi) return `http://localhost:${port}`;
  const { projectId } = firebaseApp.options;
  let baseUrl = '';
  switch (projectId) {
    case 'paguerecebivel-prod':
      baseUrl = `https://api.${service}.paguerecebivel.app`;
      break;
    case 'paguerecebivel-stg':
      baseUrl = `https://api-stg.${service}.paguerecebivel.app`;
      break;
    case 'paguerecebivel-dev':
      baseUrl = `https://api-dev.${service}.paguerecebivel.app`;
      break;
  }
  return baseUrl;
};

export const setTokenGenerator = (newTokenGenerator: GeneratorFunction) => {
  tokenGenerator = newTokenGenerator;
};

const getHeaders = async (): Promise<Record<string, string | undefined>> => {
  // const { getAccessTokenSilently } = useAuth0();
  const token: string = await tokenGenerator();

  const authorization = `Bearer ${token}`;
  const {
    app: { company },
  } = store.getState();
  return {
    authorization,
    'x-pr-idcompany': company?.id,
  };
};

const createRpcChannel = (rpcId: string /*, largeOpts*/) => {
  return eventChannel((emitter) => {
    const unsubscribe = onSnapshot(
      doc(db, RPC.RPC_COLLECTION, rpcId),
      (doc) => {
        if (!doc.exists) return;
        const rpcCall = parseDocument(doc.data()) as RPC.Call<unknown, unknown>;
        if (!rpcCall || rpcCall?.state === 'pending') return;
        if (!rpcCall.isLargeResult)
          emitter({
            ...rpcCall,
            payload: JSON.parse(rpcCall.payload as string),
            resultPayload: rpcCall.resultPayload
              ? JSON.parse(rpcCall.resultPayload as string)
              : undefined,
          });
        else {
          //TODO: Implement large results
          // const { url, headers, ...opts } = largeOpts;
          // const response = await ky(url, { method: 'get', headers, ...opts });
          // const data = await response.json();
          // emitter({ data });
        }
      }
    );
    return unsubscribe;
  });
};

type CallOpts = {
  service: Service;
  resource: string;
  payload?: unknown;
  kyOpts?: KyOptions;
};

export const apiCall = async <R>({
  service,
  resource,
  kyOpts,
  payload,
}: CallOpts) => {
  try {
    const url = `${getBaseUrl(service)}/${resource.replace(/^\//gm, '')}`;
    const headers: Record<string, string | undefined> = await getHeaders();
    const resolvedOpts: KyOptions = {
      ...kyOpts,
      json: payload,
      headers: { ...kyOpts?.headers, ...headers },
      timeout: 50000,
    };
    const response = await ky(url, resolvedOpts).json<R>();
    return response;
  } catch (e) {
    const errorObj = await (e as unknown as any).response.json();
    throw new Error(errorObj.message);
  }
};

export function* rpcCall<T, R>({
  service,
  resource,
  kyOpts,
  payload,
}: CallOpts) {
  const url = `${getBaseUrl(service)}/${resource.replace(/^\//gm, '')}`;
  const headers: Record<string, string | undefined> = yield getHeaders();
  try {
    const resolvedOpts = {
      ...kyOpts,
      json: payload,
      headers: { ...kyOpts?.headers, ...headers },
    };
    const response: KyResponse = yield ky(url, resolvedOpts);
    const rpcId = response.headers.get(RPC.RPC_HEADER);
    if (!rpcId) throw new Error('rpc id not found');
    const chan: EventChannel<RPC.Call<T, R>> = yield call(
      { context: null, fn: createRpcChannel },
      rpcId
    );
    const result: RPC.CallResult = yield take(chan);
    chan.close();
    return result;
  } catch (e) {
    return { error: (e as Error).message };
  }
}
