import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import ArchiveIcon from '@mui/icons-material/Archive';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckIcon from '@mui/icons-material/Check';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { LoadingButton } from '@mui/lab';

import {
  Accordion,
  AccordionDetails,
  Box,
  Button,
  Link,
  Stack,
  Typography,
  styled,
} from '@mui/material';
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from '@mui/material/AccordionSummary';
import { Constants } from 'pr-constants';
import React, { useEffect, useState } from 'react';
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import ConfirmationDialog from '../../../components/ConfirmationDialog/ConfirmationDialog';
import { PaymentRequestStatus } from '../../../components/PaymentRequestStatus/PaymentRequestStatus';
import { SettlementStatus } from '../../../components/SettlementStatus/SettlementStatus';
import DataTable from '../../../components/Table/DataTable';
import { TableColumn } from '../../../components/Table/table.types';
import { getFirestoreConsoleLink } from '../../../helpers/firebase';
import { useAppDispatch, useAppSelector } from '../../../helpers/hooks';
import useUserGrant from '../../../hooks/useUserGrant';
import useUserRole from '../../../hooks/useUserRole';
import { FABNewPaymentRequest } from '../components/FABNewPaymentRequest';
import PaymentRequestInfo from '../components/PaymentRequestInfo';
import { supplierActions } from '../supplier.slice';
import { ListaPagamentoVarejista } from '../supplier.types';

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary expandIcon={<ExpandMoreIcon />} {...props} />
))(({ theme }) => ({
  // backgroundColor:
  //   theme.palette.mode === 'dark'
  //     ? 'rgba(255, 255, 255, .05)'
  //     : 'rgba(0, 0, 0, .03)',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(180deg)',
  },
  '& .MuiAccordionSummary-content.Mui-expanded': {
    marginLeft: theme.spacing(1),
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}));

function FornecedorConsultaPagamentoVarejista() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const {
    selectedPaymentRequest,
    selectedPaymentRequestDetails,
    isCancelingPaymentRequest,
    isLoadingSelectedPaymentRequest,
    isArchivingPaymentRequest,
    isDeletingPaymentRequest,
  } = useAppSelector((state) => state.supplier);
  const { isDebugActivated } = useAppSelector((state) => state.devTools);
  const { isOperatingHours } =
    useAppSelector((state) => state.app.operatingHours) ?? {};
  const { isAdmin, isDev } = useUserRole();

  const [isCancelConfirmationOpen, setIsCancelConfirmationOpen] =
    useState(false);
  const [isArchiveConfirmationOpen, setIsArchiveConfirmationOpen] =
    useState(false);
  const [isDeleteConfirmationOpen, setIsDeleteConfirmationOpen] =
    useState(false);

  const { idRetailer, idPaymentRequest } = useParams();
  const showCancelButton = useUserGrant(['supplier-payment-cancel']);

  useEffect(() => {
    if (idPaymentRequest && idRetailer) {
      dispatch(
        supplierActions.loadSelectedPaymentRequest({
          idRetailer,
          idPaymentRequest,
        })
      );
    }
  }, [idPaymentRequest]);

  useEffect(() => {
    if (
      idPaymentRequest &&
      idRetailer &&
      (selectedPaymentRequest?.status === 'ativo' ||
        selectedPaymentRequest?.status === 'rejeitado')
    ) {
      dispatch(
        supplierActions.loadSelectedPaymentRequestDetails({
          idRetailer,
          idPaymentRequest,
        })
      );
    }
  }, [selectedPaymentRequest?.status]);

  useEffect(() => {
    if (
      selectedPaymentRequest?.id &&
      (selectedPaymentRequest?.status === 'aprovado' ||
        selectedPaymentRequest?.status === 'cancelamento_solicitado')
    ) {
      const interval = setInterval(() => {
        dispatch(
          supplierActions.refreshSelectedPaymentRequest(
            selectedPaymentRequest.id
          )
        );
      }, 90000);

      return () => clearInterval(interval);
    }
  }, [selectedPaymentRequest?.status]);

  const columns: TableColumn<ListaPagamentoVarejista>[] = [
    {
      id: 'id',
      label: 'id',
      hidden: !isDebugActivated,
      renderer: (v) => (
        <Link
          target="_blank"
          rel="noopener noreferrer"
          variant="caption"
          color="textSecondary"
          href={getFirestoreConsoleLink(`/payment-request-details/${v}`)}
        >
          {v}
        </Link>
      ),
    },
    {
      id: 'idPaymentRequest',
      label: 'idPaymentRequest',
      hidden: !isDebugActivated,
      renderer: (v) => (
        <Link
          target="_blank"
          rel="noopener noreferrer"
          variant="caption"
          color="textSecondary"
          href={getFirestoreConsoleLink(`/payment-requests/${v}`)}
        >
          {v}
        </Link>
      ),
    },
    { id: 'dataLiquidacao', label: 'Data de Liquidação', type: 'date' },
    { id: 'credenciadora', label: 'Credenciadora' },
    { id: 'bandeira', label: 'Bandeira do Cartão' },
    { id: 'valor', label: 'Valor', type: 'money' },
    {
      id: 'status',
      label: 'Status',
      renderer: (v) => {
        if (
          selectedPaymentRequest?.status !== 'ativo' &&
          selectedPaymentRequest?.status !== 'finalizado'
        )
          return '-';
        return <SettlementStatus status={v} />;
      },
    },
    {
      id: 'effectPriority',
      label: 'Prioridade',
      formatter: (v) => v ?? '-',
    },
  ];

  const enhancedData: ListaPagamentoVarejista[] = selectedPaymentRequest
    ? selectedPaymentRequestDetails.map((d) => {
        return {
          id: d.id,
          idPaymentRequest: d.idPaymentRequest,
          dataLiquidacao: d.incomingDate,
          credenciadora: Constants.getDebtor(d.debtor),
          bandeira: Constants.getPaymentScheme(d.paymentScheme),
          valor: d.value,
          status: d.settlementStatus,
          effectPriority: d.effectPriority,
        };
      })
    : [];

  const handleCancelClick: React.MouseEventHandler<HTMLButtonElement> = (e) => {
    e.stopPropagation();
    e.preventDefault();
    setIsCancelConfirmationOpen(true);
  };
  const handleCancelConfirmClick: React.MouseEventHandler<
    HTMLButtonElement
  > = () => {
    if (selectedPaymentRequest) {
      dispatch(
        supplierActions.cancelPaymentRequest({
          request: {
            idPaymentRequest: selectedPaymentRequest.id,
            idRetailer: selectedPaymentRequest.idRetailer,
          },
          navigate,
        })
      );
      setIsCancelConfirmationOpen(false);
    }
  };

  const handleArchiveClick: React.MouseEventHandler<HTMLButtonElement> = (
    e
  ) => {
    e.stopPropagation();
    e.preventDefault();
    setIsArchiveConfirmationOpen(true);
  };
  const handleArchiveConfirmClick: React.MouseEventHandler<
    HTMLButtonElement
  > = () => {
    if (selectedPaymentRequest) {
      dispatch(
        supplierActions.archivePaymentRequest({
          request: {
            idPaymentRequest: selectedPaymentRequest.id,
            idRetailer: selectedPaymentRequest.idRetailer,
          },
          navigate,
        })
      );
      setIsArchiveConfirmationOpen(false);
    }
  };

  const handleDeleteClick: React.MouseEventHandler<HTMLButtonElement> = (e) => {
    e.stopPropagation();
    e.preventDefault();
    setIsDeleteConfirmationOpen(true);
  };
  const handleDeleteConfirmClick: React.MouseEventHandler<
    HTMLButtonElement
  > = () => {
    if (selectedPaymentRequest?.id && idRetailer) {
      dispatch(
        supplierActions.deletePaymentRequest({
          idPaymentRequest: selectedPaymentRequest.id,
          idRetailer,
          navigate,
        })
      );
      setIsArchiveConfirmationOpen(false);
    }
  };

  const canCancelPaymentRequest =
    isOperatingHours &&
    !isLoadingSelectedPaymentRequest &&
    selectedPaymentRequest &&
    (selectedPaymentRequest.status === 'ativo' ||
      (selectedPaymentRequest.status === 'rejeitado' && isAdmin));

  const canArchivePaymentRequest =
    !isLoadingSelectedPaymentRequest &&
    selectedPaymentRequest &&
    (selectedPaymentRequest.status === 'nao_aprovado' ||
      (selectedPaymentRequest.status === 'rejeitado' && isAdmin));

  const canDeletePaymentRequest =
    !isLoadingSelectedPaymentRequest && selectedPaymentRequest && isDev;

  const showDeleteButton = isDev && isDebugActivated;

  const showArchiveButton =
    useUserGrant(['supplier-payment-create']) && canArchivePaymentRequest;

  return (
    <>
      <Box paddingBottom={2}>
        <Accordion defaultExpanded>
          <AccordionSummary aria-controls="panel1-content" id="panel1-header">
            <Stack
              width={1}
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              gap={2}
            >
              <Typography variant="h5">
                Pedido #{' '}
                {isLoadingSelectedPaymentRequest
                  ? '...'
                  : selectedPaymentRequest?.externalId ?? '-'}
              </Typography>
              {isLoadingSelectedPaymentRequest ? (
                <></>
              ) : selectedPaymentRequest?.status ? (
                <PaymentRequestStatus status={selectedPaymentRequest?.status} />
              ) : (
                <></>
              )}
              <Box flexGrow={1} />
              <Stack direction="row" gap={1}>
                {showArchiveButton && (
                  <LoadingButton
                    disabled={!canArchivePaymentRequest}
                    loading={isArchivingPaymentRequest}
                    variant="outlined"
                    onClick={handleArchiveClick}
                    startIcon={<ArchiveIcon />}
                  >
                    Arquivar
                  </LoadingButton>
                )}
                {showCancelButton && (
                  <LoadingButton
                    disabled={!canCancelPaymentRequest}
                    loading={isCancelingPaymentRequest}
                    variant="contained"
                    color="error"
                    onClick={handleCancelClick}
                    startIcon={<CancelIcon />}
                  >
                    Cancelar Pagamento
                  </LoadingButton>
                )}

                {showDeleteButton && (
                  <LoadingButton
                    disabled={!canDeletePaymentRequest}
                    loading={isDeletingPaymentRequest}
                    variant="contained"
                    color="error"
                    onClick={handleDeleteClick}
                    startIcon={<CancelIcon />}
                  >
                    Excluir Pagamento
                  </LoadingButton>
                )}
              </Stack>
            </Stack>
          </AccordionSummary>
          <AccordionDetails>
            <PaymentRequestInfo />
          </AccordionDetails>
        </Accordion>
      </Box>
      <Stack
        direction="row"
        justifyContent="space-between"
        paddingBottom={1}
        alignItems="flex-end"
      >
        <Typography variant="body2" color="textSecondary" component="p">
          Liquidações:
        </Typography>
        {selectedPaymentRequest && (
          <NavLink
            to={`/clientes/${selectedPaymentRequest.idRetailer}/extrato`}
          >
            <Button
              variant="outlined"
              size="small"
              startIcon={<AccountBalanceWalletIcon />}
            >
              Extrato
            </Button>
          </NavLink>
        )}
      </Stack>
      <DataTable<ListaPagamentoVarejista>
        columns={columns}
        data={enhancedData}
        isLoading={isLoadingSelectedPaymentRequest}
      />
      <ConfirmationDialog
        open={isCancelConfirmationOpen}
        title="Confirmação"
        isLoading={isCancelingPaymentRequest}
        actions={[
          {
            name: 'Não',
            buttonProps: {
              autoFocus: true,
              onClick: () => setIsCancelConfirmationOpen(false),
            },
          },
          {
            name: 'Sim',
            showLoading: true,
            buttonProps: {
              variant: 'contained',
              color: 'error',
              startIcon: <CheckIcon />,
              onClick: handleCancelConfirmClick,
            },
          },
        ]}
      >
        <Typography variant="body1" color="text.primary">
          Deseja cancelar este pagamento?
        </Typography>
      </ConfirmationDialog>
      <ConfirmationDialog
        open={isArchiveConfirmationOpen}
        title="Confirmação"
        isLoading={isArchivingPaymentRequest}
        actions={[
          {
            name: 'Não',
            buttonProps: {
              autoFocus: true,
              onClick: () => setIsArchiveConfirmationOpen(false),
            },
          },
          {
            name: 'Sim',
            showLoading: true,
            buttonProps: {
              variant: 'contained',
              startIcon: <CheckIcon />,
              onClick: handleArchiveConfirmClick,
            },
          },
        ]}
      >
        <Typography variant="body1" color="text.primary">
          Deseja arquivar este pagamento?
        </Typography>
      </ConfirmationDialog>
      <ConfirmationDialog
        open={isDeleteConfirmationOpen}
        title="Confirmação"
        isLoading={isDeletingPaymentRequest}
        actions={[
          {
            name: 'Não',
            buttonProps: {
              autoFocus: true,
              onClick: () => setIsDeleteConfirmationOpen(false),
            },
          },
          {
            name: 'Excluir permanentemente',
            showLoading: true,
            buttonProps: {
              variant: 'contained',
              color: 'error',
              startIcon: <CancelIcon />,
              onClick: handleDeleteConfirmClick,
            },
          },
        ]}
      >
        <Typography variant="body1" color="text.primary">
          Deseja excluir este pagamento?
        </Typography>
      </ConfirmationDialog>
      <FABNewPaymentRequest />
    </>
  );
}

export default FornecedorConsultaPagamentoVarejista;
