import { Models, Service } from 'types';
import { apiCall } from '../helpers/api';

type T = Models.Holiday;
const service: Service = 'supplier';
const resource = 'web/holiday';

export class WebHolidayHelpers {
  public static getByYear = async (year: string): Promise<T | undefined> => {
    try {
      return await apiCall<T>({
        service,
        resource: `${resource}/${year}`,
      });
    } catch (e) {
      if ((e as unknown as any)?.response?.status === 404) return undefined;
      throw e;
    }
  };
}
