import { Models } from 'types';

export * from './format';

export class Utils {
  /**
   * Cleans start and end spaces and leading/traling slashes:
   *
   * Examples
   * ```
   * Utils.cleanUrl('https://someurl.com/') // returns 'https://someurl.com'
   * Utils.cleanUrl(' https://someurl.com/ ') // returns 'https://someurl.com'
   * Utils.cleanUrl('/some/path/') // returns 'some/path'
   * ```
   * @param url string
   */
  public static cleanUrl = (url: string) => {
    return url.trim().replace(/\/$/gm, '').replace(/^\//gm, '');
  };

  public static safeParseNumber = (s?: string) => {
    if (!s) return 0;
    try {
      const num = parseFloat(s);
      return num;
    } catch (e) {
      return 0;
    }
  };

  public static safeParseFloat = (v?: string) => {
    const val = parseFloat((v ?? '').replace(',', '.'));
    if (!isNaN(val)) return val;
    return 0;
  };

  public static testCPF = (strCPF: string): boolean => {
    const strippedCPF = strCPF.replace(/[^\d+]+/g, '');
    let Soma;
    let Resto;
    Soma = 0;
    if (strippedCPF === undefined) return false;
    if (strippedCPF == '00000000000') return false;
    if (strippedCPF == '11111111111') return false;
    if (strippedCPF == '22222222222') return false;
    if (strippedCPF == '33333333333') return false;
    if (strippedCPF == '44444444444') return false;
    if (strippedCPF == '55555555555') return false;
    if (strippedCPF == '66666666666') return false;
    if (strippedCPF == '77777777777') return false;
    if (strippedCPF == '88888888888') return false;
    if (strippedCPF == '99999999999') return false;
    if (strippedCPF.length !== 11) return false;

    for (let i = 1; i <= 9; i++)
      Soma = Soma + parseInt(strippedCPF.substring(i - 1, i)) * (11 - i);
    Resto = (Soma * 10) % 11;

    if (Resto == 10 || Resto == 11) Resto = 0;
    if (Resto != parseInt(strippedCPF.substring(9, 10))) return false;

    Soma = 0;
    for (let i = 1; i <= 10; i++)
      Soma = Soma + parseInt(strippedCPF.substring(i - 1, i)) * (12 - i);
    Resto = (Soma * 10) % 11;

    if (Resto == 10 || Resto == 11) Resto = 0;
    if (Resto != parseInt(strippedCPF.substring(10, 11))) return false;
    return true;
  };

  public static testCNPJ = (cnpj: string): boolean => {
    if (cnpj === undefined) return false;
    cnpj = cnpj.replace(/[^\d]+/g, '');

    if (cnpj == '') return false;

    if (cnpj.length != 14) return false;

    // Elimina CNPJs invalidos conhecidos
    if (
      cnpj == '00000000000000' ||
      cnpj == '11111111111111' ||
      cnpj == '22222222222222' ||
      cnpj == '33333333333333' ||
      cnpj == '44444444444444' ||
      cnpj == '55555555555555' ||
      cnpj == '66666666666666' ||
      cnpj == '77777777777777' ||
      cnpj == '88888888888888' ||
      cnpj == '99999999999999'
    )
      return false;

    // Valida DVs
    let tamanho = cnpj.length - 2;
    let numeros = cnpj.substring(0, tamanho);
    const digitos = cnpj.substring(tamanho);
    let soma = 0;
    let pos = tamanho - 7;
    for (let i = tamanho; i >= 1; i--) {
      soma += parseInt(numeros.charAt(tamanho - i)) * pos--;
      if (pos < 2) pos = 9;
    }
    let resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
    if (resultado != parseInt(digitos.charAt(0))) return false;

    tamanho = tamanho + 1;
    numeros = cnpj.substring(0, tamanho);
    soma = 0;
    pos = tamanho - 7;
    for (let i = tamanho; i >= 1; i--) {
      soma += parseInt(numeros.charAt(tamanho - i)) * pos--;
      if (pos < 2) pos = 9;
    }
    resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
    if (resultado != parseInt(digitos.charAt(1))) return false;

    return true;
  };

  public static capitalize = (name: string): string => {
    return name
      .split(' ')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  };

  public static extractFirstName = (fullName: string): string => {
    const [firstName] = Utils.capitalize(fullName.trim()).split(' ');
    return firstName;
  };

  public static getGenderRelation = (
    gender: Models.Gender,
  ): Models.GenderRelation => {
    switch (gender) {
      case 'a':
        return 'à';
      case 'o':
        return 'ao';
      case '':
        return 'a';
    }
  };

  public static base64decode = (data: string) =>
    Buffer.from(data, 'base64').toString();

  public static parsePhone = (phone: string): Models.Phone => {
    const cleanPhone = phone.replace(/[^\d+]+/g, '');
    if (cleanPhone.substring(0, 1) === '+')
      throw new Error('Invalid phone number');
    const countryCode = '055';
    const areaCode = cleanPhone.substring(0, 2);
    const number = cleanPhone.substring(2);
    return { countryCode, areaCode, number };
  };

  public static slugify = (text: string): string => {
    return text
      .toString() // Cast to string (optional)
      .normalize('NFKD') // The normalize() using NFKD method returns the Unicode Normalization Form of a given string.
      .toLowerCase() // Convert the string to lowercase letters
      .trim() // Remove whitespace from both sides of a string (optional)
      .replace(/\s+/g, '-') // Replace spaces with -
      .replace(/[^\w-]+/g, '') // Remove all non-word chars
      .replace(/_/g, '-') // Replace _ with -
      .replace(/--+/g, '-') // Replace multiple - with single -
      .replace(/-$/g, ''); // Remove trailing -
  };

  public static removeExtraSpaces = (text: string) =>
    text.replace(/^\s+|\s+$/g, '');
}
