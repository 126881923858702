import { Stack, Typography } from '@mui/material';

function NoInvite() {
  return (
    <Stack
      direction="column"
      gap={4}
      alignItems="center"
      justifyContent="center"
      height={1}
    >
      <Typography variant="h4">Você não tem acesso a esta página!</Typography>
      <Typography variant="body1">
        Caso você tenha recebido um convite, o mesmo pode ter expirado! Solicite
        a criação de um novo ao seu distribuidor!
      </Typography>
    </Stack>
  );
}

export default NoInvite;
