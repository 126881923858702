import SendIcon from '@mui/icons-material/Send';
import { LoadingButton } from '@mui/lab';
import { Link, Stack } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ConfirmationDialog from '../../../components/ConfirmationDialog/ConfirmationDialog';
import { PageHeader } from '../../../components/PageHeader/PageHeader';
import DataTable from '../../../components/Table/DataTable';
import { TableColumn } from '../../../components/Table/table.types';
import { getFirestoreConsoleLink } from '../../../helpers/firebase';
import { useAppDispatch, useAppSelector } from '../../../helpers/hooks';
import { ListaUsuarios } from '../../../types';
import { settingsActions } from '../settings-slice';

function InvitesList() {
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
  const [idUserToResend, setIdUserToResend] = useState('');
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { invites, isLoading } = useAppSelector((state) => state.admin);
  const {
    isLoadingSupplierUserGroups,
    isLoadingSupplierUsers,
    supplierUserGroups,
    supplierUsers,
    isUserEditFormOpen,
    isSavingOrDeletingSupplierUsers,
  } = useAppSelector((state) => state.settings);
  const isDebugActivated = useAppSelector(
    (state) => state.devTools.isDebugActivated
  );

  const handleResendClick = (idUser: string) => {
    setIdUserToResend(idUser);
    setIsConfirmationOpen(true);
  };

  const handleResendConfirm = () => {
    dispatch(settingsActions.resendInvite(idUserToResend));
  };

  const columns: TableColumn<ListaUsuarios>[] = [
    {
      id: 'id',
      label: 'id',
      hidden: !isDebugActivated,
      renderer: (v, row) => (
        <>
          <Link
            target="_blank"
            rel="noopener noreferrer"
            variant="caption"
            color="textSecondary"
            href={getFirestoreConsoleLink(`/users/${v}`)}
          >
            {v}
          </Link>
        </>
      ),
    },
    { id: 'name', label: 'Nome' },
    {
      id: 'idSupplierUserGroup',
      label: 'Grupo',
      renderer: (v, row) => {
        const group = supplierUserGroups.find(({ id }) => id === v);
        return (
          <Stack direction="row" justifyContent="space-between">
            {group?.name ?? '-'}
            <LoadingButton
              size="small"
              loading={isSavingOrDeletingSupplierUsers}
              loadingPosition="start"
              onClick={() => handleResendClick(row.id)}
              startIcon={<SendIcon />}
            >
              Reenviar
            </LoadingButton>
          </Stack>
        );
      },
    },
  ];

  const enhancedData: ListaUsuarios[] = [
    ...supplierUsers
      .filter(({ onboardingCompletedAt }) => !onboardingCompletedAt)
      .map((c) => ({
        id: c.id,
        name: c.name ?? c.email,
        idSupplierUserGroup: c.idSupplierUserGroup,
      })),
  ].sort(({ name: a }, { name: b }) => a.localeCompare(b));

  useEffect(() => {
    dispatch(settingsActions.loadSupplierUsers());
  }, []);

  if (enhancedData.length === 0) return <></>;
  return (
    <>
      <Grid2 xs={12}>
        <PageHeader title="Convites" />
        <DataTable<ListaUsuarios>
          columns={columns}
          data={enhancedData}
          isLoading={isLoadingSupplierUsers}
          hideFooter
          disableColumnMenu
        />
      </Grid2>
      <ConfirmationDialog
        open={isConfirmationOpen}
        title="Confirmação"
        actions={[
          {
            name: 'Cancelar',
            buttonProps: {
              autoFocus: true,
              onClick: () => {
                setIsConfirmationOpen(false);
              },
            },
          },
          {
            name: 'Enviar',
            showLoading: true,
            buttonProps: {
              variant: 'contained',
              startIcon: <SendIcon />,
              onClick: () => {
                setIsConfirmationOpen(false);
                handleResendConfirm();
              },
            },
          },
        ]}
      >
        Atenção! Deseja reenviar o convite?
      </ConfirmationDialog>
    </>
  );
}

export default InvitesList;
