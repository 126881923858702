import CheckIcon from '@mui/icons-material/Check';
import DoneIcon from '@mui/icons-material/Done';
import LoadingButton from '@mui/lab/LoadingButton/LoadingButton';
import { Alert, Box, Card, Stack, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import { Constants } from 'pr-constants';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { format } from 'utils';
import EditForm from '../../../components/EditForm/EditForm';
import EditFormContent from '../../../components/EditForm/EditFormContent';
import EditFormHeader from '../../../components/EditForm/EditFormHeader';
import ResponsiveDialog from '../../../components/ResponsiveDialog/ResponsiveDialog';
import DataTable from '../../../components/Table/DataTable';
import { TableColumn } from '../../../components/Table/table.types';
import { useAppDispatch, useAppSelector } from '../../../helpers/hooks';
import { parseMaskedInput, safeParseFloat } from '../../../helpers/safe-parse';
import Unexpected from '../../../pages/Unexpected';
import { supplierActions } from '../supplier.slice';
import { PagamentoPendente } from '../supplier.types';

type DetailProps = {
  label: string;
  value?: string;
  children?: React.ReactNode;
};
const Detail = ({ label, value, children }: DetailProps) => (
  <Box minWidth="100px">
    <Typography variant="caption" color="textSecondary" component="p">
      {label}:
    </Typography>
    <Typography
      variant="body2"
      color="textPrimary"
      component="p"
      whiteSpace="nowrap"
    >
      {(value ?? '') !== '' ? value : '-'}
    </Typography>
    {children}
  </Box>
);

const FornecedorConfirmaPagamento = () => {
  const navigate = useNavigate();
  const { isDebugActivated } = useAppSelector((state) => state.devTools);
  const { supplierWarrantyConfig, supplierOwnershipAssignmentConfig } =
    useAppSelector((state) => state.settings);
  const { isOperatingHours } =
    useAppSelector((state) => state.app.operatingHours) ?? {};

  const columns: TableColumn<PagamentoPendente>[] = [
    {
      id: 'idRecebivel',
      label: 'idRecebivel',
      hidden: !isDebugActivated,
    },
    { id: 'dataRecebimento', label: 'Data Recebimento', type: 'date' },
    { id: 'credenciadora', label: 'Credenciadora' },
    { id: 'bandeira', label: 'Bandeira do Cartão' },
    {
      id: 'ultimaAtualizacao',
      label: 'Última Atualização',
      type: 'dateTime',
    },
    {
      id: 'valorPagar',
      label: 'Valor a Receber',
      type: 'money',
    },
  ];

  const {
    paymentFormData,
    isCreatePaymentRequestSuccessOpen,
    selectedRetailer,
    retailerReceivables,
    isCreatingPaymentRequest,
    selectedPaymentRequest,
  } = useAppSelector((state) => state.supplier);
  const dispatch = useAppDispatch();

  if (!paymentFormData) {
    return <Unexpected />;
  }

  const enhancedData: PagamentoPendente[] = [
    ...retailerReceivables.map((d) => {
      const p = paymentFormData.details[d.id];
      const valorPagar = safeParseFloat(p?.value.replaceAll('.', ''));
      return {
        idRecebivel: p?.idReceivable ?? '-',
        dataRecebimento: d.incomingDate,
        credenciadora: Constants.getDebtor(d.debtor),
        bandeira: Constants.getPaymentScheme(d.paymentScheme),
        valor: d.value,
        ultimaAtualizacao: d.lastUpdated,
        valorPagar,
      };
    }),
  ]
    .sort(({ dataRecebimento: a }, { dataRecebimento: b }) =>
      a.localeCompare(b)
    )
    .filter(({ valorPagar }) => valorPagar > 0);
  const sum = Object.values(paymentFormData.details)
    .map(({ value }) => value)
    .reduce((acc, cur) => {
      if (!acc) acc = 0;
      acc += safeParseFloat(cur.replaceAll('.', ''));
      return acc;
    }, 0);

  const onConfirmClick = () => {
    dispatch(supplierActions.createPaymentRequest({ navigate }));
  };
  const onOkClick = () => {
    dispatch(supplierActions.clearPaymentFormData());
    dispatch(supplierActions.setIsCreatePaymentRequestSuccessOpen(false));
    navigate(
      `/pagamentos/${selectedRetailer?.id}/${selectedPaymentRequest?.id}`
    );
  };

  const effectTypes = [
    {
      label: 'Garantia',
      value: 'garantia',
    },
    {
      label: 'Troca de Titularidade',
      value: 'transferencia',
    },
  ];

  const operacao = `${
    effectTypes.find(({ value }) => value === paymentFormData.effectType)?.label
  }${
    paymentFormData.effectType === 'garantia'
      ? ` (Venc. ${format(paymentFormData.paymentDueDate, 'date')})`
      : ''
  }`;

  const retailerInfo = `${
    selectedRetailer?.fantasy ?? selectedRetailer?.name ?? '-'
  } - ${format(selectedRetailer?.docNumber, 'docNumber') ?? '-'}`;

  const mainContactInfo = `${selectedRetailer?.mainContactName ?? '-'} - ${
    format(selectedRetailer?.mainContactPhone, 'phone') ?? '-'
  } - ${selectedRetailer?.mainContactEmail ?? '-'}`;

  return (
    <>
      <Stack direction="column" gap={1} paddingBottom={1}>
        <Alert variant="outlined" severity="warning">
          ATENÇÃO! Verifique os dados antes de confirmar a operação!
        </Alert>
        <Stack direction="row" gap={2} width={1} justifyContent="space-between">
          {/* <Grid2 container spacing={1}> */}
          {/* <Grid2 xs={6}> */}
          <Detail label="Cliente" value={retailerInfo} />
          {/* </Grid2>
          <Grid2 xs={6}> */}
          <Detail label="Responsável" value={mainContactInfo} />
          {/* </Grid2> */}
          {/* <Grid2 xs={6}>
            <Detail label="Operação" value={operacao} />
          </Grid2>
          <Grid2 xs={6}>
            <Detail label="Pedido" value={paymentFormData.externalId} />
          </Grid2>
          <Grid2 xs={6}>
            <Detail
              label="Valor do Pedido"
              value={format(
                parseMaskedInput(paymentFormData.originalValue),
                'money'
              )}
            />
          </Grid2>
          <Grid2 xs={6}>
            <Detail label="Valor a Receber" value={format(sum, 'money')} />
          </Grid2> */}
          {/* </Grid2> */}
        </Stack>
        <Stack direction="row" gap={2} width={1} justifyContent="space-between">
          <Detail label="Operação" value={operacao} />
          <Detail label="Pedido" value={paymentFormData.externalId} />
          <Detail
            label="Valor"
            value={format(
              parseMaskedInput(paymentFormData.originalValue),
              'money'
            )}
          />
          <Detail
            label={`Valor c/${
              paymentFormData.effectType === 'garantia'
                ? 'multa e juros'
                : 'custo de capital'
            }`}
            value={format(sum, 'money')}
          />
        </Stack>
      </Stack>
      <Stack
        direction="column"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: '100px',
          flexGrow: 1,
        }}
      >
        <Typography variant="caption" color="textSecondary" component="p">
          Detalhe dos recebimentos:
        </Typography>

        <DataTable<PagamentoPendente> columns={columns} data={enhancedData} />
      </Stack>
      <Card variant="outlined">
        <Stack direction="row" gap={2} p={1} justifyContent="center">
          <Button variant="outlined" onClick={() => navigate(-1)} color="error">
            Cancelar
          </Button>
          <LoadingButton
            onClick={onConfirmClick}
            type="submit"
            loading={isCreatingPaymentRequest}
            loadingPosition="start"
            disabled={!isOperatingHours}
            startIcon={<CheckIcon />}
            variant="contained"
          >
            Confirmar Operação
          </LoadingButton>
        </Stack>
      </Card>

      <ResponsiveDialog
        open={isCreatePaymentRequestSuccessOpen}
        maxWidth="xs"
        fullWidth
      >
        <EditForm>
          <EditFormHeader title="Solicitação Efetuada" />
          <EditFormContent
            display="flex"
            flexDirection="column"
            gap={2}
            alignItems="center"
          >
            <DoneIcon sx={{ fontSize: 80 }} color="success" />
            <Typography variant="body1" color="text.primary" textAlign="center">
              Solicitação de Pagamento Enviada com Sucesso.
            </Typography>
            {paymentFormData.effectType === 'garantia' &&
              supplierWarrantyConfig?.requireRetailerApproval && (
                <Typography
                  variant="body1"
                  color="text.primary"
                  textAlign="center"
                >
                  Você será notificado quando o cliente autorizar o pagamento.
                </Typography>
              )}
            {paymentFormData.effectType === 'transferencia' &&
              supplierOwnershipAssignmentConfig?.requireRetailerApproval && (
                <Typography
                  variant="body1"
                  color="text.primary"
                  textAlign="center"
                >
                  Você será notificado quando o cliente autorizar o pagamento.
                </Typography>
              )}
          </EditFormContent>
          <Stack direction="row" justifyContent="center" paddingTop={4}>
            <Button
              variant="contained"
              startIcon={<DoneIcon />}
              onClick={onOkClick}
            >
              Ok
            </Button>
          </Stack>
        </EditForm>
      </ResponsiveDialog>
    </>
  );
};

export default FornecedorConfirmaPagamento;
