import { Models, Service } from 'types';
import { apiCall } from '../helpers/api';
const service: Service = 'supplier';

export class WebSettingsHelpers {
  public static updateDocuments = async (data: Models.WebUploadDocsRequest) => {
    const updated = await apiCall<Models.Company>({
      service,
      resource: 'web/settings/update-documents',
      payload: data,
      kyOpts: { method: 'patch' },
    });

    return updated;
  };

  public static getSupplierConfig =
    async (): Promise<Models.WebGetSupplierConfigResponse> => {
      const config = await apiCall<Models.WebGetSupplierConfigResponse>({
        service: 'supplier',
        resource: 'web/settings/supplier-config',
      });

      return config;
    };

  public static updateSupplierConfig = async (
    payload: Models.WebUpdateSupplierConfigRequest
  ) => {
    await apiCall({
      service: 'supplier',
      resource: 'web/settings/supplier-config',
      payload,
      kyOpts: {
        method: 'patch',
      },
    });
  };

  public static getSupplierPersonalizationConfig = async (): Promise<
    Models.SupplierPersonalizationConfig | undefined
  > => {
    const config = await apiCall<
      Models.SupplierPersonalizationConfig | undefined
    >({
      service: 'supplier',
      resource: 'web/settings/supplier-personalization-config',
    });

    return config;
  };

  public static updateSupplierPersonalizationConfig = async (
    payload: Partial<Models.SupplierPersonalizationConfig>
  ) => {
    await apiCall({
      service: 'supplier',
      resource: 'web/settings/supplier-personalization-config',
      payload,
      kyOpts: {
        method: 'patch',
      },
    });
  };

  public static getSupplierNotificationConfig = async (): Promise<
    Models.SupplierNotificationConfig | undefined
  > => {
    const config = await apiCall<Models.SupplierNotificationConfig | undefined>(
      {
        service: 'supplier',
        resource: 'web/settings/supplier-notification-config',
      }
    );

    return config;
  };

  public static updateSupplierNotificationConfig = async (
    payload: Partial<Models.SupplierNotificationConfig>
  ) => {
    await apiCall({
      service: 'supplier',
      resource: 'web/settings/supplier-notification-config',
      payload,
      kyOpts: {
        method: 'patch',
      },
    });
  };

  public static testAllMailTemplates = async (
    payload: Models.TestAllMailTemplatesRequest
  ) => {
    await apiCall({
      service: 'supplier',
      resource: 'web/settings/test-all-mail-templates',
      payload,
      kyOpts: {
        method: 'post',
      },
    });
  };
}
