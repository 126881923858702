import { Models, Service } from 'types';
import { apiCall } from '../helpers/api';

type T = Models.User;
const service: Service = 'supplier';
const resource = 'web/supplier-user';

export class WebSupplierUserHelpers {
  public static getById = async (id: string): Promise<T | undefined> => {
    return await apiCall<T>({
      service,
      resource: `${resource}/${id}`,
    });
  };
  public static list = async (): Promise<T[]> => {
    return await apiCall<T[]>({
      service,
      resource: `${resource}`,
    });
  };
  public static create = async (
    newData: Pick<T, 'email' | 'idSupplierUserGroup'>
  ): Promise<string> => {
    const { id } = await apiCall<Pick<T, 'id'>>({
      service,
      resource: `${resource}`,
      payload: newData,
      kyOpts: { method: 'post' },
    });
    return id;
  };
  public static patch = async (
    id: string,
    dataToUpdate: Pick<T, 'idSupplierUserGroup'>
  ) => {
    await apiCall<Pick<T, 'id'>>({
      service,
      resource: `${resource}/${id}`,
      payload: dataToUpdate,
      kyOpts: { method: 'patch' },
    });
    return id;
  };
  public static delete = async (id: string) => {
    await apiCall<Pick<T, 'id'>>({
      service,
      resource: `${resource}/${id}`,
      kyOpts: { method: 'delete' },
    });
    return id;
  };
  public static resendInvite = async (id: string) => {
    await apiCall<Pick<T, 'id'>>({
      service,
      resource: `${resource}/${id}/resend-invite`,
      kyOpts: { method: 'post' },
    });
    return id;
  };
}
