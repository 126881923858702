import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  IconButton,
  LinearProgress,
  Stack,
  Typography,
} from '@mui/material';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import ConfirmationDialog from '../../components/ConfirmationDialog/ConfirmationDialog';
import EditForm from '../../components/EditForm/EditForm';
import EditFormAction from '../../components/EditForm/EditFormAction';
import EditFormContent from '../../components/EditForm/EditFormContent';
import EditFormHeader from '../../components/EditForm/EditFormHeader';
import TextField from '../../components/TextField/TextField';
import { format } from '../../helpers/format';
import { useAppDispatch, useAppSelector } from '../../helpers/hooks';
import { safeParseFloat } from '../../helpers/safe-parse';
import { PixLimitsForm } from '../admin/admin.types';
import { bankAccountActions } from './bank-account-slice';

type DetailProps = {
  label: string;
  value?: string;
  children?: React.ReactNode;
};
const Detail = ({ label, value, children }: DetailProps) => (
  <Box>
    <Typography variant="body2" color="textSecondary" component="p">
      {label}:
    </Typography>
    {value && (
      <Typography variant="body1" color="primary" component="p">
        {' '}
        {value ?? '-'}
      </Typography>
    )}
    {children}
  </Box>
);

export default function PixLimits() {
  const dispatch = useAppDispatch();
  const { dbUser } = useAppSelector((state) => state.app);
  const { pixLimitsUsage, isRequestingNewPixLimits } = useAppSelector(
    (state) => state.bankAccount
  );
  const isAdmin = dbUser?.userRole === 'adm' || dbUser?.userRole === 'dev';
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
  const [isDailyEditing, setIsDailyEditing] = useState(false);
  const [isNightlyEditing, setIsNightlyEditing] = useState(false);
  const [isSelfDailyEditing, setIsSelfDailyEditing] = useState(false);
  const [isSelfNightlyEditing, setIsSelfNightlyEditing] = useState(false);

  const {
    handleSubmit,
    formState: { isDirty, errors },
    watch,
    resetField,
    register,
  } = useForm<PixLimitsForm>({
    defaultValues: {
      daily: safeParseFloat(pixLimitsUsage?.daily_amount_limit),
      nightly: safeParseFloat(pixLimitsUsage?.self_nightly_amount_limit),
      selfDaily: safeParseFloat(pixLimitsUsage?.self_daily_amount_limit),
      selfNightly: safeParseFloat(pixLimitsUsage?.self_nightly_amount_limit),
    },
  });

  const [daily, nightly, selfDaily, selfNightly] = watch([
    'daily',
    'nightly',
    'selfDaily',
    'selfNightly',
  ]);

  const handleCloseClick = () =>
    dispatch(bankAccountActions.setIsPixLimitsFormOpen(false));
  const onSubmit = async (data: PixLimitsForm) => {
    setIsConfirmationOpen(false);
    dispatch(bankAccountActions.requestNewPixLimits(data));
  };
  const handleChangePixLimitsClick = handleSubmit(onSubmit);

  const formattedSelfDaily = format(
    safeParseFloat(pixLimitsUsage?.self_daily_amount_limit),
    'money'
  );
  const formattedSelfNightly = format(
    safeParseFloat(pixLimitsUsage?.self_nightly_amount_limit),
    'money'
  );
  const formattedDaily = format(
    safeParseFloat(pixLimitsUsage?.daily_amount_limit),
    'money'
  );
  const formattedNightly = format(
    safeParseFloat(pixLimitsUsage?.nightly_amount_limit),
    'money'
  );

  const formattedUsedSelfDaily = format(
    safeParseFloat(pixLimitsUsage?.self_daily_amount_used),
    'money'
  );
  const formattedUsedSelfNightly = format(
    safeParseFloat(pixLimitsUsage?.self_nightly_amount_used),
    'money'
  );
  const formattedUsedDaily = format(
    safeParseFloat(pixLimitsUsage?.daily_amount_used),
    'money'
  );
  const formattedUsedNightly = format(
    safeParseFloat(pixLimitsUsage?.nightly_amount_used),
    'money'
  );

  const percentageUsedSelfDaily =
    safeParseFloat(pixLimitsUsage?.self_daily_amount_percentage ?? '0') * 100;
  const percentageUsedSelfNightly =
    safeParseFloat(pixLimitsUsage?.self_nightly_amount_percentage ?? '0') * 100;
  const percentageUsedDaily =
    safeParseFloat(pixLimitsUsage?.daily_amount_percentage ?? '0') * 100;
  const percentageUsedNightly =
    safeParseFloat(pixLimitsUsage?.nightly_amount_percentage ?? '0') * 100;

  const formattedRemainingSelfDaily = format(
    safeParseFloat(pixLimitsUsage?.self_daily_amount_limit) -
      safeParseFloat(pixLimitsUsage?.self_daily_amount_used),
    'money'
  );
  const formattedRemainingSelfNightly = format(
    safeParseFloat(pixLimitsUsage?.self_nightly_amount_limit) -
      safeParseFloat(pixLimitsUsage?.self_nightly_amount_used),
    'money'
  );
  const formattedRemainingDaily = format(
    safeParseFloat(pixLimitsUsage?.daily_amount_limit) -
      safeParseFloat(pixLimitsUsage?.daily_amount_used),
    'money'
  );
  const formattedRemainingNightly = format(
    safeParseFloat(pixLimitsUsage?.nightly_amount_limit) -
      safeParseFloat(pixLimitsUsage?.nightly_amount_used),
    'money'
  );

  return (
    <EditForm>
      <EditFormHeader title="Limites Pix" onCloseClick={handleCloseClick} />
      <EditFormContent display="flex" flexDirection="column" gap={2}>
        <Typography variant="h5">Mesma Titularidade</Typography>
        <Stack direction="row" gap={2}>
          <Stack width={1} flexBasis="50%" direction="column">
            <Stack
              sx={{ display: isSelfDailyEditing ? 'none' : 'flex' }}
              width={1}
              direction="column"
              paddingBottom={1}
            >
              <Typography variant="caption" color="text.secondary">
                Diurno (06:00 às 19:59)
              </Typography>
              <Stack direction="row" gap={2} alignItems="center">
                <Typography variant="h5">{formattedSelfDaily}</Typography>
                {isAdmin && (
                  <IconButton onClick={() => setIsSelfDailyEditing(true)}>
                    <EditIcon />
                  </IconButton>
                )}
              </Stack>
            </Stack>
            <Stack
              sx={{ display: isSelfDailyEditing ? 'flex' : 'none' }}
              direction="row"
              gap={2}
              alignItems="center"
              paddingBottom={1}
            >
              <TextField
                id="selfDaily"
                label="Diurno (0h:00 às 19:59)"
                type="text"
                {...register('selfDaily', {
                  required: 'O valor é obrigatório',
                })}
                error={!!errors?.selfDaily}
                helperText={errors?.selfDaily?.message}
              />
              {isAdmin && (
                <IconButton
                  onClick={() => {
                    setIsSelfDailyEditing(false);
                    resetField('selfDaily');
                  }}
                >
                  <CloseIcon />
                </IconButton>
              )}
            </Stack>

            <LinearProgress
              variant="determinate"
              color={
                percentageUsedSelfDaily < 70
                  ? 'primary'
                  : percentageUsedSelfDaily < 90
                  ? 'warning'
                  : 'error'
              }
              value={percentageUsedSelfDaily}
            />
            <Typography variant="caption" color="text.secondary">
              Utilizado: {formattedUsedSelfDaily} ({formattedRemainingSelfDaily}{' '}
              disponíveis)
            </Typography>
          </Stack>
          <Stack width={1} flexBasis="50%" direction="column">
            <Stack
              sx={{ display: isSelfNightlyEditing ? 'none' : 'flex' }}
              width={1}
              direction="column"
              paddingBottom={1}
            >
              <Typography variant="caption" color="text.secondary">
                Noturno (20:00 às 05:59)
              </Typography>
              <Stack direction="row" gap={2} alignItems="center">
                <Typography variant="h5">{formattedSelfNightly}</Typography>
                {isAdmin && (
                  <IconButton onClick={() => setIsSelfNightlyEditing(true)}>
                    <EditIcon />
                  </IconButton>
                )}
              </Stack>
            </Stack>
            <Stack
              sx={{ display: isSelfNightlyEditing ? 'flex' : 'none' }}
              direction="row"
              gap={2}
              alignItems="center"
              paddingBottom={1}
            >
              <TextField
                id="selfNightly"
                label="Noturno (20:00 às 05:59)"
                type="text"
                {...register('selfNightly', {
                  required: 'O valor é obrigatório',
                })}
                error={!!errors?.selfNightly}
                helperText={errors?.selfNightly?.message}
              />
              {isAdmin && (
                <IconButton
                  onClick={() => {
                    setIsSelfNightlyEditing(false);
                    resetField('selfNightly');
                  }}
                >
                  <CloseIcon />
                </IconButton>
              )}
            </Stack>

            <LinearProgress
              variant="determinate"
              color={
                percentageUsedSelfNightly < 70
                  ? 'primary'
                  : percentageUsedSelfNightly < 90
                  ? 'warning'
                  : 'error'
              }
              value={percentageUsedSelfNightly}
            />
            <Typography variant="caption" color="text.secondary">
              Utilizado: {formattedUsedSelfNightly} (
              {formattedRemainingSelfNightly} disponíveis)
            </Typography>
          </Stack>
        </Stack>

        <Typography variant="h5">Terceiros</Typography>
        <Stack direction="row" gap={2}>
          <Stack width={1} flexBasis="50%" direction="column">
            <Stack
              sx={{ display: isDailyEditing ? 'none' : 'flex' }}
              width={1}
              direction="column"
              paddingBottom={1}
            >
              <Typography variant="caption" color="text.secondary">
                Diurno (06:00 às 19:59)
              </Typography>
              <Stack direction="row" gap={2} alignItems="center">
                <Typography variant="h5">{formattedDaily}</Typography>
                {isAdmin && (
                  <IconButton onClick={() => setIsDailyEditing(true)}>
                    <EditIcon />
                  </IconButton>
                )}
              </Stack>
            </Stack>
            <Stack
              sx={{ display: isDailyEditing ? 'flex' : 'none' }}
              direction="row"
              gap={2}
              alignItems="center"
              paddingBottom={1}
            >
              <TextField
                id="daily"
                label="Diurno (0h:00 às 19:59)"
                type="text"
                {...register('daily', {
                  required: 'O valor é obrigatório',
                })}
                error={!!errors?.daily}
                helperText={errors?.daily?.message}
              />
              {isAdmin && (
                <IconButton
                  onClick={() => {
                    setIsDailyEditing(false);
                    resetField('daily');
                  }}
                >
                  <CloseIcon />
                </IconButton>
              )}
            </Stack>

            <LinearProgress
              variant="determinate"
              color={
                percentageUsedDaily < 70
                  ? 'primary'
                  : percentageUsedDaily < 90
                  ? 'warning'
                  : 'error'
              }
              value={percentageUsedDaily}
            />
            <Typography variant="caption" color="text.secondary">
              Utilizado: {formattedUsedDaily} ({formattedRemainingDaily}{' '}
              disponíveis)
            </Typography>
          </Stack>
          <Stack width={1} flexBasis="50%" direction="column">
            <Stack
              sx={{ display: isNightlyEditing ? 'none' : 'flex' }}
              width={1}
              direction="column"
              paddingBottom={1}
            >
              <Typography variant="caption" color="text.secondary">
                Noturno (20:00 às 05:59)
              </Typography>
              <Stack direction="row" gap={2} alignItems="center">
                <Typography variant="h5">{formattedNightly}</Typography>
                {isAdmin && (
                  <IconButton onClick={() => setIsNightlyEditing(true)}>
                    <EditIcon />
                  </IconButton>
                )}
              </Stack>
            </Stack>
            <Stack
              sx={{ display: isNightlyEditing ? 'flex' : 'none' }}
              direction="row"
              gap={2}
              alignItems="center"
              paddingBottom={1}
            >
              <TextField
                id="nightly"
                label="Noturno (20:00 às 05:59)"
                type="text"
                {...register('nightly', {
                  required: 'O valor é obrigatório',
                })}
                error={!!errors?.nightly}
                helperText={errors?.nightly?.message}
              />
              {isAdmin && (
                <IconButton
                  onClick={() => {
                    setIsNightlyEditing(false);
                    resetField('nightly');
                  }}
                >
                  <CloseIcon />
                </IconButton>
              )}
            </Stack>

            <LinearProgress
              variant="determinate"
              color={
                percentageUsedNightly < 70
                  ? 'primary'
                  : percentageUsedNightly < 90
                  ? 'warning'
                  : 'error'
              }
              value={percentageUsedNightly}
            />
            <Typography variant="caption" color="text.secondary">
              Utilizado: {formattedUsedNightly} ({formattedRemainingNightly}{' '}
              disponíveis)
            </Typography>
          </Stack>
        </Stack>
      </EditFormContent>

      {isAdmin && (
        <EditFormAction
          display="flex"
          flexDirection="row"
          justifyContent="flex-end"
          paddingTop={2}
        >
          <LoadingButton
            onClick={() => setIsConfirmationOpen(true)}
            type="submit"
            disabled={!isDirty}
            loading={isRequestingNewPixLimits}
            loadingPosition="start"
            startIcon={<SaveIcon />}
            variant="contained"
          >
            Salvar
          </LoadingButton>
        </EditFormAction>
      )}
      <ConfirmationDialog
        open={isConfirmationOpen}
        title="Confirmação"
        actions={[
          {
            name: 'Cancelar',
            buttonProps: {
              autoFocus: true,
              onClick: () => {
                setIsConfirmationOpen(false);
              },
            },
          },
          {
            name: 'Enviar',
            showLoading: true,
            buttonProps: {
              variant: 'contained',
              color: 'primary',
              startIcon: <CheckIcon />,
              onClick: handleChangePixLimitsClick,
            },
          },
        ]}
      >
        <Stack direction="column" gap={2}>
          <Typography>Deseja solicitar mudança nos limites? (*)</Typography>
          <Detail
            label="Mesma Titularidade Diurno"
            value={format(selfDaily, 'money')}
          />
          <Detail
            label="Mesma Titularidade Noturno"
            value={format(selfNightly, 'money')}
          />
          <Detail label="Terceiros Diurno" value={format(daily, 'money')} />
          <Detail label="Terceiros Noturno" value={format(nightly, 'money')} />
          <Typography variant="caption" component="span">
            (*) A solicitação de alteração de limite está sujeita à análise e
            poderá demorar até 48 horas para ser aprovada.
          </Typography>
        </Stack>
      </ConfirmationDialog>
    </EditForm>
  );
}
