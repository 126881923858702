import {
  Alert,
  Box,
  CircularProgress,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import dayjs from 'dayjs';
import { useNavigate } from 'react-router-dom';
import { Models } from 'types';
import { WarrantyExecutionStatus } from '../../../components/WarrantyExecutionStatus/WarrantyExecutionStatus';
import { format } from '../../../helpers/format';
import { useAppSelector } from '../../../helpers/hooks';
import {
  getWarrantyExecutionDate,
  getWarrantyExecutionStatus,
} from '../../../helpers/payment-request-status';
import { getFilterUrl } from '../VarejistaListaPagamentos/get-filter-url';
import { ListaPagamento, LoadReceivablesRequest } from '../retailer.types';
import { DashboardCard } from './DashboardCard';

type ActiveCardProps = {
  receivableParams: LoadReceivablesRequest;
  paymentParams: Models.LoadPaymentRequestsForRetailerRequest;
};
export const ActiveCard = ({ paymentParams }: ActiveCardProps) => {
  let navigate = useNavigate();

  const { isLoadingPaymentRequests, paymentRequests, suppliers } =
    useAppSelector((state) => state.retailer);

  const activeStatus: Models.PaymentRequestStatus[] = ['ativo'];

  const activePaymentRequests = paymentRequests.filter(
    (p) =>
      activeStatus.includes(p.status) &&
      getWarrantyExecutionStatus(p) !== 'overdue'
  );
  const sumActivePayments = activePaymentRequests
    .map(({ totalValue }) => totalValue)
    .reduce((acc, cur) => {
      acc += cur;
      return acc;
    }, 0);

  const enhancedData: ListaPagamento[] = activePaymentRequests.map((d) => {
    const supplier = suppliers.find((r) => r.id === d.idSupplier);
    return {
      idSupplier: d.idSupplier,
      id: d.id,
      externalId: d.externalId,
      fornecedor: supplier ? supplier.fantasy ?? supplier.name : '...',
      numero: d.id,
      dataPedido: dayjs(d.dateadded).format('YYYYMMDD'),
      dataVencimento: d.dueDate,
      dataInicio: d.firstIncomingDate,
      operacao:
        d.effectType === 'garantia' ? 'Garantia' : 'Troca de Titularidade',
      valorTotal: d.totalValue,
      status: d.status,
      detalhes: 'pagamento-1',
      effectType: d.effectType,
      prioridade: d.effectPriority,
      statusExecucaoGarantia: getWarrantyExecutionStatus(d),
      dataExecucao: getWarrantyExecutionDate(d),
    };
  });

  const executionAlert = !!enhancedData.find(
    ({ statusExecucaoGarantia }) => statusExecucaoGarantia === 'executing'
  );
  const nearDueDateAlert = !!enhancedData.find(
    ({ statusExecucaoGarantia }) => statusExecucaoGarantia === 'near_due_date'
  );

  return (
    <DashboardCard
      onClick={() =>
        navigate(getFilterUrl('/varejista-pagamentos', paymentParams))
      }
      title="A pagar"
      value={
        isLoadingPaymentRequests
          ? '...'
          : sumActivePayments > 0
          ? format(sumActivePayments, 'money', { decimals: 0 })
          : '-'
      }
      color={sumActivePayments > 0 ? 'error.main' : 'text.secondary'}
    >
      {isLoadingPaymentRequests ? (
        <Box
          p={2}
          display="flex"
          alignItems="center"
          justifyContent="center"
          flexGrow={1}
          height={1}
        >
          <CircularProgress color="error" />
        </Box>
      ) : (
        <Stack direction="column" p={2} gap={1}>
          {nearDueDateAlert && (
            <Alert severity="warning" variant="outlined">
              Existem pagamentos próximos ao vencimento. Faça o pagamento para
              evitar a execução da garantia!
            </Alert>
          )}
          {executionAlert && (
            <Alert severity="error" variant="filled">
              Existem pagamentos em execução. Não é mais possível fazer o
              pagamento do pedido!
            </Alert>
          )}

          <TableContainer>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>Fornecedor</TableCell>
                  <TableCell align="center">ID do pedido</TableCell>
                  <TableCell align="center">Vencimento</TableCell>
                  <TableCell align="center">Execução</TableCell>
                  <TableCell align="center">Valor</TableCell>
                  <TableCell align="center"></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {enhancedData.map((row) => {
                  return (
                    <TableRow
                      key={row.id}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">
                        {row.fornecedor}
                      </TableCell>
                      <TableCell align="center">{row.externalId}</TableCell>
                      <TableCell align="center">
                        {format(row.dataVencimento, 'date')}
                      </TableCell>
                      <TableCell align="center">
                        {format(row.dataExecucao, 'date')}
                      </TableCell>
                      <TableCell align="center">
                        {format(row.valorTotal, 'money')}
                      </TableCell>
                      <TableCell align="center">
                        {row.statusExecucaoGarantia && (
                          <WarrantyExecutionStatus
                            status={row.statusExecucaoGarantia}
                          />
                        )}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Stack>
      )}
    </DashboardCard>
  );
};
