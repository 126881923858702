import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Box, IconButton, Stack, Typography } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import copy from 'copy-to-clipboard';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { format } from '../../../helpers/format';
import { useAppSelector } from '../../../helpers/hooks';
import { getWarrantyExecutionDate } from '../../../helpers/payment-request-status';
import { notifierActions } from '../../notifier/notifier-slice';
import BankAccountInfo from './BankAccountInfo';

type DetailProps = {
  label: string;
  value?: string;
  children?: React.ReactNode;
};

const Detail = ({ label, value, children }: DetailProps) => (
  <Box>
    <Typography variant="body2" color="textSecondary" component="p">
      {label}:
    </Typography>
    {value && (
      <Typography variant="body1" color="textPrimary" component="p">
        {' '}
        {value ?? '-'}
      </Typography>
    )}
    {children}
  </Box>
);

function PaymentRequestInfo() {
  const {
    selectedPaymentRequest,
    isLoadingSelectedPaymentRequest,
    selectedRetailer,
    isLoadingSelectedRetailer,
  } = useAppSelector((state) => state.supplier);

  const dispatch = useDispatch();

  const { isDebugActivated } = useAppSelector((state) => state.devTools);
  const [showMore, setShowMore] = useState(false);
  const [isCopied, setIsCopied] = useState(false);

  const retailerInfo = `${
    selectedRetailer?.fantasy ?? selectedRetailer?.name ?? '-'
  } - ${format(selectedRetailer?.docNumber, 'docNumber') ?? '-'}`;

  const mainContactInfo = `${selectedRetailer?.mainContactName ?? '-'} - ${
    format(selectedRetailer?.mainContactPhone, 'phone') ?? '-'
  } - ${selectedRetailer?.mainContactEmail ?? '-'}`;

  //   Cliente
  // Operação
  // ID do pedido
  // Data do Pedido
  // Data de Vencimento
  // Data de Início
  // Valor Total
  // Status
  // Prioridade

  return (
    <Grid2 container spacing={2}>
      <Grid2 xs={12}>
        <Stack
          direction="row"
          gap={2}
          justifyContent="space-between"
          alignItems="center"
        >
          <Detail
            label="Cliente"
            value={isLoadingSelectedRetailer ? '...' : retailerInfo}
          />
          <Stack direction="row" gap={0} alignItems="flex-end">
            <Detail
              label="Código do Contrato"
              value={
                isLoadingSelectedPaymentRequest ||
                selectedPaymentRequest?.status !== 'ativo'
                  ? '...'
                  : selectedPaymentRequest?.id ?? '-'
              }
            />
            {!isLoadingSelectedPaymentRequest &&
              selectedPaymentRequest?.id &&
              selectedPaymentRequest?.status === 'ativo' && (
                <IconButton
                  size="small"
                  onClick={() => {
                    copy(selectedPaymentRequest.id);
                    dispatch(
                      notifierActions.enqueue({
                        message:
                          'O código do contrato foi copiado para a área de transferência',
                        options: {
                          variant: 'success',
                        },
                      })
                    );
                    setIsCopied(true);
                  }}
                >
                  {isCopied ? (
                    <DoneAllIcon fontSize="small" color="success" />
                  ) : (
                    <ContentCopyIcon fontSize="small" />
                  )}
                </IconButton>
              )}
          </Stack>
          <Detail
            label="Data de Vencimento"
            value={
              isLoadingSelectedPaymentRequest
                ? '...'
                : selectedPaymentRequest?.effectType === 'garantia'
                ? format(selectedPaymentRequest?.dueDate, 'date') ?? '-'
                : '-'
            }
          />
          <Detail
            label="Valor do Pedido"
            value={
              isLoadingSelectedPaymentRequest
                ? '...'
                : format(selectedPaymentRequest?.originalValue, 'money') ?? '-'
            }
          />
          <Box>
            <IconButton onClick={() => setShowMore(!showMore)}>
              {showMore ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </IconButton>
          </Box>
        </Stack>
      </Grid2>
      {showMore && (
        <>
          <Grid2 xs={8}>
            <Detail
              label="Responsável"
              value={isLoadingSelectedRetailer ? '...' : mainContactInfo}
            />
          </Grid2>
          <Grid2 xs={4}>
            <Detail
              label="Operação"
              value={
                isLoadingSelectedPaymentRequest
                  ? '...'
                  : selectedPaymentRequest?.effectType === 'garantia'
                  ? 'Garantia'
                  : 'Troca de Titularidade' ?? '-'
              }
            />
          </Grid2>

          <Grid2 xs={4}>
            <Detail
              label="Data do Pedido"
              value={
                isLoadingSelectedPaymentRequest
                  ? '...'
                  : format(selectedPaymentRequest?.dateadded, 'date') ?? '-'
              }
            />
          </Grid2>

          <Grid2 xs={4}>
            <Detail
              label="Data de Execução"
              value={
                isLoadingSelectedPaymentRequest
                  ? '...'
                  : selectedPaymentRequest
                  ? format(
                      getWarrantyExecutionDate(selectedPaymentRequest),
                      'date'
                    ) ?? '-'
                  : '-'
              }
            />
          </Grid2>
          <Grid2 xs={4}>
            <Detail
              label="Valor Total"
              value={
                isLoadingSelectedPaymentRequest
                  ? '...'
                  : format(selectedPaymentRequest?.totalValue, 'money') ?? '-'
              }
            />
          </Grid2>

          {(selectedPaymentRequest?.status === 'aprovado' ||
            selectedPaymentRequest?.status === 'ativo') && (
            <>
              <Grid2 xs={4}>
                <Detail
                  label="Confirmado Em"
                  value={
                    isLoadingSelectedPaymentRequest
                      ? '...'
                      : format(
                          selectedPaymentRequest?.dateConfirmed,
                          'dateTime'
                        ) ?? '-'
                  }
                />
              </Grid2>
              <Grid2 xs={4}>
                <Detail
                  label="Confirmado por"
                  value={
                    isLoadingSelectedPaymentRequest
                      ? '...'
                      : selectedPaymentRequest?.confirmedByName ??
                        selectedPaymentRequest?.confirmedByEmail ??
                        '-'
                  }
                />
              </Grid2>
            </>
          )}
          {selectedPaymentRequest?.status === 'nao_aprovado' && (
            <>
              <Grid2 xs={4}>
                <Detail
                  label="Não aprovado em"
                  value={
                    isLoadingSelectedPaymentRequest
                      ? '...'
                      : format(
                          selectedPaymentRequest?.dateRejected,
                          'dateTime'
                        ) ?? '-'
                  }
                />
              </Grid2>
              <Grid2 xs={4}>
                <Detail
                  label="Não aprovado por"
                  value={
                    isLoadingSelectedPaymentRequest
                      ? '...'
                      : selectedPaymentRequest?.rejectedByName ??
                        selectedPaymentRequest?.rejectedByEmail ??
                        '-'
                  }
                />
              </Grid2>
              <Grid2 xs={4}>
                <Detail
                  label="Motivo da não aprovação"
                  value={
                    isLoadingSelectedPaymentRequest
                      ? '...'
                      : selectedPaymentRequest?.rejectedReason ?? '-'
                  }
                />
              </Grid2>
            </>
          )}
          {isDebugActivated && (
            <>
              <Grid2 xs={4}>
                <Detail
                  label="Prioridade"
                  value={
                    isLoadingSelectedPaymentRequest
                      ? '...'
                      : selectedPaymentRequest?.effectPriority?.toString() ??
                        '-'
                  }
                />
              </Grid2>
              <Grid2 xs={12}>
                <BankAccountInfo />
              </Grid2>
            </>
          )}
        </>
      )}
    </Grid2>
  );
}

export default PaymentRequestInfo;
