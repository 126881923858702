import { Models } from 'types';

export const getSettlementStatus = (
  status: Models.SettlementStatus
): string => {
  switch (status) {
    case 'aguardando_liquidacao':
      return 'Aguardando Liquidação';
    case 'finalizado':
      return 'Finalizado';
    case 'em_contestacao':
      return 'Em Contestação';
  }
};
