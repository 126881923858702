import CancelIcon from '@mui/icons-material/Cancel';
import SaveIcon from '@mui/icons-material/Save';
import {
  AutocompleteOwnerState,
  AutocompleteRenderOptionState,
  Box,
  InputAdornment,
  Stack,
  Typography,
  createFilterOptions,
} from '@mui/material';
import Button from '@mui/material/Button';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Models } from 'types';
import { AutocompleteFieldWithControl } from '../../../components/AutocompleteField/AutocompleteFieldWithControl';
import { DateFieldWithControl } from '../../../components/DateField/DateFieldWithControl';
import EditForm from '../../../components/EditForm/EditForm';
import EditFormAction from '../../../components/EditForm/EditFormAction';
import EditFormContent from '../../../components/EditForm/EditFormContent';
import EditFormHeader from '../../../components/EditForm/EditFormHeader';
import {
  RadioGroupField,
  RadioGroupFieldOption,
} from '../../../components/RadioGroupField/RadioGroupField';
import { OptionsFilterValues } from '../../../components/SelectField/SelectFieldWithControl';
import { MaskedInputWithRef } from '../../../components/TextField/CurrencyMaskedInput';
import { TextFieldWithControl } from '../../../components/TextField/TextFieldWithControl';
import { priceMask } from '../../../helpers/create-number-mask';
import { format } from '../../../helpers/format';
import { useAppDispatch, useAppSelector } from '../../../helpers/hooks';
import { dayjsBusinessTime } from '../../../helpers/operating-hours';
import { parseMaskedInput } from '../../../helpers/safe-parse';
import { supplierActions } from '../supplier.slice';
import { AddPaymentSubForm } from '../supplier.types';

type NovoPagamentoProps = {
  onCloseClick?: () => void;
  onSaveClick: (_data: AddPaymentSubForm) => void;
  paymentFormData?: AddPaymentSubForm;
  idRetailer?: string;
};

type CompanyOption = {
  id: string;
  name: string;
  docNumber: string;
};

export default function NovoPagamento({
  onCloseClick,
  paymentFormData,
  onSaveClick,
  idRetailer: idRetailerProp,
}: NovoPagamentoProps) {
  const { supplierWarrantyConfig, supplierOwnershipAssignmentConfig } =
    useAppSelector((state) => state.settings);
  const { isOperatingHours } =
    useAppSelector((state) => state.app.operatingHours) ?? {};
  const { selectedRetailer, retailers } = useAppSelector(
    (state) => state.supplier
  );
  const dispatch = useAppDispatch();

  const effectTypes: RadioGroupFieldOption<Models.PaymentRequestEffectType>[] =
    [];
  if (supplierWarrantyConfig?.enabled)
    effectTypes.push({
      label: 'Garantia',
      value: 'garantia',
    });
  if (supplierOwnershipAssignmentConfig?.enabled)
    effectTypes.push({
      label: 'Troca de Titularidade',
      value: 'transferencia',
    });

  useEffect(() => {
    const fieldElement = document.getElementById('idRetailer');
    // const fieldElement = elements[0] as HTMLInputElement;
    if (fieldElement) {
      fieldElement.focus();
    }
    if (retailers.length === 0) dispatch(supplierActions.loadRetailers());
  }, []);

  const selectedIdRetailer = idRetailerProp ?? selectedRetailer?.id;

  const retailersOptions: OptionsFilterValues<CompanyOption>[] =
    retailers && retailers.length > 0
      ? retailers.map((company) => {
          const { id, name, fantasy, docNumber } = company;
          return {
            value: {
              id,
              name: fantasy ?? name,
              docNumber,
            },
            label: `${fantasy ?? name} - ${format(docNumber, 'docNumber')}`,
          };
        })
      : selectedIdRetailer
      ? [
          {
            value: { id: selectedIdRetailer, name: '', docNumber: '' },
            label: '',
          },
        ]
      : [];

  const defaultValues: AddPaymentSubForm = {
    paymentDueDate:
      paymentFormData?.paymentDueDate ??
      dayjsBusinessTime().format('YYYY-MM-DD'),
    externalId: paymentFormData?.externalId ?? '',
    effectType: paymentFormData?.effectType ?? effectTypes[0]?.value ?? '',
    originalValue: paymentFormData?.originalValue ?? '',
    idRetailer: idRetailerProp ?? selectedRetailer?.id ?? null,
  };

  const { handleSubmit, control, watch } = useForm<AddPaymentSubForm>({
    mode: 'onChange',
    defaultValues,
  });
  const effectType = watch('effectType');

  const onSubmit = (data: AddPaymentSubForm) => {
    onSaveClick(data);
  };

  const renderOption = (
    props: React.HTMLAttributes<HTMLLIElement>,
    option: OptionsFilterValues<CompanyOption>,
    _state: AutocompleteRenderOptionState,
    _ownerState: AutocompleteOwnerState<any, false, false, false, 'div'>
  ) => {
    return (
      <Box {...props} key={option.value.id} component="li">
        <Stack direction="column" alignItems="flex-start">
          <Typography variant="body1">{option.value.name}</Typography>
          <Typography variant="caption" color="text.secondary">
            {format(option.value.docNumber, 'docNumber')}
          </Typography>
        </Stack>
      </Box>
    );
  };

  const isOptionEqualToValue = (
    option: OptionsFilterValues<CompanyOption>,
    value: string
  ) => {
    return option.value.id === value;
  };

  const getOptionLabel = (id?: string) => {
    const option = retailersOptions.find((r) => r.value.id === id);
    if (!option) return '-';
    if (option.value.name === '') return '-';
    return `${option.value.name} - ${format(
      option.value.docNumber,
      'docNumber'
    )}`;
  };

  const getOptionValue = (option: CompanyOption) => {
    return option.id;
  };

  const filterOptions = createFilterOptions({
    stringify: (option: OptionsFilterValues<CompanyOption>) => {
      return `${option.value.name} ${option.value.docNumber} ${format(
        option.value.docNumber,
        'docNumber'
      )}`;
    },
  });

  return (
    <EditForm>
      <EditFormHeader title="Novo Pagamento" onCloseClick={onCloseClick} />
      <form onSubmit={handleSubmit(onSubmit)}>
        <EditFormContent display="flex" flexDirection="column" gap={2}>
          <AutocompleteFieldWithControl<CompanyOption>
            key="idRetailer"
            label="Cliente"
            name="idRetailer"
            control={control}
            options={retailersOptions}
            rules={{ required: 'Campo obrigatório' }}
            renderOption={renderOption}
            isOptionEqualToValue={isOptionEqualToValue}
            getOptionLabel={getOptionLabel}
            getOptionValue={getOptionValue}
            filterOptions={filterOptions}
          />
          <RadioGroupField
            label="Operação"
            name="effectType"
            color="primary"
            control={control}
            options={effectTypes}
          />

          <Box
            sx={{
              ...(effectType === 'transferencia' && {
                display: 'none',
              }),
            }}
          >
            <DateFieldWithControl
              name="paymentDueDate"
              label="Data de vencimento"
              control={control}
              rules={{
                validate: (v) => {
                  if (v === undefined)
                    return 'A data de vencimento é obrigatória';
                },
              }}
              textFieldProps={{
                id: 'paymentDueDate',
                fullWidth: true,
              }}
            />
          </Box>
          <TextFieldWithControl
            id="originalValue"
            name="originalValue"
            label="Valor do pedido"
            type="text"
            control={control}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">R$</InputAdornment>
              ),
              inputComponent: MaskedInputWithRef as any,
            }}
            inputProps={{
              mask: priceMask,
            }}
            rules={{
              validate: (v) => {
                if (v === undefined || v === '')
                  return 'O valor do pedido é obrigatório';
                const val = parseMaskedInput(v);
                if (isNaN(val)) return 'Valor inválido';
                if (val === 0) return 'O valor precisa ser maior do que zero';
                return true;
              },
            }}
          />
          <TextFieldWithControl
            id="externalId"
            name="externalId"
            label="ID do pedido"
            type="text"
            control={control}
            rules={{
              required: 'O numero do pedido é obrigatório',
            }}
          />
        </EditFormContent>
        <EditFormAction
          display="flex"
          gap={2}
          flexDirection="row"
          justifyContent="flex-end"
          paddingTop={2}
        >
          <Button
            onClick={onCloseClick}
            startIcon={<CancelIcon />}
            variant="outlined"
          >
            Cancelar
          </Button>
          <Button
            type="submit"
            startIcon={<SaveIcon />}
            variant="contained"
            disabled={!isOperatingHours}
          >
            Salvar
          </Button>
        </EditFormAction>
      </form>
    </EditForm>
  );
}
