import { configureStore } from '@reduxjs/toolkit';

import createSagaMiddleware from 'redux-saga';

import admin from '../features/admin/admin.slice';
import app from '../features/app/app-slice';
import bankAccount from '../features/bank-account/bank-account-slice';
import companySelect from '../features/company-select/company-select-slice';
import devTools from '../features/dev-tools/dev-tools-slice';
import notifier from '../features/notifier/notifier-slice';
import onboarding from '../features/onboarding/onboarding-slice';
import profile from '../features/profile/profile-slice';
import registrar from '../features/registrar/registrar-slice';
import retailer from '../features/retailer/retailer.slice';
import settings from '../features/settings/settings-slice';
import supplier from '../features/supplier/supplier.slice';
import rootSaga from '../sagas';

const saga = createSagaMiddleware();

const store = configureStore({
  reducer: {
    app,
    devTools,
    notifier,
    onboarding,
    supplier,
    retailer,
    companySelect,
    settings,
    profile,
    bankAccount,
    admin,
    registrar,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredPaths: [
          'notifier.notifications',
          'notifier.visibleNotifications',
          'payload.navigate',
        ],
        ignoredActions: [
          'notifier/enqueue',
          'onboarding/completeOnboarding',
          'onboarding/completeSupplierOnboarding',
          'supplier/createPaymentRequest',
          'retailer/confirmPayment',
          'supplier/addRetailer',
          'supplier/saveConciliationResult',
          'settings/updateDocs',
          'settings/getDownloadLink',
          'admin/addSupplier',
          'supplier/deletePaymentRequest',
          'supplier/archivePaymentRequest',
          'supplier/calcSuggestedValues',
        ],
      },
    }).concat(saga),
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

saga.run(rootSaga);
export default store;
