import { Models, Partner, Service } from 'types';
import { apiCall } from '../helpers/api';
const service: Service = 'supplier';

export class WebAPIClientHelpers {
  public static get = async (): Promise<Models.WebApiClientConfig[]> => {
    const data = await apiCall<Models.WebApiClientConfig[]>({
      service,
      resource: '/web/api-client',
    });
    return data;
  };

  public static deleteAll = async () => {
    await apiCall({
      service,
      resource: '/web/api-client',
      kyOpts: {
        method: 'delete',
      },
    });
  };

  public static create = async (): Promise<Models.WebApiClientConfig> => {
    const created = await apiCall<Models.WebApiClientConfig>({
      service,
      resource: '/web/api-client',
      kyOpts: {
        method: 'post',
      },
    });
    return created;
  };

  public static rotateSecret = async (
    clientId: string
  ): Promise<Models.WebApiClientConfig> => {
    const updated = await apiCall<Models.WebApiClientConfig>({
      service,
      resource: `/web/api-client/${clientId}/rotate-secret`,
      kyOpts: {
        method: 'post',
      },
    });
    return updated;
  };

  public static saveConfig = async (
    clientId: string,
    payload: Partial<Omit<Partner.APIClient, 'id' | 'dateadded'>>
  ): Promise<Partner.APIClient> => {
    const saved = await apiCall<Partner.APIClient>({
      service,
      resource: `/web/api-client/${clientId}/save-config`,
      kyOpts: {
        method: 'patch',
      },
      payload,
    });
    return saved;
  };
}
