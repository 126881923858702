import {
  Autocomplete,
  AutocompleteChangeDetails,
  AutocompleteChangeReason,
  AutocompleteOwnerState,
  AutocompleteRenderOptionState,
  Box,
  FilterOptionsState,
  FormControl,
  TextField,
} from '@mui/material';
import React, { SyntheticEvent } from 'react';
import { Control, RegisterOptions, useController } from 'react-hook-form';

export type OptionsFilterValues<T = string> = {
  value: T;
  label: string;
};

type AutocompleteFieldWithControlProps<T> = {
  key: string;
  name: string;
  label: string;
  control: Control<any, any>;
  options: OptionsFilterValues<T>[];
  rules?: RegisterOptions<any, string>;
  disabled?: boolean;
  onChange?: (_value: any) => void;
  renderOption?:
    | ((
        _props: React.HTMLAttributes<HTMLLIElement>,
        _option: any,
        _state: AutocompleteRenderOptionState,
        _ownerState: AutocompleteOwnerState<any, false, false, false, 'div'>
      ) => React.ReactNode)
    | undefined;
  isOptionEqualToValue?: ((_option: any, _value: any) => boolean) | undefined;
  getOptionLabel?: ((_option: any) => string) | undefined;
  getOptionValue?: ((_option: any) => string) | undefined;
  filterOptions?:
    | ((_options: any[], _state: FilterOptionsState<any>) => any[])
    | undefined;
  loading?: boolean;
};

export const AutocompleteFieldWithControl = <T,>(
  props: AutocompleteFieldWithControlProps<T>
) => {
  const {
    control,
    rules,
    label,
    name,
    options,
    onChange,
    disabled,
    renderOption,
    isOptionEqualToValue,
    getOptionLabel,
    getOptionValue,
    filterOptions,
    loading,
  } = props;
  const {
    field: { onChange: onFieldChange, value },
    fieldState: { error },
  } = useController({
    name,
    control,
    rules,
  });

  const handleChange = (
    _event: SyntheticEvent<Element, Event>,
    option: OptionsFilterValues,
    _reason: AutocompleteChangeReason,
    _details?: AutocompleteChangeDetails<unknown> | undefined
  ) => {
    onFieldChange(
      option?.value
        ? getOptionValue
          ? getOptionValue(option.value)
          : option.value
        : null
    );
    if (onChange)
      onChange(
        option?.value
          ? getOptionValue
            ? getOptionValue(option.value)
            : option.value
          : null
      );
  };

  return (
    <Box sx={{ minWidth: 80 }}>
      <FormControl fullWidth>
        <Autocomplete
          id={name}
          value={value}
          onChange={handleChange}
          disabled={disabled}
          options={options}
          selectOnFocus
          handleHomeEndKeys
          autoHighlight
          autoSelect
          renderOption={renderOption}
          isOptionEqualToValue={isOptionEqualToValue}
          getOptionLabel={getOptionLabel}
          filterOptions={filterOptions}
          loading={loading}
          loadingText="Carregando..."
          renderInput={(params) => (
            <TextField
              {...params}
              name={name}
              label={label}
              error={!!error}
              helperText={error?.message}
            />
          )}
        />
      </FormControl>
    </Box>
  );
};
