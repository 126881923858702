import {
  Box,
  LinearProgress,
  SxProps,
  Theme,
  alpha,
  useTheme,
} from '@mui/material';
import {
  ColumnMenuPropsOverrides,
  DataGrid,
  GridColDef,
  GridColumnMenuProps,
  GridDensity,
  GridEventListener,
  GridSlots,
  gridClasses,
  useGridApiRef,
} from '@mui/x-data-grid';
import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import { JSXElementConstructor } from 'react';
import { format } from '../../helpers/format';
import { TableColumn, TableData } from './table.types';
dayjs.extend(localizedFormat);

export const ODD_OPACITY = 0.4;

type DataTableProps<T> = {
  data: TableData<T>[];
  columns: TableColumn<T>[];
  isLoading?: boolean;
  sx?: SxProps<Theme>;
  density?: GridDensity;
  columnMenu?:
    | JSXElementConstructor<GridColumnMenuProps & ColumnMenuPropsOverrides>
    | undefined;
  columnMenuIcon?: JSXElementConstructor<any> | undefined;
  columnMenuProps?: Partial<GridColumnMenuProps & ColumnMenuPropsOverrides>;
  onRowClick?: GridEventListener<'rowClick'>;
  hideFooter?: boolean;
  disableColumnMenu?: boolean;
  marginBottom?: number;
};
const DataTable = <T extends object>({
  data,
  columns,
  isLoading,
  sx,
  density,
  columnMenu,
  columnMenuIcon,
  columnMenuProps,
  onRowClick,
  hideFooter,
  disableColumnMenu,
  marginBottom,
}: DataTableProps<T>) => {
  const shownColumns = columns.filter(({ hidden }) => !hidden);
  const apiRef = useGridApiRef();
  const muiTheme = useTheme();

  const dataGridColumns: GridColDef[] = shownColumns.map(
    (c) =>
      ({
        field: c.id,
        flex: c.flex ?? 1,
        headerName: c.label,
        renderCell: c.renderer
          ? (params) => {
              if (c.renderer) return c.renderer(params.value, params.row);
            }
          : undefined,
        valueFormatter: c.type ? (v) => format(v, c.type) : c.formatter,
        align: c.center ? 'center' : 'left',
        headerAlign: c.center ? 'center' : 'left',
        cellClassName: c.cellClassName,
      } as GridColDef)
  );

  const dataGridRows = isLoading
    ? []
    : data.map(
        (row, id) =>
          ({
            id,
            ...row,
          } as T & { id: string })
      );

  return (
    <Box sx={{ overflowX: 'auto', overflowY: 'auto' }}>
      <Box marginBottom={marginBottom}>
        <DataGrid
          disableColumnFilter
          density={density ?? 'compact'}
          apiRef={apiRef}
          slots={{
            columnMenu,
            columnMenuIcon,
            loadingOverlay: LinearProgress as GridSlots['loadingOverlay'],
          }}
          slotProps={{
            columnMenu: columnMenuProps,
          }}
          disableRowSelectionOnClick
          disableColumnMenu={disableColumnMenu}
          onRowClick={onRowClick}
          autoHeight
          hideFooter={hideFooter}
          rows={dataGridRows}
          columns={dataGridColumns}
          loading={isLoading}
          sx={{
            '& .MuiTablePagination-selectLabel': {
              color: 'text.secondary',
            },
            '& .MuiTablePagination-displayedRows': {
              color: 'text.secondary',
            },
            [`& .${gridClasses.row}.even`]: {
              backgroundColor: alpha(
                muiTheme.palette.background.secondary,
                ODD_OPACITY
              ),
            },
            [`& .${gridClasses.row}.even:hover`]: {
              backgroundColor: alpha(muiTheme.palette.text.primary, 0.08),
            },
            ...(!!onRowClick && {
              [`& .${gridClasses.row}`]: {
                cursor: 'pointer',
              },
            }),
            ...sx,
          }}
          getRowClassName={(params) =>
            params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
          }
        />
      </Box>
    </Box>
  );
};

export default DataTable;
