import { Alert, Button, Stack } from '@mui/material';
import { useEffect, useState } from 'react';
import { PresenceType, useIdleTimer } from 'react-idle-timer';
import EditForm from '../../components/EditForm/EditForm';
import EditFormAction from '../../components/EditForm/EditFormAction';
import EditFormContent from '../../components/EditForm/EditFormContent';
import EditFormHeader from '../../components/EditForm/EditFormHeader';
import ResponsiveDialog from '../../components/ResponsiveDialog/ResponsiveDialog';

export type IdleDetectorProps = {
  onExpired: () => void;
};

export const IdleDetector = ({ onExpired }: IdleDetectorProps) => {
  const [remaining, setRemaining] = useState<number>(0);
  const [isIdlePromptOpen, setIsIdlePromptOpen] = useState(false);

  const onIdle = () => {
    setIsIdlePromptOpen(false);
  };

  const onActive = () => {
    setIsIdlePromptOpen(false);
  };

  const onPresenceChange = async (presence: PresenceType) => {
    if (presence.type === 'idle') onExpired();
  };

  const onPrompt = () => {
    setIsIdlePromptOpen(true);
  };

  const { activate, getRemainingTime } = useIdleTimer({
    onIdle,
    onActive,
    onPresenceChange,
    onPrompt,
    timeout: 600_000,
    throttle: 500,
    promptBeforeIdle: 45_000,
  });

  const handleStillHere = () => {
    activate();
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setRemaining(Math.ceil(getRemainingTime() / 1000));
    }, 500);

    return () => {
      clearInterval(interval);
    };
  });

  return (
    <>
      <ResponsiveDialog open={isIdlePromptOpen} maxWidth="xs" fullWidth>
        <EditForm>
          <EditFormHeader title="Inatividade" />

          <EditFormContent display="flex" flexDirection="column" gap={2}>
            <Alert severity="warning">
              Atenção! Você ficará offline em {remaining} segundos.
            </Alert>
          </EditFormContent>
          <EditFormAction>
            <Stack alignItems="center" justifyContent="center" paddingTop={2}>
              <Button variant="contained" onClick={handleStillHere}>
                Permanecer online
              </Button>
            </Stack>
          </EditFormAction>
        </EditForm>
      </ResponsiveDialog>
    </>
  );
};
