import AddIcon from '@mui/icons-material/Add';
import CheckIcon from '@mui/icons-material/Check';
import SaveIcon from '@mui/icons-material/Save';
import { LoadingButton } from '@mui/lab';
import {
  Alert,
  Box,
  CircularProgress,
  Divider,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import Button from '@mui/material/Button';
import Grid2 from '@mui/material/Unstable_Grid2';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import ConfirmationDialog from '../../components/ConfirmationDialog/ConfirmationDialog';
import EditForm from '../../components/EditForm/EditForm';
import EditFormAction from '../../components/EditForm/EditFormAction';
import EditFormContent from '../../components/EditForm/EditFormContent';
import EditFormHeader from '../../components/EditForm/EditFormHeader';
import { SwitchField } from '../../components/SwitchField/SwitchField';
import { MaskedInputWithRef } from '../../components/TextField/CurrencyMaskedInput';
import { TextFieldWithControl } from '../../components/TextField/TextFieldWithControl';
import { digitsMask } from '../../helpers/create-number-mask';
import { getFirestoreConsoleLink } from '../../helpers/firebase';
import { useAppDispatch, useAppSelector } from '../../helpers/hooks';
import { parseMaskedInput } from '../../helpers/safe-parse';
import { getNumbersRegex } from '../../helpers/validations';
import useUserRole from '../../hooks/useUserRole';
import FirebaseIcon from '../../icons/FirebaseIcon';
import { appActions } from '../app/app-slice';
import { SaveSupplierSettingsForm } from '../app/app.types';
import { settingsActions } from './settings-slice';

export default function SupplierSettings() {
  const dispatch = useAppDispatch();
  const { isLoading, company } = useAppSelector((state) => state.app);
  const { isDebugActivated } = useAppSelector((state) => state.devTools);

  const { supplierOwnershipAssignmentConfig, supplierWarrantyConfig } =
    useAppSelector((state) => state.settings);

  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
  const [formDataToSave, setFormDataToSave] = useState<
    SaveSupplierSettingsForm | undefined
  >(undefined);
  const [isFormOpen, setIsFormOpen] = useState(false);
  const { isAdmin } = useUserRole();

  const {
    handleSubmit,
    formState: { isDirty, errors },
    watch,
    register,
    control,
  } = useForm<SaveSupplierSettingsForm>({
    defaultValues: {
      warrantyEnabled: supplierWarrantyConfig?.enabled ?? false,
      warrantyRequireRetailerApproval:
        supplierWarrantyConfig?.requireRetailerApproval ?? true,
      warrantyDaysToAdd: supplierWarrantyConfig?.daysToAdd ?? 2,
      warrantyUseBusinessDays: supplierWarrantyConfig?.useBusinessDays ?? true,
      warrantyFine:
        supplierWarrantyConfig?.fine?.toString().replaceAll('.', ',') ?? '2',
      warrantyInterestRate:
        supplierWarrantyConfig?.interestRate?.toString().replaceAll('.', ',') ??
        '10',
      // warrantyEnabledNotifications:
      //   supplierWarrantyConfig?.enabledNotifications ??
      //   (Object.keys(
      //     WARRANTY_NOTIFICATIONS
      //   ) as Models.WarrantyMailTemplateName[]),
      ownershipAssignmentEnabled:
        supplierOwnershipAssignmentConfig?.enabled ?? false,
      ownershipAssignmentRequireRetailerApproval:
        supplierOwnershipAssignmentConfig?.requireRetailerApproval ?? true,
      ownershipAssignmentDaysToAdd:
        supplierOwnershipAssignmentConfig?.daysToAdd ?? 2,
      ownershipAssignmentUseBusinessDays:
        supplierOwnershipAssignmentConfig?.useBusinessDays ?? true,
      ownershipAssignmentFine:
        supplierOwnershipAssignmentConfig?.fine
          ?.toString()
          .replaceAll('.', ',') ?? '0',
      ownershipAssignmentInterestRate:
        supplierOwnershipAssignmentConfig?.interestRate
          ?.toString()
          .replaceAll('.', ',') ?? '10',
      // ownershipAssignmentEnabledNotifications:
      //   supplierOwnershipAssignmentConfig?.enabledNotifications ??
      //   (Object.keys(
      //     OWNERSHIP_ASSIGNMENT_NOTIFICATIONS
      //   ) as Models.OwnershipAssignmentMailTemplateName[]),
    },
  });

  const [
    ownershipAssignmentEnabled,
    warrantyEnabled,
    warrantyUseBusinessDays,
    ownershipAssignmentUseBusinessDays,
  ] = watch([
    'ownershipAssignmentEnabled',
    'warrantyEnabled',
    'warrantyUseBusinessDays',
    'ownershipAssignmentUseBusinessDays',
  ]);

  const onSubmit = async (data: SaveSupplierSettingsForm) => {
    console.log('data: ', data);
    setFormDataToSave(data);
    setIsConfirmationOpen(true);
  };

  const onConfirmClick = () => {
    if (formDataToSave) {
      dispatch(settingsActions.saveSupplierSettings(formDataToSave));
      setIsConfirmationOpen(false);
    }
  };

  const handleCloseClick = () => {
    dispatch(appActions.setIsSupplierSettingsOpen(false));
    setIsConfirmationOpen(false);
  };

  return (
    <EditForm>
      <EditFormHeader title="Configurações" onCloseClick={handleCloseClick} />

      <EditFormContent display="flex" flexDirection="column" gap={2}>
        {isLoading ? (
          <CircularProgress />
        ) : (
          <>
            {!supplierOwnershipAssignmentConfig && !isFormOpen ? (
              <Button
                startIcon={<AddIcon />}
                variant="outlined"
                onClick={() => setIsFormOpen(true)}
              >
                Criar uma nova configuração
              </Button>
            ) : (
              <>
                {!supplierOwnershipAssignmentConfig?.enabled &&
                  !supplierWarrantyConfig?.enabled && (
                    <Alert variant="outlined" severity="error">
                      Atenção! É necessário ativar pelo menos um tipo de
                      operação!
                    </Alert>
                  )}

                <Grid2 container spacing={1}>
                  <Grid2 xs={12}>
                    <Divider />
                  </Grid2>
                  <Grid2 xs={12}>
                    <Stack direction="row" gap={1} alignItems="center">
                      <Typography variant="h6">Operação de Garantia</Typography>
                      <SwitchField
                        control={control}
                        name="warrantyEnabled"
                        label=""
                      />
                      {isDebugActivated && (
                        <>
                          <Box flexGrow={1}></Box>
                          <IconButton
                            target="_blank"
                            rel="noopener noreferrer"
                            href={getFirestoreConsoleLink(
                              `/supplier-warranty-config/${company?.id}`
                            )}
                          >
                            <FirebaseIcon />
                          </IconButton>
                        </>
                      )}
                    </Stack>
                  </Grid2>
                  <Grid2 sm={12} md={6}>
                    <SwitchField
                      disabled={!warrantyEnabled}
                      control={control}
                      name="warrantyRequireRetailerApproval"
                      label="Precisa de aprovação do varejista"
                    />
                  </Grid2>
                  <Grid2 sm={12} md={6}>
                    <SwitchField
                      disabled={!warrantyEnabled}
                      control={control}
                      name="warrantyUseBusinessDays"
                      label="Considerar apenas dias úteis"
                    />
                  </Grid2>
                  <Grid2 sm={12} md={6}>
                    <TextField
                      disabled={!warrantyEnabled}
                      id="warrantyDaysToAdd"
                      label={`Dias ${
                        warrantyUseBusinessDays ? 'úteis' : 'corridos'
                      } para adicionar ao vencimento`}
                      type="text"
                      {...register('warrantyDaysToAdd', {
                        required: 'O campo é obrigatório',
                        validate: (v) => (v < 4 ? 'O valor mínimo é 4' : true),
                        pattern: {
                          value: getNumbersRegex(),
                          message: 'Somente números são aceitos',
                        },
                      })}
                      error={!!errors?.warrantyDaysToAdd}
                      helperText={errors?.warrantyDaysToAdd?.message}
                      fullWidth
                    />
                  </Grid2>
                  {/* <Grid2 sm={12} md={6}>
                    <MultiSelectField
                      disabled={!warrantyEnabled}
                      name="warrantyEnabledNotifications"
                      label="Notificações"
                      type="text"
                      control={control}
                      options={Object.entries(WARRANTY_NOTIFICATIONS).map(
                        ([value, label]) => ({
                          value,
                          label,
                        })
                      )}
                      // error={!!errors?.warrantyEnabledNotifications}
                      // helperText={errors?.warrantyEnabledNotifications?.message}
                    />
                  </Grid2> */}

                  <Grid2 sm={12} md={6}>
                    <TextFieldWithControl
                      disabled={!warrantyEnabled}
                      id="warrantyFine"
                      name="warrantyFine"
                      label="Multa após vencimento"
                      type="text"
                      fullWidth
                      control={control}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">%</InputAdornment>
                        ),
                        inputComponent: MaskedInputWithRef as any,
                      }}
                      inputProps={{
                        mask: digitsMask,
                      }}
                      rules={{
                        validate: (v) => {
                          if (v === undefined || v === '')
                            return 'O valor do pedido é obrigatório';
                          const val = parseMaskedInput(v);
                          if (isNaN(val)) return 'Valor inválido';
                          if (val < 0)
                            return 'O valor precisa ser igual ou maior do que zero';
                          return true;
                        },
                      }}
                    />
                  </Grid2>

                  <Grid2 sm={12} md={6}>
                    <TextFieldWithControl
                      disabled={!warrantyEnabled}
                      id="warrantyInterestRate"
                      name="warrantyInterestRate"
                      label="Taxa de juros após vencimento"
                      type="text"
                      fullWidth
                      control={control}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            % ao mês
                          </InputAdornment>
                        ),
                        inputComponent: MaskedInputWithRef as any,
                      }}
                      inputProps={{
                        mask: digitsMask,
                      }}
                      rules={{
                        validate: (v) => {
                          if (v === undefined || v === '')
                            return 'O valor do pedido é obrigatório';
                          const val = parseMaskedInput(v);
                          if (isNaN(val)) return 'Valor inválido';
                          if (val < 0)
                            return 'O valor precisa ser igual ou maior do que zero';
                          return true;
                        },
                      }}
                    />
                  </Grid2>
                  <Grid2 xs={12}>
                    <Divider />
                  </Grid2>

                  <Grid2 xs={12}>
                    <Stack direction="row" gap={1} alignItems="center">
                      <Typography variant="h6">
                        Operação de Troca de Titularidade
                      </Typography>
                      <SwitchField
                        control={control}
                        name="ownershipAssignmentEnabled"
                        label=""
                      />
                      {isDebugActivated && (
                        <>
                          <Box flexGrow={1}></Box>
                          <IconButton
                            target="_blank"
                            rel="noopener noreferrer"
                            href={getFirestoreConsoleLink(
                              `/supplier-ownership-assignment-config/${company?.id}`
                            )}
                          >
                            <FirebaseIcon />
                          </IconButton>
                        </>
                      )}
                    </Stack>
                  </Grid2>
                  <Grid2 sm={12} md={6}>
                    <SwitchField
                      disabled={!ownershipAssignmentEnabled}
                      control={control}
                      name="ownershipAssignmentRequireRetailerApproval"
                      label="Precisa de aprovação do varejista"
                    />
                  </Grid2>
                  <Grid2 sm={12} md={6}>
                    <SwitchField
                      disabled={!ownershipAssignmentEnabled}
                      control={control}
                      name="ownershipAssignmentUseBusinessDays"
                      label="Considerar apenas dias úteis"
                    />
                  </Grid2>
                  <Grid2 sm={12} md={6}>
                    <TextField
                      disabled={!ownershipAssignmentEnabled}
                      id="ownershipAssignmentDaysToAdd"
                      label={`Dias ${
                        ownershipAssignmentUseBusinessDays
                          ? 'úteis'
                          : 'corridos'
                      } para adicionar`}
                      type="text"
                      {...register('ownershipAssignmentDaysToAdd', {
                        required: 'O campo é obrigatório',
                        validate: (v) => (v < 4 ? 'O valor mínimo é 4' : true),
                        pattern: {
                          value: getNumbersRegex(),
                          message: 'Somente números são aceitos',
                        },
                      })}
                      error={!!errors?.ownershipAssignmentDaysToAdd}
                      helperText={errors?.ownershipAssignmentDaysToAdd?.message}
                      fullWidth
                    />
                  </Grid2>

                  <Grid2 sm={12} md={6}>
                    <TextFieldWithControl
                      disabled={!warrantyEnabled}
                      id="ownershipAssignmentInterestRate"
                      name="ownershipAssignmentInterestRate"
                      label="Custo de capital"
                      type="text"
                      fullWidth
                      control={control}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            % ao mês
                          </InputAdornment>
                        ),
                        inputComponent: MaskedInputWithRef as any,
                      }}
                      inputProps={{
                        mask: digitsMask,
                      }}
                      rules={{
                        validate: (v) => {
                          if (v === undefined || v === '')
                            return 'O valor do pedido é obrigatório';
                          const val = parseMaskedInput(v);
                          if (isNaN(val)) return 'Valor inválido';
                          if (val < 0)
                            return 'O valor precisa ser igual ou maior do que zero';
                          return true;
                        },
                      }}
                    />
                  </Grid2>
                </Grid2>
              </>
            )}
          </>
        )}
      </EditFormContent>
      {isAdmin && (
        <EditFormAction
          display="flex"
          flexDirection="row"
          justifyContent="flex-end"
          paddingTop={2}
        >
          <LoadingButton
            onClick={handleSubmit(onSubmit)}
            type="submit"
            disabled={!isDirty}
            loading={isLoading}
            loadingPosition="start"
            startIcon={<SaveIcon />}
            variant="contained"
          >
            Salvar
          </LoadingButton>
        </EditFormAction>
      )}
      <ConfirmationDialog
        open={isConfirmationOpen}
        title="Confirmação"
        actions={[
          {
            name: 'Cancelar',
            buttonProps: {
              autoFocus: true,
              onClick: () => {
                setIsConfirmationOpen(false);
              },
            },
          },
          {
            name: 'Enviar',
            showLoading: true,
            buttonProps: {
              variant: 'contained',
              color: 'primary',
              startIcon: <CheckIcon />,
              onClick: onConfirmClick,
            },
          },
        ]}
      >
        Deseja atualizar as configurações?
      </ConfirmationDialog>
    </EditForm>
  );
}
