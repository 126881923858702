import { Models, Service } from 'types';
import { apiCall } from '../helpers/api';

type T = Models.Company;
const service: Service = 'supplier';
const resource = 'web/supplier-company';

export class WebSupplierCompanyHelpers {
  public static getById = async (id: string): Promise<T | undefined> => {
    try {
      return await apiCall<T>({
        service,
        resource: `${resource}/${id}`,
      });
    } catch (e) {
      if ((e as unknown as any)?.response?.status === 404) return undefined;
      throw e;
    }
  };
  public static listRetailers = async (): Promise<T[]> => {
    return await apiCall<T[]>({
      service,
      resource,
    });
  };
  // public static create = async (
  //   newData: Omit<T, 'id' | 'dateadded'>
  // ): Promise<string> => {
  //   const { id } = await apiCall<Pick<T, 'id'>>({
  //     service,
  //     resource: `${resource}`,
  //     payload: newData,
  //     kyOpts: { method: 'post' },
  //   });
  //   return id;
  // };
  // public static patch = async (id: string, dataToUpdate: Partial<T>) => {
  //   await apiCall<Pick<T, 'id'>>({
  //     service,
  //     resource: `${resource}/${id}`,
  //     payload: dataToUpdate,
  //     kyOpts: { method: 'patch' },
  //   });
  //   return id;
  // };
}
