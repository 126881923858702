import CheckIcon from '@mui/icons-material/Check';
import DeleteIcon from '@mui/icons-material/Delete';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import SaveIcon from '@mui/icons-material/Save';
import {
  Box,
  Button,
  Card,
  CircularProgress,
  Stack,
  Tab,
  Tabs,
  Typography,
} from '@mui/material';
import { Constants } from 'pr-constants';
import { v4 as uuid } from 'uuid';

import { LoadingButton } from '@mui/lab';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import ConfirmationDialog from '../../../components/ConfirmationDialog/ConfirmationDialog';
import EditForm from '../../../components/EditForm/EditForm';
import EditFormContent from '../../../components/EditForm/EditFormContent';
import EditFormHeader from '../../../components/EditForm/EditFormHeader';
import { format } from '../../../helpers/format';
import { useAppDispatch, useAppSelector } from '../../../helpers/hooks';
import useIsSmallScreen from '../../../hooks/useIsSmallScreen';
import { supplierActions } from '../supplier.slice';
import {
  ExtratoVarejista,
  ListaLiquidacoesParaConciliacao,
  MarkedConciliation,
} from '../supplier.types';
import ConciliationCard from './ConciliationCard';
import SettlementCard from './SettlementCard';
import TransactionCard from './TransactionCard';

export default function ConciliacaoVarejista() {
  const navigate = useNavigate();
  const {
    paymentRequestDetails,
    paymentRequests,
    retailerBankAccountTransactions,
    retailerTransactionConciliations,
    isSavingRetailerConciliation,
    isLoadingRetailerSettlementsForConciliation,
  } = useAppSelector((state) => state.supplier);
  const [currentTab, setCurrentTab] = useState(0);
  const isSmallScreen = useIsSmallScreen();

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setCurrentTab(newValue);
  };

  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
  const [selectedSettlements, setSelectedSettlements] = useState<
    ListaLiquidacoesParaConciliacao[]
  >([]);
  const [selectedTransactions, setSelectedTransactions] = useState<
    ExtratoVarejista[]
  >([]);
  const [markedConciliations, setMarkedConciliations] = useState<
    MarkedConciliation[]
  >([]);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(supplierActions.loadRetailerSettlementsForConciliation());
  }, []);

  const handleCloseClick = () => {
    dispatch(supplierActions.setIsRetailerConciliationPanelOpen(false));
  };

  const [params] = useSearchParams();
  const idRetailer = params.get('idRetailer');
  const startDate = params.get('s') ?? dayjs().startOf('month');
  const endDate = params.get('e') ?? dayjs();

  const handleConfirmClick = () => {
    setIsConfirmationOpen(false);
    dispatch(
      supplierActions.saveConciliationResult({
        data: markedConciliations,
        navigate,
        currentUrl: `.?s=${startDate}&e=${endDate}`,
      })
    );
  };

  const settlements: ListaLiquidacoesParaConciliacao[] = paymentRequestDetails
    .map((d) => {
      const paymentRequest = paymentRequests.find(
        ({ id }) => id === d.idPaymentRequest
      );
      return { detail: d, paymentRequest };
    })
    .filter(({ paymentRequest }) => {
      return !!paymentRequest;
    })
    .map(({ detail, paymentRequest }) => {
      return {
        id: detail.id,
        idPaymentRequest: detail.idPaymentRequest,
        dataLiquidacao: detail.incomingDate,
        credenciadora: Constants.getDebtor(detail.debtor),
        bandeira: Constants.getPaymentScheme(detail.paymentScheme),
        valor: detail.value,
        status: detail.settlementStatus,
        statusPagamento: paymentRequest!.status,
        idPedido: paymentRequest!.externalId,
        operacao:
          paymentRequest!.effectType === 'garantia'
            ? 'Garantia'
            : 'Troca de Titularidade',
      };
    });

  const transactions: ExtratoVarejista[] = retailerBankAccountTransactions
    ? retailerBankAccountTransactions.data.map((d) => {
        const conciliation = (retailerTransactionConciliations ?? []).find(
          ({ transactionId }) => transactionId === d.id
        );
        const paymentRequestIds = conciliation
          ? conciliation.conciliationDetail.map(
              ({ idPaymentRequest }) => idPaymentRequest
            )
          : [];

        const filteredPaymentRequests = paymentRequestIds
          ? paymentRequests.filter(({ id }) => paymentRequestIds.includes(id))
          : [];
        return {
          id: d.id,
          data: d.createdAt,
          tipo: d.type,
          detalhe: d.description,
          valor: d.value,
          saldo: d.balance,
          idPedidos: filteredPaymentRequests.map(
            ({ externalId }) => externalId
          ),
          statusConciliacao:
            filteredPaymentRequests.length > 0
              ? 'finalizado'
              : 'aguardando_conciliacao',
        };
      })
    : [];

  const onSettlementClick = (s: ListaLiquidacoesParaConciliacao) => {
    if (selectedSettlements.find(({ id }) => id === s.id)) {
      setSelectedSettlements(
        selectedSettlements.filter(({ id }) => id !== s.id)
      );
    } else {
      setSelectedSettlements([...selectedSettlements, s]);
    }
  };

  const onTransactionClick = (s: ExtratoVarejista) => {
    if (selectedTransactions.find(({ id }) => id === s.id)) {
      setSelectedTransactions(
        selectedTransactions.filter(({ id }) => id !== s.id)
      );
    } else {
      setSelectedTransactions([...selectedTransactions, s]);
    }
  };

  const sumSelectedSettlements = selectedSettlements.reduce((acc, cur) => {
    acc += cur.valor;
    return acc;
  }, 0);
  const sumSelectedTransactions = selectedTransactions.reduce((acc, cur) => {
    acc += cur.valor;
    return acc;
  }, 0);

  const addConciliationClick = (status: 'finalizado' | 'em_contestacao') => {
    const newConciliation: MarkedConciliation = {
      tempId: uuid(),
      status,
      settlements: selectedSettlements,
      transactions: selectedTransactions,
    };
    setMarkedConciliations([...markedConciliations, newConciliation]);
    setSelectedSettlements([]);
    setSelectedTransactions([]);
  };

  const removeConciliationClick = ({ tempId }: MarkedConciliation) => {
    setMarkedConciliations(
      markedConciliations.filter(
        ({ tempId: tempIdToFind }) => tempIdToFind !== tempId
      )
    );
  };

  const resetMarkedConciliations = () => {
    setMarkedConciliations([]);
  };

  const availableSettlements = settlements.filter((s) => {
    return !markedConciliations.find(({ settlements }) =>
      settlements.find(({ id }) => s.id === id)
    );
  });

  const availableTransactions = transactions.filter(
    ({ id, statusConciliacao, valor }) =>
      statusConciliacao === 'aguardando_conciliacao' &&
      valor > 0 &&
      !markedConciliations.find(({ transactions }) =>
        transactions.find(({ id: idToFind }) => id === idToFind)
      )
  );

  console.log('markedConciliations: ', markedConciliations);
  const isSelectedSettlementsOk =
    sumSelectedSettlements === sumSelectedTransactions;

  const hasSelectedSettlements =
    selectedSettlements.length + selectedTransactions.length > 0;

  const selectedSettlementsColor = hasSelectedSettlements
    ? isSelectedSettlementsOk
      ? 'success.main'
      : 'error.main'
    : 'text.disabled';

  return (
    <EditForm sx={{ height: '100vh', overflow: 'hidden' }}>
      <EditFormHeader title="Conciliação" onCloseClick={handleCloseClick} />
      {isSmallScreen && (
        <Tabs value={currentTab} onChange={handleTabChange} centered>
          <Tab label="Liquidações" value={0} />
          <Tab label="Transações" value={1} />
        </Tabs>
      )}
      <EditFormContent
        display="flex"
        flexDirection="column"
        justifyContent="flex-start"
        gap={2}
        paddingBottom={2}
      >
        {isLoadingRetailerSettlementsForConciliation ? (
          <Stack direction="column" alignItems="center" p={8}>
            <CircularProgress />
          </Stack>
        ) : (
          <>
            <Card variant="outlined">
              <Stack direction="column" gap={2} p={2} alignItems="center">
                <Stack
                  direction="row"
                  gap={2}
                  justifyContent="center"
                  alignItems="center"
                >
                  <Card
                    variant="outlined"
                    sx={{
                      borderColor: selectedSettlementsColor,
                    }}
                  >
                    <Box p={2}>
                      <Typography
                        variant="caption"
                        color={selectedSettlementsColor}
                      >
                        Liquidações selecionadas
                      </Typography>
                      <Typography variant="h5" color={selectedSettlementsColor}>
                        {format(sumSelectedSettlements, 'money')}
                      </Typography>
                    </Box>
                  </Card>
                  <Typography variant="h4" color={selectedSettlementsColor}>
                    {isSelectedSettlementsOk ? '=' : '≠'}
                  </Typography>
                  <Card
                    variant="outlined"
                    sx={{
                      borderColor: selectedSettlementsColor,
                    }}
                  >
                    <Box p={2}>
                      <Typography
                        variant="caption"
                        color={selectedSettlementsColor}
                      >
                        Transações selecionadas
                      </Typography>
                      <Typography variant="h5" color={selectedSettlementsColor}>
                        {format(sumSelectedTransactions, 'money')}
                      </Typography>
                    </Box>
                  </Card>
                </Stack>
                {hasSelectedSettlements || markedConciliations.length > 0 ? (
                  <Stack direction="row" gap={2}>
                    <Button
                      variant="outlined"
                      disabled={
                        !hasSelectedSettlements || isSelectedSettlementsOk
                      }
                      onClick={() => addConciliationClick('em_contestacao')}
                      startIcon={<PriorityHighIcon />}
                    >
                      Contestar
                    </Button>
                    <Button
                      variant="outlined"
                      color={isSelectedSettlementsOk ? 'success' : 'error'}
                      disabled={
                        !hasSelectedSettlements || !isSelectedSettlementsOk
                      }
                      onClick={() => addConciliationClick('finalizado')}
                      startIcon={<CheckIcon />}
                    >
                      OK
                    </Button>
                  </Stack>
                ) : (
                  <Typography variant="h5">
                    Clique nos itens abaixo para iniciar a conciliação
                  </Typography>
                )}
              </Stack>
            </Card>
            <Box flexGrow={1} sx={{ height: '200px', overflowY: 'auto' }}>
              {availableTransactions.length + availableSettlements.length >
                0 && (
                <Box display={isSmallScreen ? 'default' : 'flex'} gap={2}>
                  <Box
                    flexBasis={isSmallScreen ? '100%' : '50%'}
                    sx={{
                      display:
                        !isSmallScreen || currentTab === 0 ? 'default' : 'none',
                    }}
                  >
                    <Stack direction="column" gap={2}>
                      {availableSettlements.length > 0 ? (
                        <Typography variant="h6" textAlign="center">
                          Liquidações
                        </Typography>
                      ) : (
                        <Typography variant="h6" textAlign="center">
                          Não existem mais liquidações a conciliar
                        </Typography>
                      )}
                      {availableSettlements.map((s) => (
                        <SettlementCard
                          settlement={s}
                          key={s.id}
                          onClick={onSettlementClick}
                          selected={
                            !!selectedSettlements.find(({ id }) => id === s.id)
                          }
                        />
                      ))}
                    </Stack>
                  </Box>
                  <Box
                    flexBasis={isSmallScreen ? '100%' : '50%'}
                    sx={{
                      display:
                        !isSmallScreen || currentTab === 1 ? 'default' : 'none',
                    }}
                  >
                    <Stack direction="column" gap={2}>
                      {availableTransactions.length > 0 ? (
                        <Typography variant="h6" textAlign="center">
                          Transações
                        </Typography>
                      ) : (
                        <Typography variant="h6" textAlign="center">
                          Não existem mais transações a conciliar
                        </Typography>
                      )}
                      {availableTransactions.map((t) => (
                        <TransactionCard
                          transaction={t}
                          key={t.id}
                          onClick={onTransactionClick}
                          selected={
                            !!selectedTransactions.find(({ id }) => id === t.id)
                          }
                        />
                      ))}
                    </Stack>
                  </Box>
                </Box>
              )}
              {markedConciliations.length > 0 && (
                <Card variant="outlined" sx={{ marginTop: 2 }}>
                  <Stack direction="column" alignItems="center" gap={2} p={2}>
                    <Typography variant="h6" textAlign="center">
                      Liquidações conciliadas
                    </Typography>
                    <Stack direction="row" gap={2} flexWrap="wrap">
                      {markedConciliations.map((conciliation) => (
                        <ConciliationCard
                          key={conciliation.tempId}
                          conciliation={conciliation}
                          onClick={removeConciliationClick}
                        />
                      ))}
                    </Stack>
                    <Stack direction="row" gap={2}>
                      <LoadingButton
                        loading={
                          isLoadingRetailerSettlementsForConciliation ||
                          isSavingRetailerConciliation
                        }
                        variant="outlined"
                        startIcon={<DeleteIcon />}
                        onClick={resetMarkedConciliations}
                      >
                        Limpar
                      </LoadingButton>
                      <LoadingButton
                        loading={
                          isLoadingRetailerSettlementsForConciliation ||
                          isSavingRetailerConciliation
                        }
                        variant="contained"
                        startIcon={<SaveIcon />}
                        onClick={() => setIsConfirmationOpen(true)}
                      >
                        Salvar
                      </LoadingButton>
                    </Stack>
                  </Stack>
                </Card>
              )}
            </Box>
          </>
        )}
      </EditFormContent>
      <ConfirmationDialog
        open={isConfirmationOpen}
        title="Confirmação"
        isLoading={isSavingRetailerConciliation}
        actions={[
          {
            name: 'Cancelar',
            buttonProps: {
              autoFocus: true,
              onClick: () => {
                setIsConfirmationOpen(false);
              },
            },
          },
          {
            name: 'Salvar',
            showLoading: true,
            buttonProps: {
              variant: 'contained',
              color: 'primary',
              startIcon: <CheckIcon />,
              onClick: handleConfirmClick,
            },
          },
        ]}
      >
        Deseja salvar o resultado da conciliação?
      </ConfirmationDialog>
    </EditForm>
  );
}
