import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward';
import CheckIcon from '@mui/icons-material/Check';
import SaveIcon from '@mui/icons-material/Save';
import LoadingButton from '@mui/lab/LoadingButton';
import {
  Alert,
  Box,
  Button,
  List,
  ListItem,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { CPFField } from '../../components/CPFField/CPFField';
import { CheckBoxField } from '../../components/CheckBoxField/CheckBoxField';
import FileUploadWithControl from '../../components/FileUpload/FileUploadWithControl';
import { PageHeader } from '../../components/PageHeader/PageHeader';
import { PhoneField } from '../../components/PhoneField/PhoneField';
import ResponsiveDialog from '../../components/ResponsiveDialog/ResponsiveDialog';
import { useAppDispatch, useAppSelector } from '../../helpers/hooks';
import {
  MimeType,
  validateFileSize,
  validateFileType,
} from '../../helpers/validations';
import useUserRole from '../../hooks/useUserRole';
import { SupplierOnboardingForm } from '../../types';
import PrivacyPolicySupplier from './PrivacyPolicySupplier';
import { onboardingActions } from './onboarding-slice';

function OnboardingSupplier() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { isLoading } = useAppSelector((state) => state.app);
  const { isAdmin } = useUserRole();

  const [isPrivacyPolicyOpen, setIsPrivacyPolicyOpen] = useState(false);
  const [hasReadPrivacy, setHasReadPrivacy] = useState(false);

  const {
    handleSubmit,
    control,
    register,
    formState: { errors },
  } = useForm<SupplierOnboardingForm>({
    defaultValues: {
      optinAccept: false,
      name: '',
      docNumber: '',
      phoneNumber: '',
      selfieWithDoc: '',
      docPicture: '',
      docPictureBack: '',
    },
  });

  const onSubmit = (data: SupplierOnboardingForm) => {
    const selfieWithDocFile = data.selfieWithDoc as unknown as File;
    const docPictureFile = data.docPicture as unknown as File;
    const docPictureBackFile = data.docPictureBack as unknown as File;

    dispatch(
      onboardingActions.completeSupplierOnboarding({
        data,
        navigate,
        selfieWithDocFile,
        docPictureFile,
        docPictureBackFile,
      })
    );
  };

  const maxFileMB = 8;
  const acceptedFileTypes: MimeType[] = [
    'application/pdf',
    'application/zip',
    'image/jpeg',
    'image/png',
  ];
  const canCompleteOnboarding = !isAdmin;

  return (
    <>
      <Stack direction="column" overflow="auto" gap={2}>
        {!canCompleteOnboarding && (
          <Box marginBottom={2}>
            <Alert severity="warning" variant="outlined">
              Atenção! O usuário logado não pode completar o cadastro. Entre com
              o e-mail convidado.
            </Alert>
          </Box>
        )}
        <form onSubmit={handleSubmit(onSubmit)}>
          <PageHeader title="Complete o cadastro" />
          <Grid2
            container
            sx={{ maxWidth: '1024px', marginX: 'auto' }}
            spacing={2}
          >
            <Grid2 xs={12} md={6}>
              <TextField
                fullWidth
                id="name"
                label="Nome"
                type="text"
                {...register('name', {
                  required: 'O nome é obrigatório',
                })}
                error={!!errors?.name}
                helperText={errors?.name?.message}
              />
            </Grid2>
            <Grid2 xs={12} md={6}>
              <CPFField
                fullWidth
                id="docNumber"
                type="text"
                placeholder="000.000.000-00"
                label="CPF"
                name="docNumber"
                control={control}
              />
            </Grid2>
            <Grid2 xs={12} md={6}>
              <PhoneField
                fullWidth
                id="phoneNumber"
                type="text"
                placeholder="(00) 00000-0000"
                label="Telefone"
                name="phoneNumber"
                control={control}
              />
            </Grid2>
            <Grid2 xs={12}>
              <Typography>
                Envie uma selfie segurando o documento (RG ou CNH) em frente ao
                rosto. Requisitos:
              </Typography>
            </Grid2>
            <Grid2 xs={12} md={6}>
              <List dense>
                <ListItem>
                  <Typography variant="body2">
                    - Utilize um fundo neutro
                  </Typography>
                </ListItem>
                <ListItem>
                  <Typography variant="body2">
                    - O documento deve estar legível
                  </Typography>
                </ListItem>
                <ListItem>
                  <Typography variant="body2">
                    - Não utilize acessórios no rosto
                  </Typography>
                </ListItem>
                <ListItem>
                  <Typography variant="body2">
                    - Retire o documento do plástico
                  </Typography>
                </ListItem>
                <ListItem>
                  <Typography variant="body2">
                    - Formatos aceitos: PDF/PNG/JPG com tamanho máximo de{' '}
                    {maxFileMB}MB
                  </Typography>
                </ListItem>
              </List>
            </Grid2>
            <Grid2 xs={12} md={6}>
              <Box display="flex" alignItems="center" flexDirection="column">
                <Box
                  component="img"
                  sx={{
                    maxWidth: 200,
                    borderColor: 'grey.500',
                    borderWidth: 1,
                    borderStyle: 'solid',
                    borderRadius: 2,
                  }}
                  alt="Selfie segurando o documento"
                  src="id-selfie.jpg"
                />
                <Typography variant="caption">Exemplo:</Typography>
              </Box>
            </Grid2>
            <Grid2 xs={12}>
              <FileUploadWithControl
                fullWidth
                name="selfieWithDoc"
                control={control}
                rules={{
                  required: 'O arquivo é obrigatório',
                  validate: (v) => {
                    const file = v as unknown as File;
                    if (!file) return 'O arquivo é obrigatório';
                    if (!validateFileSize(file, maxFileMB * 1024 * 1024))
                      return `O tamanho máximo deve ser de ${maxFileMB} MB`;

                    if (!validateFileType(file, acceptedFileTypes))
                      return 'Os tipos aceitos são PNG, JPG e PDF';
                    return true;
                  },
                }}
                label={`Selfie segurando o documento (PDF/PNG/JPG - máx ${maxFileMB}MB)`}
              />
            </Grid2>
            <Grid2 xs={12}>
              <Typography>
                Envie uma foto do documento (o mesmo da selfie). Requisitos:
              </Typography>
            </Grid2>
            <Grid2 xs={12} md={6}>
              <List dense>
                <ListItem>
                  <Typography variant="body2">
                    - Utilize um fundo neutro
                  </Typography>
                </ListItem>
                <ListItem>
                  <Typography variant="body2">
                    - O documento deve estar legível
                  </Typography>
                </ListItem>
                <ListItem>
                  <Typography variant="body2">
                    - Retire o documento do plástico
                  </Typography>
                </ListItem>
                <ListItem>
                  <Typography variant="body2">
                    - Use o primeiro campo abaixo para mandar uma foto única
                    frente/verso
                  </Typography>
                </ListItem>
                <ListItem>
                  <Typography variant="body2">
                    - Se for tirar 2 fotos (uma frente e outra verso), utilize
                    os 2 campos abaixo
                  </Typography>
                </ListItem>
                <ListItem>
                  <Typography variant="body2">
                    - Formatos aceitos: PDF/PNG/JPG com tamanho máximo de{' '}
                    {maxFileMB}MB
                  </Typography>
                </ListItem>
              </List>
            </Grid2>
            <Grid2 xs={12} md={6}>
              <Box display="flex" alignItems="center" flexDirection="column">
                <Box
                  component="img"
                  sx={{
                    maxWidth: 200,
                    borderColor: 'grey.500',
                    borderWidth: 1,
                    borderStyle: 'solid',
                    borderRadius: 2,
                  }}
                  alt="exemplo rg ou cnh"
                  src="cnh.jpg"
                />
                <Typography variant="caption">Exemplo:</Typography>
              </Box>
            </Grid2>
            <Grid2 xs={12} md={6}>
              <FileUploadWithControl
                fullWidth
                name="docPicture"
                control={control}
                rules={{
                  required: 'O arquivo é obrigatório',
                  validate: (v) => {
                    const file = v as unknown as File;
                    if (!file) return 'O arquivo é obrigatório';
                    if (!validateFileSize(file, maxFileMB * 1024 * 1024))
                      return `O tamanho máximo deve ser de ${maxFileMB} MB`;

                    if (!validateFileType(file, acceptedFileTypes))
                      return 'Os tipos aceitos são PNG, JPG e PDF';
                    return true;
                  },
                }}
                label={`RG ou CNH Frente ou Frente/Verso (PNG ou JPG - máx ${maxFileMB}MB)`}
              />
            </Grid2>
            <Grid2 xs={12} md={6}>
              <FileUploadWithControl
                fullWidth
                name="docPictureBack"
                control={control}
                rules={{
                  validate: (v) => {
                    const file = v as unknown as File;
                    if (!file) return true;
                    if (!validateFileSize(file, maxFileMB * 1024 * 1024))
                      return `O tamanho máximo deve ser de ${maxFileMB} MB`;

                    if (!validateFileType(file, acceptedFileTypes))
                      return 'Os tipos aceitos são PNG, JPG e PDF';
                    return true;
                  },
                }}
                label={`RG ou CNH Verso (PNG ou JPG - máx ${maxFileMB}MB)`}
              />
            </Grid2>
            <Grid2 xs={12}>
              <Box
                display="flex"
                justifyContent="flex-start"
                flexDirection="column"
                gap={2}
              >
                <Typography variant="body1">
                  Clique no botão para visualizar a política de privacidade
                </Typography>
                <Box display="flex" flexDirection="row" gap={2}>
                  <Button
                    variant="outlined"
                    color={hasReadPrivacy ? 'success' : 'primary'}
                    endIcon={
                      hasReadPrivacy ? <CheckIcon /> : <ArrowOutwardIcon />
                    }
                    onClick={() => setIsPrivacyPolicyOpen(true)}
                  >
                    Política de Privacidade
                  </Button>
                </Box>

                <CheckBoxField
                  control={control}
                  id="optinAccept"
                  name="optinAccept"
                  label="Declaro que li e aceito os termos de uso e política de privacidade"
                  customValidate={(v) => {
                    if (v && hasReadPrivacy) return true;
                    if (!hasReadPrivacy)
                      return 'Você deve ler a política de privacidade. Clique no botão POLÍTICA DE PRIVACIDADE acima para visualizar a política de privacidade';
                    if (!v)
                      return 'Você deve aceitar a declaração para poder continuar';
                  }}
                />
              </Box>
            </Grid2>
            <Grid2 xs={12}>
              {Object.keys(errors).length > 0 && (
                <Alert variant="outlined" severity="error">
                  Corrija os erros para prosseguir.
                </Alert>
              )}
            </Grid2>
            <Grid2 xs={12}>
              <Box pb={2} display="flex" justifyContent="center">
                <LoadingButton
                  onClick={handleSubmit(onSubmit)}
                  type="submit"
                  disabled={!canCompleteOnboarding}
                  loading={isLoading}
                  loadingPosition="start"
                  startIcon={<SaveIcon />}
                  variant="contained"
                >
                  Salvar
                </LoadingButton>
              </Box>
            </Grid2>
          </Grid2>
        </form>
      </Stack>
      <ResponsiveDialog
        open={isPrivacyPolicyOpen}
        maxWidth="xl"
        fullWidth
        onClose={() => setIsPrivacyPolicyOpen}
      >
        <PrivacyPolicySupplier
          onFinishReading={() => {
            setIsPrivacyPolicyOpen(false);
            setHasReadPrivacy(true);
          }}
        />
      </ResponsiveDialog>
    </>
  );
}

export default OnboardingSupplier;
