'use strict';
import { PayloadAction } from '@reduxjs/toolkit';
import { fork, put, select, take } from 'redux-saga/effects';
import { Models, Partner, RPC } from 'types';
import { Utils } from 'utils';
import { WebAPIClientHelpers } from '../../data-helpers/WebAPIClientHelpers';
import { WebDocumentHelpers } from '../../data-helpers/WebDocumentHelpers';
import { WebSettingsHelpers } from '../../data-helpers/WebSettingsHelpers';
import { WebSupplierUserGroupHelpers } from '../../data-helpers/WebSupplierUserGroupHelpers';
import { WebSupplierUserHelpers } from '../../data-helpers/WebSupplierUserHelpers';
import { rpcCall } from '../../helpers/api';
import { parseMaskedInput } from '../../helpers/safe-parse';
import {
  SaveSupplierUserForm,
  SaveSupplierUserGroupForm,
  UploadDocsFileRequest,
} from '../../types';
import { PartnerAPIForm } from '../admin/admin.types';
import { appActions } from '../app/app-slice';
import {
  AppState,
  SaveSupplierNotificationForm,
  SaveSupplierPersonalizationForm,
  SaveSupplierSettingsForm,
} from '../app/app.types';
import { notifierActions } from '../notifier/notifier-slice';
import { settingsActions } from './settings-slice';

function* watchUpdateDocs() {
  while (true) {
    try {
      const {
        payload: {
          optinFile,
          mainContactSelfieWithDocFile,
          mainContactDocPictureFile,
          mainContactDocPictureBackFile,
          companyConstitutionDocFile,
          bankContractFile,
        },
      }: PayloadAction<UploadDocsFileRequest> = yield take(
        settingsActions.updateDocs
      );
      yield put(appActions.startLoading());

      const { company }: AppState = yield select((state) => state.app);
      if (!company) throw new Error('Unexpected undefined company');

      let dataToSave: Models.WebUploadDocsRequest = {
        idCompany: company.id,
      };

      if (optinFile) {
        dataToSave.docIdOptin = yield WebDocumentHelpers.upload(optinFile);
      }
      if (mainContactSelfieWithDocFile)
        dataToSave.docIdMainContactSelfieWithDoc =
          yield WebDocumentHelpers.upload(mainContactSelfieWithDocFile);

      if (mainContactDocPictureFile)
        dataToSave.docIdMainContactDocPicture = yield WebDocumentHelpers.upload(
          mainContactDocPictureFile
        );

      if (mainContactDocPictureBackFile)
        dataToSave.docIdMainContactDocPictureBack =
          yield WebDocumentHelpers.upload(mainContactDocPictureBackFile);

      if (companyConstitutionDocFile)
        dataToSave.docIdCompanyConstitutionDoc =
          yield WebDocumentHelpers.upload(companyConstitutionDocFile);

      if (bankContractFile)
        dataToSave.docIdBankContract = yield WebDocumentHelpers.upload(
          bankContractFile
        );

      const updated: Models.Company = yield WebSettingsHelpers.updateDocuments(
        dataToSave
      );
      yield put(appActions.setCompany(updated));
      yield put(
        notifierActions.enqueue({
          message: 'Documentos atualizados com sucesso',
          options: {
            variant: 'success',
          },
        })
      );
      yield put(appActions.setIsDocumentsOpen(false));

      yield put(appActions.finishLoading());
    } catch (e) {
      yield put(appActions.setError((e as Error).message));
      yield put(appActions.finishLoading());
    }
  }
}

function* watchGetDownloadLink() {
  while (true) {
    const {
      payload: { id, buttonId },
    }: PayloadAction<{
      id: string;
      buttonId: string;
    }> = yield take(settingsActions.getDownloadLink);
    try {
      yield put(settingsActions.startLoadingButton(buttonId));
      const url: string = yield WebDocumentHelpers.getDownloadLink(id);
      if (window && window.open) {
        const newTab = window.open(url, '_blank');
        if (newTab) newTab.focus();
      }
      yield put(settingsActions.stopLoadingButton(buttonId));
    } catch (e) {
      yield put(appActions.setError((e as Error).message));
      yield put(settingsActions.stopLoadingButton(buttonId));
    }
  }
}

function* sendDocToBank(payload: Models.WebSendDocumentToBankRequest) {
  yield put(appActions.startLoading());
  const rpcCallResult: RPC.Call<
    Models.WebSendDocumentToBankRequest,
    Models.WebSendDocumentToBankResponse
  > = yield rpcCall({
    service: 'supplier',
    resource: '/web/document/send-to-bank',
    kyOpts: {
      method: 'post',
    },
    payload,
  });
  const { result, resultPayload, error } = rpcCallResult;

  if (result === 'success') {
    if (!resultPayload) throw new Error('Unexpected empty payload');
    const { updatedCompany, uploadResult } = resultPayload;
    yield put(appActions.setCompany(updatedCompany));
    yield put(
      notifierActions.enqueue({
        message: `Documento enviado com sucesso. ID: [${uploadResult.document_key}]`,
        options: {
          variant: 'success',
        },
      })
    );
    yield put(appActions.finishLoading());
  } else {
    yield put(appActions.setError(error ?? 'Error'));
    yield put(appActions.finishLoading());
  }
}

function* watchSendDocToBank() {
  while (true) {
    const { payload }: PayloadAction<Models.WebSendDocumentToBankRequest> =
      yield take(settingsActions.sendDocToBank);
    yield fork(sendDocToBank, payload);
  }
}

function* watchLoadSupplierConfig() {
  while (true) {
    yield take(settingsActions.loadSupplierConfig);
    try {
      yield put(appActions.startLoading());
      const {
        warranty,
        ownershipAssignment,
      }: Models.WebGetSupplierConfigResponse =
        yield WebSettingsHelpers.getSupplierConfig();
      if (warranty)
        yield put(settingsActions.setSupplierWarrantyConfig(warranty));
      if (ownershipAssignment)
        yield put(
          settingsActions.setSupplierOwnershipAssignmentConfig(
            ownershipAssignment
          )
        );
      yield put(appActions.finishLoading());
    } catch (e) {
      yield put(appActions.setError((e as Error).message));
      yield put(appActions.finishLoading());
    }
  }
}

function* watchSaveSupplierSettings() {
  while (true) {
    const { payload }: PayloadAction<SaveSupplierSettingsForm> = yield take(
      settingsActions.saveSupplierSettings
    );
    try {
      yield put(appActions.startLoading());
      const dataToSave: Models.WebUpdateSupplierConfigRequest = {
        warranty: {
          enabled: payload.warrantyEnabled,
          requireRetailerApproval: payload.warrantyRequireRetailerApproval,
          daysToAdd: payload.warrantyDaysToAdd,
          useBusinessDays: payload.warrantyUseBusinessDays,
          fine: parseMaskedInput(payload.warrantyFine),
          interestRate: parseMaskedInput(payload.warrantyInterestRate),
          // enabledNotifications: payload.warrantyEnabledNotifications,
        },
        ownershipAssignment: {
          enabled: payload.ownershipAssignmentEnabled,
          requireRetailerApproval:
            payload.ownershipAssignmentRequireRetailerApproval,
          daysToAdd: payload.ownershipAssignmentDaysToAdd,
          useBusinessDays: payload.ownershipAssignmentUseBusinessDays,
          fine: parseMaskedInput(payload.ownershipAssignmentFine),
          interestRate: parseMaskedInput(
            payload.ownershipAssignmentInterestRate
          ),
          // enabledNotifications: payload.ownershipAssignmentEnabledNotifications,
        },
      };
      yield WebSettingsHelpers.updateSupplierConfig(dataToSave);
      yield put(settingsActions.loadSupplierConfig());
      yield put(
        notifierActions.enqueue({
          message: 'Configurações atualizadas com sucesso.',
          options: {
            variant: 'success',
          },
        })
      );
      yield put(appActions.finishLoading());
    } catch (e) {
      yield put(appActions.setError((e as Error).message));
      yield put(appActions.finishLoading());
    }
  }
}

function* watchLoadSupplierPersonalizationConfig() {
  while (true) {
    yield take(settingsActions.loadSupplierPersonalizationConfig);
    try {
      yield put(appActions.startLoading());
      const config: Models.SupplierPersonalizationConfig =
        yield WebSettingsHelpers.getSupplierPersonalizationConfig();
      if (config)
        yield put(settingsActions.setSupplierPersonalizationConfig(config));
      yield put(appActions.finishLoading());
    } catch (e) {
      yield put(appActions.setError((e as Error).message));
      yield put(appActions.finishLoading());
    }
  }
}

function* watchSaveSupplierPersonalizationConfig() {
  while (true) {
    const { payload }: PayloadAction<SaveSupplierPersonalizationForm> =
      yield take(settingsActions.saveSupplierPersonalizationConfig);
    try {
      yield put(appActions.startLoading());
      const dataToSave: Partial<Models.SupplierPersonalizationConfig> = {
        customLogoUrl:
          payload.useCustomLogo && !!payload.customLogoUrl
            ? payload.customLogoUrl
            : null,
        customMailTemplateId:
          payload.useCustomMailTemplate && !!payload.customMailTemplateId
            ? payload.customMailTemplateId
            : null,
      };
      yield WebSettingsHelpers.updateSupplierPersonalizationConfig(dataToSave);
      yield put(settingsActions.loadSupplierPersonalizationConfig());
      yield put(
        notifierActions.enqueue({
          message: 'Configurações atualizadas com sucesso.',
          options: {
            variant: 'success',
          },
        })
      );
      yield put(appActions.finishLoading());
    } catch (e) {
      yield put(appActions.setError((e as Error).message));
      yield put(appActions.finishLoading());
    }
  }
}

function* watchLoadSupplierNotificationConfig() {
  while (true) {
    yield take(settingsActions.loadSupplierNotificationConfig);
    try {
      yield put(appActions.startLoading());
      const config: Models.SupplierNotificationConfig =
        yield WebSettingsHelpers.getSupplierNotificationConfig();
      if (config)
        yield put(settingsActions.setSupplierNotificationConfig(config));
      yield put(appActions.finishLoading());
    } catch (e) {
      yield put(appActions.setError((e as Error).message));
      yield put(appActions.finishLoading());
    }
  }
}

function* watchSaveSupplierNotificationConfig() {
  while (true) {
    const { payload }: PayloadAction<SaveSupplierNotificationForm> = yield take(
      settingsActions.saveSupplierNotificationConfig
    );
    try {
      yield put(appActions.startLoading());
      const dataToSave: Partial<Models.SupplierNotificationConfig> = {
        notificationItems: payload.notificationItems
          .filter(({ enabled }) => enabled)
          .map((item) => ({
            template: item.template,
            emails: item.emails,
          })),
      };
      yield WebSettingsHelpers.updateSupplierNotificationConfig(dataToSave);
      yield put(settingsActions.loadSupplierNotificationConfig());
      yield put(
        notifierActions.enqueue({
          message: 'Configurações atualizadas com sucesso.',
          options: {
            variant: 'success',
          },
        })
      );
      yield put(appActions.finishLoading());
    } catch (e) {
      yield put(appActions.setError((e as Error).message));
      yield put(appActions.finishLoading());
    }
  }
}

function* watchTestAllMailTemplates() {
  while (true) {
    const { payload: email }: PayloadAction<string> = yield take(
      settingsActions.testAllMailTemplates
    );
    try {
      yield put(appActions.startLoading());
      yield WebSettingsHelpers.testAllMailTemplates({ email });
      yield put(
        notifierActions.enqueue({
          message: 'Templates enviados com sucesso',
          options: {
            variant: 'success',
          },
        })
      );
      yield put(appActions.finishLoading());
    } catch (e) {
      yield put(appActions.setError((e as Error).message));
      yield put(appActions.finishLoading());
    }
  }
}

function* watchLoadSupplierAPIClient() {
  while (true) {
    yield take(settingsActions.loadSupplierAPIClient);
    try {
      yield put(appActions.startLoading());
      yield put(settingsActions.setSupplierAPIClientHelperText());
      const [client]: Models.WebApiClientConfig[] =
        yield WebAPIClientHelpers.get();
      yield put(settingsActions.setSupplierAPIClient(client));
      yield put(appActions.finishLoading());
    } catch (e) {
      yield put(appActions.setError((e as Error).message));
      yield put(appActions.finishLoading());
    }
  }
}

function* watchDeleteSupplierAPIClient() {
  while (true) {
    yield take(settingsActions.deleteSupplierAPIClient);
    try {
      yield put(
        settingsActions.startLoadingButton(
          settingsActions.deleteSupplierAPIClient.type
        )
      );
      yield put(settingsActions.setSupplierAPIClientHelperText());
      yield WebAPIClientHelpers.deleteAll();
      yield put(settingsActions.setSupplierAPIClient(undefined));
      yield put(
        notifierActions.enqueue({
          message: 'API excluída com sucesso',
          options: {
            variant: 'success',
          },
        })
      );
      yield put(
        settingsActions.setSupplierAPIClientHelperText(
          'ATENÇÃO! Todas as aplicações conectadas à API deixarão de funcionar!'
        )
      );
      yield put(
        settingsActions.stopLoadingButton(
          settingsActions.deleteSupplierAPIClient.type
        )
      );
    } catch (e) {
      yield put(appActions.setError((e as Error).message));
      yield put(appActions.finishLoading());
    }
  }
}

function* watchRotateAPIClientsSecret() {
  while (true) {
    const { payload: clientId }: PayloadAction<string> = yield take(
      settingsActions.rotateAPIClientsSecret
    );
    try {
      yield put(
        settingsActions.startLoadingButton(
          settingsActions.rotateAPIClientsSecret.type
        )
      );
      yield put(settingsActions.setSupplierAPIClientHelperText());
      const updated: Models.WebApiClientConfig =
        yield WebAPIClientHelpers.rotateSecret(clientId);
      yield put(settingsActions.setSupplierAPIClient(updated));
      yield put(
        notifierActions.enqueue({
          message: 'Segredo alterado com sucesso',
          options: {
            variant: 'success',
          },
        })
      );
      yield put(
        settingsActions.setSupplierAPIClientHelperText(
          'ATENÇÃO! As aplicações devem ser atualizadas com o novo segredo para que voltem a funcionar!'
        )
      );
      yield put(
        settingsActions.stopLoadingButton(
          settingsActions.rotateAPIClientsSecret.type
        )
      );
    } catch (e) {
      yield put(appActions.setError((e as Error).message));
      yield put(appActions.finishLoading());
    }
  }
}

function* watchCreateSupplierAPIClientConfig() {
  while (true) {
    yield take(settingsActions.createSupplierAPIClientConfig);
    try {
      yield put(
        settingsActions.startLoadingButton(
          settingsActions.createSupplierAPIClientConfig.type
        )
      );
      yield put(settingsActions.setSupplierAPIClientHelperText());
      const created: Models.WebApiClientConfig =
        yield WebAPIClientHelpers.create();
      yield put(settingsActions.setSupplierAPIClient(created));
      yield put(
        notifierActions.enqueue({
          message: 'API criada com sucesso',
          options: {
            variant: 'success',
          },
        })
      );
      yield put(
        settingsActions.stopLoadingButton(
          settingsActions.createSupplierAPIClientConfig.type
        )
      );
    } catch (e) {
      yield put(appActions.setError((e as Error).message));
      yield put(appActions.finishLoading());
    }
  }
}

function* watchSaveSupplierAPIClientConfig() {
  while (true) {
    const { payload }: PayloadAction<PartnerAPIForm> = yield take(
      settingsActions.saveSupplierAPIClientConfig
    );
    try {
      yield put(
        settingsActions.startLoadingButton(
          settingsActions.saveSupplierAPIClientConfig.type
        )
      );
      yield put(settingsActions.setSupplierAPIClientHelperText());
      const dataToSave: Partial<Omit<Partner.APIClient, 'id' | 'dateadded'>> = {
        hasWebhook: payload.hasWebhook,
        hasCustomHeader: payload.hasCustomHeader,
        hasCustomLimit: payload.hasCustomLimit,
        webhookUrl: payload.webhookUrl,
        customWebhookHeaders: payload.hasCustomHeader
          ? {
              [payload.customWebhookHeaderName]:
                payload.customWebhookHeaderValue,
            }
          : undefined,
        specialRateLimits: payload.hasCustomLimit
          ? {
              global: {
                limit: payload.globalRateLimit,
              },
              'new-customer': {
                limit: payload.newCustomerRateLimit,
              },
            }
          : undefined,
      };
      const updated: Models.WebApiClientConfig =
        yield WebAPIClientHelpers.saveConfig(payload.clientId, dataToSave);
      yield put(settingsActions.setSupplierAPIClient(updated));
      yield put(
        notifierActions.enqueue({
          message: 'Alterações salvas com sucesso',
          options: {
            variant: 'success',
          },
        })
      );
      yield put(
        settingsActions.stopLoadingButton(
          settingsActions.saveSupplierAPIClientConfig.type
        )
      );
    } catch (e) {
      yield put(appActions.setError((e as Error).message));
      yield put(appActions.finishLoading());
    }
  }
}

function* watchLoadSupplierUserGroups() {
  while (true) {
    yield take(settingsActions.loadSupplierUserGroups);
    try {
      yield put(settingsActions.setIsLoadingSupplierUserGroups(true));
      const data: Models.SupplierUserGroup[] =
        yield WebSupplierUserGroupHelpers.list();
      yield put(settingsActions.setSupplierUserGroups(data));
      yield put(settingsActions.setIsLoadingSupplierUserGroups(false));
    } catch (error) {
      yield put(appActions.setError((error as Error)?.message ?? 'Error'));
      yield put(settingsActions.setIsLoadingSupplierUserGroups(false));
    }
  }
}

function* watchLoadSupplierUsers() {
  while (true) {
    yield take(settingsActions.loadSupplierUsers);
    try {
      yield put(settingsActions.setIsLoadingSupplierUsers(true));
      const data: Models.User[] = yield WebSupplierUserHelpers.list();
      yield put(settingsActions.setSupplierUsers(data));
      yield put(settingsActions.setIsLoadingSupplierUsers(false));
    } catch (error) {
      yield put(appActions.setError((error as Error)?.message ?? 'Error'));
      yield put(settingsActions.setIsLoadingSupplierUsers(false));
    }
  }
}

function* watchSaveSupplierUserGroup() {
  while (true) {
    const { payload }: PayloadAction<SaveSupplierUserGroupForm> = yield take(
      settingsActions.saveSupplierUserGroup
    );
    try {
      yield put(settingsActions.setIsSavingOrDeletingSupplierUserGroups(true));
      const { mode, name, grants, id } = payload;
      if (mode === 'new')
        yield WebSupplierUserGroupHelpers.create({
          name: Utils.removeExtraSpaces(name),
          grants: grants
            .filter(({ checked }) => checked)
            .map(({ grant }) => grant),
        });
      else {
        if (id)
          yield WebSupplierUserGroupHelpers.patch(id, {
            name: Utils.removeExtraSpaces(name),
            grants: grants
              .filter(({ checked }) => checked)
              .map(({ grant }) => grant),
          });
      }
      yield put(settingsActions.setIsSavingOrDeletingSupplierUserGroups(false));
      yield put(settingsActions.loadSupplierUserGroups());
      yield put(settingsActions.setIsUserGroupEditFormOpen(false));
    } catch (error) {
      yield put(appActions.setError((error as Error)?.message ?? 'Error'));
      yield put(settingsActions.setIsSavingOrDeletingSupplierUserGroups(false));
    }
  }
}

function* watchDeleteSupplierUserGroup() {
  while (true) {
    const { payload: id }: PayloadAction<string> = yield take(
      settingsActions.deleteSupplierUserGroup
    );
    try {
      yield put(settingsActions.setIsSavingOrDeletingSupplierUserGroups(true));
      yield WebSupplierUserGroupHelpers.delete(id);

      yield put(settingsActions.setIsSavingOrDeletingSupplierUserGroups(false));
      yield put(settingsActions.loadSupplierUserGroups());
      yield put(settingsActions.setIsUserGroupEditFormOpen(false));
    } catch (error) {
      yield put(appActions.setError((error as Error)?.message ?? 'Error'));
      yield put(settingsActions.setIsSavingOrDeletingSupplierUserGroups(false));
    }
  }
}

function* watchSaveSupplierUser() {
  while (true) {
    const { payload }: PayloadAction<SaveSupplierUserForm> = yield take(
      settingsActions.saveSupplierUser
    );
    try {
      yield put(settingsActions.setIsSavingOrDeletingSupplierUsers(true));
      const { mode, email, id, idSupplierUserGroup } = payload;
      if (mode === 'new') {
        if (!email) throw new Error('Unexpected empty email');
        yield WebSupplierUserHelpers.create({
          email: Utils.removeExtraSpaces(email),
          idSupplierUserGroup,
        });
      } else {
        if (id)
          yield WebSupplierUserHelpers.patch(id, {
            idSupplierUserGroup,
          });
      }
      yield put(settingsActions.setIsSavingOrDeletingSupplierUsers(false));
      yield put(settingsActions.loadSupplierUsers());
      yield put(settingsActions.setIsUserEditFormOpen(false));
    } catch (error) {
      yield put(appActions.setError((error as Error)?.message ?? 'Error'));
      yield put(settingsActions.setIsSavingOrDeletingSupplierUsers(false));
    }
  }
}

function* watchDeleteSupplierUser() {
  while (true) {
    const { payload: id }: PayloadAction<string> = yield take(
      settingsActions.deleteSupplierUser
    );
    try {
      yield put(settingsActions.setIsSavingOrDeletingSupplierUsers(true));
      yield WebSupplierUserHelpers.delete(id);

      yield put(settingsActions.setIsSavingOrDeletingSupplierUsers(false));
      yield put(settingsActions.loadSupplierUsers());
      yield put(settingsActions.setIsUserEditFormOpen(false));
    } catch (error) {
      yield put(appActions.setError((error as Error)?.message ?? 'Error'));
      yield put(settingsActions.setIsSavingOrDeletingSupplierUsers(false));
    }
  }
}

function* watchResendInvite() {
  while (true) {
    const { payload: id }: PayloadAction<string> = yield take(
      settingsActions.resendInvite
    );
    try {
      yield put(settingsActions.setIsSavingOrDeletingSupplierUsers(true));
      yield WebSupplierUserHelpers.resendInvite(id);
      yield put(settingsActions.setIsSavingOrDeletingSupplierUsers(false));
      yield put(
        notifierActions.enqueue({
          message: 'Convite reenviado com sucesso!',
          options: {
            variant: 'success',
          },
        })
      );
    } catch (error) {
      yield put(appActions.setError((error as Error)?.message ?? 'Error'));
      yield put(settingsActions.setIsSavingOrDeletingSupplierUsers(false));
    }
  }
}

export default [
  watchUpdateDocs,
  watchGetDownloadLink,
  watchSendDocToBank,
  watchLoadSupplierConfig,
  watchSaveSupplierSettings,
  watchLoadSupplierPersonalizationConfig,
  watchSaveSupplierPersonalizationConfig,
  watchLoadSupplierNotificationConfig,
  watchSaveSupplierNotificationConfig,
  watchTestAllMailTemplates,
  watchLoadSupplierAPIClient,
  watchDeleteSupplierAPIClient,
  watchRotateAPIClientsSecret,
  watchCreateSupplierAPIClientConfig,
  watchSaveSupplierAPIClientConfig,
  watchLoadSupplierUserGroups,
  watchLoadSupplierUsers,
  watchSaveSupplierUserGroup,
  watchDeleteSupplierUserGroup,
  watchSaveSupplierUser,
  watchDeleteSupplierUser,
  watchResendInvite,
];
