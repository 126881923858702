import { Box, Typography } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import React from 'react';
import { format } from '../../../helpers/format';
import { useAppSelector } from '../../../helpers/hooks';

type DetailProps = {
  label: string;
  value?: string;
  children?: React.ReactNode;
};
const Detail = ({ label, value, children }: DetailProps) => (
  <Box>
    <Typography variant="caption" color="textSecondary" component="p">
      {label}:
    </Typography>
    {value && (
      <Typography variant="body2" color="textPrimary" component="p">
        {' '}
        {value ?? '-'}
      </Typography>
    )}
    {children}
  </Box>
);

function CompanyInfo() {
  const { selectedSupplier, isLoadingSelectedSupplier } = useAppSelector(
    (state) => state.admin
  );

  const supplierInfo = `${
    selectedSupplier?.fantasy ?? selectedSupplier?.name ?? '-'
  } - ${format(selectedSupplier?.docNumber, 'docNumber') ?? '-'}`;

  const mainContactInfo = `${selectedSupplier?.mainContactName ?? '-'} - ${
    format(selectedSupplier?.mainContactPhone, 'phone') ?? '-'
  } - ${selectedSupplier?.mainContactEmail ?? '-'}`;

  return (
    <Grid2 container>
      <Grid2 xs={12}>
        <Detail
          label="Fornecedor"
          value={isLoadingSelectedSupplier ? '...' : supplierInfo}
        />
      </Grid2>
      <Grid2 xs={12}>
        <Detail
          label="Responsável"
          value={isLoadingSelectedSupplier ? '...' : mainContactInfo}
        />
      </Grid2>
    </Grid2>
  );
}

export default CompanyInfo;
