import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import { CircularProgress, Fab } from '@mui/material';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ResponsiveDialog from '../../../components/ResponsiveDialog/ResponsiveDialog';
import { useAppDispatch, useAppSelector } from '../../../helpers/hooks';
import { safeParseMaskedInput } from '../../../helpers/safe-parse';
import useCompanyStatus from '../../../hooks/useCompanyStatus';
import useUserGrant from '../../../hooks/useUserGrant';
import NovoPagamento from '../FornecedorAgendas/NovoPagamento';
import { supplierActions } from '../supplier.slice';
import { AddPaymentSubForm } from '../supplier.types';

export const FABNewPaymentRequest = () => {
  const [isNewPaymentFormOpen, setIsNewPaymentFormOpen] = useState(false);

  const { company } = useAppSelector((state) => state.app);
  const { isOperatingHours } =
    useAppSelector((state) => state.app.operatingHours) ?? {};
  const { isRunningCalcSuggestedValues } = useAppSelector(
    (state) => state.supplier
  );
  const dispatch = useAppDispatch();

  const { supplierSettingsOk } = useCompanyStatus();

  const canCreatePaymentRequest = isOperatingHours && supplierSettingsOk;
  const showCreatePayment = useUserGrant(['supplier-payment-create']);

  const navigate = useNavigate();

  if (company?.companyType === 'retailer') return <></>;
  if (!showCreatePayment) return <></>;

  const handleSubFormClose = () => setIsNewPaymentFormOpen(false);
  const handleSubFormSave = (data: AddPaymentSubForm) => {
    if (data.idRetailer) {
      dispatch(
        supplierActions.calcSuggestedValues({
          opts: {
            idRetailer: data.idRetailer,
            effectType: data.effectType,
            externalId: data.externalId,
            originalValue: safeParseMaskedInput(data.originalValue),
            paymentDueDate: data.paymentDueDate,
          },
          navigate,
        })
      );
    }

    setIsNewPaymentFormOpen(false);
  };

  return (
    <>
      <Fab
        sx={{ position: 'absolute', bottom: 48, right: 48 }}
        color="secondary"
        disabled={!canCreatePaymentRequest || isRunningCalcSuggestedValues}
        onClick={() => setIsNewPaymentFormOpen(true)}
      >
        {isRunningCalcSuggestedValues ? (
          <CircularProgress color="secondary" />
        ) : (
          <AttachMoneyIcon />
        )}
      </Fab>
      <ResponsiveDialog open={isNewPaymentFormOpen} maxWidth="xs" fullWidth>
        <NovoPagamento
          onCloseClick={handleSubFormClose}
          onSaveClick={handleSubFormSave}
        />
      </ResponsiveDialog>
    </>
  );
};
