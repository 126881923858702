import CheckIcon from '@mui/icons-material/Check';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import UpdateIcon from '@mui/icons-material/Update';
import { Tooltip } from '@mui/material';
import React from 'react';
import { Models } from 'types';

type ConciliationStatusProps = {
  status?: Models.FinancialTransactionConciliationStatus | undefined;
};

export const ConciliationStatus = ({ status }: ConciliationStatusProps) => {
  let Icon: React.ReactNode = <></>;
  let statusText = '';
  switch (status) {
    case 'aguardando_conciliacao':
      Icon = <UpdateIcon fontSize="small" color="warning" />;
      statusText = 'Em conciliação';
      break;
    case 'em_contestacao':
      Icon = <ErrorOutlineIcon fontSize="small" color="error" />;
      statusText = 'Em contestação';
      break;
    case 'finalizado':
      Icon = <CheckIcon fontSize="small" color="success" />;
      statusText = 'Finalizado';
      break;
  }

  return <Tooltip title={statusText}>{Icon}</Tooltip>;
};
