import { Box, Card, Chip, Link, Stack, Typography } from '@mui/material';
import dayjs from 'dayjs';
import { Constants } from 'pr-constants';
import { MouseEvent, useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Models } from 'types';
import FilterForm from '../../../components/FilterForm/FilterForm';
import { PageHeader } from '../../../components/PageHeader/PageHeader';
import Table from '../../../components/Table/Table';
import { TableColumn } from '../../../components/Table/table.types';
import { getFirestoreConsoleLink } from '../../../helpers/firebase';
import { format } from '../../../helpers/format';
import { useAppDispatch, useAppSelector } from '../../../helpers/hooks';
import { dayjsBusinessTime } from '../../../helpers/operating-hours';
import { AgendasFilterForm } from '../../../types';
import { retailerActions } from '../retailer.slice';
import { ListaAgenda } from '../retailer.types';

function FornecedorAgendas() {
  let navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { isLoadingReceivables, receivables } = useAppSelector(
    (state) => state.retailer
  );
  const { isDebugActivated } = useAppSelector((state) => state.devTools);

  const [params] = useSearchParams();
  const startDate = params.get('s');
  const endDate = params.get('e');
  const defaultStartDate = dayjs();
  const defaultEndDate = dayjs().add(1, 'month');
  const dateExpression = params.get('x') ?? undefined;
  const queryParams: AgendasFilterForm = {
    startDate: startDate ?? defaultStartDate.format('YYYYMMDD'),
    endDate: endDate ?? defaultEndDate.format('YYYYMMDD'),
  };

  const currentDateRangeLabel =
    queryParams.startDate !== queryParams.endDate
      ? `${dayjs(queryParams.startDate).format('DD/MM/YYYY')} a ${dayjs(
          queryParams.endDate
        ).format('DD/MM/YYYY')}`
      : dayjs(queryParams.startDate).format('DD/MM/YYYY');
  const currentDateRangeFilter = `Data: ${currentDateRangeLabel}`;

  const navigateWithFilter = (
    destination: string,
    { dateExpression, startDate, endDate }: AgendasFilterForm
  ) => {
    const dateFilter = `?s=${startDate}&e=${endDate}`;
    const dateExpressionFilter = dateExpression ? `&x=${dateExpression}` : '';
    navigate(`${destination}${dateFilter}${dateExpressionFilter}`);
  };

  const handleFilter = (data: AgendasFilterForm) => {
    navigateWithFilter('.', {
      dateExpression:
        data.dateExpression as Models.LoadPaymentRequestsForRetailerRequestDateExpression,
      startDate: dayjs(data.startDate).format('YYYYMMDD'),
      endDate: dayjs(data.endDate).format('YYYYMMDD'),
    });
  };

  const handleResetFilter = () => {
    navigateWithFilter('.', {
      startDate: queryParams.startDate,
      endDate: queryParams.endDate,
      dateExpression,
    });
  };

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handleFilterChipClick = (event: MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleFilterClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    dispatch(
      retailerActions.loadReceivables({
        startDate: startDate ?? defaultStartDate.format('YYYYMMDD'),
        endDate: endDate ?? defaultEndDate.format('YYYYMMDD'),
      })
    );
  }, []);

  const columns: TableColumn<ListaAgenda>[] = [
    {
      id: 'idRecebivel',
      label: 'idRecebivel',
      hidden: !isDebugActivated,
      renderer: (v) => (
        <Link
          target="_blank"
          rel="noopener noreferrer"
          variant="caption"
          color="textSecondary"
          href={getFirestoreConsoleLink(`/receivables/${v}`)}
        >
          {v}
        </Link>
      ),
    },
    {
      id: 'dataRecebimento',
      label: 'Data Recebimento',
      type: 'date',
      nowrap: true,
      formatter: (v) =>
        `${format(v, 'date')} (${format(v, 'dayOfWeek')})${
          dayjsBusinessTime(v).isHoliday() ? ' - Feriado' : ''
        }`,
    },
    { id: 'credenciadora', label: 'Credenciadora' },
    { id: 'bandeira', label: 'Bandeira do Cartão' },
    {
      id: 'ultimaAtualizacao',
      label: 'Última Atualização',
      type: 'dateTime',
    },
    {
      id: 'statusPagamento',
      label: 'Status',
    },
    {
      id: 'valor',
      label: 'Valor Disponível',
      type: 'money',
      nowrap: true,
    },
  ];

  const enhancedData: ListaAgenda[] = [
    ...receivables.map((d, rowIndex) => {
      return {
        rowIndex,
        idRecebivel: d.id,
        dataRecebimento: d.incomingDate,
        valor: d.value,
        credenciadora: Constants.getDebtor(d.debtor),
        bandeira: Constants.getPaymentScheme(d.paymentScheme) ?? '-',
        ultimaAtualizacao: d.registrarLastUpdated,
        statusPagamento: d.idPendingPaymentRequest
          ? 'Em processamento'
          : 'Disponível',
        visualizacao: '',
      };
    }),
  ].sort(({ dataRecebimento: a }, { dataRecebimento: b }) =>
    a.localeCompare(b)
  );

  const sumAvailable = receivables
    .map(({ value }) => value)
    .reduce((acc, cur) => {
      acc += cur;
      return acc;
    }, 0);

  return (
    <>
      <PageHeader
        title="Agenda"
        actions={
          <Stack direction="row" gap={2} alignItems="center">
            <Chip
              label={currentDateRangeFilter}
              onClick={handleFilterChipClick}
            />
            <FilterForm<AgendasFilterForm>
              onFilter={handleFilter}
              onClear={handleResetFilter}
              onClose={handleFilterClose}
              anchorEl={anchorEl}
              appliedFilterCount={0}
              defaultValues={{
                startDate: queryParams.startDate,
                endDate: queryParams.endDate,
                dateExpression: dateExpression,
              }}
              fields={[
                {
                  type: 'date-range',
                  name: 'dateType',
                  startName: 'startDate',
                  endName: 'endDate',
                  formulaName: 'dateExpression',
                  label: 'Data',
                },
              ]}
            />
          </Stack>
        }
      />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: '0px',
          flexGrow: 1,
        }}
      >
        <Table<ListaAgenda>
          columns={columns}
          data={enhancedData}
          isLoading={isLoadingReceivables}
          dense
        />
      </Box>
      <Box paddingTop={2}>
        <Card variant="outlined">
          <Box p={2}>
            <Typography variant="h6" textAlign="center">
              Valor disponível: <b>{format(sumAvailable, 'money')}</b>
            </Typography>
          </Box>
        </Card>
      </Box>
    </>
  );
}

export default FornecedorAgendas;
