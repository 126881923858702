import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { Chip } from '@mui/material';
import copy from 'copy-to-clipboard';
import { notifierActions } from '../../features/notifier/notifier-slice';
import { useAppDispatch } from '../../helpers/hooks';
import { getInviteUrl } from '../../helpers/url';
import { StatusFornecedor, StatusVarejista } from '../../types';

type CompanyStatusProps = {
  status?: StatusVarejista | StatusFornecedor;
  idInvite?: string;
  inviteEmail?: string;
};

export const CompanyStatus = ({
  status,
  idInvite,
  inviteEmail,
}: CompanyStatusProps) => {
  const dispatch = useAppDispatch();
  const handleInviteClick =
    status === 'Convidado' && idInvite
      ? () => {
          if (idInvite && inviteEmail) {
            const inviteUrl = getInviteUrl(idInvite, inviteEmail);
            copy(inviteUrl);

            dispatch(
              notifierActions.enqueue({
                message: 'Convite copiado para a área de trabalho',
                options: {
                  variant: 'success',
                },
              })
            );
          }
        }
      : undefined;
  return (
    <Chip
      label={status}
      size="small"
      variant="filled"
      color={
        status === 'Ativo'
          ? 'success'
          : status === 'Convidado' && idInvite
          ? 'info'
          : 'warning'
      }
      deleteIcon={
        status === 'Convidado' && idInvite ? <ContentCopyIcon /> : undefined
      }
      onClick={handleInviteClick}
      onDelete={handleInviteClick}
    />
  );
};
