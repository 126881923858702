import { Models, Service } from 'types';
import { apiCall } from '../helpers/api';

type T = Models.OnboardingInvite;
const service: Service = 'supplier';
const resource = 'web/onboarding-invite';

export class WebOnboardingInviteHelpers {
  // public static getById = async (id: string): Promise<T | undefined> => {
  //   try {
  //     return await apiCall<T>({
  //       service,
  //       resource: `${resource}/${id}`,
  //     });
  //   } catch (e) {
  //     if ((e as unknown as any)?.response?.status === 404) return undefined;
  //     throw e;
  //   }
  // };
  // public static getValidByEmail = async (
  //   email: string
  // ): Promise<T | undefined> => {
  //   return await apiCall<T | undefined>({
  //     service,
  //     resource: `${resource}/get-valid-by-email?email=${encodeURIComponent(
  //       email
  //     )}`,
  //   });
  // };
  public static list = async (
    whereField?: 'idCompany' | 'idSupplier' | 'valid',
    whereValue?: string
  ): Promise<T[]> => {
    const filter =
      whereField && whereValue ? `?f=${whereField}&v=${whereValue}` : '';
    return await apiCall<T[]>({
      service,
      resource: `${resource}${filter}`,
    });
  };
  // public static create = async (
  //   newData: Omit<T, 'id' | 'dateadded'>
  // ): Promise<string> => {
  //   const { id } = await apiCall<Pick<T, 'id'>>({
  //     service,
  //     resource: `${resource}`,
  //     payload: newData,
  //     kyOpts: { method: 'post' },
  //   });
  //   return id;
  // };
  // public static patch = async (id: string, dataToUpdate: Partial<T>) => {
  //   await apiCall<Pick<T, 'id'>>({
  //     service,
  //     resource: `${resource}/${id}`,
  //     payload: dataToUpdate,
  //     kyOpts: { method: 'patch' },
  //   });
  //   return id;
  // };
}
