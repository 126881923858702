import { Models } from 'types';
import { useAppSelector } from '../../../helpers/hooks';
import { dayjsBusinessTime } from '../../../helpers/operating-hours';

export const calcFirstAvailableDate = (
  supplierWarrantyConfig: Models.SupplierWarrantyConfig | undefined,
  supplierOwnershipAssignmentConfig:
    | Models.SupplierOwnershipAssignmentConfig
    | undefined,
  effectType: Models.PaymentRequestEffectType,
  dueDate: string
) => {
  const useBusinessDays =
    effectType === 'garantia'
      ? supplierWarrantyConfig?.useBusinessDays
      : supplierOwnershipAssignmentConfig?.useBusinessDays;

  const daysToAdd =
    (effectType === 'garantia'
      ? supplierWarrantyConfig?.daysToAdd
      : supplierOwnershipAssignmentConfig?.daysToAdd) ?? 0;

  return useBusinessDays
    ? dayjsBusinessTime(dueDate).addBusinessTime(daysToAdd, 'days')
    : dayjsBusinessTime(dueDate).add(daysToAdd, 'days');
};
export const useFirstAvailableDate = (
  effectType: Models.PaymentRequestEffectType,
  dueDate: string
) => {
  const { supplierWarrantyConfig, supplierOwnershipAssignmentConfig } =
    useAppSelector((state) => state.settings);

  return calcFirstAvailableDate(
    supplierWarrantyConfig,
    supplierOwnershipAssignmentConfig,
    effectType,
    dueDate
  );
};
