'use strict';
import { put, select, take } from 'redux-saga/effects';
import { Models } from 'types';
import { WebOnboardingHelpers } from '../../data-helpers/WebOnboardingHelpers';
import { appActions } from '../app/app-slice';
import { AppState } from '../app/app.types';
import { notifierActions } from '../notifier/notifier-slice';
import { profileActions } from './profile-slice';

function* watchApprove() {
  while (true) {
    try {
      yield take(profileActions.approve);
      yield put(appActions.startLoading());

      const { company }: AppState = yield select((state) => state.app);
      if (!company) throw new Error('Unexpected undefined company');

      let dataToSave: Models.WebApproveOnboardingRequest = {
        idCompany: company.id,
      };

      const updated: Models.Company = yield WebOnboardingHelpers.approve(
        dataToSave
      );
      yield put(appActions.setCompany(updated));
      yield put(
        notifierActions.enqueue({
          message: 'Empresa aprovada com sucesso!',
          options: {
            variant: 'success',
          },
        })
      );
      // yield put(appActions.setIsProfileOpen(false));

      yield put(appActions.finishLoading());
    } catch (e) {
      yield put(appActions.setError((e as Error).message));
      yield put(appActions.finishLoading());
    }
  }
}

export default [watchApprove];
