import {
  Box,
  Card,
  CardActionArea,
  Stack,
  Typography,
  alpha,
  useTheme,
} from '@mui/material';

import { format } from '../../../helpers/format';
import { ExtratoVarejista } from '../supplier.types';

type TransactionCardProps = {
  transaction: ExtratoVarejista;
  selected: boolean;
  onClick: (_t: ExtratoVarejista) => void;
};

export default function TransactionCard({
  transaction,
  selected,
  onClick,
}: TransactionCardProps) {
  const muiTheme = useTheme();
  return (
    <Card
      variant="outlined"
      sx={{
        ...(selected && {
          borderColor: selected ? 'primary.main' : 'border',
          backgroundColor: alpha(muiTheme.palette.background.default, 1),
        }),
      }}
    >
      <CardActionArea onClick={() => onClick(transaction)}>
        <Box p={2}>
          <Typography variant="caption" color="text.secondary">
            {format(transaction.data, 'date')}
          </Typography>

          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="flex-end"
          >
            <Typography variant="caption" color="text.secondary">
              {transaction.detalhe}
            </Typography>

            <Typography variant="h6">
              {format(transaction.valor, 'money')}
            </Typography>
          </Stack>
        </Box>
      </CardActionArea>
    </Card>
  );
}
