import { createSlice } from '@reduxjs/toolkit';

export type ProfileState = {};
const initialState: ProfileState = {};

export const profileSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    approve: () => {},
  },
});

export const profileActions = profileSlice.actions;

export default profileSlice.reducer;
