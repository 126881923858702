import AddIcon from '@mui/icons-material/Add';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import CheckIcon from '@mui/icons-material/Check';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DeleteIcon from '@mui/icons-material/Delete';
import SaveIcon from '@mui/icons-material/Save';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { LoadingButton } from '@mui/lab';
import {
  Alert,
  Box,
  Card,
  CircularProgress,
  Divider,
  IconButton,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2';
import copy from 'copy-to-clipboard';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import ConfirmationDialog from '../../components/ConfirmationDialog/ConfirmationDialog';
import EditForm from '../../components/EditForm/EditForm';
import EditFormAction from '../../components/EditForm/EditFormAction';
import EditFormContent from '../../components/EditForm/EditFormContent';
import EditFormHeader from '../../components/EditForm/EditFormHeader';
import { SwitchField } from '../../components/SwitchField/SwitchField';
import { getBaseUrl } from '../../helpers/api';
import { useAppDispatch, useAppSelector } from '../../helpers/hooks';
import useUserRole from '../../hooks/useUserRole';
import { PartnerAPIForm } from '../admin/admin.types';
import { appActions } from '../app/app-slice';
import { notifierActions } from '../notifier/notifier-slice';
import { settingsActions } from './settings-slice';

type DetailProps = {
  label: string;
  value?: string;
  children?: React.ReactNode;
};
const Detail = ({ label, value, children }: DetailProps) => (
  <Box>
    <Typography variant="body2" color="textSecondary" component="p">
      {label}:
    </Typography>
    {value && (
      <Typography variant="body1" color="primary" component="p">
        {' '}
        {value ?? '-'}
      </Typography>
    )}
    {children}
  </Box>
);

export default function SupplierAPIClients() {
  const dispatch = useAppDispatch();
  const { isLoading } = useAppSelector((state) => state.app);
  const { supplierAPIClient, loadingButtons, supplierAPIClientHelperText } =
    useAppSelector((state) => state.settings);

  const [isSaveConfirmationOpen, setIsSaveConfirmationOpen] = useState(false);
  const [isCreateConfirmationOpen, setIsCreateConfirmationOpen] =
    useState(false);
  const [isDeleteConfirmationOpen, setIsDeleteConfirmationOpen] =
    useState(false);
  const [isRotateConfirmationOpen, setIsRotateConfirmationOpen] =
    useState(false);
  const [isSecretVisible, setIsSecretVisible] = useState(false);
  const [formDataToSave, setFormDataToSave] = useState<
    PartnerAPIForm | undefined
  >(undefined);
  const { isAdmin } = useUserRole();

  useEffect(() => {
    dispatch(settingsActions.loadSupplierAPIClient());
    dispatch(settingsActions.setSupplierAPIClientHelperText(undefined));
  }, []);

  const defaultCustomWebhookHeaderName = supplierAPIClient?.config
    .customWebhookHeaders
    ? Object.keys(supplierAPIClient.config.customWebhookHeaders)[0]
    : '';
  const defaultCustomWebhookHeaderValue =
    supplierAPIClient?.config?.customWebhookHeaders &&
    defaultCustomWebhookHeaderName
      ? supplierAPIClient.config.customWebhookHeaders[
          defaultCustomWebhookHeaderName
        ]
      : '';

  const {
    handleSubmit,
    formState: { isDirty, errors },
    watch,
    reset,
    register,
    control,
  } = useForm<PartnerAPIForm>();

  useEffect(() => {
    const defaultValues: PartnerAPIForm = {
      clientId: supplierAPIClient?.client?.client_id ?? '',
      hasWebhook: !!supplierAPIClient?.config?.hasWebhook,
      hasCustomLimit: !!supplierAPIClient?.config?.hasCustomLimit,
      hasCustomHeader: !!supplierAPIClient?.config?.hasCustomHeader,
      customWebhookHeaderName: defaultCustomWebhookHeaderName,
      customWebhookHeaderValue: defaultCustomWebhookHeaderValue,
      webhookUrl: supplierAPIClient?.config?.webhookUrl ?? '',
      globalRateLimit:
        supplierAPIClient?.config?.specialRateLimits?.global?.limit ?? 0,
      newCustomerRateLimit:
        supplierAPIClient?.config?.specialRateLimits?.['new-customer']?.limit ??
        0,
    };
    reset(defaultValues);
  }, [supplierAPIClient]);

  const [hasWebhook, hasCustomLimit, hasCustomHeader] = watch([
    'hasWebhook',
    'hasCustomLimit',
    'hasCustomHeader',
  ]);

  const onSubmit = async (data: PartnerAPIForm) => {
    setFormDataToSave(data);
    setIsSaveConfirmationOpen(true);
  };
  const onSaveClick = handleSubmit(onSubmit);
  const onCreateConfigClick = () => {
    setIsCreateConfirmationOpen(true);
  };
  const onDeleteConfigClick = () => {
    setIsDeleteConfirmationOpen(true);
  };
  const onRotateClick = () => {
    setIsRotateConfirmationOpen(true);
  };

  const onConfirmCreateClick = () => {
    dispatch(settingsActions.createSupplierAPIClientConfig());
    setIsCreateConfirmationOpen(false);
  };
  const onConfirmDeleteClick = () => {
    dispatch(settingsActions.deleteSupplierAPIClient());
    setIsDeleteConfirmationOpen(false);
  };
  const onConfirmRotateClick = () => {
    if (supplierAPIClient?.client?.client_id) {
      dispatch(
        settingsActions.rotateAPIClientsSecret(
          supplierAPIClient.client.client_id
        )
      );
      setIsRotateConfirmationOpen(false);
    }
  };
  const onConfirmSaveClick = () => {
    if (formDataToSave) {
      closeConfirmations();
      dispatch(
        settingsActions.saveSupplierAPIClientConfig({
          ...formDataToSave,
          clientId: supplierAPIClient?.client?.client_id ?? '',
        })
      );
    }
  };

  const closeConfirmations = () => {
    setIsSaveConfirmationOpen(false);
    setIsCreateConfirmationOpen(false);
    setIsDeleteConfirmationOpen(false);
    setIsRotateConfirmationOpen(false);
  };

  const handleCloseClick = () => {
    dispatch(appActions.setIsSupplierAPIClientsPanelOpen(false));
    closeConfirmations();
  };

  const baseUrl = getBaseUrl('partner');
  const apiJson = supplierAPIClient
    ? {
        client_id: supplierAPIClient.client.client_id,
        client_secret: supplierAPIClient.client.client_secret,
        audience: supplierAPIClient.grants[0].audience,
        grant_type: 'client_credentials',
      }
    : {};

  const obfuscatedApiJson = supplierAPIClient
    ? {
        client_id: supplierAPIClient.client.client_id,
        client_secret: '******************************',
        audience: supplierAPIClient.grants[0].audience,
        grant_type: 'client_credentials',
      }
    : {};

  const apiCallSnippet = `POST ${baseUrl}/oauth/token
Content-Type: application/json

${JSON.stringify(apiJson, null, 2)}`;
  const obfuscatedApiCallSnippet = `POST ${baseUrl}/oauth/token
Content-Type: application/json

${JSON.stringify(obfuscatedApiJson, null, 2)}`;

  return (
    <EditForm>
      <EditFormHeader title="API" onCloseClick={handleCloseClick} />

      <EditFormContent
        display="flex"
        flexDirection="column"
        alignItems="center"
        gap={2}
      >
        {isLoading ? (
          <CircularProgress />
        ) : (
          <>
            {!supplierAPIClient ? (
              <LoadingButton
                startIcon={<AddIcon />}
                loadingPosition="start"
                loading={
                  loadingButtons[
                    settingsActions.createSupplierAPIClientConfig.type
                  ]
                }
                variant="outlined"
                onClick={onCreateConfigClick}
              >
                Criar uma nova configuração
              </LoadingButton>
            ) : (
              <Grid2 container spacing={2} paddingLeft={2}>
                <Grid2 sm={6}>
                  <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Box flexGrow={1}>
                      <Detail label="URL" value={baseUrl} />
                    </Box>
                    <IconButton
                      onClick={() => {
                        copy(baseUrl);

                        dispatch(
                          notifierActions.enqueue({
                            message: 'URL copiada para a área de trabalho',
                            options: {
                              variant: 'success',
                            },
                          })
                        );
                      }}
                    >
                      <ContentCopyIcon />
                    </IconButton>
                  </Stack>
                </Grid2>
                <Grid2 sm={6}>
                  <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Box flexGrow={1}>
                      <Detail
                        label="Client ID"
                        value={supplierAPIClient.client.client_id}
                      />
                    </Box>
                    <IconButton
                      onClick={() => {
                        copy(supplierAPIClient.client.client_id);

                        dispatch(
                          notifierActions.enqueue({
                            message: 'ID copiado para a área de trabalho',
                            options: {
                              variant: 'success',
                            },
                          })
                        );
                      }}
                    >
                      <ContentCopyIcon />
                    </IconButton>
                  </Stack>
                </Grid2>
                <Grid2 sm={6}>
                  <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Box flexGrow={1}>
                      <Detail
                        label="Client Secret"
                        value={
                          isSecretVisible
                            ? supplierAPIClient.client.client_secret
                            : '******************************'
                        }
                      />
                    </Box>
                    <IconButton
                      onClick={() => setIsSecretVisible(!isSecretVisible)}
                    >
                      {isSecretVisible ? (
                        <VisibilityOffIcon />
                      ) : (
                        <VisibilityIcon />
                      )}
                    </IconButton>
                    <IconButton
                      onClick={() => {
                        copy(supplierAPIClient.client.client_secret);

                        dispatch(
                          notifierActions.enqueue({
                            message: 'Segredo copiado para a área de trabalho',
                            options: {
                              variant: 'success',
                            },
                          })
                        );
                      }}
                    >
                      <ContentCopyIcon />
                    </IconButton>
                  </Stack>
                </Grid2>
                <Grid2 sm={6}>
                  <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Box flexGrow={1}>
                      <Detail
                        label="Audience"
                        value={supplierAPIClient.grants[0].audience}
                      />
                    </Box>
                    <IconButton
                      onClick={() => {
                        copy(supplierAPIClient.grants[0].audience);
                        dispatch(
                          notifierActions.enqueue({
                            message: 'Segredo copiado para a área de trabalho',
                            options: {
                              variant: 'success',
                            },
                          })
                        );
                      }}
                    >
                      <ContentCopyIcon />
                    </IconButton>
                  </Stack>
                </Grid2>
                <Grid2 sm={12}>
                  <Detail label="Exemplo de chamada auth">
                    <Card variant="outlined" sx={{ fontFamily: 'Monospace' }}>
                      <Box component="pre" p={2} sx={{ position: 'relative' }}>
                        {isSecretVisible
                          ? apiCallSnippet
                          : obfuscatedApiCallSnippet}
                        <Stack
                          direction="row"
                          sx={{ position: 'absolute', right: 0, top: 0 }}
                        >
                          <IconButton
                            onClick={() => setIsSecretVisible(!isSecretVisible)}
                          >
                            {isSecretVisible ? (
                              <VisibilityOffIcon />
                            ) : (
                              <VisibilityIcon />
                            )}
                          </IconButton>
                          <IconButton
                            onClick={() => {
                              copy(apiCallSnippet);

                              dispatch(
                                notifierActions.enqueue({
                                  message:
                                    'Dados copiados para a área de trabalho',
                                  options: {
                                    variant: 'success',
                                  },
                                })
                              );
                            }}
                          >
                            <ContentCopyIcon />
                          </IconButton>
                        </Stack>
                      </Box>
                    </Card>
                  </Detail>
                </Grid2>
                <Grid2 xs={12}>
                  <Divider />
                </Grid2>
                <Grid2 xs={hasWebhook ? 6 : 12}>
                  <SwitchField
                    control={control}
                    name="hasWebhook"
                    label="Webhook"
                  />
                </Grid2>
                <Grid2 xs={6} sx={{ display: hasWebhook ? 'block' : 'none' }}>
                  <SwitchField
                    control={control}
                    name="hasCustomHeader"
                    label="Custom Header"
                  />
                </Grid2>
                <Grid2 xs={6} sx={{ display: hasWebhook ? 'block' : 'none' }}>
                  <TextField
                    id="webhookUrl"
                    label="URL"
                    placeholder="https://mycompany.com.br/webhook"
                    type="text"
                    {...register('webhookUrl', {
                      required: hasWebhook ? 'A URL é obrigatória' : false,
                    })}
                    error={!!errors?.webhookUrl}
                    helperText={errors?.webhookUrl?.message}
                    fullWidth
                  />
                </Grid2>
                <Grid2
                  xs={3}
                  sx={{ display: hasCustomHeader ? 'block' : 'none' }}
                >
                  <TextField
                    id="customWebhookHeaderName"
                    label="Nome"
                    placeholder="x-secret"
                    type="text"
                    {...register('customWebhookHeaderName', {
                      required: hasCustomHeader
                        ? 'O nome é obrigatório'
                        : false,
                    })}
                    error={!!errors?.customWebhookHeaderName}
                    helperText={errors?.customWebhookHeaderName?.message}
                    fullWidth
                  />
                </Grid2>
                <Grid2
                  xs={3}
                  sx={{ display: hasCustomHeader ? 'block' : 'none' }}
                >
                  <TextField
                    id="customWebhookHeaderValue"
                    label="Valor"
                    placeholder="12345"
                    type="text"
                    {...register('customWebhookHeaderValue', {
                      required: hasCustomHeader
                        ? 'O valor é obrigatório'
                        : false,
                    })}
                    error={!!errors?.customWebhookHeaderValue}
                    helperText={errors?.customWebhookHeaderValue?.message}
                    fullWidth
                  />
                </Grid2>
                <Grid2 xs={12}>
                  <SwitchField
                    control={control}
                    name="hasCustomLimit"
                    label="Rate Limits"
                  />
                </Grid2>
                <Grid2
                  xs={6}
                  sx={{ display: hasCustomLimit ? 'block' : 'none' }}
                >
                  <TextField
                    id="globalRateLimit"
                    label="Global (requisições/minuto)"
                    placeholder="x-secret"
                    type="text"
                    {...register('globalRateLimit', {
                      required: hasCustomLimit
                        ? 'O campo é obrigatório'
                        : false,
                      valueAsNumber: true,
                      validate: (v) => {
                        if (!hasCustomLimit) return true;
                        if (v > 0) return true;
                        return 'O valor deve ser maior do que zero';
                      },
                    })}
                    error={!!errors?.globalRateLimit}
                    helperText={errors?.globalRateLimit?.message}
                    fullWidth
                  />
                </Grid2>
                <Grid2
                  xs={6}
                  sx={{ display: hasCustomLimit ? 'block' : 'none' }}
                >
                  <TextField
                    id="newCustomerRateLimit"
                    label="Novo Cliente (requisições/dia)"
                    placeholder="x-secret"
                    type="text"
                    {...register('newCustomerRateLimit', {
                      required: hasCustomLimit
                        ? 'O campo é obrigatório'
                        : false,
                      valueAsNumber: true,
                      validate: (v) => {
                        if (!hasCustomLimit) return true;
                        if (v > 0) return true;
                        return 'O valor deve ser maior do que zero';
                      },
                    })}
                    error={!!errors?.newCustomerRateLimit}
                    helperText={errors?.newCustomerRateLimit?.message}
                    fullWidth
                  />
                </Grid2>
                {supplierAPIClientHelperText && (
                  <Grid2 sm={12}>
                    <Alert severity="warning">
                      {supplierAPIClientHelperText}
                    </Alert>
                  </Grid2>
                )}
              </Grid2>
            )}
          </>
        )}
      </EditFormContent>
      {supplierAPIClient && (
        <>
          {isAdmin && (
            <EditFormAction
              display="flex"
              flexDirection="row"
              justifyContent="flex-end"
              paddingTop={2}
              gap={2}
            >
              <LoadingButton
                color="error"
                onClick={onRotateClick}
                loading={
                  loadingButtons[settingsActions.rotateAPIClientsSecret.type]
                }
                loadingPosition="start"
                startIcon={<AutorenewIcon />}
                variant="outlined"
              >
                Rotacionar Segredo
              </LoadingButton>
              <LoadingButton
                color="error"
                onClick={onDeleteConfigClick}
                loading={
                  loadingButtons[settingsActions.deleteSupplierAPIClient.type]
                }
                loadingPosition="start"
                startIcon={<DeleteIcon />}
                variant="outlined"
              >
                Excluir API
              </LoadingButton>
              <LoadingButton
                onClick={onSaveClick}
                disabled={!isDirty}
                loading={
                  loadingButtons[
                    settingsActions.saveSupplierAPIClientConfig.type
                  ]
                }
                loadingPosition="start"
                startIcon={<SaveIcon />}
                variant="contained"
              >
                Salvar
              </LoadingButton>
            </EditFormAction>
          )}
        </>
      )}
      <ConfirmationDialog
        open={isCreateConfirmationOpen}
        title="Confirmação"
        isLoading={
          loadingButtons[settingsActions.createSupplierAPIClientConfig.type]
        }
        actions={[
          {
            name: 'Cancelar',
            buttonProps: {
              autoFocus: true,
              onClick: () => {
                setIsCreateConfirmationOpen(false);
              },
            },
          },
          {
            name: 'Criar',
            showLoading: true,
            buttonProps: {
              variant: 'contained',
              color: 'primary',
              startIcon: <CheckIcon />,
              onClick: onConfirmCreateClick,
            },
          },
        ]}
      >
        Deseja criar uma nova configuração de API?
      </ConfirmationDialog>
      <ConfirmationDialog
        open={isDeleteConfirmationOpen}
        title="Exclusão"
        isLoading={loadingButtons[settingsActions.deleteSupplierAPIClient.type]}
        actions={[
          {
            name: 'Cancelar',
            buttonProps: {
              autoFocus: true,
              onClick: () => {
                setIsDeleteConfirmationOpen(false);
              },
            },
          },
          {
            name: 'Excluir',
            showLoading: true,
            buttonProps: {
              variant: 'contained',
              color: 'error',
              startIcon: <DeleteIcon />,
              onClick: onConfirmDeleteClick,
            },
          },
        ]}
      >
        Deseja excluir a configuração de API? As aplicações perderão acesso
        imediatamente.
      </ConfirmationDialog>
      <ConfirmationDialog
        open={isRotateConfirmationOpen}
        title="Rotacionar Segredo"
        isLoading={loadingButtons[settingsActions.rotateAPIClientsSecret.type]}
        actions={[
          {
            name: 'Cancelar',
            buttonProps: {
              autoFocus: true,
              onClick: () => {
                setIsRotateConfirmationOpen(false);
              },
            },
          },
          {
            name: 'Rotacionar',
            showLoading: true,
            buttonProps: {
              variant: 'contained',
              color: 'error',
              startIcon: <AutorenewIcon />,
              onClick: onConfirmRotateClick,
            },
          },
        ]}
      >
        Deseja rotacionar o segredo dessa API? As aplicações perderão acesso
        imediatamente até que o novo segredo seja atualizado.
      </ConfirmationDialog>
      <ConfirmationDialog
        open={isSaveConfirmationOpen}
        title="Salvar Alterações"
        isLoading={
          loadingButtons[settingsActions.saveSupplierAPIClientConfig.type]
        }
        actions={[
          {
            name: 'Cancelar',
            buttonProps: {
              autoFocus: true,
              onClick: () => {
                setIsSaveConfirmationOpen(false);
              },
            },
          },
          {
            name: 'Salvar',
            showLoading: true,
            buttonProps: {
              variant: 'contained',
              color: 'primary',
              startIcon: <SaveIcon />,
              onClick: onConfirmSaveClick,
            },
          },
        ]}
      >
        Deseja salvar as alterações? As aplicações poderão deixar de funcionar
        caso não sejam atualizadas.
      </ConfirmationDialog>
    </EditForm>
  );
}
