import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from '@mui/icons-material/Search';
import { Button, Popover, Stack } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import 'dayjs/locale/pt-br';
import { ReactNode } from 'react';
import {
  DefaultValues,
  FieldValues,
  FormProvider,
  useForm,
} from 'react-hook-form';
import { DateFieldWithControl } from '../DateField/DateFieldWithControl';
import {
  DateExpression,
  DateRangeFieldWithControl,
} from '../DateField/DateRangeFieldWithControl';
import { SelectFieldWithControl } from '../SelectField/SelectFieldWithControl';
import { TextFieldWithControl } from '../TextField/TextFieldWithControl';

export type OptionsFilterValues<T = string> = {
  value: T;
  label: string;
};
export type FilterCustomField = {
  type: 'custom';
  name: string;
  label: string;
  content: ReactNode;
};
export type FilterTextField = {
  type: 'text';
  name: string;
  label: string;
};
export type FilterDateField = {
  type: 'date';
  name: string;
  label: string;
};
export type FilterDateRangeField = {
  type: 'date-range';
  name: string;
  startName: string;
  endName: string;
  formulaName: string;
  label: string;
  disabledExpressions?: DateExpression[];
};
export type FilterMultiDateRangeField = {
  type: 'multi-date-range';
  name: string;
  startName: string;
  endName: string;
  formulaName: string;
  label: string;
  dateTypeOptions: OptionsFilterValues[];
  disabledExpressions?: DateExpression[];
};
export type FilterOptionsField = {
  type: 'options';
  name: string;
  label: string;
  options: OptionsFilterValues[];
};
export type FilterField =
  | FilterTextField
  | FilterDateField
  | FilterDateRangeField
  | FilterMultiDateRangeField
  | FilterOptionsField
  | FilterCustomField;

export type FilterFormProps<T> = {
  onFilter: (_data: T) => void;
  onClear: () => void;
  onClose:
    | ((_event: {}, _reason: 'backdropClick' | 'escapeKeyDown') => void)
    | undefined;
  appliedFilterCount: number;
  defaultValues: DefaultValues<T>;
  fields: FilterField[];
  anchorEl: HTMLElement | null;
};
const FilterForm = <T extends FieldValues>({
  onFilter,
  onClear,
  onClose,
  defaultValues,
  appliedFilterCount,
  fields,
  anchorEl,
}: FilterFormProps<T>) => {
  const methods = useForm<T>({
    defaultValues,
  });
  const { handleSubmit, control, trigger } = methods;

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const onSubmit = async (data: T) => {
    if (data) onFilter(data);
  };

  const triggerSubmit = async () => {
    const isValid = await trigger();

    if (isValid) {
      handleSubmit(onSubmit)();
    }
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="pt-br">
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={onClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Stack direction="column" gap={2} p={2}>
              {fields.map((field) => {
                const { type, label, name } = field;
                switch (type) {
                  case 'date-range': {
                    const {
                      startName,
                      endName,
                      formulaName,
                      disabledExpressions,
                    } = field as FilterDateRangeField;
                    return (
                      <DateRangeFieldWithControl
                        key={startName}
                        control={control}
                        name={name}
                        startName={startName}
                        endName={endName}
                        formulaName={formulaName}
                        label={label}
                        onClose={triggerSubmit}
                        disabledExpressions={disabledExpressions}
                      />
                    );
                  }
                  case 'multi-date-range': {
                    const {
                      startName,
                      endName,
                      dateTypeOptions,
                      formulaName,
                      disabledExpressions,
                    } = field as FilterMultiDateRangeField;
                    return (
                      <DateRangeFieldWithControl
                        key={startName}
                        control={control}
                        name={name}
                        startName={startName}
                        endName={endName}
                        formulaName={formulaName}
                        label={label}
                        onClose={triggerSubmit}
                        dateTypeOptions={dateTypeOptions}
                        disabledExpressions={disabledExpressions}
                      />
                    );
                  }
                  case 'date': {
                    return (
                      <DateFieldWithControl
                        key={name}
                        name={name}
                        label={label}
                        control={control}
                        rules={{
                          validate: (v) => {
                            if (!dayjs(v).isValid()) return 'Data inválida';
                            return true;
                          },
                        }}
                      />
                    );
                  }
                  case 'options': {
                    const { options } = field as FilterOptionsField;
                    return (
                      <SelectFieldWithControl
                        key={name}
                        control={control}
                        name={name}
                        label={label}
                        options={options}
                        onChange={triggerSubmit}
                      />
                    );
                  }
                  case 'text': {
                    return (
                      <TextFieldWithControl
                        key={name}
                        name={name}
                        label={label}
                        type="text"
                        control={control}
                      />
                    );
                  }
                  case 'custom': {
                    const { content } = field as FilterCustomField;
                    return content;
                  }
                }
              })}
              <Button
                disabled={!appliedFilterCount}
                variant="outlined"
                onClick={onClear}
                startIcon={<ClearIcon />}
              >
                Limpar filtros
              </Button>
              <Button
                variant="contained"
                type="submit"
                onClick={triggerSubmit}
                startIcon={<SearchIcon />}
              >
                Aplicar
              </Button>
            </Stack>
          </form>
        </FormProvider>
      </Popover>
    </LocalizationProvider>
  );
};

export default FilterForm;
