import { TextField, TextFieldProps } from '@mui/material';
import { Control, RegisterOptions, useController } from 'react-hook-form';

type TextFieldWithControlProps = TextFieldProps & {
  control: Control<any, any>;
  rules?: RegisterOptions<any, string>;
};

export const TextFieldWithControl = (props: TextFieldWithControlProps) => {
  const { control, rules, onChange: onTextFieldChange, ...other } = props;
  const {
    field: { onChange, value },
    fieldState: { error },
  } = useController({
    name: other.name ?? '',
    control,
    rules,
  });
  return (
    <TextField
      {...other}
      error={!!error}
      helperText={error?.message}
      value={value}
      onChange={(e) => {
        if (onTextFieldChange) onTextFieldChange(e);
        onChange(e.target.value);
      }}
    />
  );
};
