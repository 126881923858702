import { Box, Typography } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import React from 'react';
import { useAppSelector } from '../../../helpers/hooks';

type DetailProps = {
  label: string;
  value?: string;
  children?: React.ReactNode;
};
const Detail = ({ label, value, children }: DetailProps) => (
  <Box>
    <Typography variant="caption" color="textSecondary" component="p">
      {label}:
    </Typography>
    {value && (
      <Typography variant="body2" color="textPrimary" component="p">
        {' '}
        {value ?? '-'}
      </Typography>
    )}
    {children}
  </Box>
);

function BankAccountInfo() {
  const { selectedRetailerBankAccount, isLoadingSelectedRetailerBankAccount } =
    useAppSelector((state) => state.supplier);
  const bankAccountInfo = `Banco ${
    selectedRetailerBankAccount?.bankCode
  } - ISPB ${selectedRetailerBankAccount?.ispb} - Agência ${
    selectedRetailerBankAccount?.branch ?? ''
  } - Número ${selectedRetailerBankAccount?.account ?? '-'}-${
    selectedRetailerBankAccount?.accountDigit ?? '-'
  }`;

  return (
    <Grid2 container>
      <Grid2 xs={12}>
        <Detail
          label="ID"
          value={
            isLoadingSelectedRetailerBankAccount
              ? '...'
              : selectedRetailerBankAccount?.bankId ?? ''
          }
        />
      </Grid2>
      <Grid2 xs={12}>
        <Detail
          label="Dados"
          value={isLoadingSelectedRetailerBankAccount ? '...' : bankAccountInfo}
        />
      </Grid2>
    </Grid2>
  );
}

export default BankAccountInfo;
