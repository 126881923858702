import AddIcon from '@mui/icons-material/Add';
import { Button, Link } from '@mui/material';

import { GridEventListener } from '@mui/x-data-grid';
import { Constants } from 'pr-constants';
import { useEffect } from 'react';
import { PageHeader } from '../../../components/PageHeader/PageHeader';
import ResponsiveDialog from '../../../components/ResponsiveDialog/ResponsiveDialog';
import DataTable from '../../../components/Table/DataTable';
import { TableColumn } from '../../../components/Table/table.types';
import { getFirestoreConsoleLink } from '../../../helpers/firebase';
import { useAppDispatch, useAppSelector } from '../../../helpers/hooks';
import { ListaGruposUsuarios } from '../../../types';
import { settingsActions } from '../settings-slice';
import EditGroup from './EditGroup';

function GroupsList() {
  const dispatch = useAppDispatch();
  const {
    isLoadingSupplierUserGroups,
    supplierUserGroups,
    isUserGroupEditFormOpen,
  } = useAppSelector((state) => state.settings);
  const isDebugActivated = useAppSelector(
    (state) => state.devTools.isDebugActivated
  );

  const columns: TableColumn<ListaGruposUsuarios>[] = [
    {
      id: 'id',
      label: 'id',
      hidden: !isDebugActivated,
      renderer: (v, _row) => (
        <>
          <Link
            target="_blank"
            rel="noopener noreferrer"
            variant="caption"
            color="textSecondary"
            href={getFirestoreConsoleLink(`/supplier-user-groups/${v}`)}
          >
            {v}
          </Link>
        </>
      ),
    },
    { id: 'name', label: 'Nome' },
    {
      id: 'grants',
      label: 'Permissões',
      formatter: (v) => {
        if (v.length === Object.keys(Constants.grants).length)
          return 'Permissão total';
        if (v.length === 1) return `${v.length} permissão`;
        return `${v.length} permissões`;
      },
    },
  ];

  const enhancedData: ListaGruposUsuarios[] = [
    ...supplierUserGroups.map((c) => ({
      id: c.id,
      name: c.name,
      grants: c.grants,
    })),
  ].sort(({ name: a }, { name: b }) => a.localeCompare(b));

  useEffect(() => {
    dispatch(settingsActions.loadSupplierUserGroups());
  }, []);

  const addGroupClick = () => {
    // navigate('novo');
    dispatch(settingsActions.setGroupEditFormMode('new'));
    dispatch(settingsActions.setSelectedSupplierUserGroup(undefined));
    dispatch(settingsActions.setIsUserGroupEditFormOpen(true));
  };

  const handleRowClick: GridEventListener<'rowClick'> = ({ row }) => {
    const selected = supplierUserGroups.find(({ id }) => id === row.id);
    if (selected) {
      dispatch(settingsActions.setGroupEditFormMode('edit'));
      dispatch(settingsActions.setSelectedSupplierUserGroup(selected));
      dispatch(settingsActions.setIsUserGroupEditFormOpen(true));
    }
  };

  return (
    <>
      <PageHeader
        title="Grupos"
        actions={
          <Button
            variant="contained"
            onClick={addGroupClick}
            startIcon={<AddIcon />}
          >
            Novo
          </Button>
        }
      />
      <DataTable<ListaGruposUsuarios>
        columns={columns}
        data={enhancedData}
        isLoading={isLoadingSupplierUserGroups}
        hideFooter
        onRowClick={handleRowClick}
        disableColumnMenu
      />
      <ResponsiveDialog
        open={isUserGroupEditFormOpen}
        maxWidth="sm"
        fullWidth
        onClose={() => {
          dispatch(settingsActions.setIsUserGroupEditFormOpen(false));
        }}
      >
        <EditGroup />
      </ResponsiveDialog>
    </>
  );
}

export default GroupsList;
