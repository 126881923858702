import EventIcon from '@mui/icons-material/Event';

import {
  Alert,
  Button,
  IconButton,
  InputAdornment,
  Popover,
  Stack,
  TextField,
  TextFieldProps,
  ToggleButton,
  Typography,
} from '@mui/material';
import {
  DatePickerProps,
  DateValidationError,
  LocalizationProvider,
  StaticDatePicker,
  StaticDatePickerProps,
} from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs, { Dayjs } from 'dayjs';
import 'dayjs/locale/pt-br';
import React, { useMemo, useState } from 'react';
import {
  Control,
  RegisterOptions,
  useController,
  useFormContext,
  useWatch,
} from 'react-hook-form';
import { OptionsFilterValues } from '../FilterForm/FilterForm';
import { ToggleButtonGroupField } from '../ToggleButtonGroupField/ToggleButtonGroupField';

export type DateExpression =
  | 'today'
  | 'yesterday'
  | 'tomorrow'
  | 'currentMonth'
  | 'last30days'
  | 'next30days';

const dateExpressions: { value: DateExpression; label: string }[] = [
  {
    value: 'today',
    label: 'Hoje',
  },
  {
    value: 'yesterday',
    label: 'Ontem',
  },
  {
    value: 'tomorrow',
    label: 'Amanhã',
  },
  {
    value: 'currentMonth',
    label: 'Mês Atual',
  },
  {
    value: 'last30days',
    label: 'Últimos 30 dias',
  },
  {
    value: 'next30days',
    label: 'Próximos 30 dias',
  },
];

type StaticDatePickerWithControlProps = StaticDatePickerProps<Dayjs> & {
  name: string;
  control: Control<any, any>;
  rules?:
    | Omit<
        RegisterOptions<any, string>,
        'disabled' | 'setValueAs' | 'valueAsNumber' | 'valueAsDate'
      >
    | undefined;
  onChange?: (_newValue: string) => void;
};

const StaticDatePickerWithControl = (
  props: StaticDatePickerWithControlProps
) => {
  const { name, control, rules, onChange, ...other } = props;
  const {
    field: { onChange: onFieldChange, value },
  } = useController({
    name,
    control,
    rules,
  });

  return (
    <StaticDatePicker
      value={dayjs(value)}
      onChange={(v) => {
        onFieldChange(v ? v.format('YYYY-MM-DD') : '');
        if (onChange) onChange(v ? v.format('YYYY-MM-DD') : '');
      }}
      slots={{}}
      slotProps={{
        toolbar: { hidden: true },
        actionBar: { actions: [] },
      }}
      {...other}
      sx={{
        ['div .MuiDateCalendar-root']: {
          // height: '300px',
        },
      }}
    />
  );
};

type DateRangeFieldWithControlProps = {
  name: string;
  startName: string;
  endName: string;
  formulaName?: string;
  label: string;
  control: Control<any, any>;
  onClose?: () => void;
  rules?:
    | Omit<
        RegisterOptions<any, string>,
        'disabled' | 'setValueAs' | 'valueAsNumber' | 'valueAsDate'
      >
    | undefined;
  datePickerProps?: DatePickerProps<Dayjs>;
  textFieldProps?: TextFieldProps;
  dateTypeOptions?: OptionsFilterValues[];
  disabledExpressions?: DateExpression[];
};

export const DateRangeFieldWithControl = (
  props: DateRangeFieldWithControlProps
) => {
  const {
    name,
    control,
    label,
    onClose,
    startName,
    endName,
    formulaName,
    dateTypeOptions,
    disabledExpressions,
  } = props;
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handleClick = () => {
    setAnchorEl(document.getElementById(name));
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOkClick = () => {
    handleClose();
    if (onClose) onClose();
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const startDate = useWatch({ control, name: startName });
  const endDate = useWatch({ control, name: endName });

  const { setValue } = useFormContext();

  const selectedOptionValue = useWatch({
    control,
    name,
  });
  const selectedOption = dateTypeOptions
    ? dateTypeOptions.find(({ value }) => value === selectedOptionValue)
    : undefined;

  const finalLabel = selectedOption ? selectedOption.label : label;

  const [error, setError] = useState<DateValidationError | null>(null);

  const errorMessage = useMemo(() => {
    switch (error) {
      case 'maxDate':
      case 'minDate':
        return 'A data final deve ser maior do que a data inicial';
      case 'invalidDate':
        return 'Data inválida';
      default:
        return '';
    }
  }, [error]);

  const currentDateRangeLabel =
    startDate !== endDate
      ? `${dayjs(startDate).format('DD/MM/YYYY')} a ${dayjs(endDate).format(
          'DD/MM/YYYY'
        )}`
      : dayjs(startDate).format('DD/MM/YYYY');

  const handleExpressionChange = (
    _event: React.MouseEvent<HTMLElement>,
    newValue: string
  ) => {
    const newDateExpression = newValue as DateExpression;
    switch (newDateExpression) {
      case 'currentMonth':
        setValue(startName, dayjs().startOf('month').format('YYYY-MM-DD'));
        setValue(endName, dayjs().endOf('month').format('YYYY-MM-DD'));
        break;
      case 'last30days':
        setValue(startName, dayjs().add(-30, 'day').format('YYYY-MM-DD'));
        setValue(endName, dayjs().format('YYYY-MM-DD'));
        break;
      case 'next30days':
        setValue(startName, dayjs().format('YYYY-MM-DD'));
        setValue(endName, dayjs().add(30, 'day').format('YYYY-MM-DD'));
        break;
      case 'today':
        setValue(startName, dayjs().format('YYYY-MM-DD'));
        setValue(endName, dayjs().format('YYYY-MM-DD'));
        break;
      case 'tomorrow':
        setValue(startName, dayjs().add(1, 'day').format('YYYY-MM-DD'));
        setValue(endName, dayjs().add(1, 'day').format('YYYY-MM-DD'));
        break;
      case 'yesterday':
        setValue(startName, dayjs().add(-1, 'day').format('YYYY-MM-DD'));
        setValue(endName, dayjs().add(-1, 'day').format('YYYY-MM-DD'));
        break;
    }
    handleClose();
    if (onClose) onClose();
  };

  const handleDatePickerChange = () => {
    if (formulaName) setValue(formulaName, null);
  };

  const filteredDateExpressions = dateExpressions.filter(
    (d) => !(disabledExpressions ?? []).includes(d.value)
  );
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="pt-br">
      <TextField
        sx={{ width: '270px' }}
        id={name}
        label={finalLabel}
        value={currentDateRangeLabel}
        placeholder={label}
        onClick={handleClick}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton edge="end" onClick={handleClick}>
                <EventIcon />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Stack direction="column" paddingTop={2}>
          {dateTypeOptions && (
            <>
              <Typography variant="body1" color="textPrimary" align="center">
                Qual data você quer aplicar o filtro?
              </Typography>
              <Stack paddingTop={1} justifyItems="center" alignItems="center">
                <ToggleButtonGroupField
                  exclusive
                  size="small"
                  color="primary"
                  control={control}
                  name={name}
                >
                  {dateTypeOptions.map(({ label, value }) => (
                    <ToggleButton key={value} value={value}>
                      {label}
                    </ToggleButton>
                  ))}
                </ToggleButtonGroupField>
              </Stack>
            </>
          )}
          <Stack direction="row" gap={2}>
            <StaticDatePickerWithControl
              name={startName}
              control={control}
              onError={(e) => setError(e)}
              maxDate={dayjs(endDate)}
              onChange={handleDatePickerChange}
            />
            <StaticDatePickerWithControl
              name={endName}
              control={control}
              onError={(e) => setError(e)}
              minDate={dayjs(startDate)}
              onChange={handleDatePickerChange}
            />
          </Stack>
          {filteredDateExpressions &&
            filteredDateExpressions.length > 0 &&
            formulaName && (
              <Stack
                paddingBottom={2}
                justifyItems="center"
                alignItems="center"
              >
                <ToggleButtonGroupField
                  exclusive
                  size="small"
                  color="primary"
                  control={control}
                  name={formulaName}
                  onChange={handleExpressionChange}
                  allowNull
                >
                  {filteredDateExpressions.map(({ label, value }) => (
                    <ToggleButton key={value} value={value}>
                      {label}
                    </ToggleButton>
                  ))}
                </ToggleButtonGroupField>
              </Stack>
            )}
          <Stack
            direction="row"
            paddingX={2}
            paddingBottom={2}
            gap={2}
            alignItems="center"
            justifyContent="space-between"
          >
            {error ? (
              <Alert severity="error">{errorMessage}</Alert>
            ) : (
              <Typography variant="h5">{currentDateRangeLabel}</Typography>
            )}
            <Button
              disabled={!!error}
              type="submit"
              variant="contained"
              onClick={handleOkClick}
            >
              OK
            </Button>
          </Stack>
        </Stack>
      </Popover>
    </LocalizationProvider>
  );
};
