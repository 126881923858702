import {
  Checkbox,
  CheckboxProps,
  FormControl,
  FormControlLabel,
  FormHelperText,
} from '@mui/material';
import React from 'react';
import { Control, useController, Validate } from 'react-hook-form';

export type CheckBoxFieldOption<T> = {
  label: string;
  value: T;
};

type CheckBoxFieldProps = CheckboxProps & {
  name: string;
  label: string;
  control: Control<any, any>;
  customValidate?: Validate<any, any>;
};

export const CheckBoxField = (props: CheckBoxFieldProps) => {
  const { control, label, name, customValidate, ...other } = props;
  const {
    field: { onChange, value },
    fieldState: { error },
  } = useController({
    name,
    control,
    rules: {
      validate: customValidate ?? (() => true),
    },
  });

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange((event.target as HTMLInputElement).checked);
  };

  return (
    <FormControl error={!!error}>
      <FormControlLabel
        label={label}
        control={
          <Checkbox {...other} checked={value} onChange={handleChange} />
        }
      />
      <FormHelperText>{error?.message}</FormHelperText>
    </FormControl>
  );
};
