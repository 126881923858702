import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { LoadingButton } from '@mui/lab';
import {
  Accordion,
  AccordionDetails,
  Alert,
  Box,
  Link,
  Stack,
  TextField,
  Typography,
  styled,
} from '@mui/material';
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from '@mui/material/AccordionSummary';
import { Constants } from 'pr-constants';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import ConfirmationDialog from '../../../components/ConfirmationDialog/ConfirmationDialog';
import { SettlementStatus } from '../../../components/SettlementStatus/SettlementStatus';
import DataTable from '../../../components/Table/DataTable';
import { TableColumn } from '../../../components/Table/table.types';
import { getFirestoreConsoleLink } from '../../../helpers/firebase';
import { useAppDispatch, useAppSelector } from '../../../helpers/hooks';
import PaymentRequestInfo from '../components/PaymentRequestInfo';
import { retailerActions } from '../retailer.slice';
import { DetalhePagamento } from '../retailer.types';

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary expandIcon={<ExpandMoreIcon />} {...props} />
))(({ theme }) => ({
  // backgroundColor:
  //   theme.palette.mode === 'dark'
  //     ? 'rgba(255, 255, 255, .05)'
  //     : 'rgba(0, 0, 0, .03)',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(180deg)',
  },
  '& .MuiAccordionSummary-content.Mui-expanded': {
    marginLeft: theme.spacing(1),
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}));

function VarejistaConsultaPagamento() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { isOperatingHours } =
    useAppSelector((state) => state.app.operatingHours) ?? {};
  const {
    isLoadingSelectedPaymentRequest,
    selectedPaymentRequest,
    selectedPaymentRequestDetails,
    isApprovingPaymentRequest,
  } = useAppSelector((state) => state.retailer);
  const { isDebugActivated } = useAppSelector((state) => state.devTools);
  const [isApproveConfirmationOpen, setIsApproveConfirmationOpen] =
    useState(false);
  const [isRejectConfirmationOpen, setIsRejectConfirmationOpen] =
    useState(false);
  const [rejectedReason, setRejectedReason] = useState('');
  const [rejectedReasonError, setRejectedReasonError] = useState<
    string | undefined
  >(undefined);

  const { idPaymentRequest } = useParams();

  useEffect(() => {
    if (idPaymentRequest) {
      dispatch(retailerActions.loadSelectedPaymentRequest(idPaymentRequest));
    }
    setRejectedReason('');
    setRejectedReasonError(undefined);
  }, [idPaymentRequest]);

  const columns: TableColumn<DetalhePagamento>[] = [
    {
      id: 'id',
      label: 'id',
      hidden: !isDebugActivated,
      renderer: (v) => (
        <Link
          target="_blank"
          rel="noopener noreferrer"
          variant="caption"
          color="textSecondary"
          href={getFirestoreConsoleLink(`/payment-request-details/${v}`)}
        >
          {v}
        </Link>
      ),
    },
    {
      id: 'idPaymentRequest',
      label: 'idPaymentRequest',
      hidden: !isDebugActivated,
      renderer: (v) => (
        <Link
          target="_blank"
          rel="noopener noreferrer"
          variant="caption"
          color="textSecondary"
          href={getFirestoreConsoleLink(`/payment-requests/${v}`)}
        >
          {v}
        </Link>
      ),
    },
    { id: 'dataLiquidacao', label: 'Data de Liquidação', type: 'date' },
    { id: 'credenciadora', label: 'Credenciadora' },
    { id: 'bandeira', label: 'Bandeira do Cartão' },
    { id: 'valor', label: 'Valor', type: 'money' },
    {
      id: 'status',
      label: 'Status',
      renderer: (v) => {
        if (
          selectedPaymentRequest?.status !== 'ativo' &&
          selectedPaymentRequest?.status !== 'finalizado'
        )
          return '-';
        return <SettlementStatus status={v} />;
      },
    },
    { id: 'effectPriority', label: 'Prioridade', type: 'number' },
  ];

  const enhancedData: DetalhePagamento[] = selectedPaymentRequest
    ? selectedPaymentRequestDetails.map((d) => {
        return {
          id: d.id,
          idPaymentRequest: d.idPaymentRequest,
          dataLiquidacao: d.incomingDate,
          credenciadora: Constants.getDebtor(d.debtor),
          bandeira: Constants.getPaymentScheme(d.paymentScheme),
          valor: d.value,
          status: d.settlementStatus,
          effectPriority: d.effectPriority,
        };
      })
    : [];

  const handleApproveClick: React.MouseEventHandler<HTMLButtonElement> = (
    e
  ) => {
    e.stopPropagation();
    e.preventDefault();
    setIsApproveConfirmationOpen(true);
  };
  const handleApproveConfirmClick: React.MouseEventHandler<
    HTMLButtonElement
  > = () => {
    if (selectedPaymentRequest) {
      dispatch(
        retailerActions.confirmPayment({
          idPaymentRequest: selectedPaymentRequest.id,
          navigate,
        })
      );
      setIsApproveConfirmationOpen(false);
    }
  };

  const handleRejectClick: React.MouseEventHandler<HTMLButtonElement> = (e) => {
    e.stopPropagation();
    e.preventDefault();
    setIsRejectConfirmationOpen(true);
  };
  const handleRejectConfirmClick: React.MouseEventHandler<
    HTMLButtonElement
  > = () => {
    if (rejectedReason.trim() === '' || rejectedReason.trim().length < 4)
      setRejectedReasonError('Preenchimento obrigatório');
    else {
      if (selectedPaymentRequest) {
        dispatch(
          retailerActions.rejectPayment({
            idPaymentRequest: selectedPaymentRequest.id,
            navigate,
            rejectedReason,
          })
        );
        setIsRejectConfirmationOpen(false);
      }
    }
  };

  const canApprovePaymentRequest =
    isOperatingHours &&
    !isLoadingSelectedPaymentRequest &&
    selectedPaymentRequest &&
    selectedPaymentRequest.status === 'pendente';

  return (
    <>
      <Box paddingBottom={2}>
        <Accordion defaultExpanded>
          <AccordionSummary aria-controls="panel1-content" id="panel1-header">
            <Stack
              width={1}
              direction="row"
              alignItems="center"
              justifyContent="space-between"
            >
              Detalhes do Pagamento
            </Stack>
          </AccordionSummary>
          <AccordionDetails>
            <PaymentRequestInfo />
            {canApprovePaymentRequest && (
              <Stack
                direction="row"
                gap={2}
                paddingTop={4}
                justifyContent="center"
              >
                <LoadingButton
                  disabled={!canApprovePaymentRequest}
                  loading={isApprovingPaymentRequest}
                  variant="contained"
                  color="error"
                  onClick={handleRejectClick}
                  startIcon={<CloseIcon />}
                >
                  Rejeitar
                </LoadingButton>
                <LoadingButton
                  disabled={!canApprovePaymentRequest}
                  loading={isApprovingPaymentRequest}
                  variant="contained"
                  color="success"
                  onClick={handleApproveClick}
                  startIcon={<CheckIcon />}
                >
                  Aprovar
                </LoadingButton>
              </Stack>
            )}
          </AccordionDetails>
        </Accordion>
      </Box>
      <Typography variant="body2" color="textSecondary" component="p">
        Liquidações:
      </Typography>
      <DataTable<DetalhePagamento>
        columns={columns}
        data={enhancedData}
        isLoading={isLoadingSelectedPaymentRequest}
      />
      <ConfirmationDialog
        open={isApproveConfirmationOpen}
        title="Confirmação"
        isLoading={isApprovingPaymentRequest}
        actions={[
          {
            name: 'Voltar',
            buttonProps: {
              autoFocus: true,
              onClick: () => setIsApproveConfirmationOpen(false),
            },
          },
          {
            name: 'Aprovar',
            showLoading: true,
            buttonProps: {
              variant: 'contained',
              color: 'success',
              startIcon: <CheckIcon />,
              onClick: handleApproveConfirmClick,
            },
          },
        ]}
      >
        <Typography variant="body1" color="text.primary">
          <Stack direction="column" gap={2}>
            Deseja APROVAR este pagamento?
            <Alert severity="warning">
              ATENÇÃO! Esta ação não poderá ser desfeita!
            </Alert>
          </Stack>
        </Typography>
      </ConfirmationDialog>
      <ConfirmationDialog
        open={isRejectConfirmationOpen}
        title="Confirmação"
        isLoading={isApprovingPaymentRequest}
        actions={[
          {
            name: 'Voltar',
            buttonProps: {
              autoFocus: true,
              onClick: () => setIsRejectConfirmationOpen(false),
            },
          },
          {
            name: 'Rejeitar',
            showLoading: true,
            buttonProps: {
              variant: 'contained',
              color: 'error',
              startIcon: <CloseIcon />,
              onClick: handleRejectConfirmClick,
            },
          },
        ]}
      >
        <Typography variant="body1" color="text.primary">
          <Stack direction="column" gap={2}>
            <Typography variant="body1">
              Descreva o motivo para não aprovar essa solicitação.
            </Typography>
            <TextField
              label="Motivo"
              onChange={(e) => {
                if (rejectedReasonError) setRejectedReasonError(undefined);
                setRejectedReason(e.target.value);
              }}
              value={rejectedReason}
              error={!!rejectedReasonError}
              helperText={rejectedReasonError}
            />
            <Alert severity="warning">
              ATENÇÃO! Esta ação não poderá ser desfeita!
            </Alert>
          </Stack>
        </Typography>
      </ConfirmationDialog>
    </>
  );
}

export default VarejistaConsultaPagamento;
