import { Models, Service } from 'types';
import { apiCall } from '../helpers/api';

type T = Models.Company;
const service: Service = 'supplier';
const resource = 'web/retailer-company';

export class WebRetailerCompanyHelpers {
  public static list = async (): Promise<T[]> => {
    return await apiCall<T[]>({
      service,
      resource: `${resource}`,
    });
  };
}
