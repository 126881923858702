import { Outlet, useSearchParams } from 'react-router-dom';

import '../../fonts/OpenSans/OpenSans-Bold.ttf';
import '../../fonts/OpenSans/OpenSans-Regular.ttf';
import '../../fonts/OpenSans/OpenSans-SemiBold.ttf';
import '../../styles/main.css';
import '../../styles/variables.css';

import { useAuth0 } from '@auth0/auth0-react';
import { Alert, Box, Stack } from '@mui/material';
import { useEffect } from 'react';
import NavigationHeader from '../../components/NavigationHeader/NavigationHeader';
import { setTokenGenerator } from '../../helpers/api';
import { useAppDispatch, useAppSelector } from '../../helpers/hooks';
import { checkOperatingHours } from '../../helpers/operating-hours';
import PageLoader from '../../pages/PageLoader';
import { appActions } from './app-slice';
import { OperatingHours } from './app.types';

export const MainContainer = () => {
  const [params] = useSearchParams();
  const { isLoading, isAuthenticated, user } = useAuth0();
  const dispatch = useAppDispatch();

  const isInitializing = useAppSelector((state) => state.app.isInitializing);

  const { getAccessTokenSilently } = useAuth0();
  useEffect(() => {
    setTokenGenerator(getAccessTokenSilently);
  }, [getAccessTokenSilently]);

  const { isOperatingHours, outsideOperatingHoursText } =
    useAppSelector((state) => state.app.operatingHours) ?? {};

  const idInvite = params.get('idInvite');
  useEffect(() => {
    if (isAuthenticated && user) {
      dispatch(
        appActions.initDBUser({ user, idInvite: idInvite ?? undefined })
      );
    }
  }, [isAuthenticated]);
  useEffect(() => {
    const intervalId = setInterval(async () => {
      const operatingHours: OperatingHours = await checkOperatingHours();
      dispatch(appActions.setOperatingHours(operatingHours));

      return () => clearInterval(intervalId);
    }, 60000);
  }, []);

  return (
    <>
      {isLoading || isInitializing ? (
        <PageLoader />
      ) : (
        <>
          <Stack
            flexGrow={1}
            direction="column"
            height="100dvh"
            paddingTop={10}
            paddingBottom={2}
            marginX="auto"
            width={1}
            maxWidth="1600px"
            paddingX={2}
            overflow="hidden"
          >
            <Box paddingBottom={1}>
              <NavigationHeader />
            </Box>
            {isOperatingHours !== undefined && !isOperatingHours && (
              <Box mb={2}>
                <Alert variant="outlined" severity="warning">
                  {outsideOperatingHoursText}
                </Alert>
              </Box>
            )}
            <Outlet />
          </Stack>
        </>
      )}
    </>
  );
};
