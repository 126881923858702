import CheckIcon from '@mui/icons-material/Check';
import ErrorIcon from '@mui/icons-material/Error';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';

import { Chip } from '@mui/material';
import { ReactElement } from 'react';
import { Models } from 'types';
import { getSettlementStatus } from '../../helpers/settlement-status';

type StatusIconProps = {
  status: Models.SettlementStatus;
};
export const SettlementStatus = ({ status }: StatusIconProps) => {
  let icon: ReactElement | undefined;
  let color:
    | 'error'
    | 'default'
    | 'success'
    | 'primary'
    | 'secondary'
    | 'info'
    | 'warning'
    | undefined;
  switch (status) {
    case 'aguardando_liquidacao':
      icon = <HourglassEmptyIcon />;
      color = 'warning';
      break;
    case 'finalizado':
      color = 'success';
      icon = <CheckIcon />;
      break;
    case 'em_contestacao':
      icon = <ErrorIcon />;
      color = 'error';
      break;
  }

  return (
    <Chip
      size="small"
      variant="filled"
      icon={icon}
      color={color}
      label={getSettlementStatus(status)}
    />
  );
};
