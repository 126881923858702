import BusinessIcon from '@mui/icons-material/Business';
import StoreIcon from '@mui/icons-material/Store';
import {
  Chip,
  Divider,
  FormControl,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
} from '@mui/material';
import React from 'react';
import { useAppDispatch, useAppSelector } from '../../helpers/hooks';
import { companySelectActions } from './company-select-slice';
type MenuDataType =
  | 'supplier'
  | 'retailer'
  | 'supplier-header'
  | 'retailer-header'
  | 'divider';
type CompanyMenuData = {
  id: string;
  name: string;
  icon?: React.ReactNode;
  chip?: React.ReactNode;
  type: MenuDataType;
};

const companySorter = (a: CompanyMenuData, b: CompanyMenuData) => {
  if (a.type === 'supplier-header') return -1;
  if (a.type === 'retailer-header' && b.type === 'retailer') return -1;
  if (a.type === 'divider' && b.type === 'retailer-header') return -1;
  if (
    a.type === 'divider' &&
    (b.type === 'supplier' || b.type === 'supplier-header')
  )
    return 1;
  if (a.type === 'supplier' && b.type === 'retailer') return -1;
  if (a.type === 'retailer' && b.type === 'supplier') return 1;
  return a.name.localeCompare(b.name);
};

export default function CompanySelect() {
  const dispatch = useAppDispatch();
  const { dbUser } = useAppSelector((state) => state.app);
  const { data } = useAppSelector((state) => state.companySelect);

  const handleChange = (event: SelectChangeEvent) => {
    dispatch(companySelectActions.selectCompany(event.target.value));
  };
  if (data.length === 0) return <></>;

  const menuData: CompanyMenuData[] = [
    ...data.map(
      ({
        id,
        name,
        fantasy,
        onboardingComplete,
        onboardingApproved,
        companyType,
      }) => {
        let icon: React.ReactNode | undefined;
        let chip: React.ReactNode | undefined;
        // if (onboardingComplete && !onboardingApproved)
        //   icon = <WarningAmberIcon color="warning" fontSize="small" />;
        if (!onboardingComplete)
          chip = <Chip size="small" color="info" label="Convidado" />;
        if (onboardingComplete && !onboardingApproved)
          chip = <Chip size="small" color="warning" label="Em análise" />;
        return {
          id,
          name: `${name ?? fantasy}`,
          type: companyType,
          icon,
          chip,
        };
      }
    ),
    {
      id: 'supplier-header',
      name: 'Fornecedores',
      type: 'supplier-header' as MenuDataType,
    },
    {
      id: 'retailer-header',
      name: 'Varejistas',
      type: 'retailer-header' as MenuDataType,
    },
    {
      id: 'divider',
      name: '',
      type: 'divider' as MenuDataType,
    },
  ].sort(companySorter);
  return (
    <FormControl variant="standard" fullWidth>
      <Select
        labelId="company-select-label"
        id="company-select"
        value={dbUser?.idCompany ?? data[0].id}
        label="Empresa"
        onChange={handleChange}
        MenuProps={{
          MenuListProps: {
            dense: true,
          },
        }}
      >
        {menuData.map(({ id, name, type, icon, chip }) => {
          if (type === 'divider') return <Divider key={id} />;
          return (
            <MenuItem
              key={id}
              value={id}
              disabled={
                type === 'retailer-header' || type === 'supplier-header'
              }
              sx={{ gap: 1 }}
            >
              <Stack direction="row" alignItems="center" gap={2} width={1}>
                {type === 'retailer-header' || type === 'supplier-header' ? (
                  <>
                    <ListItemIcon>
                      {type === 'supplier-header' ? (
                        <BusinessIcon />
                      ) : (
                        <StoreIcon />
                      )}
                    </ListItemIcon>
                    <ListItemText>{name}</ListItemText>
                  </>
                ) : (
                  <ListItemText>{name}</ListItemText>
                )}
                {icon}
                {chip}
              </Stack>
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
}
