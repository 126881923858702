import { PayloadAction, createSlice } from '@reduxjs/toolkit';

type NotificationOptions = any;
export type Notification = {
  key: string;
  message: string;
  options: NotificationOptions;
  dismissed?: boolean;
};

type NotifierState = {
  notifications: Notification[];
};

const initialState: NotifierState = {
  notifications: [],
};

export const notifierSlice = createSlice({
  name: 'notifier',
  initialState,
  reducers: {
    enqueue: (state, { payload }: PayloadAction<Omit<Notification, 'key'>>) => {
      state.notifications.push({
        key: (new Date().getTime() + Math.random()).toString(),
        ...payload,
      });
    },
    close: (state, action) => {
      const { payload } = action;
      state.notifications = state.notifications.map((notification) =>
        payload.dismissAll || notification.key === payload
          ? { ...notification, dismissed: true }
          : { ...notification }
      );
    },
    remove: (state, action) => {
      const { payload } = action;
      state.notifications = state.notifications.filter(
        (notification) => notification.key !== payload
      );
    },
  },
});

export const notifierActions = notifierSlice.actions;

export default notifierSlice.reducer;
