import { map } from 'ramda';
import { all, fork } from 'redux-saga/effects';
import adminSagas from '../features/admin/admin.sagas';
import appSagas from '../features/app/app-sagas';
import bankAccountSagas from '../features/bank-account/bank-account-sagas';
import companySelectSagas from '../features/company-select/company-select-sagas';
import devToolsSagas from '../features/dev-tools/dev-tools-sagas';
import onboardingSagas from '../features/onboarding/onboarding-sagas';
import profileSagas from '../features/profile/profile-sagas';
import registrarSagas from '../features/registrar/registrar-sagas';
import retailerSagas from '../features/retailer/retailer.sagas';
import settingsSagas from '../features/settings/settings-sagas';
import supplierSagas from '../features/supplier/supplier.sagas';

export default function* rootSaga() {
  yield all(
    map<any, any>(fork, [
      ...appSagas,
      ...devToolsSagas,
      ...onboardingSagas,
      ...supplierSagas,
      ...retailerSagas,
      ...companySelectSagas,
      ...settingsSagas,
      ...profileSagas,
      ...bankAccountSagas,
      ...adminSagas,
      ...registrarSagas,
    ])
  );
}
