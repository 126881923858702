import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';

import './fonts/OpenSans/OpenSans-Bold.ttf';
import './fonts/OpenSans/OpenSans-Regular.ttf';
import './fonts/OpenSans/OpenSans-SemiBold.ttf';
import './styles/main.css';
import './styles/variables.css';

import { Provider } from 'react-redux';
import store from './store';

//...
import * as Sentry from '@sentry/react';
import React from 'react';
import { Main } from './Main';

// if (window.location.host !== 'localhost:3000')
Sentry.init({
  environment:
    window.location.host !== 'localhost:3000'
      ? process.env.REACT_APP_ENV
      : 'local',
  dsn: 'https://900359ef5417ed32e3a8a9bdccfc5ac4@o4506864240820224.ingest.us.sentry.io/4506864243572736',
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ['localhost', 'paguerecebivel.app'],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <Main />
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
