import SearchIcon from '@mui/icons-material/Search';

import { Badge, Chip, IconButton, Link, Stack } from '@mui/material';
import { GridEventListener } from '@mui/x-data-grid';
import dayjs from 'dayjs';
import { Constants } from 'pr-constants';
import { MouseEvent, useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Models } from 'types';
import FilterForm, {
  OptionsFilterValues,
} from '../../../components/FilterForm/FilterForm';
import { PageHeader } from '../../../components/PageHeader/PageHeader';
import { SettlementStatus } from '../../../components/SettlementStatus/SettlementStatus';
import DataTable from '../../../components/Table/DataTable';
import { TableColumn } from '../../../components/Table/table.types';
import { getFirestoreConsoleLink } from '../../../helpers/firebase';
import { useAppDispatch, useAppSelector } from '../../../helpers/hooks';
import { getSettlementStatus } from '../../../helpers/settlement-status';
import { supplierActions } from '../supplier.slice';
import {
  ListaLiquidacoes,
  ListaLiquidacoesFilterForm,
} from '../supplier.types';

function FornecedorListaLiquidacoes() {
  const navigate = useNavigate();
  const {
    paymentRequestDetails,
    paymentRequests,
    retailers,
    isLoadingSettlements,
  } = useAppSelector((state) => state.supplier);
  const { isDebugActivated } = useAppSelector((state) => state.devTools);
  const dispatch = useAppDispatch();
  const [params] = useSearchParams();
  const idRetailer = params.get('idRetailer');
  const startDate = params.get('s');
  const endDate = params.get('e');
  const statuses = params.getAll('ss') as unknown as Models.SettlementStatus[];
  const defaultStartDate = dayjs().startOf('month');
  const defaultEndDate = dayjs().endOf('month');
  const dateExpression = params.get('x') ?? undefined;
  const queryParams: Models.LoadPaymentRequestDetailsForSupplierRequest = {
    startDate: startDate ?? defaultStartDate.format('YYYYMMDD'),
    endDate: endDate ?? defaultEndDate.format('YYYYMMDD'),
    idRetailer: idRetailer ?? undefined,
    statuses,
  };

  const currentDateRangeLabel =
    queryParams.startDate !== queryParams.endDate
      ? `${dayjs(queryParams.startDate).format('DD/MM/YYYY')} a ${dayjs(
          queryParams.endDate
        ).format('DD/MM/YYYY')}`
      : dayjs(queryParams.startDate).format('DD/MM/YYYY');
  const currentDateRangeFilter = `Data: ${currentDateRangeLabel}`;
  const filteredRetailer = retailers.find(
    (r) => r.id === queryParams.idRetailer
  );
  const currentRetailerFilter = filteredRetailer
    ? `Cliente: ${filteredRetailer.fantasy ?? filteredRetailer.name}`
    : '';
  const [currentStatus] = statuses;
  const currentStatusFilter = `Status: ${
    currentStatus ? getSettlementStatus(currentStatus) : ''
  }`;

  const appliedFilterCount =
    (queryParams.idRetailer ? 1 : 0) + (currentStatus ? 1 : 0);

  useEffect(() => {
    dispatch(supplierActions.loadSettlements(queryParams));
  }, []);

  const navigateWithFilter = (
    destination: string,
    {
      dateExpression,
      startDate,
      endDate,
      idRetailer,
      statuses,
    }: Models.LoadPaymentRequestDetailsForSupplierRequest & {
      dateExpression?: string;
    }
  ) => {
    const dateFilter = `?s=${startDate}&e=${endDate}`;
    const dateExpressionFilter = dateExpression ? `&x=${dateExpression}` : '';
    const statusesFilter = statuses
      ? statuses.map((s) => `&ss=${s}`).join('')
      : '';
    const retailerFilter = idRetailer ? `&idRetailer=${idRetailer}` : '';
    navigate(
      `${destination}${dateFilter}${dateExpressionFilter}${statusesFilter}${retailerFilter}`
    );
  };

  const handleFilter = (data: ListaLiquidacoesFilterForm) => {
    navigateWithFilter('.', {
      dateExpression:
        data.dateExpression as Models.LoadPaymentRequestsForSupplierRequestDateExpression,
      startDate: dayjs(data.startDate).format('YYYYMMDD'),
      endDate: dayjs(data.endDate).format('YYYYMMDD'),
      statuses:
        data.status === '_all' ? [] : [data.status as Models.SettlementStatus], //value,
      idRetailer: data.idRetailer === '_all' ? undefined : data.idRetailer,
    });
  };

  const statusFilterOptions: OptionsFilterValues<
    Models.SettlementStatus | '_all'
  >[] = [
    { label: 'Todos', value: '_all' },
    {
      label: getSettlementStatus('aguardando_liquidacao'),
      value: 'aguardando_liquidacao',
    },
    { label: getSettlementStatus('finalizado'), value: 'finalizado' },
    { label: getSettlementStatus('em_contestacao'), value: 'em_contestacao' },
  ];

  const companyFilterOptions: OptionsFilterValues<string>[] = [
    { label: 'Todos', value: '_all' },
    ...retailers
      .map((r) => ({ label: r.fantasy ?? r.name, value: r.id }))
      .sort(({ label: a }, { label: b }) => a.localeCompare(b)),
  ];

  const handleDeleteRetailerFilterClick = () => {
    navigateWithFilter('.', {
      ...queryParams,
      idRetailer: undefined,
      dateExpression,
    });
  };
  const handleDeleteStatusFilterClick = () => {
    navigateWithFilter('.', {
      ...queryParams,
      statuses: undefined,
      dateExpression,
    });
  };
  const handleResetFilter = () => {
    navigateWithFilter('.', {
      startDate: queryParams.startDate,
      endDate: queryParams.endDate,
    });
  };

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handleFilterButtonClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleFilterChipClick = () => {
    setAnchorEl(document.getElementById('searchButton'));
  };

  const handleFilterClose = () => {
    setAnchorEl(null);
  };

  const handleRowClick: GridEventListener<'rowClick'> = ({ row }) => {
    navigateWithFilter(`${row.idRetailer}/${row.idPaymentRequest}`, {
      ...queryParams,
      dateExpression,
    });
  };

  const columns: TableColumn<ListaLiquidacoes>[] = [
    {
      id: 'id',
      label: 'id',
      hidden: !isDebugActivated,
      renderer: (v) => (
        <Link
          target="_blank"
          rel="noopener noreferrer"
          variant="caption"
          color="textSecondary"
          href={getFirestoreConsoleLink(`/payment-request-details/${v}`)}
        >
          {v}
        </Link>
      ),
    },
    {
      id: 'idPaymentRequest',
      label: 'ID do Pagamento',
    },
    { id: 'cliente', label: 'Cliente' },
    { id: 'dataLiquidacao', label: 'Data', type: 'date' },
    { id: 'credenciadora', label: 'Credenciadora' },
    { id: 'bandeira', label: 'Bandeira do Cartão' },
    { id: 'valor', label: 'Valor', type: 'money' },
    {
      id: 'status',
      label: 'Status',
      center: true,
      renderer: (v) => <SettlementStatus status={v} />,
    },
    // {
    //   id: 'status',
    //   label: 'Status',
    //   renderer: (v, row: ListaLiquidacoes) => {
    //     let statusText = '-';
    //     switch (v) {
    //       case 'aguardando_liquidacao':
    //         statusText = 'Aguardando Liquidação';
    //         break;
    //       case 'em_conciliacao':
    //         statusText = 'Em Conciliação';
    //         break;
    //       case 'em_contestacao':
    //         statusText = 'Em Contestação';
    //         break;
    //       case 'finalizado':
    //         statusText = 'Finalizada';
    //         break;
    //     }
    //     const WarningIcon =
    //       row.statusPagamento !== 'ativo' && isAdmin ? (
    //         <Tooltip title={row.statusPagamento}>
    //           <WarningAmberIcon color="warning" />
    //         </Tooltip>
    //       ) : (
    //         <></>
    //       );
    //     return (
    //       <Stack
    //         direction="row"
    //         alignItems="center"
    //         gap={1}
    //         justifyContent="center"
    //       >
    //         <Typography variant="body2">{statusText}</Typography>
    //         {WarningIcon}
    //       </Stack>
    //     );
    //   },
    // },
  ];

  const enhancedData: ListaLiquidacoes[] = paymentRequestDetails
    .map((detail) => {
      const paymentRequest = paymentRequests.find(
        (p) => p.id === detail.idPaymentRequest
      );
      return { detail, paymentRequest };
    })
    .filter(
      ({ detail, paymentRequest }) =>
        detail.settlementStatus !== 'aguardando_liquidacao' || !!paymentRequest
    )
    .map(({ detail }) => {
      const retailer = retailers.find((r) => r.id === detail.idRetailer);
      return {
        id: detail.id,
        idPaymentRequest: detail.idPaymentRequest,
        idRetailer: detail.idRetailer,
        cliente: retailer ? retailer.fantasy ?? retailer.name : '...',
        dataLiquidacao: detail.incomingDate,
        credenciadora: Constants.getDebtor(detail.debtor),
        bandeira: Constants.getPaymentScheme(detail.paymentScheme),
        valor: detail.value,
        status: detail.settlementStatus,
      };
    });

  return (
    <>
      <PageHeader
        title="Liquidações"
        actions={
          <Stack direction="row" gap={2} alignItems="center">
            <Chip
              label={currentDateRangeFilter}
              onClick={handleFilterChipClick}
            />
            {queryParams.idRetailer && (
              <Chip
                label={currentRetailerFilter}
                onClick={handleFilterChipClick}
                onDelete={handleDeleteRetailerFilterClick}
              />
            )}
            {currentStatus && (
              <Chip
                label={currentStatusFilter}
                onClick={handleFilterChipClick}
                onDelete={handleDeleteStatusFilterClick}
              />
            )}
            <FilterForm<ListaLiquidacoesFilterForm>
              onFilter={handleFilter}
              onClear={handleResetFilter}
              onClose={handleFilterClose}
              anchorEl={anchorEl}
              appliedFilterCount={appliedFilterCount}
              defaultValues={{
                idRetailer: queryParams.idRetailer ?? '_all',
                status: queryParams.statuses
                  ? queryParams.statuses[0] ?? '_all'
                  : '_all',
                startDate: queryParams.startDate,
                endDate: queryParams.endDate,
                dateExpression: dateExpression,
              }}
              fields={[
                {
                  type: 'date-range',
                  name: 'dateType',
                  startName: 'startDate',
                  endName: 'endDate',
                  formulaName: 'dateExpression',
                  label: 'Data',
                },
                {
                  type: 'options',
                  name: 'idRetailer',
                  label: 'Cliente',
                  options: companyFilterOptions,
                },
                {
                  type: 'options',
                  name: 'status',
                  label: 'Status',
                  options: statusFilterOptions,
                },
              ]}
            />
            <IconButton id="searchButton" onClick={handleFilterButtonClick}>
              <Badge color="primary" badgeContent={appliedFilterCount}>
                <SearchIcon />
              </Badge>
            </IconButton>
          </Stack>
        }
      />
      <DataTable<ListaLiquidacoes>
        columns={columns}
        data={enhancedData}
        isLoading={isLoadingSettlements}
        onRowClick={handleRowClick}
      />
    </>
  );
}

export default FornecedorListaLiquidacoes;
