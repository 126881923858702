import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import CheckIcon from '@mui/icons-material/Check';
import DeleteIcon from '@mui/icons-material/Delete';
import DownloadIcon from '@mui/icons-material/Download';
import EditIcon from '@mui/icons-material/Edit';
import ErrorIcon from '@mui/icons-material/Error';
import SaveIcon from '@mui/icons-material/Save';
import { LoadingButton } from '@mui/lab';
import { Box, IconButton, Tooltip } from '@mui/material';
import React, { useState } from 'react';
import { Control, Validate, useForm } from 'react-hook-form';
import { Models } from 'types';
import ConfirmationDialog from '../../components/ConfirmationDialog/ConfirmationDialog';
import EditForm from '../../components/EditForm/EditForm';
import EditFormAction from '../../components/EditForm/EditFormAction';
import EditFormContent from '../../components/EditForm/EditFormContent';
import EditFormHeader from '../../components/EditForm/EditFormHeader';
import FileUploadWithControl from '../../components/FileUpload/FileUploadWithControl';
import { useAppDispatch, useAppSelector } from '../../helpers/hooks';
import useCompanyStatus from '../../hooks/useCompanyStatus';
import { UploadDocsForm } from '../../types';
import { appActions } from '../app/app-slice';
import { settingsActions } from './settings-slice';

type DocumentDetailProps = {
  label: string;
  mode: 'edit' | 'display';
  name: string;
  missing: boolean;
  isDownloading: boolean;
  onEditClick: React.MouseEventHandler<HTMLButtonElement>;
  onClearClick: React.MouseEventHandler<HTMLButtonElement>;
  onDownloadClick: React.MouseEventHandler<HTMLButtonElement>;
  canEdit: boolean;
  canDelete: boolean;
  control: Control<any, any>;
  customValidate?: Validate<any, any>;
  children?: React.ReactNode;
};

const DocumentDetail = ({
  label,
  mode,
  name,
  missing,
  isDownloading,
  onEditClick,
  onClearClick,
  onDownloadClick,
  canEdit,
  canDelete,
  control,
  customValidate,
  children,
}: DocumentDetailProps) => {
  return (
    <>
      <Box
        display={mode === 'display' ? 'flex' : 'none'}
        flexDirection="row"
        alignItems="center"
        gap={2}
      >
        <CheckIcon color="success" />
        <LoadingButton
          loading={isDownloading}
          loadingPosition="start"
          startIcon={<DownloadIcon />}
          onClick={onDownloadClick}
        >
          {label}
        </LoadingButton>
        <Box flexGrow={1}></Box>
        {children}
        {canEdit && (
          <Tooltip title="Substituir documento">
            <IconButton onClick={onEditClick}>
              <EditIcon />
            </IconButton>
          </Tooltip>
        )}
      </Box>
      <Box
        display={mode === 'edit' ? 'flex' : 'none'}
        flexDirection="row"
        alignItems="center"
        gap={2}
      >
        {missing ? <ErrorIcon color="error" /> : <CheckIcon color="success" />}
        <FileUploadWithControl
          fullWidth
          disabled={!canEdit}
          label={label}
          name={name}
          control={control}
          rules={{
            validate: customValidate,
          }}
        />
        {canDelete && (
          <IconButton onClick={onClearClick}>
            <DeleteIcon />
          </IconButton>
        )}
      </Box>
    </>
  );
};

export default function Documents() {
  const dispatch = useAppDispatch();
  const { dbUser, company, isLoading } = useAppSelector((state) => state.app);
  const { loadingButtons } = useAppSelector((state) => state.settings);
  const isAdmin = ['dev', 'adm'].includes(dbUser?.userRole ?? '');
  const [isSendToBankConfirmOpen, setIsSendToBankConfirmOpen] = useState(false);
  const [docIdToSend, setDocIdToSend] = useState<string | undefined>();
  const [docTypeToSend, setDocTypeToSend] = useState<
    Models.DocType | undefined
  >();

  const {
    docIdOptin,
    docIdMainContactSelfieWithDoc,
    docIdMainContactDocPicture,
    docIdMainContactDocPictureBack,
    docIdCompanyConstitutionDoc,
    docIdBankContract,
    bankDocIdBankContract,
    bankDocIdCompanyConstitutionDoc,
    bankDocIdMainContactDocPicture,
  } = company ?? {};

  const {
    handleSubmit,
    formState: { isDirty },
    watch,
    setValue,
    resetField,
    control,
  } = useForm<UploadDocsForm>({
    defaultValues: {
      optin: '',
      mainContactSelfieWithDoc: '',
      mainContactDocPicture: '',
      mainContactDocPictureBack: '',
      companyConstitutionDoc: '',
      bankContract: '',
      updateOptin: !docIdOptin,
      updateMainContactSelfieWithDoc: !docIdMainContactSelfieWithDoc,
      updateMainContactDocPicture: !docIdMainContactDocPicture,
      updateMainContactDocPictureBack: !docIdMainContactDocPictureBack,
      updateCompanyConstitutionDoc: !docIdCompanyConstitutionDoc,
      updateBankContract: !docIdBankContract,
    },
  });

  const getDocumentLink = (id?: string, buttonId?: string) => {
    if (id && buttonId)
      dispatch(settingsActions.getDownloadLink({ id, buttonId }));
  };

  const [
    updateMainContactSelfieWithDoc,
    updateMainContactDocPicture,
    updateMainContactDocPictureBack,
    updateCompanyConstitutionDoc,
    updateBankContract,
  ] = watch([
    'updateMainContactSelfieWithDoc',
    'updateMainContactDocPicture',
    'updateMainContactDocPictureBack',
    'updateCompanyConstitutionDoc',
    'updateBankContract',
  ]);

  const { missingBankDocs, missingDocs, personType } = useCompanyStatus();

  const onSubmit = async (data: UploadDocsForm) => {
    const optinFile = data.optin ? (data.optin as unknown as File) : undefined;
    const mainContactSelfieWithDocFile = data.mainContactSelfieWithDoc
      ? (data.mainContactSelfieWithDoc as unknown as File)
      : undefined;
    const mainContactDocPictureFile = data.mainContactDocPicture
      ? (data.mainContactDocPicture as unknown as File)
      : undefined;
    const mainContactDocPictureBackFile = data.mainContactDocPictureBack
      ? (data.mainContactDocPictureBack as unknown as File)
      : undefined;
    const companyConstitutionDocFile = data.companyConstitutionDoc
      ? (data.companyConstitutionDoc as unknown as File)
      : undefined;
    const bankContractFile = data.bankContract
      ? (data.bankContract as unknown as File)
      : undefined;

    dispatch(
      settingsActions.updateDocs({
        optinFile,
        mainContactSelfieWithDocFile,
        mainContactDocPictureFile,
        mainContactDocPictureBackFile,
        companyConstitutionDocFile,
        bankContractFile,
      })
    );
  };

  const handleCloseClick = () => {
    dispatch(appActions.setIsDocumentsOpen(false));
  };

  const handleSendToBankConfirmClick = () => {
    if (docIdToSend && docTypeToSend) {
      setIsSendToBankConfirmOpen(false);
      dispatch(
        settingsActions.sendDocToBank({
          id: docIdToSend,
          docType: docTypeToSend,
        })
      );
    }
  };

  return (
    <EditForm>
      <EditFormHeader title="Documentos" onCloseClick={handleCloseClick} />
      <EditFormContent display="flex" flexDirection="column" gap={2}>
        {/* <DocumentDetail
          label="Anuência"
          mode={updateOptin ? 'edit' : 'display'}
          name="optin"
          missing={false}
          isDownloading={loadingButtons['optin']}
          onEditClick={() => setValue('updateOptin', true)}
          onClearClick={() => {
            setValue('updateOptin', false);
            resetField('optin');
          }}
          onDownloadClick={() => getDocumentLink(docIdOptin, 'optin')}
          canEdit={isAdmin}
          canDelete={!!docIdOptin}
          control={control}
        /> */}

        {company?.companyType === 'retailer' && (
          <DocumentDetail
            label="Selfie"
            mode={updateMainContactSelfieWithDoc ? 'edit' : 'display'}
            name="mainContactSelfieWithDoc"
            missing={missingDocs.includes('mainContactSelfieWithDoc')}
            isDownloading={loadingButtons['mainContactSelfieWithDoc']}
            onEditClick={() => setValue('updateMainContactSelfieWithDoc', true)}
            onClearClick={() => {
              setValue('updateMainContactSelfieWithDoc', false);
              resetField('mainContactSelfieWithDoc');
            }}
            onDownloadClick={() =>
              getDocumentLink(
                docIdMainContactSelfieWithDoc,
                'mainContactSelfieWithDoc'
              )
            }
            canEdit={isAdmin}
            canDelete={!!docIdMainContactSelfieWithDoc}
            control={control}
            customValidate={(v, { updateMainContactSelfieWithDoc }) =>
              updateMainContactSelfieWithDoc && v === ''
                ? 'O documento é obrigatório'
                : true
            }
          />
        )}
        <DocumentDetail
          label="Identificação"
          mode={updateMainContactDocPicture ? 'edit' : 'display'}
          name="mainContactDocPicture"
          missing={missingDocs.includes('mainContactDocPicture')}
          isDownloading={loadingButtons['mainContactDocPicture']}
          onEditClick={() => setValue('updateMainContactDocPicture', true)}
          onClearClick={() => {
            setValue('updateMainContactDocPicture', false);
            resetField('mainContactDocPicture');
          }}
          onDownloadClick={() =>
            getDocumentLink(docIdMainContactDocPicture, 'mainContactDocPicture')
          }
          canEdit={isAdmin && !bankDocIdMainContactDocPicture}
          canDelete={!!docIdMainContactDocPicture}
          control={control}
          customValidate={(v, { updateMainContactDocPicture }) =>
            updateMainContactDocPicture && v === ''
              ? 'O documento é obrigatório'
              : true
          }
        >
          {' '}
          {company?.companyType === 'supplier' &&
            docIdMainContactDocPicture &&
            isAdmin && (
              <Tooltip
                title={
                  bankDocIdMainContactDocPicture
                    ? `Documento enviado ao banco: [${bankDocIdMainContactDocPicture}]`
                    : 'Enviar documento ao banco'
                }
              >
                <span>
                  <LoadingButton
                    disabled={!!bankDocIdMainContactDocPicture}
                    loading={isLoading}
                    loadingPosition="center"
                    variant="outlined"
                    color={bankDocIdMainContactDocPicture ? 'success' : 'error'}
                    onClick={() => {
                      setDocIdToSend(docIdMainContactDocPicture);
                      setDocTypeToSend('mainContactDocPicture');
                      setIsSendToBankConfirmOpen(true);
                    }}
                  >
                    {missingBankDocs.includes('mainContactDocPicture') && (
                      <ErrorIcon />
                    )}
                    <AccountBalanceIcon />
                    {bankDocIdMainContactDocPicture && <CheckIcon />}
                  </LoadingButton>
                </span>
              </Tooltip>
            )}
        </DocumentDetail>

        {company?.companyType === 'retailer' && (
          <DocumentDetail
            label="Identificação (Verso)"
            mode={updateMainContactDocPictureBack ? 'edit' : 'display'}
            name="mainContactDocPictureBack"
            missing={false}
            isDownloading={loadingButtons['mainContactDocPictureBack']}
            onEditClick={() =>
              setValue('updateMainContactDocPictureBack', true)
            }
            onClearClick={() => {
              setValue('updateMainContactDocPictureBack', false);
              resetField('mainContactDocPictureBack');
            }}
            onDownloadClick={() =>
              getDocumentLink(
                docIdMainContactDocPictureBack,
                'mainContactDocPictureBack'
              )
            }
            canEdit={isAdmin}
            canDelete={!!docIdMainContactDocPictureBack}
            control={control}
          >
            {' '}
          </DocumentDetail>
        )}

        {personType === 'legal' && (
          <DocumentDetail
            label="Contrato Social"
            mode={updateCompanyConstitutionDoc ? 'edit' : 'display'}
            name="companyConstitutionDoc"
            missing={missingDocs.includes('companyConstitutionDoc')}
            isDownloading={loadingButtons['companyConstitutionDoc']}
            onEditClick={() => setValue('updateCompanyConstitutionDoc', true)}
            onClearClick={() => {
              setValue('updateCompanyConstitutionDoc', false);
              resetField('companyConstitutionDoc');
            }}
            onDownloadClick={() =>
              getDocumentLink(
                docIdCompanyConstitutionDoc,
                'companyConstitutionDoc'
              )
            }
            canEdit={isAdmin && !bankDocIdCompanyConstitutionDoc}
            canDelete={!!docIdCompanyConstitutionDoc}
            control={control}
            customValidate={(v, { updateCompanyConstitutionDoc }) =>
              updateCompanyConstitutionDoc && v === ''
                ? 'O documento é obrigatório'
                : true
            }
          >
            {company?.companyType === 'supplier' &&
              docIdCompanyConstitutionDoc &&
              isAdmin && (
                <Tooltip
                  title={
                    bankDocIdCompanyConstitutionDoc
                      ? `Documento enviado ao banco: [${bankDocIdCompanyConstitutionDoc}]`
                      : 'Enviar documento ao banco'
                  }
                >
                  <span>
                    <LoadingButton
                      disabled={!!bankDocIdCompanyConstitutionDoc}
                      loading={isLoading}
                      loadingPosition="center"
                      variant="outlined"
                      color={
                        bankDocIdCompanyConstitutionDoc ? 'success' : 'error'
                      }
                      onClick={() => {
                        setDocIdToSend(docIdCompanyConstitutionDoc);
                        setDocTypeToSend('companyConstitutionDoc');
                        setIsSendToBankConfirmOpen(true);
                      }}
                    >
                      {missingBankDocs.includes('companyConstitutionDoc') && (
                        <ErrorIcon />
                      )}
                      <AccountBalanceIcon />
                      {bankDocIdCompanyConstitutionDoc && <CheckIcon />}
                    </LoadingButton>
                  </span>
                </Tooltip>
              )}
          </DocumentDetail>
        )}
        {company?.companyType === 'supplier' && (
          <DocumentDetail
            label="Termo de abertura de conta"
            mode={updateBankContract ? 'edit' : 'display'}
            name="bankContract"
            missing={missingDocs.includes('bankContract')}
            isDownloading={loadingButtons['bankContract']}
            onEditClick={() => setValue('updateBankContract', true)}
            onClearClick={() => {
              setValue('updateBankContract', false);
              resetField('bankContract');
            }}
            onDownloadClick={() =>
              getDocumentLink(docIdBankContract, 'bankContract')
            }
            canEdit={isAdmin && !bankDocIdBankContract}
            canDelete={!!docIdBankContract}
            control={control}
            customValidate={(v, { updateBankContract }) =>
              company?.companyType === 'supplier'
                ? updateBankContract && v === ''
                  ? 'O documento é obrigatório'
                  : true
                : true
            }
          >
            {docIdBankContract && isAdmin && (
              <Tooltip
                title={
                  bankDocIdBankContract
                    ? `Documento enviado ao banco: [${bankDocIdBankContract}]`
                    : 'Enviar documento ao banco'
                }
              >
                <span>
                  <LoadingButton
                    disabled={!!bankDocIdBankContract}
                    loading={isLoading}
                    loadingPosition="center"
                    variant="outlined"
                    color={bankDocIdBankContract ? 'success' : 'error'}
                    onClick={() => {
                      setDocIdToSend(docIdBankContract);
                      setDocTypeToSend('bankContract');
                      setIsSendToBankConfirmOpen(true);
                    }}
                  >
                    {missingBankDocs.includes('bankContract') && <ErrorIcon />}
                    <AccountBalanceIcon />
                    {bankDocIdBankContract && <CheckIcon />}
                  </LoadingButton>
                </span>
              </Tooltip>
            )}
          </DocumentDetail>
        )}
      </EditFormContent>
      {isAdmin && (
        <EditFormAction
          display="flex"
          flexDirection="row"
          justifyContent="flex-end"
          paddingTop={2}
        >
          <LoadingButton
            color="primary"
            onClick={handleSubmit(onSubmit)}
            type="submit"
            disabled={!isDirty}
            loading={isLoading}
            loadingPosition="start"
            startIcon={<SaveIcon />}
            variant="contained"
          >
            Salvar
          </LoadingButton>
        </EditFormAction>
      )}
      <ConfirmationDialog
        open={isSendToBankConfirmOpen}
        title="Confirmação"
        actions={[
          {
            name: 'Cancelar',
            buttonProps: {
              autoFocus: true,
              onClick: () => {
                setIsSendToBankConfirmOpen(false);
              },
            },
          },
          {
            name: 'Enviar',
            showLoading: true,
            buttonProps: {
              variant: 'contained',
              color: 'primary',
              startIcon: <CheckIcon />,
              onClick: handleSendToBankConfirmClick,
            },
          },
        ]}
      >
        Deseja enviar esse documento ao banco?
      </ConfirmationDialog>
    </EditForm>
  );
}
