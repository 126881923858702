import CheckIcon from '@mui/icons-material/Check';
import { CircularProgress, Divider } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2';
import { useState } from 'react';
import ConfirmationDialog from '../../components/ConfirmationDialog/ConfirmationDialog';
import EditForm from '../../components/EditForm/EditForm';
import EditFormContent from '../../components/EditForm/EditFormContent';
import EditFormHeader from '../../components/EditForm/EditFormHeader';
import { useAppDispatch, useAppSelector } from '../../helpers/hooks';
import useUserRole from '../../hooks/useUserRole';
import { appActions } from '../app/app-slice';
import GroupsList from './supplier-user-settings/GroupsList';
import InvitesList from './supplier-user-settings/InvitesList';
import UsersList from './supplier-user-settings/UsersList';

export default function SupplierUserSettings() {
  const dispatch = useAppDispatch();
  const { isLoading, company } = useAppSelector((state) => state.app);
  const { isDebugActivated } = useAppSelector((state) => state.devTools);

  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
  const { isAdmin } = useUserRole();

  const handleCloseClick = () => {
    dispatch(appActions.setIsSupplierUserSettingsOpen(false));
    // setIsConfirmationOpen(false);
  };

  return (
    <EditForm>
      <EditFormHeader
        title="Usuários e Permissões"
        onCloseClick={handleCloseClick}
      />

      <EditFormContent display="flex" flexDirection="column" gap={2}>
        {isLoading ? (
          <CircularProgress />
        ) : (
          <Grid2 container spacing={2}>
            <Grid2 xs={12}>
              <Divider />
            </Grid2>
            <Grid2 xs={12}>
              <GroupsList />
            </Grid2>
            <Grid2 xs={12}>
              <UsersList />
            </Grid2>

            <InvitesList />
          </Grid2>
        )}
      </EditFormContent>
      <ConfirmationDialog
        open={isConfirmationOpen}
        title="Confirmação"
        actions={[
          {
            name: 'Cancelar',
            buttonProps: {
              autoFocus: true,
              onClick: () => {
                setIsConfirmationOpen(false);
              },
            },
          },
          {
            name: 'Enviar',
            showLoading: true,
            buttonProps: {
              variant: 'contained',
              color: 'primary',
              startIcon: <CheckIcon />,
              onClick: () => {},
            },
          },
        ]}
      >
        Deseja atualizar as configurações?
      </ConfirmationDialog>
    </EditForm>
  );
}
