import { Models, Service } from 'types';
import { apiCall } from '../helpers/api';

type T = Models.Company;
const service: Service = 'supplier';
const resource = 'web/company';

export class WebCompanyHelpers {
  public static getById = async (id: string): Promise<T | undefined> => {
    try {
      return await apiCall<T>({
        service,
        resource: `${resource}/${id}`,
      });
    } catch (e) {
      if ((e as unknown as any)?.response?.status === 404) return undefined;
      throw e;
    }
  };
  public static list = async (
    companyTypes: Models.CompanyType[],
    whereField?: 'idSupplier' | 'idOwner',
    whereValue?: string
  ): Promise<T[]> => {
    const params = new URLSearchParams();
    for (const type of companyTypes) {
      params.append('t', type);
    }
    if (whereField && whereValue) {
      params.append('f', whereField);
      params.append('v', whereValue);
    }
    return await apiCall<T[]>({
      service,
      resource: `${resource}?${params.toString()}`,
    });
  };
  // public static create = async (
  //   newData: Omit<T, 'id' | 'dateadded'>
  // ): Promise<string> => {
  //   const { id } = await apiCall<Pick<T, 'id'>>({
  //     service,
  //     resource: `${resource}`,
  //     payload: newData,
  //     kyOpts: { method: 'post' },
  //   });
  //   return id;
  // };
  // public static patch = async (id: string, dataToUpdate: Partial<T>) => {
  //   await apiCall<Pick<T, 'id'>>({
  //     service,
  //     resource: `${resource}/${id}`,
  //     payload: dataToUpdate,
  //     kyOpts: { method: 'patch' },
  //   });
  //   return id;
  // };
}
