/* eslint-disable @typescript-eslint/no-namespace */
import { Models } from 'types';

export namespace Constants {
  const states: Models.State[] = [
    'AC',
    'AL',
    'AP',
    'AM',
    'BA',
    'CE',
    'DF',
    'ES',
    'GO',
    'MA',
    'MT',
    'MS',
    'MG',
    'PA',
    'PB',
    'PR',
    'PE',
    'PI',
    'RJ',
    'RN',
    'RS',
    'RO',
    'RR',
    'SC',
    'SP',
    'SE',
    'TO',
  ];
  export const getStateList = () => states;

  const maritalStatusMap: Record<Models.MaritalStatus, string> = {
    single: 'Solteiro(a)',
    married: 'Casado(a)',
    widower: 'Viúvo(a)',
    divorced: 'Divorciado(a)',
    separated: 'Separado(a)',
  };
  export const getMaritalStatus = (
    maritalStatus: Models.MaritalStatus,
  ): string => maritalStatusMap[maritalStatus] ?? maritalStatus;
  export const getMaritalStatusMap = () => maritalStatusMap;

  const paymentSchemeMap: Record<Models.PaymentScheme, string> = {
    ACC: 'Amex Cartão de Crédito',
    AUC: 'Aura Cartão de Crédito',
    BCC: 'Banescard Cartão de Crédito',
    BNC: 'Banese Crédito',
    BRS: 'Banrisul (Compra)',
    CAC: 'Cielo Amex Crédito',
    CBD: 'Cabal Débito',
    CSC: 'Credi-Shop Cartão de Crédito',
    CZC: 'Credz Cartão de Crédito',
    DBC: 'Discover Cartão de Crédito',
    ECC: 'Elo Cartão de Crédito',
    FRC: 'Fortbrasil Cartão de Crédito',
    HCC: 'Hipercard Cartão de Crédito',
    JCC: 'JCB Cartão de Crédito',
    MCC: 'Mastercard Cartão de Crédito',
    MXC: 'Maxifrota Cartão de Crédito',
    RCC: 'Redesplan Cartão de Crédito',
    SCD: 'Sorocred Cartão de Débito',
    SIC: 'Sicred (Compra)',
    TKC: 'Ticketlog Pós Cartão de Crédito',
    VCD: 'Visa Cartão de Débito',
    AGC: 'Agiplan Cartão de Crédito',
    AVC: 'Avista Cartão de Crédito',
    BCD: 'Banescard Cartão de Débito',
    BRC: 'Brasil Card Cartão de Crédito',
    BVV: 'Ben Visa Vale',
    CBC: 'Cabal Crédito',
    CCD: 'Calcard Cartão de Crédito',
    CUP: 'CUP Cartão de Crédito',
    DAC: 'Dacasa Cartão de Crédito',
    DCC: 'Dinners Cartão de Crédito',
    ECD: 'Elo Cartão de Débito',
    GCC: 'Goodcard Crédito',
    HCD: 'Hipercard Cartão de Débito',
    MAC: 'Mais Cartão de Crédito',
    MCD: 'Mastercard Cartão de Débito',
    OCD: 'Ourocard Cartão de Débito',
    SCC: 'Sorocred Cartão de Crédito',
    SFC: 'Senff Cartão de Crédito',
    SPC: 'Sem Parar Cartão de Crédito',
    VCC: 'Visa Cartão de Crédito',
    VDC: 'Verdecard Cartão de Crédito',
  };

  export const getPaymentScheme = (
    paymentScheme: Models.PaymentScheme,
  ): string => paymentSchemeMap[paymentScheme] ?? paymentScheme;

  /* eslint-disable camelcase */
  const companyCategoryMap: Record<Models.CompanyCategory, string> = {
    ltda: 'Limitada',
    sa: 'Sociedade Anônima',
    micro_enterprise: 'Micro Empresa',
    freelancer: 'Freelancer',
    sa_opened: 'Sociedade Anônima de Capital Aberto',
    sa_closed: 'Sociedade Anônima de Capital Fechado',
    se_ltda: 'Sociedade Empresária Limitada',
    se_cn: 'Sociedade Empresária em Nome Coletivo',
    se_cs: 'Sociedade Empresária em Comandita Simples',
    se_ca: 'Sociedade Empresária em Comandita por Ações',
    scp: 'Sociedade em Conta de Participação',
    ei: 'Empresário Individual',
    ese: 'Estabelecimento, no Brasil, de Sociedade Estrangeira',
    eeab: 'Estabelecimento, no Brasil, de Empresa Binacional Argentino-Brasileira',
    ssp: 'Sociedade Simples Pura',
    ss_ltda: 'Sociedade Simples Limitada',
    ss_cn: 'Sociedade Simples em Nome Coletivo',
    ss_cs: 'Sociedade Simples em Comandita Simples',
    eireli_ne:
      'Empresa Individual de Responsabilidade Limitada (de Natureza Empresária)',
    eireli_ns:
      'Empresa Individual de Responsabilidade Limitada (de Natureza Simples)',
    eireli: 'Empresa de Responsabilidade Individual',
    mei: 'Micro Empreendedor Individual',
    me: 'Micro Empresa',
    cop: 'Cooperativa',
    private_association: 'Sociedade Privada',
  };
  /* eslint-enable camelcase */

  export const getCompanyCategory = (
    companyCategory: Models.CompanyCategory,
  ): string => companyCategoryMap[companyCategory] ?? companyCategory;
  export const getCompanyCategoryMap = () => companyCategoryMap;

  const debtors: Record<string, string> = {
    16501555000157: 'Stone',
    14338304000178: 'Yapay',
    31688539000109: 'Stone',
    18727053000174: 'Pagar.me',
  };

  export const getDebtor = (debtor: string): string =>
    debtors[debtor] ?? debtor;

  export const grants: Record<Models.Grant, string> = {
    'supplier-customer-add': 'Adicionar cliente',
    'supplier-customer-transactions': 'Visualizar extrato cliente',
    'supplier-customer-schedule-payments-view':
      'Visualizar agenda e pagamentos',
    'supplier-payment-create': 'Criar pagamento',
    'supplier-payment-cancel': 'Cancelar pagamentos',
    'supplier-document-view': 'Visualizar documentos',
    'supplier-bankaccount': 'Conta bancária',
    'supplier-user': 'Gestão de usuários',
  };

  export const adminGroup: Models.SupplierUserGroup = {
    id: 'admin',
    name: 'Administradores',
    grants: Object.keys(grants) as Models.Grant[],
    idSupplier: '',
    dateadded: new Date(),
  };

  /* eslint-disable */
  export const warrantyStatus: Record<
    Models.PaymentRequestWarrantyExecutionStatus,
    string
  > = {
    on_schedule: 'No prazo',
    near_due_date: 'A vencer',
    overdue: 'Vencido',
    executing: 'Em execução',
  };
  /* eslint-enable */
}
