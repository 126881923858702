import { Stack, Typography } from '@mui/material';

function WrongEmail() {
  return (
    <Stack
      direction="column"
      gap={4}
      alignItems="center"
      justifyContent="center"
      height={1}
    >
      <Typography variant="h4">Você não tem acesso a esta página.</Typography>
      <Typography variant="body1">
        Caso você tenha recebido um convite, você deve fazer o cadastro
        utilizando o mesmo e-mail para qual o convite foi enviado!
      </Typography>
    </Stack>
  );
}

export default WrongEmail;
