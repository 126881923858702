import { ThemeProvider } from '@emotion/react';
import {
  Alert,
  Button,
  Card,
  CssBaseline,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { lightTheme } from '../helpers/themes';

function Logout() {
  const navigate = useNavigate();
  const [params] = useSearchParams();
  const reason = params.get('reason');

  const theme = useTheme();

  const img =
    theme.palette.mode === 'dark'
      ? '/PR_LogoAbrev-FundoEscuro.png'
      : '/PR_LogoAbrev-FundoBranco.png';

  return (
    <ThemeProvider theme={lightTheme}>
      <CssBaseline />
      <Stack
        direction="column"
        width={1}
        height="100dvh"
        alignItems="center"
        justifyContent="center"
        gap={4}
        sx={{
          backgroundImage:
            'linear-gradient(130deg, rgb(143, 161, 193)  0%, rgb(81, 159, 255) 100%)',
        }}
      >
        <Card variant="elevation">
          <Stack
            paddingY={6}
            paddingX={4}
            direction="column"
            alignItems="center"
            justifyContent="center"
            gap={4}
          >
            <img src={img} height="52px" />
            {reason === 'expired' ? (
              <Alert severity="info" variant="outlined">
                Você foi desconectado automaticamente devido à inatividade.
              </Alert>
            ) : (
              <Typography variant="h5">Bem-vindo!</Typography>
            )}
            <Button
              variant="contained"
              onClick={() => navigate('/')}
              sx={{ width: '250px' }}
            >
              Entrar
            </Button>
          </Stack>
        </Card>
      </Stack>
    </ThemeProvider>
  );
}

export default Logout;
