import { useAuth0 } from '@auth0/auth0-react';
import SendIcon from '@mui/icons-material/Send';
import LoadingButton from '@mui/lab/LoadingButton/LoadingButton';
import { onboardingActions } from '../features/onboarding/onboarding-slice';
import { useAppDispatch, useAppSelector } from '../helpers/hooks';

function EmailNotVerified() {
  const { user } = useAuth0();
  const dispatch = useAppDispatch();
  const { isLoading } = useAppSelector((state) => state.app);
  const { emailVerificationSent } = useAppSelector((state) => state.onboarding);

  const handleClick = () => {
    if (user?.sub)
      dispatch(onboardingActions.resendVerificationEmail(user.sub));
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        gap: '16px',
        alignItems: 'center',
      }}
    >
      <p>E-mail não verificado!</p>
      <p>
        Antes de completar o cadastro, você precisa verificar seu e-mail.
        Verifique a sua caixa de entrada e a caixa de SPAM.
      </p>
      {user?.sub && (
        <LoadingButton
          onClick={handleClick}
          type="submit"
          loading={isLoading}
          disabled={emailVerificationSent}
          loadingPosition="start"
          startIcon={<SendIcon />}
          variant="contained"
        >
          Reenviar e-mail de verificação
        </LoadingButton>
      )}
      {emailVerificationSent && (
        <p style={{ color: 'green' }}>E-mail enviado com sucesso!</p>
      )}
    </div>
  );
}

export default EmailNotVerified;
