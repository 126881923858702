import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { NavigateFunction } from 'react-router-dom';
import {
  OnboardingForm,
  OnboardingState,
  SupplierOnboardingForm,
} from '../../types';

const initialState: OnboardingState = {
  emailVerificationSent: false,
};

export const onboardingSlice = createSlice({
  name: 'onboarding',
  initialState,
  reducers: {
    setError: (state, { payload }: PayloadAction<string>) => {
      state.error = payload;
    },
    completeRetailerOnboarding: (
      _state,
      _action: PayloadAction<{
        data: OnboardingForm;
        mainContactSelfieWithDocFile: File;
        mainContactDocPictureFile: File;
        mainContactDocPictureBackFile?: File;
        companyConstitutionDocFile?: File;
        navigate: NavigateFunction;
      }>
    ) => {},
    completeSupplierOnboarding: (
      _state,
      _action: PayloadAction<{
        data: SupplierOnboardingForm;
        selfieWithDocFile: File;
        docPictureFile: File;
        docPictureBackFile?: File;
        navigate: NavigateFunction;
      }>
    ) => {},
    resendVerificationEmail: (_state, _action: PayloadAction<string>) => {},
    setEmailVerificationSent: (state, { payload }: PayloadAction<boolean>) => {
      state.emailVerificationSent = payload;
    },
  },
});

export const onboardingActions = onboardingSlice.actions;

export default onboardingSlice.reducer;
