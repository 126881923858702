import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import {
  NavLink,
  createSearchParams,
  useMatches,
  useSearchParams,
} from 'react-router-dom';

import { Breadcrumbs, CircularProgress, Link, Typography } from '@mui/material';
import { useAppSelector } from '../../helpers/hooks';

export type CrumbType =
  | 'default'
  | 'selectedSupplier'
  | 'selectedRetailer'
  | 'selectedPaymentRequest';
export type Crumb = {
  type: CrumbType;
  label?: string;
};
export type CrumbHandle = () => Crumb;

function NavigationHeader() {
  const { selectedSupplier, isLoadingSelectedSupplier } = useAppSelector(
    (state) => state.admin
  );
  const { company } = useAppSelector((state) => state.app);
  const {
    selectedRetailer,
    isLoadingSelectedRetailer,
    isLoadingSelectedPaymentRequest,
    selectedPaymentRequest,
  } = useAppSelector((state) => state.supplier);
  const {
    isLoadingSelectedPaymentRequest: isLoadingSelectedPaymentRequestRetailer,
    selectedPaymentRequest: selectedPaymentRequestRetailer,
  } = useAppSelector((state) => state.retailer);
  let matches = useMatches();
  let crumbs = matches.filter((match) =>
    Boolean((match.handle as unknown as any)?.crumb)
  );
  const searchParams = `?${createSearchParams(useSearchParams()[0])}`;

  return crumbs.length > 1 ? (
    <Breadcrumbs
      aria-label="breadcrumb"
      separator={<NavigateNextIcon fontSize="small" />}
    >
      {crumbs.map((crumb, index) => {
        const last = index === crumbs.length - 1;
        const handle = (crumb.handle as unknown as any)?.crumb as CrumbHandle;
        const { pathname } = crumb;
        const { type, label } = handle();
        let crumbLabel = label;
        let showLoading = false;
        switch (type) {
          case 'selectedRetailer':
            showLoading = isLoadingSelectedRetailer;
            crumbLabel = selectedRetailer?.fantasy ?? selectedRetailer?.name;
            break;
          case 'selectedSupplier':
            showLoading = isLoadingSelectedSupplier;
            crumbLabel = selectedSupplier?.fantasy ?? selectedSupplier?.name;
            break;
          case 'selectedPaymentRequest':
            showLoading =
              company?.companyType === 'retailer'
                ? isLoadingSelectedPaymentRequestRetailer
                : isLoadingSelectedPaymentRequest;
            crumbLabel =
              company?.companyType === 'retailer'
                ? `Pedido # ${selectedPaymentRequestRetailer?.externalId}`
                : `Pedido # ${selectedPaymentRequest?.externalId}`;
            break;
        }

        return last || type !== 'default' ? (
          <Typography color="text.secondary" variant="body2" key={pathname}>
            {showLoading ? <CircularProgress size="1em" /> : crumbLabel}
          </Typography>
        ) : (
          <Link
            underline="hover"
            component={NavLink}
            to={`${pathname}${searchParams}`}
            key={pathname}
          >
            <Typography variant="body2">
              {showLoading ? <CircularProgress size="1em" /> : crumbLabel}
            </Typography>
          </Link>
        );
      })}
    </Breadcrumbs>
  ) : (
    <></>
  );
}

export default NavigationHeader;
