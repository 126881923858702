export const safeParseNumber = (s?: string) => {
  if (!s) return 0;
  try {
    const num = parseFloat(s);
    return num;
  } catch (e) {
    return 0;
  }
};

export const safeParseFloat = (v?: string) => {
  const val = parseFloat((v ?? '').replace(',', '.'));
  if (!isNaN(val)) return val;
  return 0;
};

export const parseMaskedInput = (v?: string) => {
  const val = parseFloat((v ?? '').replaceAll('.', '').replace(',', '.'));
  return val;
};

export const safeParseMaskedInput = (v?: string) => {
  const val = parseMaskedInput(v);
  if (!isNaN(val)) return val;
  return 0;
};
