import { Models } from 'types';
import { useAppSelector } from '../helpers/hooks';

const useUserGrant = (requiredGrants: Models.Grant[]) => {
  const { supplierUserGroup } = useAppSelector((state) => state.app);
  return (
    requiredGrants.filter((v) => (supplierUserGroup?.grants ?? []).includes(v))
      .length > 0
  );
};
export default useUserGrant;
