import { Box, Stack } from '@mui/material';
import dayjs from 'dayjs';
import { useEffect } from 'react';
import { Models } from 'types';
import { PageHeader } from '../../../components/PageHeader/PageHeader';
import { useAppDispatch, useAppSelector } from '../../../helpers/hooks';
import { getWarrantyExecutionStatus } from '../../../helpers/payment-request-status';
import { retailerActions } from '../retailer.slice';
import { LoadReceivablesRequest } from '../retailer.types';
import { ActiveCard } from './ActiveCard';
import { OverdueCard } from './OverdueCard';
import { PendingCard } from './PendingCard';
import { ReceivablesCard } from './ReceivablesCard';

function VarejistaDashboard() {
  const dispatch = useAppDispatch();
  const startDate = dayjs().format('YYYYMMDD');
  const startDatePayments = dayjs().add(-1, 'month').format('YYYYMMDD');
  const endDate = dayjs().add(1, 'month').format('YYYYMMDD');
  const endDatePayments = dayjs().add(1, 'year').format('YYYYMMDD');
  const dateType: Models.LoadPaymentRequestsForRetailerRequestDateType =
    'dueDate';

  const receivableParams: LoadReceivablesRequest = {
    startDate,
    endDate,
  };
  const paymentParams: Models.LoadPaymentRequestsForRetailerRequest = {
    dateType,
    startDate: startDatePayments,
    endDate: endDatePayments,
  };

  useEffect(() => {
    dispatch(retailerActions.loadReceivables(receivableParams));
    dispatch(retailerActions.loadPaymentRequests(paymentParams));
  }, []);

  const { paymentRequests } = useAppSelector((state) => state.retailer);

  const pendingPaymentRequests = paymentRequests.filter(
    ({ status }) => status === 'pendente'
  );

  const activeStatus: Models.PaymentRequestStatus[] = ['ativo'];

  const overduePaymentRequests = paymentRequests.filter(
    (p) =>
      activeStatus.includes(p.status) &&
      getWarrantyExecutionStatus(p) === 'overdue'
  );

  return (
    <Box overflow="auto">
      <PageHeader title="Dashboard" />
      <Stack direction="column" gap={2}>
        {pendingPaymentRequests.length > 0 && (
          <PendingCard
            paymentParams={paymentParams}
            receivableParams={receivableParams}
          />
        )}
        {overduePaymentRequests.length > 0 && (
          <OverdueCard
            paymentParams={paymentParams}
            receivableParams={receivableParams}
          />
        )}
        <ReceivablesCard />
        <ActiveCard
          paymentParams={paymentParams}
          receivableParams={receivableParams}
        />
      </Stack>
    </Box>
  );
}

export default VarejistaDashboard;
