import { useAppSelector } from '../../helpers/hooks';
import OnboardingRetailer from '../onboarding/OnboardingRetailer';
import OnboardingSupplier from '../onboarding/OnboardingSupplier';

export const Onboarding = () => {
  const company = useAppSelector((state) => state.app.company);

  const OnboardingComponent =
    company?.companyType === 'supplier'
      ? OnboardingSupplier
      : OnboardingRetailer;

  return <OnboardingComponent />;
};
