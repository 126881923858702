import { AppState, Auth0Provider, User } from '@auth0/auth0-react';
import { ReactNode } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

type Auth0ProviderWithNavigateProps = {
  children: ReactNode;
};

export const Auth0ProviderWithNavigate = ({
  children,
}: Auth0ProviderWithNavigateProps) => {
  const navigate = useNavigate();
  const [params] = useSearchParams();

  const domain = process.env.REACT_APP_AUTH0_DOMAIN;
  const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID;
  const redirectUri = process.env.REACT_APP_AUTH0_CALLBACK_URL;
  const audience = process.env.REACT_APP_AUTH0_AUDIENCE;

  const idInvite = params.get('idInvite');
  const email = params.get('email');

  const onRedirectCallback: (
    _appState?: AppState | undefined,
    _user?: User | undefined
  ) => void = (appState) => {
    navigate(appState?.returnTo || window.location.pathname);
  };

  if (!(domain && clientId && redirectUri)) {
    return null;
  }

  return (
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      authorizationParams={{
        redirect_uri: redirectUri,
        audience,
        screen_hint: idInvite ? 'signup' : 'login',
        login_hint: email ?? undefined,
      }}
      onRedirectCallback={onRedirectCallback}
    >
      {children}
    </Auth0Provider>
  );
};
