import BuildCircleIcon from '@mui/icons-material/BuildCircle';
import CloseIcon from '@mui/icons-material/Close';
import { Box } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import React from 'react';
import { devToolsActions } from '../../features/dev-tools/dev-tools-slice';
import { useAppDispatch, useAppSelector } from '../../helpers/hooks';
import useUserRole from '../../hooks/useUserRole';

type EditFormHeaderProps = {
  title: React.ReactNode | string;
  actions?: React.ReactNode;
  onCloseClick?: React.MouseEventHandler<HTMLButtonElement> | undefined;
  closeDisabled?: boolean;
};

export default function EditFormHeader({
  title,
  actions,
  onCloseClick,
  closeDisabled,
}: EditFormHeaderProps) {
  const dispatch = useAppDispatch();
  const { isDebugActivated } = useAppSelector((state) => state.devTools);
  const { isDev } = useUserRole();
  const handleDevClick = () =>
    dispatch(devToolsActions.setIsDebugActivated(!isDebugActivated));
  return (
    <Box width={1} display="flex" flexDirection="row" alignItems="center">
      <Box flexGrow={1}>
        {typeof title === 'string' ? (
          <Typography variant="h4" gutterBottom>
            {title}
          </Typography>
        ) : (
          title
        )}
      </Box>
      {actions}
      {isDev && (
        <IconButton
          aria-label="delete"
          onClick={handleDevClick}
          color={isDebugActivated ? 'error' : 'default'}
        >
          <BuildCircleIcon fontSize="inherit" />
        </IconButton>
      )}
      {onCloseClick && (
        <Box marginLeft={2}>
          <IconButton
            edge="end"
            size="small"
            onClick={onCloseClick}
            disabled={closeDisabled}
          >
            <CloseIcon fontSize="large" />
          </IconButton>
        </Box>
      )}
    </Box>
  );
}
