import { Models, Service } from 'types';
import { apiCall } from '../helpers/api';

type T = Models.SupplierUserGroup;
const service: Service = 'supplier';
const resource = 'web/supplier-user-group';

export class WebSupplierUserGroupHelpers {
  public static getById = async (id: string): Promise<T | undefined> => {
    return await apiCall<T>({
      service,
      resource: `${resource}/${id}`,
    });
  };
  public static list = async (): Promise<T[]> => {
    return await apiCall<T[]>({
      service,
      resource: `${resource}`,
    });
  };
  public static create = async (
    newData: Omit<T, 'id' | 'dateadded' | 'idSupplier'>
  ): Promise<string> => {
    const { id } = await apiCall<Pick<T, 'id'>>({
      service,
      resource: `${resource}`,
      payload: newData,
      kyOpts: { method: 'post' },
    });
    return id;
  };
  public static patch = async (id: string, dataToUpdate: Partial<T>) => {
    await apiCall<Pick<T, 'id'>>({
      service,
      resource: `${resource}/${id}`,
      payload: dataToUpdate,
      kyOpts: { method: 'patch' },
    });
    return id;
  };
  public static delete = async (id: string) => {
    await apiCall<Pick<T, 'id'>>({
      service,
      resource: `${resource}/${id}`,
      kyOpts: { method: 'delete' },
    });
    return id;
  };
}
