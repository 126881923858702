import AddIcon from '@mui/icons-material/Add';
import CheckIcon from '@mui/icons-material/Check';
import SaveIcon from '@mui/icons-material/Save';
import SendIcon from '@mui/icons-material/Send';
import { LoadingButton } from '@mui/lab';
import { CircularProgress, IconButton, Stack, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import Grid2 from '@mui/material/Unstable_Grid2';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import ConfirmationDialog from '../../components/ConfirmationDialog/ConfirmationDialog';
import EditForm from '../../components/EditForm/EditForm';
import EditFormAction from '../../components/EditForm/EditFormAction';
import EditFormContent from '../../components/EditForm/EditFormContent';
import EditFormHeader from '../../components/EditForm/EditFormHeader';
import { SwitchField } from '../../components/SwitchField/SwitchField';
import TextField from '../../components/TextField/TextField';
import { getFirestoreConsoleLink } from '../../helpers/firebase';
import { useAppDispatch, useAppSelector } from '../../helpers/hooks';
import useUserRole from '../../hooks/useUserRole';
import FirebaseIcon from '../../icons/FirebaseIcon';
import { appActions } from '../app/app-slice';
import { SaveSupplierPersonalizationForm } from '../app/app.types';
import { settingsActions } from './settings-slice';

export default function SupplierPersonalization() {
  const dispatch = useAppDispatch();
  const { isLoading, company } = useAppSelector((state) => state.app);
  const { isDebugActivated } = useAppSelector((state) => state.devTools);
  const { supplierPersonalizationConfig } = useAppSelector(
    (state) => state.settings
  );

  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
  const [isTestMailConfirmationOpen, setIsTestMailConfirmationOpen] =
    useState(false);
  const [testMail, setTestMail] = useState('');
  const [formDataToSave, setFormDataToSave] = useState<
    SaveSupplierPersonalizationForm | undefined
  >(undefined);
  const [isFormOpen, setIsFormOpen] = useState(false);
  const { isAdmin } = useUserRole();

  const {
    handleSubmit,
    watch,
    formState: { isDirty, errors },
    register,
    control,
  } = useForm<SaveSupplierPersonalizationForm>({
    defaultValues: {
      useCustomLogo: !!supplierPersonalizationConfig?.customLogoUrl ?? false,
      customLogoUrl: supplierPersonalizationConfig?.customLogoUrl ?? null,
      useCustomMailTemplate:
        !!supplierPersonalizationConfig?.customMailTemplateId ?? false,
      customMailTemplateId:
        supplierPersonalizationConfig?.customMailTemplateId ?? null,
    },
  });

  const onSubmit = async (data: SaveSupplierPersonalizationForm) => {
    setFormDataToSave(data);
    setIsConfirmationOpen(true);
  };

  const onConfirmClick = () => {
    if (formDataToSave) {
      dispatch(
        settingsActions.saveSupplierPersonalizationConfig(formDataToSave)
      );
      setIsConfirmationOpen(false);
    }
  };

  const handleCloseClick = () => {
    dispatch(appActions.setIsSupplierPersonalizationOpen(false));
    setIsConfirmationOpen(false);
  };

  const onConfirmTestAllTemplatesClick = () => {
    dispatch(settingsActions.testAllMailTemplates(testMail));
    setIsTestMailConfirmationOpen(false);
  };

  const [useCustomLogo, useCustomMailTemplate] = watch([
    'useCustomLogo',
    'useCustomMailTemplate',
  ]);

  return (
    <EditForm>
      <EditFormHeader
        title="Personalizações"
        onCloseClick={handleCloseClick}
        actions={
          isDebugActivated && (
            <IconButton
              target="_blank"
              rel="noopener noreferrer"
              href={getFirestoreConsoleLink(
                `/supplier-personalization-config/${company?.id}`
              )}
            >
              <FirebaseIcon />
            </IconButton>
          )
        }
      />

      <EditFormContent display="flex" flexDirection="column" gap={2}>
        {isLoading ? (
          <CircularProgress />
        ) : (
          <>
            {!supplierPersonalizationConfig && !isFormOpen ? (
              <Button
                startIcon={<AddIcon />}
                variant="outlined"
                onClick={() => setIsFormOpen(true)}
              >
                Criar uma nova configuração
              </Button>
            ) : (
              <>
                <Grid2 container spacing={2}>
                  <Grid2 sm={12} md={6}>
                    <SwitchField
                      control={control}
                      name="useCustomLogo"
                      label="Logo personalizado"
                    />
                    <TextField
                      disabled={!useCustomLogo}
                      id="customLogoUrl"
                      label="URL do logo personalizado"
                      type="text"
                      {...register('customLogoUrl', {
                        validate: (v, row) => {
                          if (!row.useCustomLogo) return true;
                          if (!v || v.trim() === '')
                            return 'O campo é obrigatório';
                        },
                      })}
                      error={!!errors?.customLogoUrl}
                      helperText={errors?.customLogoUrl?.message}
                    />
                  </Grid2>

                  <Grid2 sm={12} md={6}>
                    <SwitchField
                      control={control}
                      name="useCustomMailTemplate"
                      label="Template de e-mail personalizado"
                    />
                    <TextField
                      disabled={!useCustomMailTemplate}
                      id="customMailTemplateId"
                      label="Template ID"
                      type="text"
                      {...register('customMailTemplateId', {
                        validate: (v, row) => {
                          if (!row.useCustomMailTemplate) return true;
                          if (!v || v.trim() === '')
                            return 'O campo é obrigatório';
                        },
                      })}
                      error={!!errors?.customMailTemplateId}
                      helperText={errors?.customMailTemplateId?.message}
                    />
                  </Grid2>
                </Grid2>
              </>
            )}
          </>
        )}
      </EditFormContent>
      {isAdmin && (
        <EditFormAction
          display="flex"
          flexDirection="row"
          justifyContent="flex-end"
          paddingTop={2}
          gap={2}
        >
          <LoadingButton
            color="primary"
            onClick={() => setIsTestMailConfirmationOpen(true)}
            loading={isLoading}
            loadingPosition="start"
            startIcon={<SendIcon />}
            variant="outlined"
          >
            Testar Templates
          </LoadingButton>
          <LoadingButton
            color="primary"
            onClick={handleSubmit(onSubmit)}
            type="submit"
            disabled={!isDirty}
            loading={isLoading}
            loadingPosition="start"
            startIcon={<SaveIcon />}
            variant="contained"
          >
            Salvar
          </LoadingButton>
        </EditFormAction>
      )}
      <ConfirmationDialog
        open={isConfirmationOpen}
        title="Confirmação"
        actions={[
          {
            name: 'Cancelar',
            buttonProps: {
              autoFocus: true,
              onClick: () => {
                setIsConfirmationOpen(false);
              },
            },
          },
          {
            name: 'Enviar',
            showLoading: true,
            buttonProps: {
              variant: 'contained',
              color: 'primary',
              startIcon: <CheckIcon />,
              onClick: onConfirmClick,
            },
          },
        ]}
      >
        Deseja atualizar as configurações?
      </ConfirmationDialog>
      <ConfirmationDialog
        open={isTestMailConfirmationOpen}
        title="Testar Templates"
        actions={[
          {
            name: 'Cancelar',
            buttonProps: {
              autoFocus: true,
              onClick: () => {
                setIsTestMailConfirmationOpen(false);
              },
            },
          },
          {
            name: 'Enviar',
            showLoading: true,
            buttonProps: {
              variant: 'contained',
              color: 'primary',
              startIcon: <SendIcon />,
              onClick: onConfirmTestAllTemplatesClick,
              disabled: testMail === '',
            },
          },
        ]}
      >
        <Stack direction="column" gap={2}>
          <Typography variant="body1">E-mail para o teste:</Typography>
          <TextField
            name="testMail"
            id="testMail"
            label="E-mail"
            type="text"
            value={testMail}
            onChange={(e) => setTestMail(e.target.value)}
          />
        </Stack>
      </ConfirmationDialog>
    </EditForm>
  );
}
