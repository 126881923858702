import { TextFieldProps } from '@mui/material';
import {
  DatePicker,
  DatePickerProps,
  LocalizationProvider,
} from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs, { Dayjs } from 'dayjs';
import 'dayjs/locale/pt-br';
import { Control, RegisterOptions, useController } from 'react-hook-form';

type DateFieldWithControlProps = DatePickerProps<Dayjs> & {
  name: string;
  control: Control<any, any>;
  rules?:
    | Omit<
        RegisterOptions<any, string>,
        'disabled' | 'setValueAs' | 'valueAsNumber' | 'valueAsDate'
      >
    | undefined;
  error?: boolean;
  helperText?: string;
  textFieldProps?: TextFieldProps;
};

export const DateFieldWithControl = (props: DateFieldWithControlProps) => {
  const { control, rules, label, textFieldProps, ...other } = props;
  const {
    field: { onChange, value },
    fieldState: { error },
  } = useController({
    name: other.name ?? '',
    control,
    rules,
  });
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="pt-br">
      <DatePicker
        label={label}
        value={dayjs(value)}
        onChange={(v) => {
          onChange(v ? v.format('YYYY-MM-DD') : '');
        }}
        // onError={(e) => onChange(e)}
        slotProps={{
          textField: {
            helperText: error?.message,
            error: !!error,
            ...textFieldProps,
          },
        }}
      />
    </LocalizationProvider>
    // <DateField
    //   {...other}
    //   error={!!error}
    //   helperText={error?.message}
    //   value={value}
    //   onChange={(e) => {
    //     if (onDateFieldChange) onDateFieldChange(e);
    //     onChange(e.target.value);
    //   }}
    // />
  );
};
