import { PayloadAction } from '@reduxjs/toolkit';
import { fork, put, take } from 'redux-saga/effects';
import { Supplier } from 'types';
import { DevToolsState } from '../../types';
import { notifierActions } from '../notifier/notifier-slice';
import { devToolsActions } from './dev-tools-slice';

function* watchSetIsLocalApi() {
  while (true) {
    const { payload }: PayloadAction<boolean> = yield take(
      devToolsActions.setIsLocalApi
    );
    const prev = (localStorage.getItem('isLocalApi') ?? 'false') === 'true';
    if (prev !== payload)
      localStorage.setItem('isLocalApi', payload ? 'true' : 'false');
  }
}

function* watchSetSkipRegistrar() {
  while (true) {
    const { payload }: PayloadAction<boolean> = yield take(
      devToolsActions.setSkipRegistrar
    );
    const prev = (localStorage.getItem('skipRegistrar') ?? 'false') === 'true';
    if (prev !== payload)
      localStorage.setItem('skipRegistrar', payload ? 'true' : 'false');
  }
}

function* watchReset() {
  while (true) {
    yield take(devToolsActions.reset);
    localStorage.clear();
    yield put(
      notifierActions.enqueue({
        message: 'Memória interna reinicializada',
        options: {
          variant: 'success',
        },
      })
    );
  }
}

const patchDevToolsDataStorage = (dataToUpdate: Partial<DevToolsState>) => {
  const data = localStorage.getItem('devToolsData');
  const parsedData: DevToolsState = data ? JSON.parse(data) : {};
  localStorage.setItem(
    'devToolsData',
    JSON.stringify({ ...parsedData, ...dataToUpdate })
  );
};

function* watchSetIdCompany() {
  while (true) {
    const { payload: idCompany }: PayloadAction<string> = yield take(
      devToolsActions.setIdCompany
    );
    patchDevToolsDataStorage({ idCompany });
  }
}
function* watchSetIdInvite() {
  while (true) {
    const { payload: idInvite }: PayloadAction<string> = yield take(
      devToolsActions.setIdInvite
    );
    patchDevToolsDataStorage({ idInvite });
  }
}

function* watchLoad() {
  while (true) {
    yield take(devToolsActions.load);
  }
}

function* asyncCall(payload: Supplier.AsyncPostPayload) {
  // yield WebContractHelpers.create();
  // console.log('rpcCall: ', payload);
  // const rpcCallResult: RPC.Call<
  //   Supplier.AsyncPostPayload,
  //   Supplier.Event1Result
  // > = yield rpcCall({
  //   service: 'supplier',
  //   resource: '/supplier/some-async-post',
  //   kyOpts: {
  //     method: 'post',
  //   },
  //   payload,
  // });
  // const { result, resultPayload, error } = rpcCallResult;
  // console.log('rpcCallResult: ', rpcCallResult);
  // if (result === 'success') {
  //   yield put(
  //     notifierActions.enqueue({
  //       message: resultPayload?.outcome ?? 'Success',
  //       options: {
  //         variant: 'success',
  //       },
  //     })
  //   );
  // } else {
  //   yield put(appActions.setError(error ?? 'Error'));
  // }
}

function* watchAsyncCall() {
  while (true) {
    const { payload }: PayloadAction<Supplier.AsyncPostPayload> = yield take(
      devToolsActions.asyncCall
    );
    yield fork(asyncCall, payload);
  }
}

export default [
  watchSetIsLocalApi,
  watchReset,
  watchLoad,
  watchSetIdCompany,
  watchSetIdInvite,
  watchAsyncCall,
  watchSetSkipRegistrar,
];
