import CheckIcon from '@mui/icons-material/Check';
import ErrorIcon from '@mui/icons-material/Error';
import { LoadingButton } from '@mui/lab';
import { Box, Stack, Typography } from '@mui/material';
import React, { useState } from 'react';
import ConfirmationDialog from '../../components/ConfirmationDialog/ConfirmationDialog';
import EditForm from '../../components/EditForm/EditForm';
import EditFormContent from '../../components/EditForm/EditFormContent';
import EditFormHeader from '../../components/EditForm/EditFormHeader';
import { format } from '../../helpers/format';
import { useAppDispatch, useAppSelector } from '../../helpers/hooks';
import useCompanyStatus from '../../hooks/useCompanyStatus';
import { appActions } from '../app/app-slice';
import { profileActions } from './profile-slice';

type DetailProps = {
  label: string;
  value?: string;
  children?: React.ReactNode;
};
const Detail = ({ label, value, children }: DetailProps) => (
  <Box>
    <Typography variant="body2" color="textSecondary" component="p">
      {label}:
    </Typography>
    {value && (
      <Typography variant="body1" color="textPrimary" component="p">
        {' '}
        {value ?? '-'}
      </Typography>
    )}
    {children}
  </Box>
);

export default function Profile() {
  const dispatch = useAppDispatch();
  const { dbUser, company, isLoading } = useAppSelector((state) => state.app);
  const isAdmin = dbUser?.userRole === 'adm' || dbUser?.userRole === 'dev';
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);

  const handleCloseClick = () => {
    dispatch(appActions.setIsProfileOpen(false));
  };
  const handleApproveClick = () => {
    setIsConfirmationOpen(true);
  };
  const handleConfirmClick = () => {
    setIsConfirmationOpen(false);
    dispatch(profileActions.approve());
  };

  const { documentsOk, bankAccountOk, companyStatus } = useCompanyStatus();

  return (
    <EditForm>
      <EditFormHeader title="Perfil" onCloseClick={handleCloseClick} />
      <EditFormContent display="flex" flexDirection="column" gap={2}>
        <Detail label="Razão Social" value={company?.name} />

        <Detail label="Nome Fantasia" value={company?.fantasy} />

        <Detail label="CNPJ" value={format(company?.docNumber, 'docNumber')} />

        <Detail label="Responsável" value={company?.mainContactName} />

        <Detail
          label="CPF do Responsável"
          value={format(company?.mainContactDocNumber, 'docNumber')}
        />

        <Detail
          label="E-mail do Responsável"
          value={company?.mainContactEmail}
        />

        <Detail
          label="Telefone"
          value={format(company?.mainContactPhone, 'phone')}
        />

        <Stack direction="row" gap={2} alignItems="center">
          <Detail label="Status">
            <Stack direction="row" gap={1}>
              <Typography variant="body1" color="primary" component="p">
                {' '}
                {companyStatus}
              </Typography>
              {companyStatus === 'Ativo' ? (
                <CheckIcon color="success" fontSize="small" />
              ) : (
                <ErrorIcon color="warning" fontSize="small" />
              )}
            </Stack>
          </Detail>
          {companyStatus === 'Em análise' && isAdmin && (
            <LoadingButton
              loading={isLoading}
              loadingPosition="start"
              startIcon={<CheckIcon />}
              variant="outlined"
              onClick={handleApproveClick}
              disabled={!documentsOk || !bankAccountOk}
            >
              Aprovar
            </LoadingButton>
          )}
        </Stack>
      </EditFormContent>
      <ConfirmationDialog
        open={isConfirmationOpen}
        title="Confirmação"
        actions={[
          {
            name: 'Cancelar',
            buttonProps: {
              autoFocus: true,
              onClick: () => {
                setIsConfirmationOpen(false);
              },
            },
          },
          {
            name: 'Aprovar',
            showLoading: true,
            buttonProps: {
              variant: 'contained',
              color: 'primary',
              startIcon: <CheckIcon />,
              onClick: handleConfirmClick,
            },
          },
        ]}
      >
        Deseja aprovar o cadastro da empresa?
      </ConfirmationDialog>
    </EditForm>
  );
}
