import {
  Box,
  Card,
  CardActionArea,
  Stack,
  Typography,
  alpha,
  useTheme,
} from '@mui/material';

import { format } from '../../../helpers/format';
import { ListaLiquidacoesParaConciliacao } from '../supplier.types';

type SettlementCardProps = {
  settlement: ListaLiquidacoesParaConciliacao;
  selected: boolean;
  onClick: (_s: ListaLiquidacoesParaConciliacao) => void;
};

export default function SettlementCard({
  settlement,
  selected,
  onClick,
}: SettlementCardProps) {
  const muiTheme = useTheme();
  return (
    <Card
      variant="outlined"
      sx={{
        ...(selected && {
          borderColor: selected ? 'primary.main' : 'border',
          backgroundColor: alpha(muiTheme.palette.background.default, 1),
        }),
      }}
    >
      <CardActionArea onClick={() => onClick(settlement)}>
        <Box p={2}>
          <Typography variant="caption" color="text.secondary" component="p">
            {format(settlement.dataLiquidacao, 'date')} -{' '}
            {settlement.credenciadora} - {settlement.bandeira}
          </Typography>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="flex-end"
          >
            <Box>
              <Typography variant="caption" color="text.secondary">
                ID Pedido:
              </Typography>
              <Typography variant="body2" component="span">
                {settlement.idPedido}
              </Typography>
            </Box>

            <Typography variant="h6">
              {format(settlement.valor, 'money')}
            </Typography>
          </Stack>
        </Box>
      </CardActionArea>
    </Card>
  );
}
