import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  RadioGroupProps,
} from '@mui/material';
import React from 'react';
import { Control, useController } from 'react-hook-form';

export type RadioGroupFieldOption<T> = {
  label: string;
  value: T;
};

type RadioGroupFieldProps<T> = RadioGroupProps & {
  name: string;
  label: string;
  options: RadioGroupFieldOption<T>[];
  control: Control<any, any>;
};

export const RadioGroupField = <T,>(props: RadioGroupFieldProps<T>) => {
  const { control, label, name, options, ...other } = props;
  const {
    field: { onChange, value },
  } = useController({
    name,
    control,
  });

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange((event.target as HTMLInputElement).value);
  };

  return (
    <FormControl sx={{ paddingLeft: 1 }}>
      <FormLabel id={name} sx={{ fontSize: '14px' }}>
        {label}
      </FormLabel>
      <RadioGroup {...other} name={name} value={value} onChange={handleChange}>
        {options.map((option, index) => (
          <FormControlLabel
            key={index}
            value={option.value}
            control={<Radio size="small" />}
            label={option.label}
          />
        ))}
      </RadioGroup>
    </FormControl>
  );
};
