import {
  Box,
  LinearProgress,
  Table as MuiTable,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  alpha,
  useTheme,
} from '@mui/material';
import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import React, { useState } from 'react';
import { format } from '../../helpers/format';
import { TableColumn, TableData } from './table.types';
dayjs.extend(localizedFormat);

export const ODD_OPACITY = 0.4;

type TableProps<T> = {
  data: TableData<T>[];
  columns: TableColumn<T>[];
  linkColumnId?: keyof T;
  generateLink?: (_data: TableData<T>) => string;
  isLoading?: boolean;
  dense?: boolean;
};
const Table = <T extends unknown>({
  data,
  columns,
  isLoading,
  dense,
}: TableProps<T>) => {
  const shownColumns = columns.filter(({ hidden }) => !hidden);
  const muiTheme = useTheme();

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);

  const dataGridRows = isLoading ? [] : data;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - data.length) : 0;

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  return (
    <>
      <Box
        overflow="auto"
        sx={{
          borderRadius: '4px',
          borderColor: muiTheme.table.border,
          borderStyle: 'solid',
          borderWidth: '1px',
        }}
      >
        <TableContainer>
          <MuiTable size={dense ? 'small' : 'medium'}>
            <TableHead>
              <TableRow>
                {shownColumns.map((column, index) => (
                  <TableCell key={index}>{column.label}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {isLoading && (
                <TableRow
                  sx={{
                    border: 0,
                  }}
                >
                  <TableCell
                    colSpan={shownColumns.length}
                    sx={{ padding: 0, border: 0 }}
                  >
                    <LinearProgress />
                  </TableCell>
                </TableRow>
              )}
              {(rowsPerPage > 0
                ? dataGridRows.slice(
                    page * rowsPerPage,
                    page * rowsPerPage + rowsPerPage
                  )
                : dataGridRows
              ).map((row, pageRowIndex) => {
                const rowIndex = pageRowIndex + page * rowsPerPage;
                return (
                  <TableRow
                    key={rowIndex}
                    sx={{
                      [`&:nth-of-type(${isLoading ? 'even' : 'odd'})`]: {
                        backgroundColor: alpha(
                          muiTheme.palette.background.secondary,
                          ODD_OPACITY
                        ),
                      },
                      '&:last-child td, &:last-child th': {
                        border: 0,
                      },
                    }}
                  >
                    {shownColumns.map((column, colIndex) => (
                      <TableCell
                        key={colIndex}
                        sx={{
                          ...(column.nowrap && {
                            whiteSpace: 'nowrap',
                          }),
                          ...(dense
                            ? {
                                ...(rowIndex === 0 &&
                                  isLoading && { paddingTop: '2px' }),
                              }
                            : {
                                ...(rowIndex === 0 &&
                                  isLoading && { paddingTop: '12px' }),
                              }),
                        }}
                      >
                        <>
                          {column.renderer
                            ? column.renderer(row[column.id], row)
                            : column.formatter
                            ? column.formatter(row[column.id], row)
                            : format(
                                row[column.id],
                                column.type,
                                column.formatOpts
                              )}
                        </>
                      </TableCell>
                    ))}
                  </TableRow>
                );
              })}
            </TableBody>
          </MuiTable>
        </TableContainer>
      </Box>
      <Box flexGrow={1} />

      {/* <TableFooter sx={{ marginX: 'auto' }}>
        <TableRow
          sx={{
            '&:last-child td, &:last-child th': {
              border: 0,
            },
          }}
        > */}

      <TablePagination
        component="div"
        sx={{
          overflow: 'hidden',
          marginX: 'auto',
          border: 0,
          '& .MuiTablePagination-selectLabel': {
            color: 'text.secondary',
          },
          '& .MuiTablePagination-displayedRows': {
            color: 'text.secondary',
          },
          minHeight: '36px',
        }}
        rowsPerPageOptions={[10, 20, 50, { label: 'All', value: -1 }]}
        colSpan={shownColumns.length}
        count={dataGridRows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        slotProps={{
          select: {
            inputProps: {
              'aria-label': 'rows per page',
            },
            native: true,
          },
        }}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        labelRowsPerPage={'Linhas por página'}
        labelDisplayedRows={({ from, to, count }) =>
          `${from}-${to} de ${count !== -1 ? count : `mais de ${to}`}`
        }
      />
      {/* </TableRow>
      </TableFooter> */}
    </>
  );
};

export default Table;
