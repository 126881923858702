'use strict';
import { PayloadAction } from '@reduxjs/toolkit';
import { put, select, take } from 'redux-saga/effects';
import { Models } from 'types';
import { WebCompanyHelpers } from '../../data-helpers/WebCompanyHelpers';
import { appActions } from '../app/app-slice';
import { AppState } from '../app/app.types';
import { companySelectActions } from './company-select-slice';

function* watchLoadCompanies() {
  while (true) {
    try {
      yield take(companySelectActions.loadCompanies);
      yield put(appActions.startLoading());
      const { dbUser }: AppState = yield select((state) => state.app);

      let companies: Models.Company[] = [];
      // TODO - Permissão de empresas
      if (dbUser?.userRole === 'dev' || dbUser?.userRole === 'adm')
        companies = yield WebCompanyHelpers.list(['retailer', 'supplier']);

      yield put(companySelectActions.loadCompaniesSuccess(companies));

      yield put(appActions.finishLoading());
    } catch (e) {
      yield put(appActions.setError((e as Error).message));
      yield put(companySelectActions.loadCompaniesFail());
      yield put(appActions.finishLoading());
    }
  }
}

function* watchSelectCompany() {
  while (true) {
    const { payload: idCompany }: PayloadAction<string> = yield take(
      companySelectActions.selectCompany
    );
    try {
      yield put(appActions.startLoading());
      yield put(appActions.changeUserCompany(idCompany));
      yield put(appActions.finishLoading());
    } catch (e) {
      // console.log('Erro ao executar watchGetEnterprises');
      // console.log(e);
      yield put(appActions.setError((e as Error).message));
      yield put(companySelectActions.selectCompanyFail());
      yield put(appActions.finishLoading());
    }
  }
}

export default [watchLoadCompanies, watchSelectCompany];
