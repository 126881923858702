type FirebaseIconProps = {
  disabled?: boolean;
};
export default function FirebaseIcon({ disabled }: FirebaseIconProps) {
  return (
    <img
      style={{
        width: '1em',
        height: '1em',
        ...(disabled && {
          filter: 'saturate(0)',
        }),
      }}
      src="/firebase_28dp.png"
    />
  );
}
