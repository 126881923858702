import { Box, CircularProgress, useTheme } from '@mui/material';
import dayjs from 'dayjs';
import { useNavigate } from 'react-router-dom';
import { format } from '../../../helpers/format';
import { useAppSelector } from '../../../helpers/hooks';
import { BarChart, BarChartData } from './BarChart';
import { DashboardCard } from './DashboardCard';

const getDaysArray = function (s: string, e: string) {
  const a = [];
  for (
    const d = dayjs(s).toDate();
    d <= dayjs(e).toDate();
    d.setDate(d.getDate() + 1)
  ) {
    a.push(dayjs(d).format('YYYYMMDD'));
  }
  return a;
};

export const ReceivablesCard = () => {
  let navigate = useNavigate();

  const { isLoadingReceivables, receivables } = useAppSelector(
    (state) => state.retailer
  );
  const theme = useTheme();

  const startDate = dayjs().format('YYYYMMDD');
  const endDate = dayjs().add(1, 'month').format('YYYYMMDD');

  const sumAvailable = receivables
    .map(({ value }) => value)
    .reduce((acc, cur) => {
      acc += cur;
      return acc;
    }, 0);

  const initialReceivablesChartData: BarChartData[] = getDaysArray(
    startDate,
    endDate
  ).map((d) => ({
    label: dayjs(d).format('DD/MM'),
    value: 0,
  }));

  const receivablesChartData: BarChartData[] = receivables.reduce(
    (acc, cur) => {
      const formattedDate = dayjs(cur.incomingDate).format('DD/MM');
      let elem: BarChartData | undefined = acc.find(
        ({ label }) => label === formattedDate
      );
      if (!elem) {
        elem = { label: formattedDate, value: cur.value };
        acc.push(elem);
      } else elem.value += cur.value;
      return acc;
    },
    initialReceivablesChartData
  );

  return (
    <DashboardCard
      onClick={() => navigate('/agenda')}
      title="A receber"
      value={
        isLoadingReceivables
          ? '...'
          : format(sumAvailable, 'money', { decimals: 0 })
      }
      color="success.main"
    >
      <Box
        height="200px"
        flexGrow={1}
        paddingY={3}
        paddingRight={2}
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        {isLoadingReceivables ? (
          <CircularProgress color="success" />
        ) : (
          <BarChart
            data={receivablesChartData}
            color={theme.palette.success.main}
          />
        )}
      </Box>
    </DashboardCard>
  );
};
