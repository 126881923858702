import React, { forwardRef, useEffect, useState } from 'react';
import styles from './TextField.module.css';

export type TextFieldProps = {
  id: string;
  name: string;
  label: string;
  type: 'text';
  placeholder?: string;
  disabled?: boolean;
  value?: string;
  inputComponent?: any;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  onBlur?: React.FocusEventHandler<HTMLInputElement>;
  error?: boolean;
  helperText?: string;
  InputProps?: any;
};

const TextField = forwardRef<HTMLInputElement, TextFieldProps>(
  function TextField(
    {
      id,
      name,
      label,
      type,
      placeholder,
      onChange,
      onBlur,
      disabled,
      InputProps,
      error,
      helperText,
      value,
    }: TextFieldProps,
    ref
  ) {
    const [isLabelRetracted, setIsLabelRetracted] = useState<boolean>(false);
    const [isFocused, setIsFocused] = useState<boolean>(false);
    const [currentValue, setCurrentValue] = useState<string>();
    const { inputComponent } = InputProps ?? {};
    const ResolvedInputComponent: React.ComponentClass<any> = inputComponent;

    useEffect(() => {
      const initialValue = (document.getElementById(id) as HTMLInputElement)
        ?.value;
      if (initialValue !== '') {
        setCurrentValue(initialValue);
        setIsLabelRetracted(true);
      }
    }, []);

    const handleChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
      setCurrentValue(e.target.value);
      if (onChange) onChange(e);
    };
    const handleBlur: React.FocusEventHandler<HTMLInputElement> = (e) => {
      if (!currentValue) setIsLabelRetracted(false);
      setIsFocused(false);
      if (onBlur) onBlur(e);
    };
    const handleFocus: React.FocusEventHandler<HTMLInputElement> = () => {
      if (!currentValue) setIsLabelRetracted(true);
      setIsFocused(true);
    };

    return (
      <div className={`${styles.root}`}>
        <label
          className={`${styles.inputLabel} ${
            isLabelRetracted ? styles.inputLabelRetracted : ''
          }
        ${error ? styles.inputLabelError : ''}
        ${disabled ? styles.inputLabelDisabled : ''}
        `}
          htmlFor={id}
        >
          {label}
        </label>
        <div
          className={`${styles.inputBase} ${
            disabled ? styles.inputBaseDisabled : ''
          }`}
        >
          {ResolvedInputComponent ? (
            <ResolvedInputComponent
              id={id}
              className={`${styles.input} ${
                disabled ? styles.inputDisabled : ''
              }`}
              name={name}
              type={type}
              value={value}
              placeholder={isLabelRetracted ? placeholder : undefined}
              onChange={handleChange}
              onBlur={handleBlur}
              onFocus={handleFocus}
              disabled={disabled}
            />
          ) : (
            <input
              id={id}
              className={`${styles.input} ${
                disabled ? styles.inputDisabled : ''
              }`}
              name={name}
              type={type}
              value={value}
              placeholder={isLabelRetracted ? placeholder : undefined}
              onChange={handleChange}
              onBlur={handleBlur}
              onFocus={handleFocus}
              disabled={disabled}
              ref={ref}
            />
          )}

          <fieldset
            className={`${styles.inputFieldset} 
        ${error ? styles.inputFieldsetError : ''}
        ${isFocused ? styles.inputFieldsetFocused : ''}
        `}
          >
            <legend
              className={`${styles.inputFieldsetLegend} 
        ${!isLabelRetracted ? styles.inputFieldsetLegendFocused : ''}`}
            >
              <span>{label}</span>
            </legend>
          </fieldset>
        </div>
        {helperText && <p className={styles.helperTextError}>{helperText}</p>}
      </div>
    );
  }
);

export default TextField;
