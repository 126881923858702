import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { NavigateFunction } from 'react-router-dom';
import { Models } from 'types';
import { AddSupplierForm, AdminState } from './admin.types';

const initialState: AdminState = {
  isLoading: false,
  isLoadingSelectedSupplier: false,
  suppliers: [],
  invites: [],
};

export const adminSlice = createSlice({
  name: 'admin',
  initialState,
  reducers: {
    setIsLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.isLoading = payload;
    },
    setIsLoadingSelectedSupplier: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.isLoadingSelectedSupplier = payload;
    },
    loadSuppliers: () => {},
    setSuppliers: (state, { payload }: PayloadAction<Models.Company[]>) => {
      state.suppliers = payload;
    },
    setInvites: (
      state,
      { payload }: PayloadAction<Models.OnboardingInvite[]>
    ) => {
      state.invites = payload;
    },
    addSupplier: (
      _state,
      _action: PayloadAction<{
        data: AddSupplierForm;
        bankContractFile?: File;
        companyConstitutionDocFile?: File;
        repDocFile?: File;
        navigate: NavigateFunction;
      }>
    ) => {},
    loadSupplierBankAccountTransactions: (
      _state,
      _action: PayloadAction<Models.ListSupplierBankAccountTransactionsRequest>
    ) => {},
    setSupplierBankAccountTransactions: (
      state,
      { payload }: PayloadAction<Models.ListBankAccountTransactionResponse>
    ) => {
      state.supplierBankAccountTransactions = payload;
    },
    setSelectedSupplierBankAccount: (
      state,
      { payload }: PayloadAction<Models.BankAccount>
    ) => {
      state.selectedSupplierBankAccount = payload;
    },
    setSelectedSupplier: (
      state,
      { payload }: PayloadAction<Models.Company>
    ) => {
      state.selectedSupplier = payload;
    },
    setSelectedSupplierOwner: (
      state,
      { payload }: PayloadAction<Models.User>
    ) => {
      state.selectedSupplierOwner = payload;
    },
    setSupplierTransactionConciliations: (
      state,
      { payload }: PayloadAction<Models.FinancialTransaction[]>
    ) => {
      state.supplierTransactionConciliations = payload;
    },
  },
});

export const adminActions = adminSlice.actions;
export default adminSlice.reducer;
