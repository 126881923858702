import { useSearchParams } from 'react-router-dom';

import { useAuth0 } from '@auth0/auth0-react';
import { Stack } from '@mui/material';
import { useEffect } from 'react';
import { AuthenticationGuard } from '../../AuthenticationGuard';
import { setTokenGenerator } from '../../helpers/api';
import { useAppDispatch } from '../../helpers/hooks';
import { MainAppBarAndDrawer } from './MainAppBarAndDrawer';
import { MainContainer } from './MainContainer';
import { appActions } from './app-slice';

export const App = () => {
  const [params] = useSearchParams();
  const { getAccessTokenSilently, isAuthenticated, user } = useAuth0();
  const dispatch = useAppDispatch();

  useEffect(() => {
    setTokenGenerator(getAccessTokenSilently);
  }, [getAccessTokenSilently]);

  const idInvite = params.get('idInvite');
  useEffect(() => {
    if (isAuthenticated && user) {
      dispatch(
        appActions.initDBUser({ user, idInvite: idInvite ?? undefined })
      );
    }
  }, [isAuthenticated]);

  return (
    <Stack direction="row">
      <AuthenticationGuard component={MainAppBarAndDrawer} />
      <MainContainer />
    </Stack>
  );
};
