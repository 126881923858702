import { Models, Service } from 'types';
import { apiCall } from '../helpers/api';

type T = Models.PaymentRequest;
const service: Service = 'supplier';
const resource = 'web/retailer-payment-request';

export class WebRetailerPaymentRequestHelpers {
  public static getById = async (id: string): Promise<T | undefined> => {
    return await apiCall<T>({
      service,
      resource: `${resource}/${id}`,
    });
  };
  public static list = async ({
    dateType,
    startDate,
    endDate,
    statuses,
    warrantyStatuses,
    externalId,
    effectType,
  }: Models.LoadPaymentRequestsForRetailerRequest): Promise<T[]> => {
    const dateFilter = `?t=${dateType}&s=${startDate}&e=${endDate}`;
    const statusesFilter = statuses
      ? statuses.map((s) => `&ss=${s}`).join('')
      : '';
    const warrantyStatusesFilter = warrantyStatuses
      ? warrantyStatuses.map((s) => `&wss=${s}`).join('')
      : '';
    const effectTypeFilter = effectType ? `&effectType=${effectType}` : '';
    const externalIdFilter = externalId ? `&externalId=${externalId}` : '';

    return await apiCall<T[]>({
      service,
      resource: `${resource}${dateFilter}${effectTypeFilter}${statusesFilter}${warrantyStatusesFilter}${externalIdFilter}`,
    });
  };

  public static confirm = async (idPaymentRequest: string) => {
    await apiCall({
      service: 'supplier',
      resource: `${resource}/${idPaymentRequest}/confirm`,
      kyOpts: { method: 'post' },
    });
  };
  public static reject = async (
    idPaymentRequest: string,
    rejectedReason: string
  ) => {
    await apiCall({
      service: 'supplier',
      resource: `${resource}/${idPaymentRequest}/reject`,
      payload: { rejectedReason },
      kyOpts: { method: 'post' },
    });
  };
}
