import AddIcon from '@mui/icons-material/Add';
import { Button, Link } from '@mui/material';

import { useEffect } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { CompanyStatus } from '../../../components/CompanyStatus/CompanyStatus';
import { PageHeader } from '../../../components/PageHeader/PageHeader';
import DataTable from '../../../components/Table/DataTable';
import { TableColumn } from '../../../components/Table/table.types';
import { getCompanyStatus } from '../../../helpers/company-status';
import { getFirestoreConsoleLink } from '../../../helpers/firebase';
import { useAppDispatch, useAppSelector } from '../../../helpers/hooks';
import { ListaFornecedor, ListaFornecedorStatusConvite } from '../../../types';
import { adminActions } from '../admin.slice';

function ListaFornecedores() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { invites, isLoading, suppliers } = useAppSelector(
    (state) => state.admin
  );
  const isDebugActivated = useAppSelector(
    (state) => state.devTools.isDebugActivated
  );

  const columns: TableColumn<ListaFornecedor>[] = [
    {
      id: 'idSupplier',
      label: 'id',
      hidden: !isDebugActivated,
      renderer: (v, row) => (
        <>
          <Link
            target="_blank"
            rel="noopener noreferrer"
            variant="caption"
            color="textSecondary"
            href={getFirestoreConsoleLink(`/companies/${v}`)}
          >
            {v}
          </Link>
        </>
      ),
    },
    { id: 'docNumber', label: 'CPF/CNPJ', type: 'docNumber', flex: 0.3 },
    { id: 'nomeFornecedor', label: 'Nome' },
    {
      id: 'statusConvite',
      label: 'Status',
      center: true,
      flex: 0.3,
      renderer: (status: ListaFornecedorStatusConvite | undefined) => (
        <CompanyStatus
          status={status?.status}
          idInvite={status?.invite?.id}
          inviteEmail={status?.invite?.email}
        />
      ),
    },
    {
      id: 'acaoExtrato',
      label: 'Extrato',
      center: true,
      flex: 0.3,
      renderer: (_v, row: ListaFornecedor) =>
        row.statusConvite.status === 'Ativo' ? (
          <NavLink to={`${row.idSupplier}/extrato`}>
            <Button size="small" variant="text">
              Extrato
            </Button>
          </NavLink>
        ) : (
          <></>
        ),
    },
  ];

  const enhancedData: ListaFornecedor[] = [
    ...suppliers.map((c) => ({
      idSupplier: c.id,
      docNumber: c.docNumber,
      nomeFornecedor: c.name,
      statusConvite: {
        status: getCompanyStatus(c),
        invite: invites.find((v) => v.idCompany === c.id),
      },
      acaoAgenda: 'agenda-1',
      acaoPagamento: 'pagamento-1',
      acaoLiquidacoes: 'liquidacoes-1',
      acaoExtrato: 'extrato-1',
    })),
  ].sort(({ nomeFornecedor: a }, { nomeFornecedor: b }) => a.localeCompare(b));
  useEffect(() => {
    dispatch(adminActions.loadSuppliers());
  }, []);

  const addCompanyClick = () => {
    navigate('novo');
  };
  return (
    <>
      <PageHeader
        title="Fornecedores"
        actions={
          <Button
            variant="contained"
            onClick={addCompanyClick}
            startIcon={<AddIcon />}
          >
            Novo
          </Button>
        }
      />
      <DataTable<ListaFornecedor>
        columns={columns}
        data={enhancedData}
        isLoading={isLoading}
      />
    </>
  );
}

export default ListaFornecedores;
