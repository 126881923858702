import {
  Paper,
  ToggleButtonGroup,
  ToggleButtonGroupProps,
  styled,
  toggleButtonGroupClasses,
} from '@mui/material';
import React, { ReactNode } from 'react';
import { Control, useController } from 'react-hook-form';

type ToggleButtonGroupFieldProps = ToggleButtonGroupProps & {
  name: string;
  control: Control<any, any>;
  children: ReactNode;
  allowNull?: boolean;
  onChange?: (_event: React.MouseEvent<HTMLElement>, _newValue: string) => void;
};

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  [`& .${toggleButtonGroupClasses.grouped}`]: {
    margin: theme.spacing(0.5),
    border: 0,
    borderRadius: theme.shape.borderRadius,
    [`&.${toggleButtonGroupClasses.disabled}`]: {
      border: 0,
    },
  },
  [`& .${toggleButtonGroupClasses.middleButton},& .${toggleButtonGroupClasses.lastButton}`]:
    {
      marginLeft: -1,
      borderLeft: '1px solid transparent',
    },

  [`& .MuiButtonBase-root.MuiToggleButton-root.Mui-selected`]: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    // border: '1px solid',
  },
  [`& .MuiButtonBase-root.MuiToggleButton-root.Mui-selected:hover`]: {
    backgroundColor: theme.palette.primary.dark,
    color: theme.palette.primary.contrastText,
    // border: '1px solid',
  },
  [`& .MuiButtonBase-root.MuiToggleButton-root:hover`]: {
    backgroundColor: theme.palette.action.hover,
  },
}));

export const ToggleButtonGroupField = (props: ToggleButtonGroupFieldProps) => {
  const { control, name, children, allowNull, onChange, ...other } = props;
  const {
    field: { onChange: onFieldChange, value },
  } = useController({
    name,
    control,
  });

  const handleChange = (
    event: React.MouseEvent<HTMLElement>,
    newValue: string
  ) => {
    if (newValue || (newValue === null && allowNull)) {
      onFieldChange(newValue);
      if (onChange) onChange(event, newValue);
    }
  };

  return (
    <Paper
      elevation={0}
      sx={{
        display: 'flex',
        border: (theme) => `1px solid ${theme.palette.divider}`,
        flexWrap: 'wrap',
      }}
    >
      <StyledToggleButtonGroup {...other} value={value} onChange={handleChange}>
        {children}
      </StyledToggleButtonGroup>
    </Paper>
  );
};
