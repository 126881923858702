import { Models } from 'types';

export const getCompanyStatus = (
  c?: Models.Company
): Models.CompanyStatus | undefined =>
  c
    ? c.onboardingComplete && c.onboardingApproved
      ? 'Ativo'
      : c.onboardingComplete && !c.onboardingApproved
      ? 'Em análise'
      : 'Convidado'
    : undefined;
