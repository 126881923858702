import { Box, Stack } from '@mui/material';
import dayjs from 'dayjs';
import { useEffect } from 'react';
import { Models } from 'types';
import { PageHeader } from '../../../components/PageHeader/PageHeader';
import { useAppDispatch, useAppSelector } from '../../../helpers/hooks';
import { getWarrantyExecutionStatus } from '../../../helpers/payment-request-status';
import { FABNewPaymentRequest } from '../components/FABNewPaymentRequest';
import { supplierActions } from '../supplier.slice';
import { ActiveCard } from './ActiveCard';
import { NonApprovedCard } from './NonApprovedCard';
import { OverdueCard } from './OverdueCard';
import { PendingCard } from './PendingCard';
import { RejectedCard } from './RejectedCard';

function FornecedorDashboard() {
  const dispatch = useAppDispatch();
  const startDate = dayjs().add(-1, 'month').format('YYYYMMDD');
  const endDate = dayjs().add(1, 'year').format('YYYYMMDD');
  const dateType: Models.LoadPaymentRequestsForRetailerRequestDateType =
    'dueDate';

  const paymentParams: Models.LoadPaymentRequestsForSupplierRequest = {
    dateType,
    startDate,
    endDate,
  };

  useEffect(() => {
    dispatch(supplierActions.loadPaymentRequests(paymentParams));
  }, []);

  const { paymentRequests } = useAppSelector((state) => state.supplier);

  const pendingPaymentRequests = paymentRequests.filter(
    ({ status }) => status === 'pendente'
  );

  const activeStatus: Models.PaymentRequestStatus[] = ['ativo'];
  const overduePaymentRequests = paymentRequests.filter(
    (p) =>
      activeStatus.includes(p.status) &&
      getWarrantyExecutionStatus(p) === 'overdue'
  );

  const rejectedStatus: Models.PaymentRequestStatus[] = ['rejeitado'];
  const rejectedPaymentRequests = paymentRequests.filter((p) =>
    rejectedStatus.includes(p.status)
  );

  const nonApprovedStatus: Models.PaymentRequestStatus[] = ['nao_aprovado'];
  const nonApprovedPaymentRequests = paymentRequests.filter((p) =>
    nonApprovedStatus.includes(p.status)
  );

  return (
    <>
      <Box overflow="auto" paddingBottom={12}>
        <PageHeader title="Dashboard" />
        <Stack direction="column" gap={2}>
          {pendingPaymentRequests.length > 0 && (
            <PendingCard paymentParams={paymentParams} />
          )}
          {overduePaymentRequests.length > 0 && (
            <OverdueCard paymentParams={paymentParams} />
          )}
          {rejectedPaymentRequests.length > 0 && (
            <RejectedCard paymentParams={paymentParams} />
          )}
          {nonApprovedPaymentRequests.length > 0 && (
            <NonApprovedCard paymentParams={paymentParams} />
          )}
          <ActiveCard paymentParams={paymentParams} />
        </Stack>
      </Box>
      <FABNewPaymentRequest />
    </>
  );
}

export default FornecedorDashboard;
