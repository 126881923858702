import { OperatingHours } from '../features/app/app.types';

import dayjs, { BusinessHoursMap } from 'dayjs';
import dayjsBusinessTimePlugin from 'dayjs-business-time';
import 'dayjs/locale/pt-br';
import { WebHolidayHelpers } from '../data-helpers/WebHolidayHelpers';
dayjs.extend(dayjsBusinessTimePlugin);
dayjs.locale('pt-br');

const start =
  process.env.REACT_APP_ENV === 'paguerecebivel-prod' ? '09:00:00' : '09:00:00';
const end =
  process.env.REACT_APP_ENV === 'paguerecebivel-prod' ? '19:00:00' : '18:00:00';

let holidays: string[] | undefined = undefined;

const businessTimes: BusinessHoursMap = {
  sunday: null,
  monday: [{ start, end }],
  tuesday: [{ start, end }],
  wednesday: [{ start, end }],
  thursday: [{ start, end }],
  friday: [{ start, end }],
  saturday: null,
};

dayjs.setBusinessTime(businessTimes);

const checkOperatingHours = async (): Promise<OperatingHours> => {
  if (!holidays) {
    const years = [
      dayjs().format('YYYY'),
      dayjs().add(1, 'year').format('YYYY'),
    ];
    const holidaysResponse = await Promise.all(
      years.map((year) => WebHolidayHelpers.getByYear(year))
    );
    holidays = holidaysResponse.map((h) => (h ? h.holidays : [])).flat();
  }
  dayjs.setHolidays(holidays);
  const isOperatingHours = dayjs().isBusinessTime();
  const isHoliday = dayjs().isHoliday();

  const outsideOperatingHoursText = isHoliday
    ? 'Atenção! Devido ao feriado, algumas funções estão desabilitadas.'
    : `Atenção! O horário de processamento é de segunda a sexta, das ${start} às ${end}. Algumas funções estão desabilitadas.`;

  return { isOperatingHours, outsideOperatingHoursText };
};
const dayjsBusinessTime = dayjs;
export { checkOperatingHours, dayjsBusinessTime };
