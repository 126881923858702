import { Box } from '@mui/material';
import PrivacyPolicyRetailer from '../features/onboarding/PrivacyPolicyRetailer';
import PrivacyPolicySupplier from '../features/onboarding/PrivacyPolicySupplier';
import { useAppSelector } from '../helpers/hooks';

function Privacidade() {
  const { company } = useAppSelector((state) => state.app);

  if (!company) return <></>;

  return (
    <Box overflow="auto">
      {company.companyType === 'retailer' ? (
        <PrivacyPolicyRetailer />
      ) : (
        <PrivacyPolicySupplier />
      )}
    </Box>
  );
}

export default Privacidade;
