import { getStorage, ref, uploadBytes } from 'firebase/storage';
import { Models, Service } from 'types';
import { v4 as uuid } from 'uuid';
import { apiCall } from '../helpers/api';
const service: Service = 'supplier';

export class WebDocumentHelpers {
  public static upload = async (file: File): Promise<string> => {
    const id = uuid();
    const fileRef = ref(getStorage(), id);
    await uploadBytes(fileRef, file, {
      customMetadata: {
        filename: file.name,
        filetype: file.type,
      },
    });
    return id;
  };

  public static getDownloadLink = async (id: string) => {
    const url = await apiCall<Models.Company>({
      service,
      resource: `/web/document/${id}`,
    });

    return url;
  };
}
