import { Box, Stack, Typography } from '@mui/material';
import Desert from '../icons/Desert';

function NotFound() {
  return (
    <Stack
      direction="column"
      gap={4}
      alignItems="center"
      justifyContent="center"
      height={1}
    >
      <Box width="390px">
        <Desert />
      </Box>
      <Typography variant="h4">Página não encontrada</Typography>
    </Stack>
  );
}

export default NotFound;
