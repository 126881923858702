import SaveIcon from '@mui/icons-material/Save';
import { LoadingButton } from '@mui/lab';
import { Box, Button, Stack, TextField } from '@mui/material';
import { useForm } from 'react-hook-form';
import { NavLink, useNavigate } from 'react-router-dom';
import { CNPJField } from '../../../components/CNPJField/CNPJField';
import { CPFField } from '../../../components/CPFField/CPFField';
import { PageHeader } from '../../../components/PageHeader/PageHeader';
import { RadioGroupField } from '../../../components/RadioGroupField/RadioGroupField';
import { useAppDispatch, useAppSelector } from '../../../helpers/hooks';
import {
  getValidAddressRegex,
  getValidCompanyRegex,
  getValidLettersRegex,
  testCNPJ,
  testCPF,
} from '../../../helpers/validations';
import { AddRetailerForm } from '../../../types';
import { supplierActions } from '../supplier.slice';

function FornecedorNovoCliente() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { isSavingRetailer } = useAppSelector((state) => state.supplier);

  const {
    handleSubmit,
    formState: { errors },
    register,
    control,
    watch,
  } = useForm<AddRetailerForm>({
    defaultValues: {
      name: '',
      fantasy: '',
      docNumber: '',
      cpf: '',
      cnpj: '',
      email: '',
      address: '',
      optin: '',
      type: 'pj',
      mainContactName: '',
      gender: 'a',
    },
  });

  const type = watch('type');
  const fantasy = watch('fantasy');

  const onSubmit = async (data: AddRetailerForm) => {
    //TODO: Remover campos de file
    const optinFile =
      data.optin && data.optin[0]
        ? (data.optin[0] as unknown as File)
        : undefined;
    const dataToSave = {
      ...data,
      docNumber: (data.type === 'pf' ? data.cpf : data.cnpj) ?? '',
      mainContactName: data.type === 'pf' ? data.name : data.mainContactName,
      gender: data.type === 'pf' ? '' : data.gender,
    };

    dispatch(
      supplierActions.addRetailer({ data: dataToSave, optinFile, navigate })
    );
  };
  console.log('errors: ', errors);

  return (
    <Stack direction="column" overflow="auto" gap={2}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <PageHeader title="Novo Cliente" />
        <Stack direction="column" gap={1} maxWidth="sm" marginX="auto">
          <RadioGroupField
            name="type"
            label="Tipo de entidade"
            options={[
              { label: 'Pessoa jurídica', value: 'pj' },
              { label: 'Pessoa física', value: 'pf' },
            ]}
            control={control}
          />
          <TextField
            id="name"
            label={type === 'pf' ? 'Nome' : 'Razão Social'}
            type="text"
            {...register('name', {
              required: 'O nome é obrigatório',
              pattern: {
                value: getValidCompanyRegex(),
                message: 'Caracteres especiais não são aceitos',
              },
            })}
            error={!!errors?.name}
            helperText={errors?.name?.message}
            fullWidth
          />
          <Box
            sx={{
              ...(type === 'pf' && {
                display: 'none',
              }),
            }}
          >
            <CNPJField
              id="cnpj"
              name="cnpj"
              label="CNPJ"
              type="text"
              control={control}
              customValidate={(value, { type }) => {
                return type === 'pf' || testCNPJ(value) || 'CNPJ inválido';
              }}
              fullWidth
            />
          </Box>
          <Box
            sx={{
              ...(type === 'pj' && {
                display: 'none',
              }),
            }}
          >
            <CPFField
              id="cpf"
              name="cpf"
              label="CPF"
              type="text"
              control={control}
              customValidate={(value, { type }) => {
                return type === 'pj' || testCPF(value) || 'CPF inválido';
              }}
              fullWidth
            />
          </Box>

          <TextField
            id="fantasy"
            label="Nome Fantasia"
            type="text"
            {...register('fantasy', {
              required: 'O nome fantasia é obrigatório',
              pattern: {
                value: getValidCompanyRegex(),
                message: 'Caracteres especiais não são aceitos',
              },
            })}
            error={!!errors?.fantasy}
            helperText={errors?.fantasy?.message}
            fullWidth
          />
          <Box
            sx={{
              ...(type === 'pf' && {
                display: 'none',
              }),
            }}
          >
            <RadioGroupField
              name="gender"
              label="Como devemos nos referir à empresa?"
              options={[
                { label: `O ${fantasy}`, value: 'o' },
                { label: `A ${fantasy}`, value: 'a' },
              ]}
              control={control}
            />
          </Box>
          <TextField
            id="address"
            label="Endereço"
            type="text"
            {...register('address', {
              required: 'O endereço é obrigatório',
              pattern: {
                value: getValidAddressRegex(),
                message: 'Caracteres especiais não são aceitos',
              },
            })}
            error={!!errors?.address}
            helperText={errors?.address?.message}
            fullWidth
          />
          <Box
            sx={{
              ...(type === 'pf' && {
                display: 'none',
              }),
            }}
          >
            <TextField
              id="mainContactName"
              label="Nome do Responsável"
              type="text"
              {...register('mainContactName', {
                pattern: {
                  value: getValidLettersRegex(),
                  message: 'Caracteres especiais não são aceitos',
                },
                validate: (value, { type }) => {
                  return (
                    type === 'pf' ||
                    ((value ?? '') === '' ? 'O nome é obrigatório' : true)
                  );
                },
              })}
              error={!!errors?.mainContactName}
              helperText={errors?.mainContactName?.message}
              fullWidth
            />
          </Box>
          <TextField
            id="email"
            label="E-mail"
            type="text"
            {...register('email', {
              required: 'O email é obrigatório',
              pattern: {
                value:
                  /^(([^<>()[\]\\.,;:\s@"À-ÿ]+(\.[^<>()[\]\\.,;:\s@"À-ÿ]+)*)|("[a-zA-Z\-0-9]+"))@(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,})$/,
                message: 'E-mail inválido',
              },
            })}
            error={!!errors?.email}
            helperText={errors?.email?.message}
            fullWidth
          />
          {/* <div className="grid-item fullwidth">
              <FileField
                id="optin"
                label="Anuência"
                type="file"
                {...register('optin')}
                error={!!errors?.optin}
                helperText={errors?.optin?.message}
              />
            </div> */}
          <Stack direction="row" gap={2} justifyContent="center" paddingY={4}>
            <NavLink to="..">
              <Button variant="outlined">Cancelar</Button>
            </NavLink>
            <LoadingButton
              onClick={handleSubmit(onSubmit)}
              type="submit"
              loading={isSavingRetailer}
              loadingPosition="start"
              startIcon={<SaveIcon />}
              variant="contained"
            >
              Salvar
            </LoadingButton>
          </Stack>
        </Stack>
      </form>
    </Stack>
  );
}

export default FornecedorNovoCliente;
