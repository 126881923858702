import { Stack, Typography } from '@mui/material';
import { Utils } from 'utils';
import { useAppSelector } from '../helpers/hooks';

function Pending() {
  const { company } = useAppSelector((state) => state.app);

  const firstName = Utils.capitalize(
    Utils.extractFirstName(company?.mainContactName ?? '')
  );

  return (
    <Stack
      direction="column"
      gap={4}
      alignItems="center"
      justifyContent="center"
      height={1}
    >
      <Typography variant="h4">Olá, {firstName}!</Typography>
      <Typography variant="body1">
        Obrigado por completar o seu cadastro.
      </Typography>
      <Typography variant="body1">
        A conta d{(company?.gender ?? '') !== '' ? company?.gender : 'e'}{' '}
        {company?.fantasy ?? company?.name} está em análise, assim que for
        concluída você receberá um e-mail de confirmação.
      </Typography>
      <Typography variant="body1">
        A análise dura em média 1 dia útil.
      </Typography>
    </Stack>
  );
}

export default Pending;
