import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Models } from 'types';
import {
  SaveSupplierUserForm,
  SaveSupplierUserGroupForm,
  UploadDocsFileRequest,
} from '../../types';
import { PartnerAPIForm } from '../admin/admin.types';
import {
  SaveSupplierNotificationForm,
  SaveSupplierPersonalizationForm,
  SaveSupplierSettingsForm,
} from '../app/app.types';

export type SettingsState = {
  loadingButtons: Record<string, boolean>;
  supplierWarrantyConfig?: Models.SupplierWarrantyConfig;
  supplierOwnershipAssignmentConfig?: Models.SupplierOwnershipAssignmentConfig;
  supplierPersonalizationConfig?: Models.SupplierPersonalizationConfig;
  supplierNotificationConfig?: Models.SupplierNotificationConfig;
  supplierAPIClient?: Models.WebApiClientConfig;
  supplierAPIClientHelperText?: string;
  supplierUserGroups: Models.SupplierUserGroup[];
  supplierUsers: Models.User[];
  isLoadingSupplierUserGroups: boolean;
  isSavingOrDeletingSupplierUserGroups: boolean;
  isLoadingSupplierUsers: boolean;
  isSavingOrDeletingSupplierUsers: boolean;
  selectedSupplierUserGroup: Models.SupplierUserGroup | undefined;
  selectedSupplierUser: Models.User | undefined;
  isUserGroupEditFormOpen: boolean;
  groupEditFormMode: 'new' | 'edit';
  isUserEditFormOpen: boolean;
  userEditFormMode: 'new' | 'edit';
};
const initialState: SettingsState = {
  loadingButtons: {},
  isLoadingSupplierUserGroups: false,
  isLoadingSupplierUsers: false,
  isSavingOrDeletingSupplierUserGroups: false,
  isSavingOrDeletingSupplierUsers: false,
  supplierUserGroups: [],
  supplierUsers: [],
  isUserGroupEditFormOpen: false,
  groupEditFormMode: 'new',
  selectedSupplierUserGroup: undefined,
  isUserEditFormOpen: false,
  userEditFormMode: 'new',
  selectedSupplierUser: undefined,
};

export const settingsSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    updateDocs: (_state, _action: PayloadAction<UploadDocsFileRequest>) => {},
    sendDocToBank: (
      _state,
      _action: PayloadAction<Models.WebSendDocumentToBankRequest>
    ) => {},
    startLoadingButton: (state, { payload }: PayloadAction<string>) => {
      state.loadingButtons[payload] = true;
    },
    stopLoadingButton: (state, { payload }: PayloadAction<string>) => {
      state.loadingButtons[payload] = false;
    },
    getDownloadLink: (
      _state,
      _action: PayloadAction<{
        id: string;
        buttonId: string;
      }>
    ) => {},
    loadSupplierConfig: () => {},
    setSupplierWarrantyConfig: (
      state,
      { payload }: PayloadAction<Models.SupplierWarrantyConfig | undefined>
    ) => {
      state.supplierWarrantyConfig = payload;
    },
    setSupplierOwnershipAssignmentConfig: (
      state,
      {
        payload,
      }: PayloadAction<Models.SupplierOwnershipAssignmentConfig | undefined>
    ) => {
      state.supplierOwnershipAssignmentConfig = payload;
    },
    saveSupplierSettings: (
      _state,
      _action: PayloadAction<SaveSupplierSettingsForm>
    ) => {},
    loadSupplierPersonalizationConfig: () => {},
    setSupplierPersonalizationConfig: (
      state,
      {
        payload,
      }: PayloadAction<Models.SupplierPersonalizationConfig | undefined>
    ) => {
      state.supplierPersonalizationConfig = payload;
    },
    saveSupplierPersonalizationConfig: (
      _state,
      _action: PayloadAction<Partial<SaveSupplierPersonalizationForm>>
    ) => {},
    loadSupplierNotificationConfig: () => {},
    setSupplierNotificationConfig: (
      state,
      { payload }: PayloadAction<Models.SupplierNotificationConfig | undefined>
    ) => {
      state.supplierNotificationConfig = payload;
    },
    saveSupplierNotificationConfig: (
      _state,
      _action: PayloadAction<Partial<SaveSupplierNotificationForm>>
    ) => {},
    loadSupplierAPIClient: () => {},
    deleteSupplierAPIClient: () => {},
    rotateAPIClientsSecret: (_state, _action: PayloadAction<string>) => {},
    createSupplierAPIClientConfig: () => {},
    saveSupplierAPIClientConfig: (
      _state,
      _action: PayloadAction<PartnerAPIForm>
    ) => {},
    setSupplierAPIClient: (
      state,
      { payload }: PayloadAction<Models.WebApiClientConfig | undefined>
    ) => {
      state.supplierAPIClient = payload;
    },
    setSupplierAPIClientHelperText: (
      state,
      { payload }: PayloadAction<string | undefined>
    ) => {
      state.supplierAPIClientHelperText = payload;
    },
    testAllMailTemplates: (_state, _action: PayloadAction<string>) => {},
    loadSupplierUserGroups: () => {},
    setSupplierUserGroups: (
      state,
      { payload }: PayloadAction<Models.SupplierUserGroup[]>
    ) => {
      state.supplierUserGroups = payload;
    },
    loadSupplierUsers: () => {},
    setSupplierUsers: (state, { payload }: PayloadAction<Models.User[]>) => {
      state.supplierUsers = payload;
    },
    setIsLoadingSupplierUserGroups: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.isLoadingSupplierUserGroups = payload;
    },
    setIsLoadingSupplierUsers: (state, { payload }: PayloadAction<boolean>) => {
      state.isLoadingSupplierUsers = payload;
    },
    setIsSavingOrDeletingSupplierUserGroups: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.isSavingOrDeletingSupplierUserGroups = payload;
    },
    setSelectedSupplierUserGroup: (
      state,
      { payload }: PayloadAction<Models.SupplierUserGroup | undefined>
    ) => {
      state.selectedSupplierUserGroup = payload;
    },
    setIsUserGroupEditFormOpen: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.isUserGroupEditFormOpen = payload;
    },
    setGroupEditFormMode: (
      state,
      { payload }: PayloadAction<'new' | 'edit'>
    ) => {
      state.groupEditFormMode = payload;
    },
    saveSupplierUserGroup: (
      _state,
      _action: PayloadAction<SaveSupplierUserGroupForm>
    ) => {},
    deleteSupplierUserGroup: (_state, _action: PayloadAction<string>) => {},
    setIsSavingOrDeletingSupplierUsers: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.isSavingOrDeletingSupplierUsers = payload;
    },
    setSelectedSupplierUser: (
      state,
      { payload }: PayloadAction<Models.User | undefined>
    ) => {
      state.selectedSupplierUser = payload;
    },
    setIsUserEditFormOpen: (state, { payload }: PayloadAction<boolean>) => {
      state.isUserEditFormOpen = payload;
    },
    setUserEditFormMode: (
      state,
      { payload }: PayloadAction<'new' | 'edit'>
    ) => {
      state.userEditFormMode = payload;
    },
    saveSupplierUser: (
      _state,
      _action: PayloadAction<SaveSupplierUserForm>
    ) => {},
    deleteSupplierUser: (_state, _action: PayloadAction<string>) => {},
    resendInvite: (_state, _action: PayloadAction<string>) => {},
  },
});

export const settingsActions = settingsSlice.actions;

export default settingsSlice.reducer;
