import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { NavigateFunction } from 'react-router-dom';
import { Models } from 'types';
import {
  LoadReceivablesRequest,
  PaymentRequestWithConfirmationUser,
  RetailerState,
} from './retailer.types';

const initialState: RetailerState = {
  paymentRequests: [],
  receivables: [],
  suppliers: [],
  isLoadingReceivables: false,
  isLoadingPaymentRequests: false,
  isLoadingSelectedPaymentRequest: false,
  isLoadingSelectedSupplier: false,
  selectedPaymentRequestDetails: [],
  isApprovingPaymentRequest: false,
};

export const retailerSlice = createSlice({
  name: 'retailer',
  initialState,
  reducers: {
    setIsLoadingReceivables: (state, { payload }: PayloadAction<boolean>) => {
      state.isLoadingReceivables = payload;
    },
    setIsLoadingPaymentRequests: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.isLoadingPaymentRequests = payload;
    },
    setIsLoadingSelectedPaymentRequest: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.isLoadingSelectedPaymentRequest = payload;
    },
    setIsLoadingSelectedSupplier: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.isLoadingSelectedSupplier = payload;
    },
    loadPaymentRequests: (
      _state,
      _action: PayloadAction<Models.LoadPaymentRequestsForRetailerRequest>
    ) => {},
    setPaymentRequests: (
      state,
      { payload }: PayloadAction<Models.PaymentRequest[]>
    ) => {
      state.paymentRequests = payload;
    },

    loadReceivables: (
      _state,
      _action: PayloadAction<LoadReceivablesRequest>
    ) => {},
    setReceivables: (
      state,
      { payload }: PayloadAction<Models.Receivable[]>
    ) => {
      state.receivables = payload;
    },
    loadSuppliers: () => {},
    setSuppliers: (state, { payload }: PayloadAction<Models.Company[]>) => {
      state.suppliers = payload;
    },
    loadSelectedPaymentRequest: (_state, _action: PayloadAction<string>) => {},
    setSelectedPaymentRequest: (
      state,
      { payload }: PayloadAction<PaymentRequestWithConfirmationUser | undefined>
    ) => {
      state.selectedPaymentRequest = payload;
    },
    setSelectedPaymentRequestDetails: (
      state,
      { payload }: PayloadAction<Models.PaymentRequestDetail[]>
    ) => {
      state.selectedPaymentRequestDetails = payload;
    },

    setSelectedSupplier: (
      state,
      { payload }: PayloadAction<Models.Company | undefined>
    ) => {
      state.selectedSupplier = payload;
    },
    setSelectedSupplierOwner: (
      state,
      { payload }: PayloadAction<Models.User | undefined>
    ) => {
      state.selectedSupplierOwner = payload;
    },
    confirmPayment: (
      _state,
      _action: PayloadAction<{
        idPaymentRequest: string;
        navigate: NavigateFunction;
      }>
    ) => {},
    rejectPayment: (
      _state,
      _action: PayloadAction<{
        idPaymentRequest: string;
        navigate: NavigateFunction;
        rejectedReason: string;
      }>
    ) => {},
    setIsApprovingPaymentRequest: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.isApprovingPaymentRequest = payload;
    },
  },
});

export const retailerActions = retailerSlice.actions;

export default retailerSlice.reducer;
