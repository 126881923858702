import {
  Alert,
  Box,
  CircularProgress,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import dayjs from 'dayjs';
import { useNavigate } from 'react-router-dom';
import { Models } from 'types';
import { format } from '../../../helpers/format';
import { useAppSelector } from '../../../helpers/hooks';
import {
  getWarrantyExecutionDate,
  getWarrantyExecutionStatus,
} from '../../../helpers/payment-request-status';
import { getFilterUrl } from '../VarejistaListaPagamentos/get-filter-url';
import { ListaPagamento, LoadReceivablesRequest } from '../retailer.types';
import { DashboardCard } from './DashboardCard';

type PendingCardProps = {
  receivableParams: LoadReceivablesRequest;
  paymentParams: Models.LoadPaymentRequestsForRetailerRequest;
};
export const PendingCard = ({ paymentParams }: PendingCardProps) => {
  let navigate = useNavigate();

  const { isLoadingPaymentRequests, suppliers, paymentRequests } =
    useAppSelector((state) => state.retailer);

  const pendingPaymentRequests = paymentRequests.filter(
    ({ status }) => status === 'pendente'
  );

  const sumPendingPayments = pendingPaymentRequests
    .map(({ totalValue }) => totalValue)
    .reduce((acc, cur) => {
      acc += cur;
      return acc;
    }, 0);

  const enhancedData: ListaPagamento[] = pendingPaymentRequests.map((d) => {
    const supplier = suppliers.find((r) => r.id === d.idSupplier);
    return {
      idSupplier: d.idSupplier,
      id: d.id,
      externalId: d.externalId,
      fornecedor: supplier ? supplier.fantasy ?? supplier.name : '...',
      numero: d.id,
      dataPedido: dayjs(d.dateadded).format('YYYYMMDD'),
      dataVencimento: d.dueDate,
      dataInicio: d.firstIncomingDate,
      operacao:
        d.effectType === 'garantia' ? 'Garantia' : 'Troca de Titularidade',
      valorTotal: d.totalValue,
      status: d.status,
      detalhes: 'pagamento-1',
      effectType: d.effectType,
      prioridade: d.effectPriority,
      statusExecucaoGarantia: getWarrantyExecutionStatus(d),
      dataExecucao: getWarrantyExecutionDate(d),
    };
  });

  return (
    <DashboardCard
      borderColor="warning.main"
      onClick={() =>
        navigate(
          getFilterUrl('/varejista-pagamentos', {
            ...paymentParams,
            statuses: ['pendente'],
          })
        )
      }
      title="Solicitações aguardando aprovação"
      value={
        isLoadingPaymentRequests
          ? '...'
          : sumPendingPayments > 0
          ? format(sumPendingPayments, 'money', { decimals: 0 })
          : '-'
      }
      color={sumPendingPayments > 0 ? 'warning.main' : 'text.secondary'}
    >
      {isLoadingPaymentRequests ? (
        <Box
          p={2}
          display="flex"
          alignItems="center"
          justifyContent="center"
          flexGrow={1}
          height={1}
        >
          <CircularProgress color="warning" />
        </Box>
      ) : (
        <Stack direction="column" p={2} gap={1}>
          <Alert severity="warning" variant="outlined">
            Faça a aprovação o quanto antes para evitar atrasos no seu pedido!
          </Alert>
          <TableContainer>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>Fornecedor</TableCell>
                  <TableCell align="center">ID do pedido</TableCell>
                  <TableCell align="center">Vencimento</TableCell>
                  <TableCell align="center">Execução</TableCell>
                  <TableCell align="center">Valor</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {enhancedData.map((row) => (
                  <TableRow
                    key={row.id}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {row.fornecedor}
                    </TableCell>
                    <TableCell align="center">{row.externalId}</TableCell>
                    <TableCell align="center">
                      {format(row.dataVencimento, 'date')}
                    </TableCell>
                    <TableCell align="center">
                      {format(row.dataExecucao, 'date')}
                    </TableCell>
                    <TableCell align="center">
                      {format(row.valorTotal, 'money')}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Stack>
      )}
    </DashboardCard>
  );
};
