import { Stack, Typography } from '@mui/material';

function Unexpected() {
  return (
    <Stack
      direction="column"
      gap={4}
      alignItems="center"
      justifyContent="center"
      height={1}
    >
      <Typography variant="h4">Você não tem acesso a esta página.</Typography>
      <Typography variant="body1">
        Caso você tenha recebido um convite, siga as instruções enviadas por
        e-mail!
      </Typography>
    </Stack>
  );
}

export default Unexpected;
