import { Box, Button, Typography } from '@mui/material';

type PrivacyPolicyRetailerProps = {
  onFinishReading?: () => void;
};

export default function PrivacyPolicyRetailer({
  onFinishReading,
}: PrivacyPolicyRetailerProps) {
  return (
    <Box p={2} display="flex" flexDirection="column" gap={2}>
      <Typography variant="h4">Política de Privacidade</Typography>
      <Typography variant="body1">
        Esta Política de Privacidade, nos termos da legislação brasileira
        aplicável, com destaque para a Lei no 13.709/2019 (Lei Geral de Proteção
        de Dados Pessoais ou “LGPD”) e Resolução da Autoridade Nacional de
        Proteção de Dados 2/2022, conforme alteradas, serve para informá-lo
        acerca da segurança e privacidade das informações coletadas pela Pague
        Recebível, em ambientes online ou offline, de clientes ou usuários de
        serviços prestados pela Pague Recebível, que são os efetivos titulares
        dos Dados Pessoais, conforme abaixo definido (simplesmente “você”).
      </Typography>
      <Typography variant="body1">1. O que são Dados Pessoais?</Typography>
      <Typography variant="body1">
        “Dados Pessoais” são informações e/ou conjunto de informações que podem
        ser associadas a uma pessoa natural identificada ou identificável,
        podendo incluir nome, endereço, número de telefone, e-mail, número de
        cartão de pagamento, informações financeiras, número de conta, data de
        nascimento e números de registro (por exemplo, carteira nacional de
        habilitação, RG e passaporte). A Pague Recebível poderá utilizar sua
        foto e biometria facial no momento de seu cadastro e/ou para
        identificação e autenticação de seu acesso com a finalidade de prevenção
        à fraude e ameaças de segurança.
      </Typography>
      <Typography variant="body1">
        2. O que é tratamento de Dados Pessoais?
      </Typography>
      <Typography variant="body1">
        Qualquer operação realizada com Dados Pessoais, incluindo, mas não se
        limitando a: coleta, acesso, modificação, processamento, armazenamento,
        transmissão ou eliminação.
      </Typography>
      <Typography variant="body1">
        3. Qual a finalidade da coleta de Dados Pessoais pela Pague Recebível?
      </Typography>
      <Typography variant="body1">
        - Identificação: verificação e autenticação de acesso.
      </Typography>
      <Typography variant="body1">
        - Prestação de serviços: viabilizar a comunicação, contratação e correta
        prestação dos serviços, incluindo, mas não se limitando a, melhorias em
        experiência do usuário, serviços e algoritmos da Pague Recebível,
        prevenção e resolução de problemas, testes e criação de novos serviços
        ou funcionalidades.
      </Typography>
      <Typography variant="body1">
        - Monitoramento: monitoramento de uso e performance dos serviços da
        Pague Recebível e de parceiros, bem como para prevenção a riscos,
        ameaças de segurança, fraudes e cumprimento de obrigações legais e
        regulatórias.
      </Typography>
      <Typography variant="body1">
        - Indicativos: dados de transações, afiliações, receitas, vendas,
        produtos, serviços, compradores, entre outros, para Pague Recebível e/ou
        parceiros.
      </Typography>
      <Typography variant="body1">
        - Perfil financeiro: crédito, análise de risco e outras verificações
        financeiras.
      </Typography>
      <Typography variant="body1">
        - Terceiros: criar conexão com plataforma de terceiros.
      </Typography>
      <Typography variant="body1">
        - Marketing: prospecção, pesquisas de mercado, de opinião e promoção dos
        serviços da Pague Recebível e/ou parceiros.
      </Typography>
      <Typography variant="body1">
        - Obrigações legais: cumprimento de ordem judicial, ordem de autoridade
        competente ou de órgão fiscalizador, de obrigação legal ou regulatória,
        para exercício regular de direitos da Pague Recebível, inclusive
        apresentação de documentos em processos judiciais e administrativos.
      </Typography>
      <Typography variant="body1">
        4. Como os Dados Pessoais são coletados?
      </Typography>
      <Typography variant="body1">
        Em alguns casos, você fornece diretamente os seus Dados Pessoais, mas
        também podemos coletar seus Dados Pessoais por meio de fontes públicas e
        privadas, bem como de maneira automática por meio de cookies, registros
        em serviços, aplicações, softwares e tecnologias semelhantes. Também
        podemos receber seus Dados Pessoais por intermédio de parceiros e/ou de
        fornecedores dos quais você realiza a contratação de produtos ou
        serviços, bem como podemos coletar Dados Pessoais sobre você quando
        entra em contato com a Pague Recebível por meio dos canais de
        comunicação.
      </Typography>
      <Typography variant="body1">
        5. Quais Dados Pessoais são coletados?
      </Typography>
      <Typography variant="body1">
        - Dados técnicos: sobre a sua navegação, tais como o seu endereço de IP,
        hora e data de acesso, cookies e tecnologias similares (como pixels e
        tags), ações executadas e seções visitadas por você (incluindo a data e
        hora das ações e acessos), dispositivo utilizado para acesso e dados de
        geolocalização.
      </Typography>
      <Typography variant="body1">
        - Dados de identificação: tais como o seu nome completo, documentos de
        identificação (RG, CPF, CNH e/ou RNE), endereço completo, telefone de
        contato, e-mail de contato, foto e biometria facial.
      </Typography>
      <Typography variant="body1">
        - Dados de uso: Informações sobre a contratação e utilização dos
        serviços.
      </Typography>
      <Typography variant="body1">
        - Dados financeiros: pagamentos, agenda de recebíveis, informações
        bancárias, eventuais restrições financeiras, histórico de crédito,
        score, dentre outras.
      </Typography>
      <Typography variant="body1">
        - Dados públicos: informações sobre você que estejam disponíveis
        publicamente ou que foram tornadas públicas por você.
      </Typography>
      <Typography variant="body1">6. Quais são seus direitos?</Typography>
      <Typography variant="body1">
        - Direito de confirmação e acesso: saber se a Pague Recebível realiza
        tratamento de Dados Pessoais de sua titularidade e, em caso afirmativo,
        de ter acesso ao conjunto de informações tratadas. Vale reiterar que
        essas informações estão à sua disposição para consulta a qualquer
        momento, mediante sua solicitação.
      </Typography>
      <Typography variant="body1">
        - Direito de correção: requerer e obter a retificação de Dados Pessoais
        incompletos, inexatos e/ou desatualizados.
      </Typography>
      <Typography variant="body1">
        - Direito à anonimização, bloqueio ou eliminação: solicitar a
        anonimização, bloqueio ou eliminação de Dados Pessoais de sua
        titularidade, quando considerar desnecessários, excessivos ou tratados
        em desconformidade com a legislação brasileira.
      </Typography>
      <Typography variant="body1">
        - Direito à portabilidade: portabilidade dos seus Dados Pessoais a outro
        fornecedor de serviço, mediante requisição, nos termos da legislação
        vigente e observados os segredos comercial e industrial.
      </Typography>
      <Typography variant="body1">
        - Direito à informação: solicitar informação sobre as entidades públicas
        e privadas com as quais a Pague Recebível compartilhou seus Dados
        Pessoais, bem como sobre a possibilidade de não fornecer consentimento.
      </Typography>
      <Typography variant="body1">
        - Direito à revogação do consentimento: requerer a revogação do
        consentimento para tratamento de Dados Pessoais, a qualquer tempo.
      </Typography>
      <Typography variant="body1">
        Os direitos acima listados podem ser limitados caso haja previsão legal.
      </Typography>
      <Typography variant="body1">
        7. A Pague Recebível utiliza Cookies?
      </Typography>
      <Typography variant="body1">
        A Pague Recebível pode utilizar cookies e outras tecnologias de
        rastreamento para reconhecê-lo como usuário, personalizar os serviços,
        realizar análises e pesquisas de negócios e mitigar riscos de fraudes.
      </Typography>
      <Typography variant="body1">
        8. Há compartilhamento de Dados Pessoais com terceiros?
      </Typography>
      <Typography variant="body1">
        As operações da Pague Recebível são suportadas por rede de computadores,
        servidores baseados em nuvens e outras infraestruturas e tecnologias da
        informação, próprias ou providas por prestadores de serviços
        terceirizados, como exemplo https://unico.io/ e
        https://www.serpro.gov.br/. Nesse contexto, os Dados Pessoais poderão
        ser processados, inclusive armazenados, em outros países com nível de
        proteção adequado, dentre os quais os Estados Unidos da América, sempre
        respeitada a legislação vigente aplicável. A Pague Recebível poderá
        compartilhar Dados Pessoais com terceiros parceiros, clientes,
        prestadores de serviços, tais como empresas de serviços de meios de
        pagamento, credenciadores, subcredenciadores, registradoras de
        recebíveis de cartão, intermediários na gestão de pagamentos, serviços
        de tecnologia da informação e armazenamento de dados em nuvem,
        instituições financeiras, empresas prestadoras de serviços de
        atendimento ao consumidor, gerenciamento de risco, detecção de atos
        ilícitos, envio de anúncios e comunicações personalizados, oferta
        conjunta de produtos ou serviços, análise de dados estatísticos,
        pesquisas, dentre outros. Por fim, poderá haver compartilhamento e/ou a
        transferência de Dados Pessoais às entidades ou órgãos governamentais,
        para cumprimento de leis, regulações ou ordens judiciais ou
        administrativas de autoridades competentes, dentre as quais o Banco
        Central do Brasil - BCB, a Unidade de Inteligência Financeira - UIF, a
        Receita Federal do Brasil, e as Secretarias Estaduais e Municipais, bem
        como entidades que intervenham na resolução de disputas (incluindo
        órgãos ou entidades públicas, juízos arbitrais e demais órgãos
        competentes para resolução de disputas).
      </Typography>
      <Typography variant="body1">
        9. Por quanto tempo os Dados Pessoais são armazenados?
      </Typography>
      <Typography variant="body1">
        Os seus Dados Pessoais serão armazenados pela Pague Recebível, pelo
        menos, durante toda a vigência da relação comercial. Em caso de
        encerramento da prestação dos serviços, a Pague Recebível poderá
        continuar tratando certos Dados Pessoais, na medida do necessário e pelo
        prazo legal, visando o cumprimento de obrigações legais, especialmente
        aquelas relacionadas à (i) categorização e classificação de riscos, (ii)
        combate à lavagem de dinheiro e financiamento ao terrorismo, (iii)
        identificação do beneficiário final das transações, (iv) identificação
        da origem dos recursos, (v) prevenção a fraudes, entre outros.
      </Typography>
      <Typography variant="body1">
        10. Como a Pague Recebível protege seus Dados Pessoais?
      </Typography>
      <Typography variant="body1">
        Observando a legislação e melhores práticas de mercado e utilizando
        medidas de segurança técnicas e administrativas para fornecer a proteção
        necessária para os Dados Pessoais contra incidentes, tais como perda,
        mau uso, acesso não autorizado, divulgação e/ou alteração. As medidas de
        segurança podem incluir múltiplos fatores de autenticação, monitoramento
        contínuo, testes de segurança, auditorias, firewalls, criptografia de
        dados e controles de acesso às informações. Você é responsável por
        proteger e manter a privacidade de suas credenciais de acesso, incluindo
        usuário e senha e por assegurar que os Dados Pessoais fornecidos para a
        Pague Recebível são verdadeiros, precisos e atuais.
      </Typography>

      {onFinishReading && (
        <Button variant="contained" onClick={onFinishReading}>
          Já li o documento, fechar
        </Button>
      )}
    </Box>
  );
}
