import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Models } from 'types';

export type CompanySelectState = {
  data: Models.Company[];
};
const initialState: CompanySelectState = {
  data: [],
};

export const companySelectSlice = createSlice({
  name: 'company-select',
  initialState,
  reducers: {
    loadCompanies: () => {},
    loadCompaniesSuccess: (state, action) => {
      state.data = action.payload;
    },
    loadCompaniesFail: (state) => {
      state.data = [];
    },
    selectCompanyFail: () => {},
    selectCompany: (_state, _action: PayloadAction<string>) => {},
  },
});

export const companySelectActions = companySelectSlice.actions;

export default companySelectSlice.reducer;
