import { Models, Service } from 'types';
import { apiCall } from '../helpers/api';
const service: Service = 'supplier';

export class WebOnboardingHelpers {
  public static init = async (
    data: Models.WebInitRequest
  ): Promise<Models.WebInitResponse> => {
    const response = await apiCall<Models.WebInitResponse>({
      service,
      resource: 'web/onboarding/init',
      payload: data,
      kyOpts: { method: 'post' },
    });
    return response;
  };

  public static createRetailer = async (
    data: Models.WebRetailerOnboardingRequest
  ): Promise<Models.WebOnboardingResponse> => {
    const { idCompany, idInvite } = await apiCall<Models.WebOnboardingResponse>(
      {
        service,
        resource: 'web/onboarding/create-retailer',
        payload: data,
        kyOpts: { method: 'post' },
      }
    );
    return { idCompany, idInvite };
  };

  public static createSupplier = async (
    data: Models.WebSupplierOnboardingRequest
  ): Promise<Models.WebOnboardingResponse> => {
    const { idCompany, idInvite } = await apiCall<Models.WebOnboardingResponse>(
      {
        service,
        resource: 'web/onboarding/create-supplier',
        payload: data,
        kyOpts: { method: 'post' },
      }
    );
    return { idCompany, idInvite };
  };

  public static completeRetailer = async (
    data: Models.WebCompleteRetailerOnboardingRequest
  ): Promise<Models.Company> => {
    const company = await apiCall<Models.Company>({
      service,
      resource: 'web/onboarding/complete-retailer',
      payload: data,
      kyOpts: { method: 'post' },
    });
    return company;
  };
  public static completeSupplier = async (
    data: Models.WebCompleteSupplierOnboardingRequest
  ): Promise<Models.Company> => {
    const company = await apiCall<Models.Company>({
      service,
      resource: 'web/onboarding/complete-supplier',
      payload: data,
      kyOpts: { method: 'post' },
    });
    return company;
  };

  public static approve = async (
    data: Models.WebApproveOnboardingRequest
  ): Promise<Models.Company> => {
    const company = await apiCall<Models.Company>({
      service,
      resource: 'web/onboarding/approve',
      payload: data,
      kyOpts: { method: 'post' },
    });
    return company;
  };

  public static resendVerificationEmail = async (userId: string) => {
    await apiCall({
      service,
      resource: 'web/onboarding/resend-verification-email',
      payload: { userId },
      kyOpts: { method: 'post' },
    });
  };
}
