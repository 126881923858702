import LaunchIcon from '@mui/icons-material/Launch';
import { Box, Card, CardActionArea, Stack, Typography } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import React, { ReactNode } from 'react';

type DashboardCardProps = {
  onClick?: React.MouseEventHandler<HTMLButtonElement> | undefined;
  title: string;
  value: string;
  color: string;
  borderColor?: string;
  children: ReactNode;
  icon?: ReactNode;
  helperText?: ReactNode;
};
export const DashboardCard = ({
  onClick,
  title,
  value,
  color,
  borderColor,
  children,
  icon,
  helperText,
}: DashboardCardProps) => {
  return (
    <Card sx={{ borderColor }}>
      <CardActionArea onClick={onClick}>
        <Grid2 container spacing={2}>
          <Grid2 xs={4}>
            <Stack direction="column" p={2} height={1}>
              <Stack direction="row" gap={2}>
                {icon}
                <Stack direction="column">
                  <Typography variant="body2" color="text.secondary">
                    {title}
                  </Typography>
                  <Typography variant="h3" color={color}>
                    {value}
                  </Typography>
                </Stack>
              </Stack>
              {helperText}
              <Box flexGrow={1} p={2} />
              <LaunchIcon />
            </Stack>
          </Grid2>
          <Grid2 xs={8}>{children}</Grid2>
        </Grid2>
      </CardActionArea>
    </Card>
  );
};
