import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material';
import { Control, useController } from 'react-hook-form';

export type OptionsFilterValues<T = string> = {
  value: T;
  label: string;
};

type SelectFieldWithControlProps = {
  key: string;
  name: string;
  label: string;
  control: Control<any, any>;
  options: OptionsFilterValues[];
  disabled?: boolean;
  onChange?: (_value: string) => void;
};

export const SelectFieldWithControl = (props: SelectFieldWithControlProps) => {
  const { control, label, name, options, onChange, disabled } = props;
  const {
    field: { onChange: onFieldChange, value },
  } = useController({
    name,
    control,
  });

  const handleChange = (event: SelectChangeEvent) => {
    onFieldChange(event.target.value as string);
    if (onChange) onChange(event.target.value as string);
  };

  return (
    <Box sx={{ minWidth: 120 }}>
      <FormControl fullWidth>
        <InputLabel id={name}>{label}</InputLabel>
        <Select
          labelId={name}
          label={label}
          value={value}
          onChange={handleChange}
          disabled={disabled}
        >
          {options.map(({ value, label }) => (
            <MenuItem key={value} value={value}>
              {label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
};
