import { Models, Service } from 'types';
import { apiCall } from '../helpers/api';

type T = Models.Receivable;
const service: Service = 'supplier';
const resource = 'web/supplier-receivable';

export class WebSupplierReceivableHelpers {
  public static listByRetailer = async (
    startDate: string,
    endDate: string,
    idRetailer: string
  ): Promise<T[]> => {
    const dateFilter = `?s=${startDate}&e=${endDate}`;
    const filter = `&idRetailer=${idRetailer}`;
    return await apiCall<T[]>({
      service,
      resource: `${resource}${dateFilter}${filter}`,
    });
  };
}
