import { Box, Stack, Typography } from '@mui/material';
import React from 'react';

type PageHeaderProps = {
  title: string;
  actions?: React.ReactNode;
};

export const PageHeader = ({ title, actions }: PageHeaderProps) => {
  return (
    <Stack
      direction="row"
      paddingBottom={1}
      alignItems="center"
      justifyContent="space-between"
    >
      <Typography variant="h4">{title}</Typography>
      <Box flexGrow={1} />
      {actions}
    </Stack>
  );
};
