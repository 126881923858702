import DeleteIcon from '@mui/icons-material/Delete';
import SaveIcon from '@mui/icons-material/Save';
import { LoadingButton } from '@mui/lab';
import {
  Alert,
  Button,
  Divider,
  IconButton,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2';
import { Constants } from 'pr-constants';
import { useEffect, useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { Models } from 'types';
import { CheckBoxField } from '../../../components/CheckBoxField/CheckBoxField';
import ConfirmationDialog from '../../../components/ConfirmationDialog/ConfirmationDialog';
import EditForm from '../../../components/EditForm/EditForm';
import EditFormAction from '../../../components/EditForm/EditFormAction';
import EditFormContent from '../../../components/EditForm/EditFormContent';
import EditFormHeader from '../../../components/EditForm/EditFormHeader';
import { getFirestoreConsoleLink } from '../../../helpers/firebase';
import { useAppDispatch, useAppSelector } from '../../../helpers/hooks';
import { getValidCompanyRegex } from '../../../helpers/validations';
import FirebaseIcon from '../../../icons/FirebaseIcon';
import { SaveSupplierUserGroupForm } from '../../../types';
import { settingsActions } from '../settings-slice';

export default function EditGroup() {
  const dispatch = useAppDispatch();
  const { isDebugActivated } = useAppSelector((state) => state.devTools);
  const {
    selectedSupplierUserGroup,
    groupEditFormMode,
    isSavingOrDeletingSupplierUserGroups,
  } = useAppSelector((state) => state.settings);

  const [isDiscardConfirmationOpen, setIsDiscardConfirmationOpen] =
    useState(false);
  const [isDeleteConfirmationOpen, setIsDeleteConfirmationOpen] =
    useState(false);

  const defaultValues: SaveSupplierUserGroupForm = {
    id: selectedSupplierUserGroup?.id,
    mode: groupEditFormMode,
    fullControl:
      (selectedSupplierUserGroup?.grants ?? []).length ===
      Object.keys(Constants.grants).length,
    name: selectedSupplierUserGroup?.name ?? '',
    grants: Object.keys(Constants.grants).map((grant) => ({
      grant: grant as Models.Grant,
      checked: (selectedSupplierUserGroup?.grants ?? []).includes(
        grant as Models.Grant
      ),
    })),
  };

  const {
    handleSubmit,
    formState: { isDirty, errors },
    watch,
    register,
    control,
    setValue,
  } = useForm<SaveSupplierUserGroupForm>({
    defaultValues,
  });

  const { fields } = useFieldArray({
    name: 'grants',
    control,
  });

  const fullControl = watch('fullControl');
  const grants = watch('grants');
  // console.log('grants: ', grants);

  useEffect(() => {
    if (isDirty && fullControl)
      Object.entries(Constants.grants).forEach((_v, index) =>
        setValue(`grants.${index}.checked`, fullControl)
      );
  }, [fullControl]);

  useEffect(() => {
    if (isDirty) {
      const isFull = Object.values(grants).every(({ checked }) => checked);
      if (isFull && !fullControl) setValue('fullControl', true);
      if (!isFull && fullControl) setValue('fullControl', false);
    }
  }, [JSON.stringify(grants)]);

  const handleCloseClick = () => {
    if (isDirty) setIsDiscardConfirmationOpen(true);
    else closeForm();
  };

  const handleDeleteClick = () => setIsDeleteConfirmationOpen(true);
  const handleDeleteConfirm = () => {
    if (selectedSupplierUserGroup)
      dispatch(
        settingsActions.deleteSupplierUserGroup(selectedSupplierUserGroup.id)
      );
  };

  const closeForm = () => {
    dispatch(settingsActions.setIsUserGroupEditFormOpen(false));
  };

  const onSubmit = (data: SaveSupplierUserGroupForm) => {
    dispatch(settingsActions.saveSupplierUserGroup(data));
  };

  return (
    <EditForm>
      <EditFormHeader
        title={groupEditFormMode === 'edit' ? 'Alterar' : 'Novo Grupo'}
        onCloseClick={handleCloseClick}
        actions={
          isDebugActivated &&
          selectedSupplierUserGroup?.id && (
            <IconButton
              target="_blank"
              rel="noopener noreferrer"
              href={getFirestoreConsoleLink(
                `/supplier-user-groups/${selectedSupplierUserGroup?.id}`
              )}
            >
              <FirebaseIcon />
            </IconButton>
          )
        }
      />

      <EditFormContent display="flex" flexDirection="column" gap={2}>
        <Grid2 container spacing={2}>
          <Grid2 xs={12}>
            <TextField
              fullWidth
              id="name"
              label="Nome"
              disabled={selectedSupplierUserGroup?.id === 'admin'}
              type="text"
              {...register('name', {
                required: 'O nome é obrigatório',
                pattern: {
                  value: getValidCompanyRegex(),
                  message: 'Caracteres especiais não são aceitos',
                },
              })}
              error={!!errors?.name}
              helperText={errors?.name?.message}
            />
          </Grid2>
          <Grid2 xs={12}>
            <Divider />
          </Grid2>
          <Grid2 xs={12}>
            <CheckBoxField
              name="fullControl"
              label="Este grupo terá permissão total"
              disabled={selectedSupplierUserGroup?.id === 'admin'}
              control={control}
            />
          </Grid2>
          <Grid2 xs={12}>
            <Typography variant="body1">Permissões</Typography>
          </Grid2>
          <Grid2 xs={12} container spacing={0}>
            {fields.map((field, index) => (
              <Grid2 xs={6} key={index}>
                <CheckBoxField
                  disabled={selectedSupplierUserGroup?.id === 'admin'}
                  name={`grants.${index}.checked`}
                  label={Constants.grants[field.grant]}
                  control={control}
                />
              </Grid2>
            ))}
          </Grid2>
          <Grid2 xs={12}>
            <Divider />
          </Grid2>
        </Grid2>
      </EditFormContent>
      <EditFormAction>
        <Stack direction="row" justifyContent="flex-end" paddingTop={2} gap={1}>
          {groupEditFormMode === 'edit' &&
            selectedSupplierUserGroup?.id !== 'admin' && (
              <LoadingButton
                onClick={handleDeleteClick}
                variant="contained"
                color="error"
                loading={isSavingOrDeletingSupplierUserGroups}
                loadingPosition="start"
                startIcon={<DeleteIcon />}
              >
                Excluir
              </LoadingButton>
            )}
          <Button onClick={handleCloseClick} variant="outlined">
            Cancelar
          </Button>
          <LoadingButton
            onClick={handleSubmit(onSubmit)}
            disabled={!isDirty}
            type="submit"
            loading={isSavingOrDeletingSupplierUserGroups}
            loadingPosition="start"
            startIcon={<SaveIcon />}
            variant="contained"
          >
            Salvar
          </LoadingButton>
        </Stack>
      </EditFormAction>
      <ConfirmationDialog
        open={isDiscardConfirmationOpen}
        title="Existem dados não salvos"
        actions={[
          {
            name: 'Cancelar',
            buttonProps: {
              autoFocus: true,
              onClick: () => {
                setIsDiscardConfirmationOpen(false);
              },
            },
          },
          {
            name: 'Sair sem Salvar',
            buttonProps: {
              onClick: () => {
                closeForm();
              },
            },
          },
          {
            name: 'Salvar',
            showLoading: true,
            buttonProps: {
              variant: 'contained',
              startIcon: <SaveIcon />,
              onClick: () => {
                setIsDiscardConfirmationOpen(false);
                handleSubmit(onSubmit)();
              },
            },
          },
        ]}
      >
        <Typography>
          Alguns dados foram modificados e ainda não foram salvos.
        </Typography>
        <Typography>Deseja salvá-los antes de fechar a janela?</Typography>
      </ConfirmationDialog>

      <ConfirmationDialog
        open={isDeleteConfirmationOpen}
        title="Confirmação"
        actions={[
          {
            name: 'Cancelar',
            buttonProps: {
              autoFocus: true,
              onClick: () => {
                setIsDeleteConfirmationOpen(false);
              },
            },
          },
          {
            name: 'Excluir',
            showLoading: true,
            buttonProps: {
              variant: 'contained',
              color: 'error',
              startIcon: <DeleteIcon />,
              onClick: () => {
                setIsDeleteConfirmationOpen(false);
                handleDeleteConfirm();
              },
            },
          },
        ]}
      >
        <Alert variant="outlined" severity="error">
          Atenção! Deseja excluir este grupo?
        </Alert>
      </ConfirmationDialog>
    </EditForm>
  );
}
