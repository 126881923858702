import { Models } from 'types';

export const getFilterUrl = (
  destination: string,
  {
    dateType,
    dateExpression,
    startDate,
    endDate,
    externalId,
    idRetailer,
    statuses,
    warrantyStatuses,
    effectType,
  }: Models.LoadPaymentRequestsForSupplierRequest & {
    dateExpression?: string;
  }
) => {
  const dateFilter = `?t=${dateType}&s=${startDate}&e=${endDate}`;
  const dateExpressionFilter = dateExpression ? `&x=${dateExpression}` : '';
  const statusesFilter = statuses
    ? statuses.map((s) => `&ss=${s}`).join('')
    : '';
  const warrantyStatusesFilter = warrantyStatuses
    ? warrantyStatuses.map((s) => `&wss=${s}`).join('')
    : '';
  const effectTypeFilter = effectType ? `&effectType=${effectType}` : '';
  const retailerFilter = idRetailer ? `&idRetailer=${idRetailer}` : '';
  const externalIdFilter = externalId ? `&externalId=${externalId}` : '';
  return `${destination}${dateFilter}${dateExpressionFilter}${effectTypeFilter}${statusesFilter}${warrantyStatusesFilter}${retailerFilter}${externalIdFilter}`;
};
