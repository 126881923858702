import React from 'react';

import { LoadingButton } from '@mui/lab';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import { useAppSelector } from '../../helpers/hooks';

type ConfirmationDialogProps = {
  open: boolean;
  title: string;
  children: React.ReactNode;
  actions: any[];
  isLoading?: boolean;
};

export default function ConfirmationDialog({
  open,
  title,
  children,
  actions,
  isLoading,
}: ConfirmationDialogProps) {
  const { isLoading: appIsLoading } = useAppSelector((state) => state.app);

  return (
    <Dialog
      disableEscapeKeyDown
      maxWidth="xs"
      aria-labelledby="confirmation-dialog-title"
      open={open}
    >
      <DialogTitle id="confirmation-dialog-title" color="text.primary">
        {title}
      </DialogTitle>
      <DialogContent>
        {typeof children === 'string' ? (
          <DialogContentText id="alert-dialog-description">
            {children}
          </DialogContentText>
        ) : (
          children
        )}
      </DialogContent>
      <DialogActions>
        {actions.map((action, index) =>
          action.showLoading ? (
            <LoadingButton
              key={index}
              loading={isLoading !== undefined ? isLoading : appIsLoading}
              loadingPosition="start"
              {...action.buttonProps}
            >
              {action.name}
            </LoadingButton>
          ) : (
            <Button
              disabled={isLoading !== undefined ? isLoading : appIsLoading}
              key={index}
              {...action.buttonProps}
            >
              {action.name}
            </Button>
          )
        )}
      </DialogActions>
    </Dialog>
  );
}
