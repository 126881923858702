import { SnackbarKey, useSnackbar } from 'notistack';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useAppSelector } from '../../helpers/hooks';

import { notifierActions } from './notifier-slice';

let displayed: SnackbarKey[] = [];

const Notifier = () => {
  const dispatch = useDispatch();
  const { notifications } = useAppSelector((state) => state.notifier);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const storeDisplayed = (id: SnackbarKey) => {
    displayed = [...displayed, id];
  };

  const removeDisplayed = (id: SnackbarKey) => {
    displayed = [...displayed.filter((key) => id !== key)];
  };

  useEffect(() => {
    notifications.forEach(
      ({ key, message, options = {}, dismissed = false }) => {
        if (dismissed) {
          // dismiss snackbar using notistack
          closeSnackbar(key);
          return;
        }

        // do nothing if snackbar is already displayed
        if (displayed.includes(key)) return;

        if (message ?? '' !== '') {
          // display snackbar using notistack
          enqueueSnackbar(message, {
            key,
            ...options,
            onExited: (_event, keyToRemove) => {
              // remove this snackbar from redux store
              dispatch(notifierActions.remove(keyToRemove));
              removeDisplayed(keyToRemove);
            },
          });

          // keep track of snackbars that we've displayed
          storeDisplayed(key);
        }
      }
    );
  }, [notifications, closeSnackbar, enqueueSnackbar, dispatch]);

  return <></>;
};

export default Notifier;
