import { Box } from '@mui/material';
import TermsOfServiceRetailer from '../features/onboarding/TermsOfServiceRetailer';
import TermsOfServiceSupplier from '../features/onboarding/TermsOfServiceSupplier';
import { useAppSelector } from '../helpers/hooks';

function Termos() {
  const { company } = useAppSelector((state) => state.app);

  if (!company) return <></>;

  return (
    <Box overflow="auto">
      {company.companyType === 'retailer' ? (
        <TermsOfServiceRetailer />
      ) : (
        <TermsOfServiceSupplier />
      )}
    </Box>
  );
}

export default Termos;
