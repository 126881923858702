import { doc, onSnapshot } from 'firebase/firestore';
import { eventChannel } from 'redux-saga';
import { Models, Service, Supplier } from 'types';
import { apiCall } from '../helpers/api';
import { db, parseDocument } from '../helpers/firebase';

type T = Models.PaymentRequest;
const service: Service = 'supplier';
const resource = 'web/supplier-payment-request';

export class WebSupplierPaymentRequestHelpers {
  public static getById = async (id: string): Promise<T | undefined> => {
    return await apiCall<T>({
      service,
      resource: `${resource}/${id}`,
    });
  };
  public static list = async ({
    dateType,
    startDate,
    endDate,
    statuses,
    warrantyStatuses,
    idRetailer,
    externalId,
    effectType,
  }: Models.LoadPaymentRequestsForSupplierRequest): Promise<T[]> => {
    const dateFilter = `?t=${dateType}&s=${startDate}&e=${endDate}`;
    const statusesFilter = statuses
      ? statuses.map((s) => `&ss=${s}`).join('')
      : '';
    const warrantyStatusesFilter = warrantyStatuses
      ? warrantyStatuses.map((s) => `&wss=${s}`).join('')
      : '';
    const effectTypeFilter = effectType ? `&effectType=${effectType}` : '';
    const retailerFilter = idRetailer ? `&idRetailer=${idRetailer}` : '';
    const externalIdFilter = externalId ? `&externalId=${externalId}` : '';

    return await apiCall<T[]>({
      service,
      resource: `${resource}${dateFilter}${effectTypeFilter}${statusesFilter}${warrantyStatusesFilter}${retailerFilter}${externalIdFilter}`,
    });
  };

  public static create = async (
    payload: Models.WebPaymentRequest
  ): Promise<Models.PaymentRequest> => {
    const createdId = await apiCall<Models.PaymentRequest>({
      service,
      resource,
      payload,
      kyOpts: {
        method: 'post',
      },
    });

    return createdId;
  };

  public static cancel = async (
    payload: Models.WebCancelPaymentRequestPayload
  ) => {
    const { idPaymentRequest } = payload;
    await apiCall({
      service,
      resource: `${resource}/${idPaymentRequest}/cancel`,
      payload,
      kyOpts: {
        method: 'post',
      },
    });
  };

  public static archive = async (
    payload: Models.WebArchivePaymentRequestPayload
  ) => {
    const { idPaymentRequest } = payload;
    await apiCall({
      service,
      resource: `${resource}/${idPaymentRequest}/archive`,
      payload,
      kyOpts: {
        method: 'post',
      },
    });
  };

  public static delete = async (idPaymentRequest: string) => {
    await apiCall({
      service,
      resource: `${resource}/${idPaymentRequest}`,
      kyOpts: {
        method: 'delete',
      },
    });
  };

  public static refresh = async (idPaymentRequest: string) => {
    await apiCall({
      service,
      resource: `${resource}/${idPaymentRequest}/refresh`,
      kyOpts: {
        method: 'post',
      },
    });
  };

  public static calcSuggestedValues = async (
    payload: Supplier.CalcBalanceOpts
  ): Promise<Supplier.CalcBalanceResult> => {
    return await apiCall({
      service,
      resource: `${resource}/calc-suggested-values`,
      payload,
      kyOpts: {
        method: 'post',
      },
    });
  };

  public static createRealtimeChannel = (id: string) => {
    return eventChannel((emitter) => {
      const unsubscribe = onSnapshot(
        doc(db, 'payment-requests-realtime-data', id),
        (doc) => {
          const emptyDoc: Models.PaymentRequestRealtimeData = {
            id,
          };
          if (!doc.exists) {
            emitter(emptyDoc);
            return;
          }
          const updatedDoc = parseDocument(
            doc.data()
          ) as Models.PaymentRequestRealtimeData;
          emitter(updatedDoc ?? emptyDoc);
        }
      );
      return unsubscribe;
    });
  };
}
