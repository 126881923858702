import { Box, CircularProgress, Stack, useTheme } from '@mui/material';

function PageLoader() {
  const theme = useTheme();

  const img =
    theme.palette.mode === 'dark'
      ? '/PR_LogoAbrev-FundoEscuro.png'
      : '/PR_LogoAbrev-FundoBranco.png';
  return (
    <Stack
      flexGrow={1}
      direction="column"
      alignItems="center"
      justifyContent="center"
      height="100dvh"
    >
      <Stack
        flexGrow={1}
        marginTop="64px"
        alignItems="center"
        justifyContent="center"
      >
        <Box sx={{ position: 'relative', display: 'inline-flex' }}>
          <CircularProgress size={280} thickness={0.5} />
          <Box
            sx={{
              top: 0,
              left: 0,
              bottom: 0,
              right: 0,
              position: 'absolute',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <img src={img} height="96px" />
          </Box>
        </Box>
      </Stack>
    </Stack>
  );
}

export default PageLoader;
