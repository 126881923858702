import './fonts/OpenSans/OpenSans-Bold.ttf';
import './fonts/OpenSans/OpenSans-Regular.ttf';
import './fonts/OpenSans/OpenSans-SemiBold.ttf';
import './styles/main.css';
import './styles/variables.css';

import { SnackbarProvider } from 'notistack';
import { Auth0ProviderWithNavigate } from './Auth0ProviderWithNavigate';
import Notifier from './features/notifier/Notifier';

//...
import { CssBaseline, ThemeProvider } from '@mui/material';
import { useEffect } from 'react';
import {
  Route,
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements,
  redirect,
  useLocation,
} from 'react-router-dom';
import { AuthenticationGuard } from './AuthenticationGuard';
import { Crumb } from './components/NavigationHeader/NavigationHeader';
import FornecedorExtrato from './features/admin/FornecedorExtrato/FornecedorExtrato';
import ListaFornecedores from './features/admin/ListaFormecedores/ListaFornecedores';
import NovoFornecedor from './features/admin/NovoFornecedor/NovoFornecedor';
import { App } from './features/app/App';
import { Onboarding } from './features/app/Onboarding';
import { appActions } from './features/app/app-slice';
import VarejistaAgendas from './features/retailer/VarejistaAgendas/VarejistaAgendas';
import VarejistaConsultaPagamento from './features/retailer/VarejistaConsultaPagamento/VarejistaConsultaPagamento';
import VarejistaDashboard from './features/retailer/VarejistaDashboard/VarejistaDashboard';
import VarejistaListaPagamentos from './features/retailer/VarejistaListaPagamentos/VarejistaListaPagamentos';
import Clientes from './features/supplier/Clientes/Clientes';
import FornecedorAgendas from './features/supplier/FornecedorAgendas/FornecedorAgendas';
import FornecedorConfirmaPagamento from './features/supplier/FornecedorConfirmaPagamento/FornecedorConfirmaPagamento';
import FornecedorConsultaPagamentoVarejista from './features/supplier/FornecedorConsultaPagamentoVarejista/FornecedorConsultaPagamentoVarejista';
import FornecedorContaConsolidadora from './features/supplier/FornecedorContaConsolidadora/FornecedorContaConsolidadora';
import FornecedorDashboard from './features/supplier/FornecedorDashboard/FornecedorDashboard';
import FornecedorExtratoVarejista from './features/supplier/FornecedorExtratoVarejista/FornecedorExtratoVarejista';
import FornecedorListaLiquidacoes from './features/supplier/FornecedorListaLiquidacoes/FornecedorListaLiquidacoes';
import FornecedorListaPagamentos from './features/supplier/FornecedorListaPagamentos/FornecedorListaPagamentos';
import FornecedorNovoCliente from './features/supplier/FornecedorNovoCliente/FornecedorNovoCliente';
import { useAppDispatch, useAppSelector } from './helpers/hooks';
import { darkTheme, lightTheme } from './helpers/themes';
import Callback from './pages/Callback';
import EmailNotVerified from './pages/EmailNotVerified';
import Login from './pages/Login';
import Logout from './pages/Logout';
import NoInvite from './pages/NoInvite';
import NotFound from './pages/NotFound';
import Pending from './pages/Pending';
import Privacidade from './pages/Privacidade';
import Termos from './pages/Termos';
import Unexpected from './pages/Unexpected';
import UnknownUser from './pages/UnknownUser';
import WrongEmail from './pages/WrongEmail';

const Layout = () => {
  const theme = useAppSelector((state) => state.app.theme);
  let location = useLocation();
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(appActions.changeLocation());
  }, [location]);

  return (
    <Auth0ProviderWithNavigate>
      <ThemeProvider theme={theme && theme === 'dark' ? darkTheme : lightTheme}>
        <CssBaseline />
        <SnackbarProvider maxSnack={3}>
          <Notifier />
          <App />
        </SnackbarProvider>
      </ThemeProvider>
    </Auth0ProviderWithNavigate>
  );
};

export const Main = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(appActions.init());
  }, []);

  const company = useAppSelector((state) => state.app.company);

  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route>
        <Route path="/logout" element={<Logout />} />
        <Route element={<Layout />}>
          <Route
            index
            element={<></>}
            loader={async () => {
              if (!company) return null;
              if (company.companyType === 'retailer')
                return redirect('/varejista');
              if (company.companyType === 'supplier')
                return redirect('/dashboard');
            }}
          />
          <Route
            path="/unexpected"
            element={<AuthenticationGuard component={Unexpected} />}
          />

          <Route path="/login" element={<Login />} />
          <Route path="/termos" element={<Termos />} />
          <Route path="/privacidade" element={<Privacidade />} />
          <Route path="/no-invite" element={<NoInvite />} />
          <Route path="/wrong-email" element={<WrongEmail />} />
          <Route path="/unknown-user" element={<UnknownUser />} />
          <Route
            path="/email-not-verified"
            element={<AuthenticationGuard component={EmailNotVerified} />}
          />
          <Route
            path="/pending"
            element={<AuthenticationGuard component={Pending} />}
          />
          <Route
            path="/onboarding"
            element={<AuthenticationGuard component={Onboarding} />}
          />
          <Route
            path="/varejista"
            element={<AuthenticationGuard component={VarejistaDashboard} />}
          />
          <Route
            path="/dashboard"
            element={<AuthenticationGuard component={FornecedorDashboard} />}
          />
          <Route
            path="/agenda"
            element={<AuthenticationGuard component={VarejistaAgendas} />}
          />
          <Route
            path="/conta-consolidadora"
            element={
              <AuthenticationGuard component={FornecedorContaConsolidadora} />
            }
          />
          <Route
            path="/clientes"
            handle={{
              crumb: () =>
                ({
                  type: 'default',
                  label: 'Clientes',
                } as Crumb),
            }}
          >
            <Route
              index
              element={<AuthenticationGuard component={Clientes} />}
            />
            <Route
              path=":idRetailer"
              handle={{
                crumb: () =>
                  ({
                    type: 'selectedRetailer',
                  } as Crumb),
              }}
            >
              <Route
                path="extrato"
                element={
                  <AuthenticationGuard component={FornecedorExtratoVarejista} />
                }
                handle={{
                  crumb: () =>
                    ({
                      type: 'default',
                      label: 'Extrato',
                    } as Crumb),
                }}
              />
              <Route
                path="agenda"
                handle={{
                  crumb: () =>
                    ({
                      type: 'default',
                      label: 'Agenda',
                    } as Crumb),
                }}
              >
                <Route
                  index
                  element={
                    <AuthenticationGuard component={FornecedorAgendas} />
                  }
                />
                <Route
                  path="confirma-pagamento"
                  element={
                    <AuthenticationGuard
                      component={FornecedorConfirmaPagamento}
                    />
                  }
                  handle={{
                    crumb: () =>
                      ({
                        type: 'default',
                        label: 'Confirmação',
                      } as Crumb),
                  }}
                />
              </Route>
            </Route>
            <Route
              path="novo"
              element={
                <AuthenticationGuard component={FornecedorNovoCliente} />
              }
              handle={{
                crumb: () =>
                  ({
                    type: 'default',
                    label: 'Novo',
                  } as Crumb),
              }}
            />
          </Route>
          <Route
            path="/fornecedores"
            handle={{
              crumb: () =>
                ({
                  type: 'default',
                  label: 'Fornecedores',
                } as Crumb),
            }}
          >
            <Route
              index
              element={
                <AuthenticationGuard
                  component={ListaFornecedores}
                  requiredRoles={['adm', 'dev']}
                />
              }
            />
            <Route
              path=":idSupplier"
              handle={{
                crumb: () =>
                  ({
                    type: 'selectedSupplier',
                  } as Crumb),
              }}
            >
              <Route
                path="extrato"
                element={
                  <AuthenticationGuard
                    component={FornecedorExtrato}
                    requiredRoles={['adm', 'dev']}
                  />
                }
                handle={{
                  crumb: () =>
                    ({
                      type: 'default',
                      label: 'Extrato',
                    } as Crumb),
                }}
              />
            </Route>
            <Route
              path="novo"
              element={
                <AuthenticationGuard
                  component={NovoFornecedor}
                  requiredRoles={['adm', 'dev']}
                />
              }
              handle={{
                crumb: () =>
                  ({
                    type: 'default',
                    label: 'Novo',
                  } as Crumb),
              }}
            />
          </Route>
          <Route
            path="pagamentos"
            handle={{
              crumb: () =>
                ({
                  type: 'default',
                  label: 'Pagamentos',
                } as Crumb),
            }}
          >
            <Route
              index
              element={
                <AuthenticationGuard component={FornecedorListaPagamentos} />
              }
            />
            <Route
              path=":idRetailer/:idPaymentRequest"
              handle={{
                crumb: () =>
                  ({
                    type: 'selectedPaymentRequest',
                  } as Crumb),
              }}
              element={
                <AuthenticationGuard
                  component={FornecedorConsultaPagamentoVarejista}
                />
              }
            />
          </Route>
          <Route
            path="varejista-pagamentos"
            handle={{
              crumb: () =>
                ({
                  type: 'default',
                  label: 'Pagamentos',
                } as Crumb),
            }}
          >
            <Route
              index
              element={
                <AuthenticationGuard component={VarejistaListaPagamentos} />
              }
            />
            <Route
              path=":idPaymentRequest"
              handle={{
                crumb: () =>
                  ({
                    type: 'selectedPaymentRequest',
                  } as Crumb),
              }}
              element={
                <AuthenticationGuard component={VarejistaConsultaPagamento} />
              }
            />
          </Route>
          <Route
            path="liquidacoes"
            handle={{
              crumb: () =>
                ({
                  type: 'default',
                  label: 'Liquidações',
                } as Crumb),
            }}
          >
            <Route
              index
              element={
                <AuthenticationGuard component={FornecedorListaLiquidacoes} />
              }
            />
            <Route
              path=":idRetailer/:idPaymentRequest"
              handle={{
                crumb: () =>
                  ({
                    type: 'selectedPaymentRequest',
                  } as Crumb),
              }}
              element={
                <AuthenticationGuard
                  component={FornecedorConsultaPagamentoVarejista}
                />
              }
            />
          </Route>
          <Route path="/callback" element={<Callback />} />
          <Route path="*" element={<NotFound />} />
        </Route>
      </Route>
    )
  );

  return <RouterProvider router={router} />;
};
