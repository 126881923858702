import { Models, Service } from 'types';
import { apiCall } from '../helpers/api';

type T = Models.PaymentRequestDetail;
const service: Service = 'supplier';
const resource = 'web/payment-request-detail';

export class WebPaymentRequestDetailHelpers {
  // public static getById = async (id: string): Promise<T | undefined> => {
  //   return await apiCall<T>({
  //     service,
  //     resource: `${resource}/${id}`,
  //   });
  // };
  public static list = async (
    statuses: Models.SettlementStatus[],
    startDate: string,
    endDate: string,
    whereField?: 'idPaymentRequest' | 'idRetailer',
    whereValue?: string
  ): Promise<T[]> => {
    const dateFilter = `?s=${startDate}&e=${endDate}`;
    const statusesFilter = statuses.map((s) => `&ss=${s}`).join('');
    const filter =
      whereField && whereValue ? `&f=${whereField}&v=${whereValue}` : '';
    return await apiCall<T[]>({
      service,
      resource: `${resource}${dateFilter}${statusesFilter}${filter}`,
    });
  };

  public static listForSupplier = async ({
    startDate,
    endDate,
    statuses,
    idRetailer,
  }: Models.LoadPaymentRequestDetailsForSupplierRequest): Promise<T[]> => {
    const dateFilter = `?s=${startDate}&e=${endDate}`;
    const statusesFilter = statuses
      ? statuses.map((s) => `&ss=${s}`).join('')
      : '';
    const retailerFilter = idRetailer ? `&idRetailer=${idRetailer}` : '';

    return await apiCall<T[]>({
      service,
      resource: `${resource}/list-for-supplier${dateFilter}${statusesFilter}${retailerFilter}`,
    });
  };

  // public static create = async (
  //   newData: Omit<T, 'id' | 'dateadded'>
  // ): Promise<string> => {
  //   const { id } = await apiCall<Pick<T, 'id'>>({
  //     service,
  //     resource: `${resource}`,
  //     payload: newData,
  //     kyOpts: { method: 'post' },
  //   });
  //   return id;
  // };
  // public static batchCreate = async (
  //   newData: Omit<T, 'id' | 'dateadded'>[]
  // ) => {
  //   await apiCall<Pick<T, 'id'>>({
  //     service,
  //     resource: `${resource}/batch-create`,
  //     payload: newData,
  //     kyOpts: { method: 'post' },
  //   });
  // };
  // public static patch = async (id: string, dataToUpdate: Partial<T>) => {
  //   await apiCall<Pick<T, 'id'>>({
  //     service,
  //     resource: `${resource}/${id}`,
  //     payload: dataToUpdate,
  //     kyOpts: { method: 'patch' },
  //   });
  //   return id;
  // };
}
