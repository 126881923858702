import ErrorIcon from '@mui/icons-material/Error';

import { Chip } from '@mui/material';
import { Constants } from 'pr-constants';
import { ReactElement } from 'react';
import { Models } from 'types';

type StatusIconProps = {
  status: Models.PaymentRequestWarrantyExecutionStatus;
};
export const WarrantyExecutionStatus = ({ status }: StatusIconProps) => {
  let icon: ReactElement | undefined;
  let color:
    | 'error'
    | 'default'
    | 'success'
    | 'primary'
    | 'secondary'
    | 'info'
    | 'warning'
    | undefined;
  switch (status) {
    case 'executing':
      icon = <ErrorIcon />;
      color = 'error';
      break;
    case 'near_due_date':
      color = 'warning';
      break;
    case 'overdue':
      icon = <ErrorIcon />;
      color = 'warning';
      break;
    case 'on_schedule':
      color = 'primary';
      break;
  }

  return (
    <Chip
      size="small"
      variant="filled"
      icon={icon}
      color={color}
      label={Constants.warrantyStatus[status]}
    />
  );
};
