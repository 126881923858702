import { Alert, Typography } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import React from 'react';
import { PaymentRequestStatus } from '../../../components/PaymentRequestStatus/PaymentRequestStatus';
import { WarrantyExecutionStatus } from '../../../components/WarrantyExecutionStatus/WarrantyExecutionStatus';
import { format } from '../../../helpers/format';
import { useAppSelector } from '../../../helpers/hooks';
import {
  getWarrantyExecutionDate,
  getWarrantyExecutionStatus,
} from '../../../helpers/payment-request-status';

type DetailProps = {
  label: string;
  value?: string;
  children?: React.ReactNode;
};

const Detail = ({ label, value, children }: DetailProps) => (
  <>
    <Typography variant="body2" color="textSecondary" component="p">
      {label}:
    </Typography>
    {value && (
      <Typography variant="body1" color="textPrimary" component="p">
        {' '}
        {value ?? '-'}
      </Typography>
    )}
    {children}
  </>
);

function PaymentRequestInfo() {
  const { selectedPaymentRequest, isLoadingSelectedPaymentRequest, suppliers } =
    useAppSelector((state) => state.retailer);

  const { isDebugActivated } = useAppSelector((state) => state.devTools);
  const supplier = suppliers.find(
    (r) => r.id === selectedPaymentRequest?.idSupplier
  );

  const supplierInfo = `${supplier?.fantasy ?? supplier?.name ?? '-'} - ${
    format(supplier?.docNumber, 'docNumber') ?? '-'
  }`;

  const mainContactInfo = `${supplier?.mainContactName ?? '-'} - ${
    format(supplier?.mainContactPhone, 'phone') ?? '-'
  } - ${supplier?.mainContactEmail ?? '-'}`;

  //   Cliente
  // Operação
  // ID do pedido
  // Data do Pedido
  // Data de Vencimento
  // Data de Início
  // Valor Total
  // Status
  // Prioridade

  const warrantyExecutionStatus = selectedPaymentRequest
    ? getWarrantyExecutionStatus(selectedPaymentRequest)
    : undefined;

  const executionAlert = warrantyExecutionStatus === 'executing';
  const nearDueDateAlert = warrantyExecutionStatus === 'near_due_date';
  const overdueAlert = warrantyExecutionStatus === 'overdue';

  return (
    <Grid2 container spacing={2}>
      {!isLoadingSelectedPaymentRequest && (
        <>
          {nearDueDateAlert && (
            <Grid2 xs={12}>
              <Alert severity="warning" variant="outlined">
                Pagamento próximo ao vencimento. Faça o pagamento para evitar a
                execução da garantia!
              </Alert>
            </Grid2>
          )}
          {overdueAlert && (
            <Grid2 xs={12}>
              <Alert severity="warning" variant="outlined">
                Pagamento vencido. Faça o pagamento para evitar a execução da
                garantia!
              </Alert>
            </Grid2>
          )}
          {executionAlert && (
            <Grid2 xs={12}>
              <Alert severity="error" variant="filled">
                Garantia em execução. Não é mais possível fazer o pagamento do
                pedido!
              </Alert>
            </Grid2>
          )}
        </>
      )}
      <Grid2 xs={4}>
        <Detail label="Fornecedor" value={supplier ? supplierInfo : '...'} />
      </Grid2>
      <Grid2 xs={8}>
        <Detail
          label="Responsável"
          value={supplier ? mainContactInfo : '...'}
        />
      </Grid2>
      <Grid2 xs={4}>
        <Detail
          label="Número do Contrato"
          value={
            isLoadingSelectedPaymentRequest
              ? '...'
              : selectedPaymentRequest?.id ?? '-'
          }
        />
      </Grid2>
      <Grid2 xs={4}>
        <Detail
          label="Operação"
          value={
            isLoadingSelectedPaymentRequest
              ? '...'
              : selectedPaymentRequest?.effectType === 'garantia'
              ? 'Garantia'
              : 'Troca de Titularidade' ?? '-'
          }
        />
      </Grid2>
      <Grid2 xs={4}>
        <Detail
          label="ID do pedido"
          value={
            isLoadingSelectedPaymentRequest
              ? '...'
              : selectedPaymentRequest?.externalId ?? '-'
          }
        />
      </Grid2>
      <Grid2 xs={4}>
        <Detail
          label="Data do Pedido"
          value={
            isLoadingSelectedPaymentRequest
              ? '...'
              : format(selectedPaymentRequest?.dateadded, 'date') ?? '-'
          }
        />
      </Grid2>
      <Grid2 xs={4}>
        <Detail
          label="Data de Vencimento"
          value={
            isLoadingSelectedPaymentRequest
              ? '...'
              : selectedPaymentRequest?.effectType === 'garantia'
              ? format(selectedPaymentRequest?.dueDate, 'date') ?? '-'
              : '-'
          }
        />
      </Grid2>
      <Grid2 xs={4}>
        <Detail
          label="Data de Execução"
          value={
            isLoadingSelectedPaymentRequest
              ? '...'
              : selectedPaymentRequest
              ? format(
                  getWarrantyExecutionDate(selectedPaymentRequest),
                  'date'
                ) ?? '-'
              : '-'
          }
        />
      </Grid2>
      <Grid2 xs={4}>
        <Detail
          label="Valor do Pedido"
          value={
            isLoadingSelectedPaymentRequest
              ? '...'
              : format(selectedPaymentRequest?.originalValue, 'money') ?? '-'
          }
        />
      </Grid2>
      <Grid2 xs={4}>
        <Detail
          label="Valor do Pagamento"
          value={
            isLoadingSelectedPaymentRequest
              ? '...'
              : format(selectedPaymentRequest?.totalValue, 'money') ?? '-'
          }
        />
      </Grid2>
      <Grid2 xs={4}>
        <Detail
          label="Status do Registro"
          value={isLoadingSelectedPaymentRequest ? '...' : ''}
        >
          {isLoadingSelectedPaymentRequest ? (
            <></>
          ) : selectedPaymentRequest?.status ? (
            <PaymentRequestStatus status={selectedPaymentRequest?.status} />
          ) : (
            <></>
          )}
        </Detail>
      </Grid2>
      {selectedPaymentRequest?.status === 'ativo' &&
        !!warrantyExecutionStatus && (
          <Grid2 xs={4}>
            <Detail
              label="Status da Garantia"
              value={isLoadingSelectedPaymentRequest ? '...' : ''}
            >
              {isLoadingSelectedPaymentRequest ? (
                <></>
              ) : warrantyExecutionStatus ? (
                <WarrantyExecutionStatus status={warrantyExecutionStatus} />
              ) : (
                <></>
              )}
            </Detail>
          </Grid2>
        )}

      {(selectedPaymentRequest?.status === 'aprovado' ||
        selectedPaymentRequest?.status === 'ativo') && (
        <>
          <Grid2 xs={4}>
            <Detail
              label="Confirmado em"
              value={
                isLoadingSelectedPaymentRequest
                  ? '...'
                  : format(selectedPaymentRequest?.dateConfirmed, 'dateTime') ??
                    '-'
              }
            />
          </Grid2>
          <Grid2 xs={4}>
            <Detail
              label="Confirmado por"
              value={
                isLoadingSelectedPaymentRequest
                  ? '...'
                  : selectedPaymentRequest?.confirmedByName ??
                    selectedPaymentRequest?.confirmedByEmail ??
                    '-'
              }
            />
          </Grid2>
        </>
      )}
      {selectedPaymentRequest?.status === 'nao_aprovado' && (
        <>
          <Grid2 xs={4}>
            <Detail
              label="Não aprovado em"
              value={
                isLoadingSelectedPaymentRequest
                  ? '...'
                  : format(selectedPaymentRequest?.dateRejected, 'dateTime') ??
                    '-'
              }
            />
          </Grid2>
          <Grid2 xs={4}>
            <Detail
              label="Não aprovado por"
              value={
                isLoadingSelectedPaymentRequest
                  ? '...'
                  : selectedPaymentRequest?.rejectedByName ??
                    selectedPaymentRequest?.rejectedByEmail ??
                    '-'
              }
            />
          </Grid2>
          <Grid2 xs={4}>
            <Detail
              label="Motivo da não aprovação"
              value={
                isLoadingSelectedPaymentRequest
                  ? '...'
                  : selectedPaymentRequest?.rejectedReason ?? '-'
              }
            />
          </Grid2>
        </>
      )}
      {isDebugActivated && (
        <Grid2 xs={4}>
          <Detail
            label="Prioridade"
            value={
              isLoadingSelectedPaymentRequest
                ? '...'
                : selectedPaymentRequest?.effectPriority?.toString() ?? '-'
            }
          />
        </Grid2>
      )}
    </Grid2>
  );
}

export default PaymentRequestInfo;
