import CheckIcon from '@mui/icons-material/Check';
import DeleteIcon from '@mui/icons-material/Delete';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';

import { Box, Card, IconButton, Stack, Typography } from '@mui/material';
import { format } from '../../../helpers/format';
import { MarkedConciliation } from '../supplier.types';

type ConciliationCardProps = {
  conciliation: MarkedConciliation;
  onClick: (_c: MarkedConciliation) => void;
};

export default function ConciliationCard({
  conciliation,
  onClick,
}: ConciliationCardProps) {
  const { settlements, status, transactions } = conciliation;
  const pedidos = settlements.map(({ idPedido }) => idPedido);
  const valorLiquidacoes = settlements.reduce((acc, cur) => {
    acc += cur.valor;
    return acc;
  }, 0);
  const valorTransacoes = transactions.reduce((acc, cur) => {
    acc += cur.valor;
    return acc;
  }, 0);
  return (
    <Card variant="outlined">
      <Stack direction="row" gap={2} alignItems="center" paddingRight={2}>
        <Box p={2}>
          <Stack direction="row" gap={1} alignItems="center">
            {status === 'finalizado' ? (
              <CheckIcon fontSize="small" color="success" />
            ) : (
              <PriorityHighIcon fontSize="small" color="error" />
            )}
            <Box>
              <Typography variant="caption" color="text.secondary">
                ID Pedido{pedidos.length > 1 ? 's' : ''}:{' '}
              </Typography>
              <Typography variant="body2" component="span">
                {pedidos.join(', ')}
              </Typography>
            </Box>
          </Stack>
          <Typography variant="h6">
            {status === 'finalizado'
              ? format(valorLiquidacoes, 'money')
              : `${format(valorLiquidacoes, 'money')} ≠ ${format(
                  valorTransacoes,
                  'money'
                )}`}
          </Typography>
        </Box>
        <IconButton onClick={() => onClick(conciliation)}>
          <DeleteIcon />
        </IconButton>
      </Stack>
    </Card>
  );
}
