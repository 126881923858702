import SearchIcon from '@mui/icons-material/Search';
import { Badge, Chip, IconButton, Link, Stack } from '@mui/material';
import { GridEventListener } from '@mui/x-data-grid';
import dayjs from 'dayjs';
import { Constants } from 'pr-constants';
import { MouseEvent, useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Models } from 'types';
import FilterForm from '../../../components/FilterForm/FilterForm';
import { OptionsFilterValues } from '../../../components/OptionsFilter/OptionsFilter';
import { PageHeader } from '../../../components/PageHeader/PageHeader';
import { PaymentRequestStatus } from '../../../components/PaymentRequestStatus/PaymentRequestStatus';
import DataTable from '../../../components/Table/DataTable';
import { TableColumn } from '../../../components/Table/table.types';
import { WarrantyExecutionStatus } from '../../../components/WarrantyExecutionStatus/WarrantyExecutionStatus';
import { getFirestoreConsoleLink } from '../../../helpers/firebase';
import { format } from '../../../helpers/format';
import { useAppDispatch, useAppSelector } from '../../../helpers/hooks';
import {
  getPaymentRequestStatus,
  getWarrantyExecutionDate,
  getWarrantyExecutionStatus,
} from '../../../helpers/payment-request-status';
import { FABNewPaymentRequest } from '../components/FABNewPaymentRequest';
import { supplierActions } from '../supplier.slice';
import {
  ListaPagamentoFornecedor,
  ListaPagamentosFilterForm,
} from '../supplier.types';
import { getFilterUrl } from './get-filter-url';

const dateTypeOptions = [
  {
    value: 'dateadded',
    label: 'Data do Pedido',
  },
  {
    value: 'dueDate',
    label: 'Data de Vencimento',
  },
  {
    value: 'firstIncomingDate',
    label: 'Data de Início',
  },
];

function FornecedorListaPagamentos() {
  const navigate = useNavigate();
  const { paymentRequests, retailers, isLoadingPaymentRequests } =
    useAppSelector((state) => state.supplier);
  const { isDebugActivated } = useAppSelector((state) => state.devTools);

  const dispatch = useAppDispatch();
  const [params] = useSearchParams();
  const idRetailer = params.get('idRetailer');
  const externalId = params.get('externalId');
  const effectType = params.get('effectType');
  const statuses = params.getAll(
    'ss'
  ) as unknown as Models.PaymentRequestStatus[];
  const warrantyStatuses = params.getAll(
    'wss'
  ) as unknown as Models.PaymentRequestWarrantyExecutionStatus[];
  const dateType = params.get('t');
  const startDate = params.get('s');
  const endDate = params.get('e');
  const defaultDateType: Models.LoadPaymentRequestsForSupplierRequestDateType =
    'dateadded';
  const defaultStartDate = dayjs().startOf('month');
  const defaultEndDate = dayjs().endOf('month');
  const dateExpression = params.get('x') ?? undefined;
  const queryParams: Models.LoadPaymentRequestsForSupplierRequest = {
    dateType: (dateType ??
      defaultDateType) as Models.LoadPaymentRequestsForSupplierRequestDateType,
    startDate: startDate ?? defaultStartDate.format('YYYYMMDD'),
    endDate: endDate ?? defaultEndDate.format('YYYYMMDD'),
    idRetailer: idRetailer ?? undefined,
    externalId: externalId ?? undefined,
    effectType: effectType ?? undefined,
    statuses,
    warrantyStatuses,
  };

  const dateTypeLabel =
    dateTypeOptions.find(({ value }) => value === queryParams.dateType)
      ?.label ?? 'Data';
  const currentDateRangeLabel =
    queryParams.startDate !== queryParams.endDate
      ? `${dayjs(queryParams.startDate).format('DD/MM/YYYY')} a ${dayjs(
          queryParams.endDate
        ).format('DD/MM/YYYY')}`
      : dayjs(queryParams.startDate).format('DD/MM/YYYY');
  const currentDateRangeFilter = `${dateTypeLabel}: ${currentDateRangeLabel}`;
  const filteredRetailer = retailers.find(
    (r) => r.id === queryParams.idRetailer
  );
  const currentRetailerFilter = filteredRetailer
    ? `Cliente: ${filteredRetailer.fantasy ?? filteredRetailer.name}`
    : '';
  const currentEffectTypeFilter = `Operação: ${
    queryParams.effectType === 'garantia'
      ? 'Garantia'
      : queryParams.effectType === 'transferencia'
      ? 'Troca de Titularidade'
      : ''
  }`;
  const [currentStatus] = statuses;
  const currentStatusFilter = `Status Registro: ${
    currentStatus ? getPaymentRequestStatus(currentStatus) : ''
  }`;

  const [currentWarrantyStatus] = warrantyStatuses;
  const currentWarrantyStatusFilter = `Status Garantia: ${
    currentWarrantyStatus ? Constants.warrantyStatus[currentWarrantyStatus] : ''
  }`;

  const currentExternalIdFilter = queryParams.externalId
    ? `ID do pedido: ${queryParams.externalId}`
    : '';

  const appliedFilterCount =
    (queryParams.idRetailer ? 1 : 0) +
    (queryParams.effectType ? 1 : 0) +
    (currentStatus ? 1 : 0) +
    (currentWarrantyStatus ? 1 : 0) +
    (queryParams.externalId ? 1 : 0);

  useEffect(() => {
    dispatch(supplierActions.loadPaymentRequests(queryParams));
  }, []);

  const handleFilter = (data: ListaPagamentosFilterForm) => {
    navigate(
      getFilterUrl('.', {
        dateType:
          data.dateType as Models.LoadPaymentRequestsForSupplierRequestDateType,
        dateExpression:
          data.dateExpression as Models.LoadPaymentRequestsForSupplierRequestDateExpression,
        startDate: dayjs(data.startDate).format('YYYYMMDD'),
        endDate: dayjs(data.endDate).format('YYYYMMDD'),
        statuses:
          data.status === '_all'
            ? []
            : [data.status as Models.PaymentRequestStatus], //value,
        warrantyStatuses:
          data.warrantyStatus === '_all'
            ? []
            : [
                data.warrantyStatus as Models.PaymentRequestWarrantyExecutionStatus,
              ], //value,
        idRetailer: data.idRetailer === '_all' ? undefined : data.idRetailer,
        externalId: data.externalId,
        effectType: data.effectType === '_all' ? undefined : data.effectType,
      })
    );
  };

  const columns: TableColumn<ListaPagamentoFornecedor>[] = [
    {
      id: 'id',
      label: 'id',
      hidden: !isDebugActivated,
      renderer: (v) => (
        <Link
          target="_blank"
          rel="noopener noreferrer"
          variant="caption"
          color="textSecondary"
          href={getFirestoreConsoleLink(`/payment-requests/${v}`)}
        >
          {v}
        </Link>
      ),
    },
    { id: 'cliente', label: 'Cliente' },
    { id: 'operacao', label: 'Operação', center: true },
    { id: 'externalId', label: 'ID do pedido', center: true },
    { id: 'dataPedido', label: 'Data do Pedido', type: 'date', center: true },
    {
      id: 'dataVencimento',
      label: 'Data de Vencimento',
      center: true,
      formatter: (v, row) => {
        if (row.effectType === 'transferencia') return '-';

        return v !== undefined ? format(v, 'date') : '-';
      },
    },
    {
      id: 'dataExecucao',
      label: 'Data de Execução',
      type: 'date',
      center: true,
    },
    { id: 'valorTotal', label: 'Valor Total', type: 'money', center: true },
    {
      id: 'status',
      label: 'Status Registro',
      center: true,
      renderer: (v) => <PaymentRequestStatus status={v} />,
    },
    {
      id: 'prioridade',
      label: 'Prioridade',
      center: true,
      hidden: !isDebugActivated,
    },
    {
      id: 'statusExecucaoGarantia',
      label: 'Status Garantia',
      center: true,
      renderer: (v, row) =>
        row.status === 'ativo' && <WarrantyExecutionStatus status={v} />,
    },
  ];

  const effectTypeFilterOptions: OptionsFilterValues<
    Models.PaymentRequestEffectType | '_all'
  >[] = [
    { label: 'Todos', value: '_all' },
    { label: 'Garantia', value: 'garantia' },
    { label: 'Troca de Titularidade', value: 'transferencia' },
  ];

  const statusFilterOptions: OptionsFilterValues<
    Models.PaymentRequestStatus | '_all'
  >[] = [
    { label: 'Todos', value: '_all' },
    { label: getPaymentRequestStatus('pendente'), value: 'pendente' },
    { label: getPaymentRequestStatus('aprovado'), value: 'aprovado' },
    { label: getPaymentRequestStatus('ativo'), value: 'ativo' },
    { label: getPaymentRequestStatus('cancelado'), value: 'cancelado' },
    { label: getPaymentRequestStatus('finalizado'), value: 'finalizado' },
    { label: getPaymentRequestStatus('nao_aprovado'), value: 'nao_aprovado' },
    { label: getPaymentRequestStatus('rejeitado'), value: 'rejeitado' },
  ];

  const warrantyStatusFilterOptions: OptionsFilterValues<
    Models.PaymentRequestWarrantyExecutionStatus | '_all'
  >[] = [
    { label: 'Todos', value: '_all' },
    { label: Constants.warrantyStatus['on_schedule'], value: 'on_schedule' },
    {
      label: Constants.warrantyStatus['near_due_date'],
      value: 'near_due_date',
    },
    { label: Constants.warrantyStatus['overdue'], value: 'overdue' },
    { label: Constants.warrantyStatus['executing'], value: 'executing' },
  ];

  const companyFilterOptions: OptionsFilterValues<string>[] = [
    { label: 'Todos', value: '_all' },
    ...retailers
      .map((r) => ({ label: r.fantasy ?? r.name, value: r.id }))
      .sort(({ label: a }, { label: b }) => a.localeCompare(b)),
  ];

  const handleDeleteRetailerFilterClick = () => {
    navigate(
      getFilterUrl('.', {
        ...queryParams,
        idRetailer: undefined,
        dateExpression,
      })
    );
  };
  const handleDeleteEffectTypeFilterClick = () => {
    navigate(
      getFilterUrl('.', {
        ...queryParams,
        effectType: undefined,
        dateExpression,
      })
    );
  };
  const handleDeleteStatusFilterClick = () => {
    navigate(
      getFilterUrl('.', {
        ...queryParams,
        statuses: undefined,
        dateExpression,
      })
    );
  };
  const handleDeleteWarrantyStatusFilterClick = () => {
    navigate(
      getFilterUrl('.', {
        ...queryParams,
        warrantyStatuses: undefined,
        dateExpression,
      })
    );
  };
  const handleDeleteExternalIdFilterClick = () => {
    navigate(
      getFilterUrl('.', {
        ...queryParams,
        externalId: undefined,
        dateExpression,
      })
    );
  };
  const handleResetFilter = () => {
    navigate(
      getFilterUrl('.', {
        dateType: queryParams.dateType,
        startDate: queryParams.startDate,
        endDate: queryParams.endDate,
      })
    );
  };

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handleFilterButtonClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleFilterChipClick = () => {
    setAnchorEl(document.getElementById('searchButton'));
  };

  const handleFilterClose = () => {
    setAnchorEl(null);
  };

  const handleRowClick: GridEventListener<'rowClick'> = ({ row }) => {
    // console.log(params);
    navigate(
      getFilterUrl(`${row.idRetailer}/${row.id}`, {
        ...queryParams,
        dateExpression,
      })
    );
  };

  const enhancedData: ListaPagamentoFornecedor[] = paymentRequests
    .map((d) => {
      const retailer = retailers.find((r) => r.id === d.idRetailer);
      return {
        idRetailer: d.idRetailer,
        id: d.id,
        externalId: d.externalId,
        cliente: retailer ? retailer.fantasy ?? retailer.name : '...',
        numero: d.id,
        dataPedido: dayjs(d.dateadded).format('YYYYMMDD'),
        dataVencimento: d.dueDate,
        dataInicio: d.firstIncomingDate,
        operacao:
          d.effectType === 'garantia' ? 'Garantia' : 'Troca de Titularidade',
        valorTotal: d.totalValue,
        status: d.status,
        detalhes: 'pagamento-1',
        effectType: d.effectType,
        prioridade: d.effectPriority,
        statusExecucaoGarantia: getWarrantyExecutionStatus(d),
        dataExecucao: getWarrantyExecutionDate(d),
      };
    })
    .filter((p) => {
      if (!currentWarrantyStatus) return true;
      return (
        p.statusExecucaoGarantia === currentWarrantyStatus &&
        p.status === 'ativo'
      );
    });

  return (
    <>
      <PageHeader
        title="Pagamentos"
        actions={
          <Stack direction="row" gap={2} alignItems="center">
            <Chip
              label={currentDateRangeFilter}
              onClick={handleFilterChipClick}
            />
            {queryParams.idRetailer && (
              <Chip
                label={currentRetailerFilter}
                onClick={handleFilterChipClick}
                onDelete={handleDeleteRetailerFilterClick}
              />
            )}
            {queryParams.effectType && (
              <Chip
                label={currentEffectTypeFilter}
                onClick={handleFilterChipClick}
                onDelete={handleDeleteEffectTypeFilterClick}
              />
            )}
            {currentStatus && (
              <Chip
                label={currentStatusFilter}
                onClick={handleFilterChipClick}
                onDelete={handleDeleteStatusFilterClick}
              />
            )}
            {currentWarrantyStatus && (
              <Chip
                label={currentWarrantyStatusFilter}
                onClick={handleFilterChipClick}
                onDelete={handleDeleteWarrantyStatusFilterClick}
              />
            )}
            {queryParams.externalId && (
              <Chip
                label={currentExternalIdFilter}
                onClick={handleFilterChipClick}
                onDelete={handleDeleteExternalIdFilterClick}
              />
            )}
            <FilterForm<ListaPagamentosFilterForm>
              onFilter={handleFilter}
              onClear={handleResetFilter}
              onClose={handleFilterClose}
              anchorEl={anchorEl}
              appliedFilterCount={appliedFilterCount}
              defaultValues={{
                idRetailer: queryParams.idRetailer ?? '_all',
                effectType: queryParams.effectType ?? '_all',
                status: queryParams.statuses
                  ? queryParams.statuses[0] ?? '_all'
                  : '_all',
                warrantyStatus: queryParams.warrantyStatuses
                  ? queryParams.warrantyStatuses[0] ?? '_all'
                  : '_all',
                externalId: queryParams.externalId ?? '',
                dateType: queryParams.dateType,
                startDate: queryParams.startDate,
                endDate: queryParams.endDate,
                dateExpression: dateExpression,
              }}
              fields={[
                {
                  type: 'multi-date-range',
                  name: 'dateType',
                  startName: 'startDate',
                  endName: 'endDate',
                  formulaName: 'dateExpression',
                  label: 'Data',
                  dateTypeOptions,
                },
                {
                  type: 'options',
                  name: 'idRetailer',
                  label: 'Cliente',
                  options: companyFilterOptions,
                },
                {
                  type: 'options',
                  name: 'effectType',
                  label: 'Operação',
                  options: effectTypeFilterOptions,
                },
                {
                  type: 'options',
                  name: 'status',
                  label: 'Status Registro',
                  options: statusFilterOptions,
                },
                {
                  type: 'options',
                  name: 'warrantyStatus',
                  label: 'Status Garantia',
                  options: warrantyStatusFilterOptions,
                },
                {
                  type: 'text',
                  name: 'externalId',
                  label: 'ID do pedido',
                },
              ]}
            />
            <IconButton id="searchButton" onClick={handleFilterButtonClick}>
              <Badge color="primary" badgeContent={appliedFilterCount}>
                <SearchIcon />
              </Badge>
            </IconButton>
          </Stack>
        }
      />
      <DataTable<ListaPagamentoFornecedor>
        columns={columns}
        data={enhancedData}
        isLoading={isLoadingPaymentRequests}
        onRowClick={handleRowClick}
        marginBottom={12}
      />
      <FABNewPaymentRequest />
    </>
  );
}

export default FornecedorListaPagamentos;
