import dayjs from 'dayjs';
import { Models } from 'types';
import { dayjsBusinessTime } from './operating-hours';

export const getPaymentRequestStatus = (
  status: Models.PaymentRequestStatus
): string => {
  switch (status) {
    case 'aprovado':
      return 'Registrando';
    case 'ativo':
      return 'Confirmado';
    case 'cancelado':
      return 'Cancelado';
    case 'cancelamento_solicitado':
      return 'Cancelamento Solicitado';
    case 'erro':
      return 'Erro';
    case 'finalizado':
      return 'Finalizado';
    case 'nao_aprovado':
      return 'Não Aprovado';
    case 'pendente':
      return 'Pendente';
    case 'rejeitado':
      return 'Rejeitado';
  }
};

export const isNearDueDate = (p: Models.PaymentRequest) => {
  if (p.effectType !== 'garantia') return false;

  return dayjsBusinessTime(p.dueDate).businessDaysDiff(dayjs()) > -2;
};

export const isOverdue = (p: Models.PaymentRequest) => {
  if (p.effectType !== 'garantia') return false;

  return dayjsBusinessTime(p.dueDate).businessDaysDiff(dayjs()) > 0;
};

export const isExecuting = (p: Models.PaymentRequest) => {
  if (p.effectType !== 'garantia') return false;

  return (
    dayjsBusinessTime(getWarrantyExecutionDate(p)).businessDaysDiff(dayjs()) >=
    0
  );
};

export const getWarrantyExecutionStatus = (
  p: Models.PaymentRequest
): Models.PaymentRequestWarrantyExecutionStatus => {
  const nearDueDate = isNearDueDate(p);
  const overdue = isOverdue(p);
  const executing = isExecuting(p);

  const status: Models.PaymentRequestWarrantyExecutionStatus = executing
    ? 'executing'
    : overdue
    ? 'overdue'
    : nearDueDate
    ? 'near_due_date'
    : 'on_schedule';
  return status;
};

export const getWarrantyExecutionDate = (p: Models.PaymentRequest) =>
  dayjsBusinessTime(p.firstIncomingDate)
    .subtractBusinessDays(2)
    .format('YYYYMMDD');
