import { Models, Service } from 'types';
import { apiCall } from '../helpers/api';

const service: Service = 'supplier';
const resource = 'web/target-bank-account';

export class WebTargetBankAccountHelpers {
  // public static getById = async (id: string): Promise<T | undefined> => {
  //   try {
  //     return await apiCall<T>({
  //       service,
  //       resource: `${resource}/${id}`,
  //     });
  //   } catch (e) {
  //     if ((e as unknown as any)?.response?.status === 404) return undefined;
  //     throw e;
  //   }
  // };
  // public static list = async (
  //   companyTypes: Models.CompanyType[],
  //   whereField?: 'idSupplier' | 'idOwner',
  //   whereValue?: string
  // ): Promise<T[]> => {
  //   const params = new URLSearchParams();
  //   for (const type of companyTypes) {
  //     params.append('t', type);
  //   }
  //   if (whereField && whereValue) {
  //     params.append('f', whereField);
  //     params.append('v', whereValue);
  //   }
  //   return await apiCall<T[]>({
  //     service,
  //     resource: `${resource}?${params.toString()}`,
  //   });
  // };
  public static create = async (
    newData: Models.SaveTargetBankAccountPayload
  ): Promise<Models.TargetBankAccount> => {
    const created = await apiCall<Models.TargetBankAccount>({
      service,
      resource: `${resource}`,
      payload: newData,
      kyOpts: { method: 'post' },
    });
    return created;
  };
  public static patch = async ({
    id,
    data,
  }: Models.UpdateTargetBankAccountPayload) => {
    const updated = await apiCall<Models.TargetBankAccount>({
      service,
      resource: `${resource}/${id}`,
      payload: data,
      kyOpts: { method: 'patch' },
    });
    return updated;
  };
}
