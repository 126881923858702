import { Box, Typography } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import React from 'react';
import { useAppSelector } from '../../../helpers/hooks';

type DetailProps = {
  label: string;
  value?: string;
  children?: React.ReactNode;
};
const Detail = ({ label, value, children }: DetailProps) => (
  <Box>
    <Typography variant="caption" color="textSecondary" component="p">
      {label}:
    </Typography>
    {value && (
      <Typography variant="body2" color="textPrimary" component="p">
        {' '}
        {value ?? '-'}
      </Typography>
    )}
    {children}
  </Box>
);

function BankAccountInfo() {
  const { isLoading, selectedSupplierBankAccount } = useAppSelector(
    (state) => state.admin
  );

  const bankAccountInfo = `Banco ${
    selectedSupplierBankAccount?.bankCode
  } - ISPB ${selectedSupplierBankAccount?.ispb} - Agência ${
    selectedSupplierBankAccount?.branch ?? ''
  } - Número ${selectedSupplierBankAccount?.account ?? '-'}-${
    selectedSupplierBankAccount?.accountDigit ?? '-'
  }`;

  return (
    <Grid2 container>
      <Grid2 xs={12}>
        <Detail
          label="ID"
          value={isLoading ? '...' : selectedSupplierBankAccount?.bankId ?? ''}
        />
      </Grid2>
      <Grid2 xs={12}>
        <Detail label="Dados" value={isLoading ? '...' : bankAccountInfo} />
      </Grid2>
    </Grid2>
  );
}

export default BankAccountInfo;
