import { FirebaseOptions, initializeApp } from 'firebase/app';
import { initializeFirestore, Timestamp } from 'firebase/firestore';

import _ from 'lodash';

const config: Record<string, FirebaseOptions> = {
  'paguerecebivel-prod': {
    apiKey: 'AIzaSyB9K0EWk3zEyzWstAQj0vlGSuI_I6aPrEs',
    authDomain: 'paguerecebivel-prod.firebaseapp.com',
    projectId: 'paguerecebivel-prod',
    storageBucket: 'paguerecebivel-prod.appspot.com',
    messagingSenderId: '283755612636',
    appId: '1:283755612636:web:660a1435780f58f93fe719',
  },
  'paguerecebivel-dev': {
    apiKey: 'AIzaSyBI-Fxf2P2kF3hrFgepiRNoIF3hwiCH_Kc',
    authDomain: 'paguerecebivel-dev.firebaseapp.com',
    projectId: 'paguerecebivel-dev',
    storageBucket: 'paguerecebivel-dev.appspot.com',
    messagingSenderId: '730134327921',
    appId: '1:730134327921:web:c0930b111e7ddcd160e1d0',
  },
  'paguerecebivel-stg': {
    apiKey: 'AIzaSyApwP1mMtY0POpwAfPjifXLUUaCsTe0i38',
    authDomain: 'paguerecebivel-stg.firebaseapp.com',
    projectId: 'paguerecebivel-stg',
    storageBucket: 'paguerecebivel-stg.appspot.com',
    messagingSenderId: '84484995387',
    appId: '1:84484995387:web:90fd461ce438887c065f1e',
  },
};

export const getFirestoreConsoleLink = (docPath: string, queryUrl?: string) =>
  `https://console.firebase.google.com/project/${
    process.env.REACT_APP_ENV
  }/firestore/data/${encodeURIComponent(docPath)}${queryUrl ?? ''}`;

const _getQueryUrl = (collection: string, query: string) =>
  `?view=query-view&scopeType=collection&scopeName=%2F${collection}&query=${encodeURIComponent(
    query
  )}`;

export const getFirestoreConsoleLinkOptinsQuery = (
  companyDocNumber: string,
  supplierDocNumber: string
) => {
  const collection = 'optins';
  const companyDocNumberSnippet = `${companyDocNumber.length}/${companyDocNumber}`;
  const supplierDocNumberSnippet = `${supplierDocNumber.length}/${supplierDocNumber}`;
  const query = `3|WH|1|16/companyDocNumber|EQ|STR|${companyDocNumberSnippet}|WH|1|17/supplierDocNumber|EQ|STR|${supplierDocNumberSnippet}|WH|1|6/status|EQ|STR|6/active`;
  const queryUrl = _getQueryUrl(collection, query);
  return getFirestoreConsoleLink(`/${collection}`, queryUrl);
};

export const getFirestoreConsoleLinkReceivablesQuery = (
  companyDocNumber: string
) => {
  const collection = 'receivables';
  const companyDocNumberSnippet = `${companyDocNumber.length}/${companyDocNumber}`;
  const query = `1|WH|1|16/companyDocNumber|EQ|STR|${companyDocNumberSnippet}`;
  const queryUrl = _getQueryUrl(collection, query);
  return getFirestoreConsoleLink(`/${collection}`, queryUrl);
};

export const firebaseApp = initializeApp(
  config[process.env.REACT_APP_ENV ?? 'paguerecebivel-dev']
);

export const db = initializeFirestore(firebaseApp, {
  ignoreUndefinedProperties: true,
});

/**
 * Transforma os Timestamp para o tipo Date
 */
export const parseDocument = (obj: any): any => {
  if (obj === null) return obj;
  if (Array.isArray(obj)) return obj.map((item) => parseDocument(item));
  if (typeof obj === 'object') {
    if (obj instanceof Timestamp) return obj.toDate();
    return _.mapValues(obj, (item: any) => parseDocument(item));
  }
  return obj;
};
