import { Box, Stack, Typography } from '@mui/material';
import React from 'react';
import { Models } from 'types';
import { format } from '../../../helpers/format';
import { useAppSelector } from '../../../helpers/hooks';

type DetailProps = {
  label: string;
  value?: string;
  children?: React.ReactNode;
};
const Detail = ({ label, value, children }: DetailProps) => (
  <Box minWidth="100px">
    <Typography variant="caption" color="textSecondary" component="p">
      {label}:
    </Typography>
    <Typography
      variant="body2"
      color="textPrimary"
      component="p"
      whiteSpace="nowrap"
    >
      {(value ?? '') !== '' ? value : '-'}
    </Typography>
    {children}
  </Box>
);

type PaymentRequestInfoProps = {
  externalId: string;
  effectType: Models.PaymentRequestEffectType;
  paymentDueDate?: string;
  originalValue: number | null;
  sum: number | null;
};

export default function PaymentRequestFormInfo({
  externalId,
  effectType,
  paymentDueDate,
  originalValue,
  sum,
}: PaymentRequestInfoProps) {
  const { supplierWarrantyConfig, supplierOwnershipAssignmentConfig } =
    useAppSelector((state) => state.settings);

  const effectTypes = [
    {
      label: 'Garantia',
      value: 'garantia',
    },
    {
      label: 'Troca de Titularidade',
      value: 'transferencia',
    },
  ];

  if (!supplierWarrantyConfig || !supplierOwnershipAssignmentConfig)
    return <></>;

  const operacao = `${
    effectTypes.find(({ value }) => value === effectType)?.label
  }${
    effectType === 'garantia'
      ? ` (Venc. ${format(paymentDueDate, 'date')})`
      : ''
  }`;

  return (
    <Stack direction="row" gap={2} width={1}>
      <Detail label="Operação" value={operacao} />
      <Detail label="Pedido" value={externalId} />
      <Detail label="Valor" value={format(originalValue, 'money')} />
      <Detail
        label={`Valor c/${
          effectType === 'garantia' ? 'multa e juros' : 'custo de capital'
        }`}
        value={format(sum, 'money')}
      />
    </Stack>
  );
}
