import { FormControl, FormControlLabel } from '@mui/material';
import Switch, { SwitchProps } from '@mui/material/Switch';
import { Control, useController } from 'react-hook-form';

type SwitchFieldProps = SwitchProps & {
  name: string;
  label: string;
  control: Control<any, any>;
};

export const SwitchField = (props: SwitchFieldProps) => {
  const { control, label, name, ...other } = props;
  const {
    field: { onChange, value },
  } = useController({
    name,
    control,
  });

  return (
    <FormControl sx={{ paddingLeft: 1 }}>
      <FormControlLabel
        sx={{ fontSize: '14px' }}
        control={
          <Switch
            {...other}
            onChange={(e) => onChange(e.target.checked)}
            checked={value !== undefined ? value : false}
          />
        }
        label={label}
      />
    </FormControl>
  );
};
